import React from "react";
import { Tabs } from "antd";
import { useParams } from "react-router-dom";
import DealsDetailsSection from "../ISODealsDetails/DealsDetailsSection";
import Stipulations from "../ISODealsDetails/DealStipulation";
import DealsOffersTable from "../ISODealsDetails/DealsOfferTable";
import DealsRepayments from "../ISODealsDetails/DealsRepayments";
const { TabPane } = Tabs;

const IsoNonUserDealsDetails = () => {
  const { id } = useParams();
  const portal = 2;
  const type = 3;
  return (
    <div className="pt-5 mt-5  container advance-details-page">
      <div className="pt-5 table-container">
        <Tabs defaultActiveKey="1">
          <TabPane tab="Deal Details" key="1">
            <DealsDetailsSection id={id} portal={portal} type={type} />
          </TabPane>
          <TabPane tab="Stipulation" key="2">
            <Stipulations id={id} portal={portal} type={type} />
          </TabPane>
          <TabPane tab="Deal Offers" key="3">
            <DealsOffersTable id={id} portal={portal} type={type} />
          </TabPane>
          <TabPane tab="Repayments" key="4">
            <DealsRepayments id={id} portal={portal} type={type} />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default IsoNonUserDealsDetails;
