import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';
export const getSingleStipulationAttachmentStart = () => ({
  type: types.GET_SINGLE_STIPULATION_ATTACHMENT_START
});

export const getSingleStipulationAttachmentSuccess = payload => ({
  type: types.GET_SINGLE_STIPULATION_ATTACHMENT_SUCCESS,
  payload
});

export const getSingleStipulationAttachmentFail = payload => ({
  type: types.GET_SINGLE_STIPULATION_ATTACHMENT_FAIL,
  payload
});

export const getSingleStipulationAttachmentCleanup = () => ({
  type: types.GET_SINGLE_STIPULATION_ATTACHMENT_CLEANUP
});

export const getSingleStipulationAttachment = (portal,type,id)=> async dispatch => {
  try {
    dispatch(getSingleStipulationAttachmentStart());
    const requestObj = {
      path: `attachment/byparent/${portal}/iso/${type}/${id}/stipulation`,
      method: "GET",   
    }
    const  data  = await AxiosCall(requestObj);
    dispatch(getSingleStipulationAttachmentSuccess(data));
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(getSingleStipulationAttachmentFail(error));
  }
} 