export const customStyles = {
  option: (base) => ({
    ...base,
    overflow: "hidden",
    top: "-30px",
    cursor: "pointer",
    position: "static",
    height: "30px",
    paddingBottom: "40px",
    zIndex: 1000,
    "&:hover": {
      backgroundColor: "#5D99FB",
      color: "white",
    },
  }),
  menu: (provided, state) => ({
    ...provided,
    maxWidth: "300px",
    background: "#f6f6f6",
    color: "black",
    overflow: "hidden",
    fontSize: "12px",
    fontWeight: "600",
    position: "static",
    zIndex: 1000,
    marginBottom: "-30px",
  }),
  control: (base) => ({
    ...base,
    border: 0,
    borderBottom: "1px solid #777771",
    height: "50px",
    marginTop: "-10px",
    boxShadow: "none",
  }),
  singleValue: (provided, state) => {
    const width = "100%";
    return { ...provided, width };
  },
};
