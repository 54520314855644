import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';

export const getLookupListStart = () => ({
  type: types.GET_LOOKUP_LIST_START
});

export const getLookupListSuccess = payload => ({
  type: types.GET_LOOKUP_LIST_SUCCESS,
  payload
});

export const getLookupListFail = payload => ({
  type: types.GET_LOOKUP_LIST_FAIL,
  payload
});

export const getLookupListCleanup = () => ({
  type: types.GET_LOOKUP_LIST_CLEANUP
});

export const getLookupList = lookupPath => async dispatch => {
  try {
    dispatch(getLookupListStart());
    const path = `lookup/${lookupPath}`;
    const requestObj = {
      path,
      method: 'GET'
    }
    const  data  = await AxiosCall(requestObj);
    dispatch(getLookupListSuccess({
      lookupPath,
      data
    }));
    
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(getLookupListFail(error));
  }
}
