import './index.css';
import React from 'react';
import {
  Card,
  CardBody
} from 'reactstrap';
import { Link } from "react-router-dom";

const EmailVerification = () => {

  return (
    <div className='container email-verification'>
      <div className="content">
        <Card className='success-con'>
          <CardBody>
            <h4 className='m-5 text-center sos-text-primary sos-font-weight-700'>
              You Have successfuly created an account.<br />
              A verification link have been sent to your email address 
            </h4>
            <p className='text-center sos-text-semi-medium sos-font-weight-600'>After verifying your email, <Link to="/login" className='sos-text-primary'>Go to Login</Link></p>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

export default EmailVerification;
