import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';

export const getAllOffersStart = () => ({
  type: types.GET_ALL_OFFERS_START
});

export const getAllOffersSuccess = payload => ({
  type: types.GET_ALL_OFFERS_SUCCESS,
  payload
});

export const getAllOffersFail = payload => ({
  type: types.GET_ALL_OFFERS_FAIL,
  payload
});

export const getAllOffersCleanup = () => ({
  type: types.GET_ALL_OFFERS_CLEANUP
});

export const getAllOffers = (portal)=> async dispatch => {
  try {
    dispatch(getAllOffersStart());
    const requestObj = {
      path: `offer/${portal}`,
      method: "GET"
    }
    const  data  = await AxiosCall(requestObj);
    dispatch(getAllOffersSuccess(data));
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(getAllOffersFail(error));
  }
}
