import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Tag, Space, Card, Input, Button } from 'antd';
import Highlighter from 'react-highlight-words';
import { CardTitle } from 'reactstrap';
import { SearchOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

//actions
import { myISORepayment, myISORepaymentCleanup } from "../../../../store/actions/my-iso-repayments";

const DealRepayments = ({ id }) => {
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const dispatch = useDispatch();
  const getMyISORepaymentsState = useSelector(s => s.getMyISORepayments);

  const getColumnSearchProps = (dataIndex, Title) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${Title}`}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex);
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size='small'
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#29c273' : undefined }} />
    ),
    onFilter: (value, record) => {
      if(Array.isArray(dataIndex) && dataIndex.length === 2 &&  record[dataIndex[0]] && record[dataIndex[0]].Name){
        return record[dataIndex[0]].Name
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
      }else if(record[dataIndex]){
        return record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
      }else{
        return '';
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  useEffect(() => {
    dispatch(myISORepayment(3, 1, id));
  }, []);

  useEffect(() => {
    if (getMyISORepaymentsState.isSuccessful) {
      setData(getMyISORepaymentsState.data);
      dispatch(myISORepaymentCleanup());
    } else if (getMyISORepaymentsState.error) {
      dispatch(myISORepaymentCleanup());
    }
  }, [getMyISORepaymentsState]);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'Name',
      key: 'Name',
      ...getColumnSearchProps('Name', 'Name'),
      render: (text, data) => <Link to={`/broker/repayment-details/${data.Id}`}>{text}</Link>,
    },
    {
      title: 'Payer Account',
      dataIndex: ['Payee_Account__r', 'Name'],
      key: 'Payee_Account__r',
      ...getColumnSearchProps(['Payee_Account__r', 'Name'], 'Description')
    },
    {
      title: 'Payoff (%)',
      dataIndex: 'Payoff_Text_Only__c',
      key: 'Payoff_Text_Only__c',
      ...getColumnSearchProps('Payoff_Text_Only__c', 'Payoff')
    }
  ];

  return (
    <div className='pt-5 container'>
      <div className='pt-5 table-container'>
        <Card>
          <CardTitle className='text-cente p-4'>
            <div className='sos-text-primary mb-0 font-weight-bold d-flex justify-content-between'>
              <div>Repayments</div>
              <div>
                {data.length} {data.length < 2 ? 'item' : 'items'}
              </div>
            </div>
          </CardTitle>
        </Card>
        <hr />
        <Table
          columns={columns}
          loading={getMyISORepaymentsState.isLoading}
          dataSource={data}
          rowKey={(data) => data.Id}
          scroll={{ x: 550 }}
        />
      </div>
    </div>
  );
};

export default DealRepayments;
