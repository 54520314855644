import './index.css';
import React, { useRef, forwardRef, useImperativeHandle } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Row,
  Col,
  Label,
  FormGroup,
  Input,
  Form
} from 'reactstrap';
import Select from "react-select";
import moment from 'moment';
import { DatePicker } from 'antd';
import { useSelector } from 'react-redux';

//utils
import { getValueObject } from '../../../../../utils/helpers';
import { requestedFundingTimelineOptions } from '../../../../../utils/dropdown-values'

const Step1 = ({ values }, ref) => {
  const formikRef = useRef();
  const profile = useSelector(s => s.getProfile.data);
  const validationSchema = Yup.object().shape({
    Amount_Requested__c: Yup.number()
      .required('Amount Requested is required')
      .min(1, 'Amount Requested should be greater than 0'),
    AnnualRevenue: Yup.number()
      .required('Annual Revenue is required')
      .min(1, 'Annual Revenue should be greater than 0'),
    Date_Business_Established__c: Yup.date()
      .required('Business Inception is required'),
    Requested_Funding_Timeline__c: Yup.string()
      .required('Funding timeline is required'),
  });

  useImperativeHandle(ref, () => ({
    isValid: async () => {
      const errors = await formikRef.current.validateForm();
      if(errors.constructor === Object && Object.keys(errors).length === 0){
        return true;
      }else{
        return false;
      }
    },
    getValues: () => {
      return formikRef.current.values
    },
    handleSubmit: () => {
      formikRef.current.handleSubmit();
    }
  }));

  return (
    <div className='mt-5 funding-application-step1'>
   <Formik
        initialValues={{
          Amount_Requested__c: "",
          AnnualRevenue: profile.Annual_Income__c,
          Requested_Funding_Timeline__c: "",
          Date_Business_Established__c: "",
          ...values,
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
        }}
        enableReinitialize={true}
        validationSchema={validationSchema}
        innerRef={formikRef}
      >
        {({ handleChange, values, errors, touched, handleBlur, setFieldValue, setFieldTouched }) => (
          <Form>
            <Row>
              <Col md="6" sm="12" className='mb-4'>
                <FormGroup className={`${errors.Amount_Requested__c && touched.Amount_Requested__c ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>Amount Requested <span className='text-danger'>*</span></Label>
                  <Input
                    placeholder="$"
                    type="text" 
                    name='Amount_Requested__c'
                    onChange={handleChange('Amount_Requested__c')}
                    onBlur={handleBlur('Amount_Requested__c')}
                    value={values.Amount_Requested__c}
                  />
                </FormGroup>
                <small className='text-danger'>{errors.Amount_Requested__c && touched.Amount_Requested__c ? errors.Amount_Requested__c : null}</small>
              </Col>
              <Col sm='12' md='6'>
                <FormGroup className={`${errors.AnnualRevenue && touched.AnnualRevenue ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>Annual Revenue <span className='text-danger'>*</span></Label>
                  <Input
                    placeholder="$"
                    type="text" 
                    name='AnnualRevenue'
                    onChange={handleChange('AnnualRevenue')}
                    onBlur={handleBlur('AnnualRevenue')}
                    value={values.AnnualRevenue}
                  />
                </FormGroup>
                <small className='text-danger'>{errors.AnnualRevenue && touched.AnnualRevenue ? errors.AnnualRevenue : null}</small>
              </Col>
              <Col sm='12' md='6'>
                <FormGroup className={`${errors.Date_Business_Established__c && touched.Date_Business_Established__c ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>Business Inception <span className='text-danger'>*</span></Label>
                    <DatePicker
                      className='col-12'
                      value={values.Date_Business_Established__c && moment(values.Date_Business_Established__c)}
                      onChange={date => {
                        setFieldValue('Date_Business_Established__c', moment(date).format())
                      }}
                      onBlur={() => setFieldTouched('Date_Business_Established__c')}
                      allowClear={false}
                      format='MM/DD/YYYY'
                    />
                </FormGroup>
                <small className='text-danger'>{errors.Date_Business_Established__c && touched.Date_Business_Established__c ? errors.Date_Business_Established__c : null}</small>
              </Col>
              <Col md="6" sm="12" className='mb-4'>
                <Label className='text-medium'>When do you require Funding? <span className='text-danger'>*</span></Label>
                <Select
                  className="react-select info"
                  classNamePrefix="react-select"
                  placeholder="--None--"
                  name="Requested_Funding_Timeline__c"
                  value={getValueObject(requestedFundingTimelineOptions, values.Requested_Funding_Timeline__c)}
                  onChange={value => setFieldValue('Requested_Funding_Timeline__c', value.value)}
                  options={requestedFundingTimelineOptions}
                  onBlur={() => setFieldTouched('Requested_Funding_Timeline__c')}
                />
                <small className='text-danger'>{errors.Requested_Funding_Timeline__c && touched.Requested_Funding_Timeline__c ? errors.Requested_Funding_Timeline__c : null}</small>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default forwardRef(Step1);
