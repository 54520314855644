import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';

export const createBrokerFundingStart = () => ({
  type: types.CREATE_BROKER_FUNDING_START
});

export const createBrokerFundingSuccess = payload => ({
  type: types.CREATE_BROKER_FUNDING_SUCCESS,
  payload
});

export const createBrokerFundingFail = payload => ({
  type: types.CREATE_BROKER_FUNDING_FAIL,
  payload
});

export const createBrokerFundingCleanup = () => ({
  type: types.CREATE_BROKER_FUNDING_CLEANUP
});

export const createBrokerFunding = payload => async dispatch => {
  try {
    dispatch(createBrokerFundingStart());
    const path = 'brokerfunding';
    const requestObj = {
      path,
      method: 'POST',
      data: payload
    };
    const  data  = await AxiosCall(requestObj);
    dispatch(createBrokerFundingSuccess(data));
    
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(createBrokerFundingFail(error));
  }
}
