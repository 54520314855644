import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';
export const myISODealsStart = () => ({
  type: types.GET_MY_ISO_DEALS_START
});

export const myISODealsSuccess = payload => ({
  type: types.GET_MY_ISO_DEALS_SUCCESS,
  payload
});

export const myISODealsFail = payload => ({
  type: types.GET_MY_ISO_DEALS_FAIL,
  payload
});

export const myISODealsCleanup = () => ({
  type: types.GET_MY_ISO_DEALS_CLEANUP
});

export const myISODeals = (portal,type)=> async dispatch => {
  try {
    dispatch(myISODealsStart());
    const requestObj = {
      path: `opportunity/${portal}/iso/${type}`,
      method: "GET",   
    }
    const  data  = await AxiosCall(requestObj);
    dispatch(myISODealsSuccess(data));
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(myISODealsFail(error));
  }
}
