import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import AuthLayout from "../layouts/Auth.js";
import PublicLayout from "../layouts/Public.js";
import Error404 from "../views/Error404";
import IsoLayout from "../layouts/Iso";
import BrokerLayout from "../layouts/Broker";
import SyndicationLayout from "../layouts/Syndication";
import ReferrerLayout from "../layouts/Referrer";
import NewAuthLayout from "../layouts/NewAuth";
import NewPublicLayout from "../layouts/NewPublic";

const Routes = () => (
  <Router>
    <Switch>
      <Route path="/user" render={(props) => <AuthLayout {...props} />} />
      <Route path="/iso" render={(props) => <IsoLayout {...props} />} />
      <Route path="/broker" render={(props) => <BrokerLayout {...props} />} />
      <Route
        path="/syndication"
        render={(props) => <SyndicationLayout {...props} />}
      />
      <Route
        path="/referrer"
        render={(props) => <ReferrerLayout {...props} />}
      />
      <Route path="/new" render={(props) => <NewAuthLayout {...props} />} />
      <Route
        path="/new-public"
        render={(props) => <NewPublicLayout {...props} />}
      />
      <Route path="/404" component={Error404} />
      <Route path="/" render={(props) => <PublicLayout {...props} />} />
      <Redirect to="/" />
    </Switch>
  </Router>
);

export default Routes;
