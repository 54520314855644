import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Space, Card, Input, Button } from "antd";
import Highlighter from "react-highlight-words";
import { CardTitle } from "reactstrap";
import { SearchOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

//actions
import {
  getSyndicatorDeals,
  getSyndicatorDealsCleanup,
} from "../../../../store/actions/get-syndicators-deals";

//utils
import { formatCurrency } from "../../../../utils/helpers";

const MySyndications = ({ type, title }) => {
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const dispatch = useDispatch();
  const getSyndicatorDealsState = useSelector((s) => s.getSyndicatorDeals);
  const getColumnSearchProps = (dataIndex, Title) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${Title}`}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex);
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#29c273" : undefined }} />
    ),
    onFilter: (value, record) => {
      if (
        Array.isArray(dataIndex) &&
        dataIndex.length === 3 &&
        record[dataIndex[0]] &&
        record[dataIndex[0]][dataIndex[1]].Name
      ) {
        return record[dataIndex[0]][dataIndex[1]].Name.toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      } else if (record[dataIndex]) {
        return record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      } else {
        return "";
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  useEffect(() => {
    dispatch(getSyndicatorDeals(false, type));
  }, []);

  useEffect(() => {
    if (getSyndicatorDealsState.isSuccessful) {
      setData(getSyndicatorDealsState.data);
      dispatch(getSyndicatorDealsCleanup());
    } else if (getSyndicatorDealsState.error) {
      dispatch(getSyndicatorDealsCleanup());
    }
  }, [getSyndicatorDealsState]);

  const columns = [
    {
      title: "Deal Name",
      dataIndex: ["RepaymentId__r", "Opportunity__r", "Name"],
      key: "DealName",
      ...getColumnSearchProps(
        ["RepaymentId__r", "Opportunity__r", "Name"],
        "Deal Name"
      ),
    },
    {
      title: "Syndication Name",
      dataIndex: "Name",
      key: "SyndicationName",
      ...getColumnSearchProps("Name", "Syndication Name"),
      render: (text, data) => (
        <Link to={`${type}/syndication-details/${data.Id}`}>{text}</Link>
      ),
    },    {
      title: "Status",
      dataIndex: "Repayment_Status__c",
      key: "Repayment_Status__c",
      ...getColumnSearchProps("Repayment_Status__c", "Status"),
    },
    {
      title: "% of Funding",
      dataIndex: "Percent_Of_Funding__c",
      key: "Percent_Of_Funding__c",
      ...getColumnSearchProps("Percent_Of_Funding__c", "% of Funding"),
    },
    {
      title: "Syndication Amount",
      dataIndex: "Syndication_Amount__c",
      key: "Syndication_Amount__c",
      ...getColumnSearchProps("Syndication_Amount__c", "Syndication Amount"),
      render: (text) => <span>{formatCurrency(text === null ? 0 : text)}</span>,
    },
    {
      title: "Gross Syndication Payback",
      dataIndex: "Gross_Payback__c",
      key: "Gross_Payback__c",
      ...getColumnSearchProps("Gross_Payback__c", "Gross Syndication Payback"),
      render: (text) => <span>{formatCurrency(text === null ? 0 : text)}</span>,
    },
    {
      title: "Net Payback Amount",
      dataIndex: "Net_Payback_Amt__c",
      key: "Net_Payback_Amt__c",
      ...getColumnSearchProps("Net_Payback_Amt__c", "Net Payback Amount"),
      render: (text) => <span>{formatCurrency(text === null ? 0 : text)}</span>,
    },
    {
      title: "Management Fee",
      dataIndex: "Management_Fee__c",
      key: "Management_Fee__c",
      ...getColumnSearchProps("Management_Fee__c", "Management Fee"),
      render: (text) => <span>{formatCurrency(text === null ? 0 : text)}</span>,
    },
    {
      title: "Net Dollar Profit",
      dataIndex: "Net_D_Profit__c",
      key: "Net_D_Profit__c",
      ...getColumnSearchProps("Net_D_Profit__c", "Net Dollar Profit"),
      render: (text) => <span>{formatCurrency(text === null ? 0 : text)}</span>,
    },
    {
      title: "Gross % Return",
      dataIndex: "Gross_P_Return__c",
      key: "Gross_P_Return__c",
      ...getColumnSearchProps("Gross_P_Return__c", "Gross % Return"),
      render: (text) => <span>{text === null ? "%" + 0 : "%" + text}</span>,
    },
    {
      title: "Syndication Balance",
      dataIndex: "Syndication_Balance_Formula__c",
      key: "Syndication_Balance_Formula__c",
      ...getColumnSearchProps(
        "Syndication_Balance_Formula__c",
        "Syndication Balance"
      ),
      render: (text) => <span>{formatCurrency(text === null ? 0 : text)}</span>,
    },
    {
      title: "Syndicator Name",
      dataIndex: ["Syndicator__r", "Contact__r", "Name"],
      key: "SyndicatorName",
      ...getColumnSearchProps(
        ["Syndicator__r", "Contact__r", "Name"],
        "Syndicator Name"
      ),
    },
  ];
  return (
    <>
      <div className=" container">
        <div className="table-container">
          <Card>
            <CardTitle className="text-cente p-4">
              <div className="sos-text-primary mb-0 font-weight-bold d-flex justify-content-between">
                <div>{title}</div>
                <div>
                  {data.length} {data.length < 2 ? "item" : "items"}
                </div>
              </div>
            </CardTitle>
          </Card>
          <hr />
          <Table
            columns={columns}
            loading={getSyndicatorDealsState.isLoading}
            dataSource={data}
            scroll={{ x: 2300 }}
            rowKey={(data) => data.Id}
          />
        </div>
      </div>
    </>
  );
};
export default MySyndications;
