import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';

export const getSingleRepaymentStart = () => ({
  type: types.GET_SINGLE_REPAYMENT_START
});

export const getSingleRepaymentSuccess = payload => ({
  type: types.GET_SINGLE_REPAYMENT_SUCCESS,
  payload
});

export const getSingleRepaymentFail = payload => ({
  type: types.GET_SINGLE_REPAYMENT_FAIL,
  payload
});

export const getSingleRepaymentCleanup = () => ({
  type: types.GET_SINGLE_REPAYMENT_CLEANUP
});

export const getSingleRepayment = (currentPortal, id, type=null) => async dispatch => {
  try {
    dispatch(getSingleRepaymentStart());
    const path = type ? `repayment/${currentPortal}/iso/${type}/${id}`
      : `repayment/${currentPortal}/${id}`;
    const requestObj = {
      path,
      method: 'GET'
    }
    const  data  = await AxiosCall(requestObj);
    dispatch(getSingleRepaymentSuccess(data[0]));
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(getSingleRepaymentFail(error));
  }
}
