import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Card, Alert, Button, Form, Input } from "antd";
import {
  CardBody,
  Col,
  Row,
  CardTitle,
} from "reactstrap";

//utils
import firebase from "../../../utils/firebase";

const ChangePassword = () => {
  const [message, setMessage] = useState(null);
  const profile = useSelector(s => s.getProfile.data);
  const [form] = Form.useForm();

  const handleSubmit = async (values) => {
    try {
      if(message) setMessage(null);
      const { oldPassword, password } = values;
      await firebase.doSignInWithEmailAndPassword(profile.Email, oldPassword)
      await firebase.doPasswordUpdate(password);
      setMessage(<Alert message={"Password has been updated successfully"} type="success" />);
      form.resetFields();
    } catch (error) {
      setMessage(<Alert message={error.message} type="error" />);
    }
  };

  return (
    <div className="pt-5  container change-password-page">
      <div className="pt-5 table-container">
        <Card>
          <CardTitle className="text-cente p-4">
            <div className="sos-text-primary mb-0 font-weight-bold d-flex justify-content-between">
              <div>Change Password</div>
            </div>
          </CardTitle>
        </Card>
        <hr />
        <Card>
          <CardTitle className="px-4 pt-4 pb-0">
            <p className='mb-0'>Please enter your new password.</p>
          </CardTitle>
          {message ? <div className='mb-4'>{message}</div> : null}
          <CardBody>
            <Row>
              <Col className="" lg="6" md="6">
                <Form
                  labelCol={{ span: 24 }}
                  wrapperCol={{span: 24}}
                  name='reset'
                  onFinish={handleSubmit}
                  className='px-4 justify-content-center w-100'
                  layout='vertical'
                  form={form}
                >
                  <Form.Item
                    label='Old Password'
                    name='oldPassword'
                    rules={[{ required: true, message: 'Please input your password!' }]}
                    className='w-100 mb-4 sos-text-medium sos-font-weight-600'
                  >
                    <Input.Password />
                  </Form.Item>
                  <Form.Item
                    label='New Password'
                    name='password'
                    rules={[{ required: true, message: 'Please input your password!' }]}
                    className='w-100 mb-4 sos-text-medium sos-font-weight-600'
                  >
                    <Input.Password />
                  </Form.Item>
                  <Form.Item
                    name='confirm'
                    label='Confirm New Password'
                    dependencies={['password']}
                    rules={[
                      {
                        required: true,
                        message: 'Please confirm your password!',
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('Passwords does not match!'));
                        },
                      }),
                    ]}
                    className='w-100 mb-4 sos-text-medium sos-font-weight-600'
                  >
                    <Input.Password />
                  </Form.Item>
                  <Form.Item className='text-center'>
                    <Button htmlType='submit' className='sos-bg-primary sos-border-primary text-white'>Submit</Button> 
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default ChangePassword;
