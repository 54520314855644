import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';

export const searchInstitutionStart = () => ({
  type: types.SEARCH_INSTITUTION_START
});

export const searchInstitutionSuccess = payload => ({
  type: types.SEARCH_INSTITUTION_SUCCESS,
  payload
});

export const searchInstitutionFail = payload => ({
  type: types.SEARCH_INSTITUTION_FAIL,
  payload
});

export const searchInstitutionCleanup = () => ({
  type: types.SEARCH_INSTITUTION_CLEANUP
});

export const searchInstitution = payload => async dispatch => {
  try {
    dispatch(searchInstitutionStart());
    const requestObj = {
      path: "plaid/search_institution",
      method: "POST",
      data: payload
    }
    const  data  = await AxiosCall(requestObj);
    dispatch(searchInstitutionSuccess(data));
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(searchInstitutionFail(error));
  }
}
