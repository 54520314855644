import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';

export const checkEmailExistStart = () => ({
  type: types.CHECK_EMAIL_EXIST_START
});

export const checkEmailExistSuccess = payload => ({
  type: types.CHECK_EMAIL_EXIST_SUCCESS,
  payload
});

export const checkEmailExistFail = payload => ({
  type: types.CHECK_EMAIL_EXIST_FAIL,
  payload
});

export const checkEmailExistCleanup = () => ({
  type: types.CHECK_EMAIL_EXIST_CLEANUP
});

export const checkEmailExist = payload => async dispatch => {
  try {
    dispatch(checkEmailExistStart());
    const requestObj = {
      path: "plaid/plaid_email_check",
      method: "POST",
      data: payload
    }
    const  data  = await AxiosCall(requestObj);
    if(data.found){
      dispatch(checkEmailExistSuccess('Email exists'));
    }else{
      dispatch(checkEmailExistFail('Email does not exist'))
    }
    
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(checkEmailExistFail(error));
  }
}
