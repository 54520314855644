import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Tag, Space, Card, Input, Button } from "antd";
import Currency from "react-currency-formatter";
import Highlighter from "react-highlight-words";
import { CardTitle } from "reactstrap";
import { SearchOutlined } from "@ant-design/icons";
import { Link } from 'react-router-dom';

//actions
import { getAllOffers, getAllOffersCleanup } from "../../../store/actions/get-all-offers";

const AllOffers = () => {
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const dispatch = useDispatch();
  const getAllOffersState = useSelector((s) => s.getAllOffers);

  const getColumnSearchProps = (dataIndex, Title) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${Title}`}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex);
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#29c273" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  useEffect(() => {
    dispatch(getAllOffers(1));
  }, []);

  useEffect(() => {
    if (getAllOffersState.isSuccessful) {
      setData(getAllOffersState.data);
      dispatch(getAllOffersCleanup());
    } else if (getAllOffersState.error) {
      dispatch(getAllOffersCleanup());
    }
  }, [getAllOffersState]);

  const columns = [
    {
      title: "Deal Type",
      dataIndex: "Deal_Type__c",
      key: "deal-type",
      ...getColumnSearchProps("Deal_Type__c", "Deal Type"),
    },
    {
      title: "Funding Amount",
      dataIndex: "Amount__c",
      key: "AmountRequested",
      ...getColumnSearchProps("Amount__c", "Funding Amount"),
      render: (amount) => (
        <Currency quantity={amount ? amount : 0} currency="USD" />
      ),
    },
    {
      title: "Payoff Amount",
      dataIndex: "Payoff_Amount__c",
      key: "PayoffAmount",
      ...getColumnSearchProps("Payoff_Amount__c", "Payoff Amount"),
      render: (amount) => (
        <Currency quantity={amount ? amount : 0} currency="USD" />
      ),
    },
    {
      title: "Net Funded Amount",
      dataIndex: "Net_Funded_Amount__c",
      key: "NetAmount",
      ...getColumnSearchProps("Net_Funded_Amount__c", "Net Funded Amount"),
      render: (amount) => (
        <Currency quantity={amount ? amount : 0} currency="USD" />
      ),
    },
    {
      title: "Purchase Amount",
      dataIndex: "Purchase_Amount__c",
      key: "PurchaseAmount",
      ...getColumnSearchProps("Purchase_Amount__c", "Purchase Amount"),
      render: (amount) => (
        <Currency quantity={amount ? amount : 0} currency="USD" />
      ),
    },

    {
      title: "Term (Months)",
      dataIndex: "Term_Months__c",
      key: "Term (Months)",
      ...getColumnSearchProps("Term_Months__c", "Term (Months)"),
    },
    {
      title: "Status",
      dataIndex: "Status__c",
      key: "Status",
      render: Status => (
        <Tag
          color={Status === "Chosen" ? "green" : Status === "Presented" ? "blue" : 
            Status === "Rejected" ? "red" : Status === "Revision" ? "orange" : ""}
        >
          {Status}
        </Tag>
      ),
      filters: [
        "Presented",
        "Chosen",
        "Removed",
        "Rejected",
        "Revision"
      ].map((e) => ({ text: e, value: e, key: e })),
      onFilter: (value, record) => record.Status__c.indexOf(value) === 0,
    },
    {
      title: "Action",
      dataIndex: "Id",
      key: "Action",
      render: (Id, record) => (
        <div>
          {record.Status__c === 'Presented' && record.RecordType && record.RecordType.Name === 'FlexOffer'
            && record.Opportunity__r.StageName === 'Approved' ? (
            <Link className='inline text-warning' to={`/user/calculator/${Id}`}>Review Offer</Link>
          ) : (
            <Link className='inline' to={`/user/offer-details/${Id}`}>View</Link>
          )}
        </div>
      )
    },
  ];
  return (
    <>
      <div className="pt-5  container">
        <div className="pt-5 table-container">
          <Card>
            <CardTitle className="text-cente p-4">
              <div className="sos-text-primary mb-0 font-weight-bold d-flex justify-content-between">
                <div> All Offers </div>
                <div>
                  {data.length} {data.length < 2 ? "item" : "items"}
                </div>
              </div>
            </CardTitle>
          </Card>
          <hr />
          <Table
            columns={columns}
            loading={getAllOffersState.isLoading}
            dataSource={data}
            scroll={{ x: 1000 }}
            rowKey={(data) => data.Id}
          />
        </div>
      </div>
    </>
  );
};
export default AllOffers;
