import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';

export const getAdvanceDetailsStart = () => ({
  type: types.GET_ADVANCE_DETAILS_START
});

export const getAdvanceDetailsSuccess = payload => ({
  type: types.GET_ADVANCE_DETAILS_SUCCESS,
  payload
});

export const getAdvanceDetailsFail = payload => ({
  type: types.GET_ADVANCE_DETAILS_FAIL,
  payload
});

export const getAdvanceDetailsCleanup = () => ({
  type: types.GET_ADVANCE_DETAILS_CLEANUP
});

export const getAdvanceDetails = id => async dispatch => {
  try {
    dispatch(getAdvanceDetailsStart());
    const requestObj = {
      path: `submission/${id}`,
      method: "GET"
    }
    const  data  = await AxiosCall(requestObj);
    dispatch(getAdvanceDetailsSuccess(data[0]));
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(getAdvanceDetailsFail(error));
  }
}
