import React from "react";
import { Tabs } from "antd";
import { useParams } from "react-router-dom";
import DealsDetailsSection from "./DealsDetailsSection";
import Stipulations from "./DealStipulation";
import DealsOffersTable from "./DealsOfferTable";
import DealsRepayments from "./DealsRepayments";
const { TabPane } = Tabs;
const DealsDetails = () => {
  const { id } = useParams();
  const portal = 2;
  const type = 1;
  return (
    <div className="pt-5 mt-5  container advance-details-page">
      <div className="pt-5 table-container">
        <Tabs defaultActiveKey="1">
          <TabPane tab="Deal Details" key="1">
            <DealsDetailsSection id={id} portal={portal} type={type} />
          </TabPane>
          <TabPane tab="Stipulations" key="2">
            <Stipulations id={id} portal={portal} type={type} />
          </TabPane>
          <TabPane tab="Deal Offers" key="3">
            <DealsOffersTable id={id} portal={portal} type={type} />
          </TabPane>
          <TabPane tab="Repayments" key="4">
            <DealsRepayments id={id} portal={portal} type={type} />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default DealsDetails;
