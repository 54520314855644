import "./index.css";
import React, { useRef, forwardRef, useImperativeHandle } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Row, Col, Label, FormGroup, Input, Form } from "reactstrap";
import Select from "react-select";
import moment from "moment";
import { DatePicker } from "antd";

//utils
import { getValueObject } from "../../../../utils/helpers";
import { requestedFundingTimelineOptions } from "../../../../utils/dropdown-values";
import { customStyles } from "../../../../utils/react-select-settings/index";

const Step1 = ({ values }, ref) => {
  const formikRef = useRef();
  const validationSchema = Yup.object().shape({
    AmountRequested: Yup.number()
      .required("Amount Requested is required")
      .min(1, "Amount Requested should be greater than 0"),
    AnnualRevenue: Yup.number()
      .required("Annual Revenue is required")
      .min(1, "Annual Revenue should be greater than 0"),
    DateBusinessEstablished: Yup.date().required(
      "Business Inception is required"
    ),
    RequestedFundingTimeline: Yup.string().required(
      "Funding timeline is required"
    ),
  });

  useImperativeHandle(ref, () => ({
    isValid: async () => {
      const errors = await formikRef.current.validateForm();
      if (errors.constructor === Object && Object.keys(errors).length === 0) {
        return true;
      } else {
        return false;
      }
    },
    getValues: () => {
      return formikRef.current.values;
    },
    handleSubmit: () => {
      formikRef.current.handleSubmit();
    },
  }));

  return (
    <div className="mt-5 funding-application-step1">
      <Formik
        initialValues={{
          AmountRequested: "",
          AnnualRevenue: "",
          RequestedFundingTimeline: "",
          DateBusinessEstablished: "",
          ...values,
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
        }}
        validationSchema={validationSchema}
        innerRef={formikRef}
      >
        {({
          handleChange,
          values,
          errors,
          touched,
          handleBlur,
          setFieldValue,
          setFieldTouched,
        }) => (
          <Form className="form-height">
            <h2 className="sos-text-primary mb-0 font-weight-bold q-text">
              What can we do for you?
            </h2>
            <Row>
              <Col md="12" sm="12" className="mb-4">
                <FormGroup
                  className={`${
                    errors.AmountRequested && touched.AmountRequested
                      ? "mb-0"
                      : ""
                  }`}
                >
                  <Label className="text-medium">
                    Amount Requested <span className="text-danger">*</span>
                  </Label>
                  <Input
                    className={"no-margin"}
                    placeholder="$"
                    type="text"
                    name="AmountRequested"
                    onChange={handleChange("AmountRequested")}
                    onBlur={handleBlur("AmountRequested")}
                    value={values.AmountRequested}
                  />
                </FormGroup>
                <small className="text-danger">
                  {errors.AmountRequested && touched.AmountRequested
                    ? errors.AmountRequested
                    : null}
                </small>
              </Col>
              <Col sm="12" md="12">
                <FormGroup
                  className={`${
                    errors.AnnualRevenue && touched.AnnualRevenue ? "mb-0" : ""
                  }`}
                >
                  <Label className="text-medium">
                    Annual Revenue <span className="text-danger">*</span>
                  </Label>
                  <Input
                    className={"no-margin"}
                    placeholder="$"
                    type="text"
                    name="AnnualRevenue"
                    onChange={handleChange("AnnualRevenue")}
                    onBlur={handleBlur("AnnualRevenue")}
                    value={values.AnnualRevenue}
                  />
                </FormGroup>
                <small className="text-danger">
                  {errors.AnnualRevenue && touched.AnnualRevenue
                    ? errors.AnnualRevenue
                    : null}
                </small>
              </Col>
              <Col md="12" sm="12" className="mb-5">
                <Label className="text-medium">
                  When do you require Funding?{" "}
                  <span className="text-danger">*</span>
                </Label>
                <Select
                  components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                  styles={customStyles}
                  placeholder="--None--"
                  name="RequestedFundingTimeline"
                  value={getValueObject(
                    requestedFundingTimelineOptions,
                    values.RequestedFundingTimeline
                  )}
                  onChange={(value) =>
                    setFieldValue("RequestedFundingTimeline", value.value)
                  }
                  options={requestedFundingTimelineOptions}
                  onBlur={() => setFieldTouched("RequestedFundingTimeline")}
                />
                <small className="text-danger">
                  {errors.RequestedFundingTimeline &&
                  touched.RequestedFundingTimeline
                    ? errors.RequestedFundingTimeline
                    : null}
                </small>
              </Col>
              <Col sm="12" md="12">
                <FormGroup
                  className={`${
                    errors.DateBusinessEstablished &&
                    touched.DateBusinessEstablished
                      ? "mb-0"
                      : ""
                  }`}
                >
                  <Label className="text-medium">
                    Business Inception <span className="text-danger">*</span>
                  </Label>
                  <DatePicker
                    className={"no-margin col-12"}
                    value={
                      values.DateBusinessEstablished &&
                      moment(values.DateBusinessEstablished)
                    }
                    onChange={(date) => {
                      setFieldValue(
                        "DateBusinessEstablished",
                        moment(date).format()
                      );
                    }}
                    onBlur={() => setFieldTouched("DateBusinessEstablished")}
                    allowClear={false}
                    format="MM/DD/YYYY"
                  />
                </FormGroup>
                <small className="text-danger">
                  {errors.DateBusinessEstablished &&
                  touched.DateBusinessEstablished
                    ? errors.DateBusinessEstablished
                    : null}
                </small>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default forwardRef(Step1);
