import "./index.css";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Card, Divider } from "antd";
import { CardTitle } from "reactstrap";
import moment from "moment";
import { useParams } from "react-router";

//actions
import {
  getSyndicatorDeals,
  getSyndicatorDealsCleanup,
} from "../../../store/actions/get-syndicators-deals";

//components
import Loader from "../../../components/Loader";

//utils
import { formatCurrency } from "../../../utils/helpers";

const SyndicationPayableDetails = () => {
  const dispatch = useDispatch();
  const getSyndicatorDealsState = useSelector((s) => s.getSyndicatorDeals);
  const [data, setData] = useState([]);
  const { type, id } = useParams();
  useEffect(() => {
    dispatch(getSyndicatorDeals(true, type, id));
  }, []);

  useEffect(() => {
    if (getSyndicatorDealsState.isSuccessful) {
      setData(getSyndicatorDealsState.data[0]);
      dispatch(getSyndicatorDealsCleanup());
    } else if (getSyndicatorDealsState.error) {
      dispatch(getSyndicatorDealsCleanup());
    }
  }, [getSyndicatorDealsState]);

  return (
    <div className=" container broker-deal-details-page--">
      <div className="table-container-syn">
        <Card>
          <CardTitle className="text-cente p-4">
            <div className="sos-text-primary mb-0 font-weight-bold d-flex justify-content-between">
              <div>Syndication Payable Details</div>
            </div>
          </CardTitle>
        </Card>
        <hr />
        <Card>
          {getSyndicatorDealsState.isLoading && !data ? (
            <div className="m-5 d-flex justify-content-center">
              <Loader isLoading={getSyndicatorDealsState.isLoading} />
            </div>
          ) : data ? (
            <div>
              <Divider orientation="center">
                <h4 className="sos-font-weight-600 mt-0 mb-0">Details</h4>
              </Divider>
              <div className="row">
                <div className="col-md-6">
                  <p>
                    <strong>Syndicator Payable Name: </strong>
                    {data.Name}
                  </p>
                  <p>
                    <strong>Payable Date: </strong>
                    {data.Payable_Date__c
                      ? moment(data.Payable_Date__c).format("MMMM Do, YYYY")
                      : "N/A"}
                  </p>
                  <p>
                    <strong>Merchant Payment Amount: </strong>
                    {formatCurrency(
                      data.Merchant_Payment_Amt__c === null
                        ? 0
                        : data.Merchant_Payment_Amt__c
                    )}
                  </p>
                  <p>
                    <strong> % Of Funding (with fee): </strong>
                    {data.of_Funding__c}%
                  </p>
                  <p>
                    <strong>Payable Amount(Gross): </strong>
                    {formatCurrency(
                      data.Payable_Amt_Gross__c === null
                        ? 0
                        : data.Payable_Amt_Gross__c
                    )}
                  </p>
                  <p>
                    <strong>Management Fee: </strong>
                    {formatCurrency(
                      data.Management_Fee_Amt__c === null
                        ? 0
                        : data.Management_Fee_Amt__c
                    )}
                  </p>

                  <p>
                    <strong>Merchant: </strong>
                    {data.Merchant__c}
                  </p>
                  <p>
                    <strong>Merchant Fee New: </strong>
                    {formatCurrency(
                      data.Management_Fee_New__c === null
                        ? 0
                        : data.Management_Fee_New__c
                    )}
                  </p>
                  <p>
                    <strong> Payable Amount New: </strong>
                    {formatCurrency(
                      data.Payable_Amount_New__c === null
                        ? 0
                        : data.Payable_Amount_New__c
                    )}
                  </p>
                  <p>
                    <strong>Payable Amount: </strong>
                    {formatCurrency(
                      data.Payable_Amt__c === null ? 0 : data.Payable_Amt__c
                    )}
                  </p>
                  <p>
                    <strong>Previous Amount: </strong>
                    {formatCurrency(
                      data.Previous_Amount__c === null
                        ? 0
                        : data.Previous_Amount__c
                    )}
                  </p>
                </div>

                <div className="col-md-6">
                  <p>
                    <strong>Payable Status: </strong>
                    {data.Payable_Status__c}
                  </p>
                  <p>
                    <strong>Syndicator: </strong>
                    {data.Syndicator__r && data.Syndicator__r.Name}
                  </p>
                  <p>
                    <strong>Syndication: </strong>
                    {data.Syndication__r && data.Syndication__r.Name}
                  </p>
                  <p>
                    <strong>Due to Account: </strong>
                    {data.Due_to_Account__c}
                  </p>
                  <p>
                    <strong>Payment: </strong>
                    {data.Payment__r && data.Payment__r.Name}
                  </p>
                  <p>
                    <strong>Repayment: </strong>
                    {data.Repayment__r && data.Repayment__r.Name}
                  </p>
                  <p>
                    <strong>Remittance Line Item: </strong>
                    {data.Remittance_Line_Item__c}%
                  </p>
                  <p>
                    <strong>Repayment Status: </strong>
                    {data.Repayment_Status__c}
                  </p>
                </div>
              </div>
              <Divider orientation="center">
                <h4 className="sos-font-weight-600 mt-0 mb-0">
                  Balances and Fees
                </h4>
              </Divider>
              <div className="row">
                <div className="col-md-6">
                  <p>
                    <strong>Amount Paid: </strong>
                    {formatCurrency(
                      data.Amount_Paid__c === null ? 0 : data.Amount_Paid__c
                    )}
                  </p>
                  <p>
                    <strong>Date Paid: </strong>
                    {data.Date_Paid__c
                      ? moment(data.Date_Paid__c).format("MMMM Do, YYYY")
                      : "N/A"}
                  </p>
                  <p>
                    <strong>Amount Due: </strong>
                    {formatCurrency(
                      data.Amount_Due_Formula__c === null
                        ? 0
                        : data.Amount_Due_Formula__c
                    )}
                  </p>
                </div>

                <div className="col-md-6">
                  <p>
                    <strong>Remittance Created: </strong>
                    {data.Remittance_Created__c ? "Yes" : "No"}
                  </p>
                  <p>
                    <strong>Management Fee Percentage: </strong>
                    {data.Management_Fee_Percentage__c} %
                  </p>
                  <p>
                    <strong>Management Fee Percent Of: </strong>
                    {data.Management_Fee_Of__c}
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <h4>Details found</h4>
          )}
        </Card>
      </div>
    </div>
  );
};

export default SyndicationPayableDetails;
