import './index.css';
import React, { useRef, forwardRef, useImperativeHandle } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Row,
  Col,
  Label,
  FormGroup,
  Input,
  Form,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from 'reactstrap';
import Select from "react-select";
import { DatePicker } from 'antd';
import moment from 'moment';

//utils
import { LegalEntityType, OppStage } from '../../../../../utils/dropdown-values'
import { getValueObject, formatTaxId } from '../../../../../utils/helpers';
import industries from '../../../../../utils/industries';

const Step1 = ({ values , initialVal }, ref) => {
  const formikRef = useRef();
  const taxIdRegex = /^\d{2}\-?\d{7}$/;
  const phoneRegex = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;
  const urlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;

  const validationSchema = Yup.object().shape({
    Name: Yup.string()
      .trim()
      .required('Business Name is required')
      .min(2, 'Business Name should have minimum of two characters'),
    Legal_Name__c: Yup.string()
      .trim()
      .required('Business Legal Name is required')
      .min(2, 'Business Legal Name should have minimum of two characters'),
    DBA__c: Yup.string()
      .trim()
      .required('DBA is required')
      .min(2, 'DBA should have minimum of two characters'),
    Type_of_Entity__c: Yup.string()
      .required('Type of Entity is required'),
    Industry: Yup.string()
      .required('Industry is required'),
    Date_Business_Established__c: Yup.date()
      .required('Date Business Established is required'),
    Years_in_Business__c: Yup.number()
      .required('Years in Business is required')
      .min(0, 'Years in Business should not be less than 0'),
    Federal_Tax_ID_No__c: Yup.string()
      .trim()
      .required('Tax ID is required')
      .matches(taxIdRegex,'Tax ID is not valid'),
    StageName: Yup.string()
      .required('Stage is required'),
    Closedate: Yup.date()
      .required('Close date is required'),
    BillingStreet: Yup.string()
      .trim()
      .required('Billing Street is required')
      .min(2, 'Billing Street should have minimum of two characters'),
    BillingCity: Yup.string()
      .trim()
      .required('Billing City is required')
      .min(2, 'Billing City should have minimum of two characters'),
    BillingState: Yup.string()
      .trim()
      .required('Billing State is required')
      .min(2, 'Billing State should have minimum of two characters'),
    BillingPostalCode: Yup.string()
      .trim()
      .required('Shipping Postal Code is required')
      .min(2, 'Shipping Postal Code should have minimum of two characters'),
    Phone: Yup.string()
      .trim()
      .matches(phoneRegex,'Business Phone number is not a valid phone number'),
    Fax: Yup.string()
      .trim()
      .min(2, 'Business Fax should have minimum of two characters'),
    Website: Yup.string()
      .trim()
      .matches(urlRegex, 'Website should be a valid url'),
    Number_Bank_Statements_Available__c: Yup.number()
      .min(0, 'Number of avavilable bank statement should not be less than 0'),
    NDBs_avg__c: Yup.number()
      .min(0, 'NDB Average should not be less than 0'),
    Use_of_Proceeds__c: Yup.string()
      .trim()
      .required('Use of Proceeds is required')
      .min(2, 'Use of Proceeds should have minimum of two characters'),
    Requested_Amount_of_Funds__c: Yup.number()
      .required('Amount Requested is required')
      .min(1, 'Amount Requested should be greater than 0'),
    Credit_Card_Sales__c: Yup.number()
      .required('Credit Card Sales is required')
      .min(0, 'Credit Card Sales should not be less than 0'),
    Avg_Gross_Sales_Used__c: Yup.number()
      .required('Avg. Gross Sales (Used) is required')
      .min(1, 'Avg. Gross Sales (Used) should be greater than 0'),
  });

  useImperativeHandle(ref, () => ({
    isValid: async () => {
      const errors = await formikRef.current.validateForm();
      if(errors.constructor === Object && Object.keys(errors).length === 0){
        return true;
      }else{
        return false;
      }
    },
    getValues: () => {
      return formikRef.current.values
    },
    handleSubmit: () => {
      formikRef.current.handleSubmit();
    }
  }));

  return (
    <div className='mt-5 add-deal-step1'>
      <Formik 
    initialValues={{
      Id: initialVal.Id ? initialVal.Id : "",
      Name: initialVal.Name ? initialVal.Name : "",
      Legal_Name__c: initialVal.Legal_Name__c
        ? initialVal.Legal_Name__c
        : "",
      DBA__c: initialVal.DBA__c ? initialVal.DBA__c : "",
      Type_of_Entity__c: initialVal.Type_of_Entity__c
        ? initialVal.Type_of_Entity__c
        : "",
      Industry: initialVal.Industry ? initialVal.Industry : "",
      Date_Business_Established__c: initialVal.Date_Business_Established__c
        ? initialVal.Date_Business_Established__c
        : "",
      Federal_Tax_ID_No__c: initialVal.Federal_Tax_ID_No__c
        ? initialVal.Federal_Tax_ID_No__c
        : "",
      StageName:  "Submitted",
      Closedate: new Date(),
      Years_in_Business__c: initialVal.Years_in_Business__c
        ? initialVal.Years_in_Business__c
        : "",
      BillingStreet: initialVal.BillingStreet
        ? initialVal.BillingStreet
        : "",
      BillingCity: initialVal.BillingCity ? initialVal.BillingCity : "",
      BillingState: initialVal.BillingState ? initialVal.BillingState : "",
      BillingPostalCode: initialVal.BillingPostalCode
        ? initialVal.BillingPostalCode
        : "",
      Phone: initialVal.Phone ? initialVal.Phone : "",
      Fax: initialVal.Fax ? initialVal.Fax : "",
      Website: initialVal.Website ? initialVal.Website : "",
      Number_Bank_Statements_Available__c: initialVal.Bank_Stmt__c
        ? initialVal.Bank_Stmt__c
        : "",
      NDBs_avg__c: "",
      Use_of_Proceeds__c: "",
      Requested_Amount_of_Funds__c: "",
      Credit_Card_Sales__c: "",
      Avg_Gross_Sales_Used__c: "",
      ...values,
    }}
    onSubmit={(values, { setSubmitting }) => {
      setSubmitting(false);
    }}
    enableReinitialize={true}
    validationSchema={validationSchema}
    innerRef={formikRef}
  >
        {({ handleChange, values, errors, touched, handleBlur, setFieldValue, setFieldTouched }) => (
          <Form>
            <Row>
              {/*
            <Col md="6" sm="12" className='mb-4' >
                <FormGroup className={`${errors.Id && touched.Id ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>Business ID <span className='text-danger'>*</span></Label>
                  <Input
                    placeholder=""
                    type="text" 
                    name='Id'
                    onChange={handleChange('Id')}
                    onBlur={handleBlur('Id')}
                    value={values.Id}
                  />
                </FormGroup>
                <small className='text-danger'>{errors.Id && touched.Id ? errors.Id : null}</small>
              </Col>
        */}
              <Col md="6" sm="12" className='mb-4'>
                <FormGroup className={`${errors.Name && touched.Name ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>Business Name <span className='text-danger'>*</span></Label>
                  <Input
                    placeholder=""
                    type="text" 
                    name='Name'
                    onChange={handleChange('Name')}
                    onBlur={handleBlur('Name')}
                    value={values.Name}
                  />
                </FormGroup>
                <small className='text-danger'>{errors.Name && touched.Name ? errors.Name : null}</small>
              </Col>
              <Col md="6" sm="12" className='mb-4'>
                <FormGroup className={`${errors.Legal_Name__c && touched.Legal_Name__c ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>Legal Name <span className='text-danger'>*</span></Label>
                  <Input
                    placeholder=""
                    type="text"
                    name='Legal_Name__c'
                    onChange={handleChange('Legal_Name__c')}
                    onBlur={handleBlur('Legal_Name__c')}
                    value={values.Legal_Name__c}
                  />
                </FormGroup>
                <small className='text-danger'>{errors.Legal_Name__c && touched.Legal_Name__c ? errors.Legal_Name__c : null}</small>
              </Col>
              <Col md="6" sm="12" className='mb-4'>
                <FormGroup className={`${errors.DBA__c && touched.DBA__c ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>DBA <span className='text-danger'>*</span></Label>
                  <Input
                    placeholder=""
                    type="text" 
                    name='DBA__c'
                    onChange={handleChange('DBA__c')}
                    onBlur={handleBlur('DBA__c')}
                    value={values.DBA__c}
                  />
                </FormGroup>
                <small className='text-danger'>{errors.DBA__c && touched.DBA__c ? errors.DBA__c : null}</small>
              </Col>
              <Col md="6" sm="12" className='mb-4'>
                <Label className='text-medium'>Type of Entity <span className='text-danger'>*</span></Label>
                <Select
                  className="react-select info"
                  classNamePrefix="react-select"
                  placeholder="--None--"
                  name="Type_of_Entity__c"
                  value={getValueObject(LegalEntityType, values.Type_of_Entity__c)}
                  onChange={value => setFieldValue('Type_of_Entity__c', value.value)}
                  options={LegalEntityType}
                  onBlur={() => setFieldTouched('Type_of_Entity__c')}
                />
                <small className='text-danger'>{errors.Type_of_Entity__c && touched.Type_of_Entity__c ? errors.Type_of_Entity__c : null}</small>
              </Col>
              <Col md="6" sm="12" className='mb-4'>
                <Label className='text-medium'>Industry <span className='text-danger'>*</span></Label>
                <Select
                  className="react-select info"
                  classNamePrefix="react-select"
                  placeholder="--None--"
                  name="Industry"
                  value={getValueObject(industries, values.Sic)}
                  onChange={value => {
                    setFieldValue('Industry', value.label);
                    setFieldValue('Sic', value.value);
                  }}
                  options={industries}
                  onBlur={() => setFieldTouched('Industry')}
                />
                <small className='text-danger'>{errors.Industry && touched.Industry ? errors.Industry : null}</small>
              </Col>
              <Col sm='12' md='6'>
                <FormGroup className={`${errors.Date_Business_Established__c && touched.Date_Business_Established__c ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>Date Business Established <span className='text-danger'>*</span></Label>
                    <DatePicker
                      className='col-12'
                      value={values.Date_Business_Established__c && moment(values.Date_Business_Established__c)}
                      onChange={date => {
                        setFieldValue('Date_Business_Established__c', moment(date).format())
                      }}
                      onBlur={() => setFieldTouched('Date_Business_Established__c')}
                      allowClear={false}
                      format='MM/DD/YYYY'
                    />
                </FormGroup>
                <small className='text-danger'>{errors.Date_Business_Established__c && touched.Date_Business_Established__c ? errors.Date_Business_Established__c : null}</small>
              </Col>
              <Col md="6" sm="12" className='mb-4'>
                <FormGroup className={`${errors.Years_in_Business__c && touched.Years_in_Business__c ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>Years in Business <span className='text-danger'>*</span></Label>
                  <Input
                    placeholder=""
                    type="text" 
                    name='Years_in_Business__c'
                    onChange={handleChange('Years_in_Business__c')}
                    onBlur={handleBlur('Years_in_Business__c')}
                    value={values.Years_in_Business__c}
                  />
                </FormGroup>
                <small className='text-danger'>{errors.Years_in_Business__c && touched.Years_in_Business__c ? errors.Years_in_Business__c : null}</small>
              </Col>
              <Col md="6" sm="12" className='mb-4'>
                <FormGroup className={`${errors.Federal_Tax_ID_No__c && touched.Federal_Tax_ID_No__c ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>Federal Tax ID <span className='text-danger'>*</span></Label>
                  <Input
                    placeholder=""
                    type="text" 
                    name='Federal_Tax_ID_No__c'
                    onChange={e => setFieldValue('Federal_Tax_ID_No__c', formatTaxId(e.target.value))}
                    onBlur={handleBlur('Federal_Tax_ID_No__c')}
                    value={values.Federal_Tax_ID_No__c}
                  />
                </FormGroup>
                <small className='text-danger'>{errors.Federal_Tax_ID_No__c && touched.Federal_Tax_ID_No__c ? errors.Federal_Tax_ID_No__c : null}</small>
              </Col>
              {/*
              <Col md="6" sm="12" className='mb-4'>
                <Label className='text-medium'>Stage <span className='text-danger'>*</span></Label>
                <Select
                  className="react-select info"
                  classNamePrefix="react-select"
                  placeholder="--None--"
                  name="StageName"
                  value={getValueObject(OppStage, values.StageName)}
                  onChange={value => setFieldValue('StageName', value.value)}
                  options={OppStage}
                  onBlur={() => setFieldTouched('StageName')}
                />
                <small className='text-danger'>{errors.StageName && touched.StageName ? errors.StageName : null}</small>
                    </Col> */}
              <Col sm="12" md="6" className='mb-4'>
                <FormGroup className={`${errors.BillingStreet && touched.BillingStreet ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>Billing Street <span className='text-danger'>*</span></Label>
                  <Input
                    placeholder=""
                    type="text" 
                    name='BillingStreet'
                    onChange={handleChange('BillingStreet')}
                    onBlur={handleBlur('BillingStreet')}
                    value={values.BillingStreet}
                  />
                </FormGroup>
                <small className='text-danger'>{errors.BillingStreet && touched.BillingStreet ? errors.BillingStreet : null}</small>
              </Col>
              <Col md="6" sm="12" className='mb-4'>
                <FormGroup className={`${errors.BillingCity && touched.BillingCity ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>Billing City <span className='text-danger'>*</span></Label>
                  <Input
                    placeholder=""
                    type="text" 
                    name='BillingCity'
                    onChange={handleChange('BillingCity')}
                    onBlur={handleBlur('BillingCity')}
                    value={values.BillingCity}
                  />
                </FormGroup>
                <small className='text-danger'>{errors.BillingCity && touched.BillingCity ? errors.BillingCity : null}</small>
              </Col>
              <Col md="6" sm="12" className='mb-4'>
                <FormGroup className={`${errors.BillingState && touched.BillingState ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>Billing State/Province <span className='text-danger'>*</span></Label>
                  <Input
                    placeholder=""
                    type="text" 
                    name='BillingState'
                    onChange={handleChange('BillingState')}
                    onBlur={handleBlur('BillingState')}
                    value={values.BillingState}
                  />
                </FormGroup>
                <small className='text-danger'>{errors.BillingState && touched.BillingState ? errors.BillingState : null}</small>
              </Col>
              <Col md="6" sm="12" className='mb-4'>
                <FormGroup className={`${errors.BillingPostalCode && touched.BillingPostalCode ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>Shipping Zip/Postal Code <span className='text-danger'>*</span></Label>
                  <Input
                    placeholder=""
                    type="text" 
                    name='BillingPostalCode'
                    onChange={handleChange('BillingPostalCode')}
                    onBlur={handleBlur('BillingPostalCode')}
                    value={values.BillingPostalCode}
                  />
                </FormGroup>
                <small className='text-danger'>{errors.BillingPostalCode && touched.BillingPostalCode ? errors.BillingPostalCode : null}</small>
              </Col>
              <Col md="6" sm="12" className='mb-4'>
                <FormGroup className={`${errors.Phone && touched.Phone ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>Business Phone Number</Label>
                  <Input
                    placeholder=""
                    type="text" 
                    name='Phone'
                    onChange={handleChange('Phone')}
                    onBlur={handleBlur('Phone')}
                    value={values.Phone}
                  />
                </FormGroup>
                <small className='text-danger'>{errors.Phone && touched.Phone ? errors.Phone : null}</small>
              </Col>
              <Col md="6" sm="12" className='mb-4'>
                <FormGroup className={`${errors.Fax && touched.Fax ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>Business Fax</Label>
                  <Input
                    placeholder=""
                    type="text" 
                    name='Fax'
                    onChange={handleChange('Fax')}
                    onBlur={handleBlur('Fax')}
                    value={values.Fax}
                  />
                </FormGroup>
                <small className='text-danger'>{errors.Fax && touched.Fax ? errors.Fax : null}</small>
              </Col>
              <Col md="6" sm="12" className='mb-4'>
                <FormGroup className={`${errors.Website && touched.Website ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>Website</Label>
                  <Input
                    placeholder=""
                    type="text" 
                    name='Website'
                    onChange={handleChange('Website')}
                    onBlur={handleBlur('Website')}
                    value={values.Website}
                  />
                </FormGroup>
                <small className='text-danger'>{errors.Website && touched.Website ? errors.Website : null}</small>
              </Col>
              <Col md="6" sm="12" className='mb-4'>
                <FormGroup className={`${errors.Number_Bank_Statements_Available__c && touched.Number_Bank_Statements_Available__c ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>Number of Bank Statements Available</Label>
                  <Input
                    placeholder=""
                    type="text" 
                    name='Number_Bank_Statements_Available__c'
                    onChange={handleChange('Number_Bank_Statements_Available__c')}
                    onBlur={handleBlur('Number_Bank_Statements_Available__c')}
                    value={values.Number_Bank_Statements_Available__c}
                  />
                </FormGroup>
                <small className='text-danger'>{errors.Number_Bank_Statements_Available__c && touched.Number_Bank_Statements_Available__c ? errors.Number_Bank_Statements_Available__c : null}</small>
              </Col>
              <Col md="6" sm="12" className='mb-4'>
                <FormGroup className={`${errors.NDBs_avg__c && touched.NDBs_avg__c ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>NDB</Label>
                  <Input
                    placeholder=""
                    type="text" 
                    name='NDBs_avg__c'
                    onChange={handleChange('NDBs_avg__c')}
                    onBlur={handleBlur('NDBs_avg__c')}
                    value={values.NDBs_avg__c}
                  />
                </FormGroup>
                <small className='text-danger'>{errors.NDBs_avg__c && touched.NDBs_avg__c ? errors.NDBs_avg__c : null}</small>
              </Col>
              <Col md="6" sm="12" className='mb-4'>
                <FormGroup className={`${errors.Use_of_Proceeds__c && touched.Use_of_Proceeds__c ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>Use of Proceeds <span className='text-danger'>*</span></Label>
                  <Input
                    placeholder=""
                    type="text" 
                    name='Use_of_Proceeds__c'
                    onChange={handleChange('Use_of_Proceeds__c')}
                    onBlur={handleBlur('Use_of_Proceeds__c')}
                    value={values.Use_of_Proceeds__c}
                  />
                </FormGroup>
                <small className='text-danger'>{errors.Use_of_Proceeds__c && touched.Use_of_Proceeds__c ? errors.Use_of_Proceeds__c : null}</small>
              </Col>
              <Col md="6" sm="12" className='mb-4'>
                <FormGroup className={`${errors.Requested_Amount_of_Funds__c && touched.Requested_Amount_of_Funds__c ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>Amount Requested <span className='text-danger'>*</span></Label>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText className='sos-text-small'>$</InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder=""
                      type="text" 
                      name='Requested_Amount_of_Funds__c'
                      onChange={handleChange('Requested_Amount_of_Funds__c')}
                      onBlur={handleBlur('Requested_Amount_of_Funds__c')}
                      value={values.Requested_Amount_of_Funds__c}
                    />
                  </InputGroup>
                </FormGroup>
                <small className='text-danger'>{errors.Requested_Amount_of_Funds__c && touched.Requested_Amount_of_Funds__c ? errors.Requested_Amount_of_Funds__c : null}</small>
              </Col>
              <Col md="6" sm="12" className='mb-4'>
                <FormGroup className={`${errors.Credit_Card_Sales__c && touched.Credit_Card_Sales__c ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>Credit Card Sales <span className='text-danger'>*</span></Label>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText className='sos-text-small'>$</InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder=""
                      type="text" 
                      name='Credit_Card_Sales__c'
                      onChange={handleChange('Credit_Card_Sales__c')}
                      onBlur={handleBlur('Credit_Card_Sales__c')}
                      value={values.Credit_Card_Sales__c}
                    />
                  </InputGroup>
                </FormGroup>
                <small className='text-danger'>{errors.Credit_Card_Sales__c && touched.Credit_Card_Sales__c ? errors.Credit_Card_Sales__c : null}</small>
              </Col>
              <Col sm='12' md='6'>
                <FormGroup className={`${errors.Avg_Gross_Sales_Used__c && touched.Avg_Gross_Sales_Used__c ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>Avg. Gross Sales (Used) <span className='text-danger'>*</span></Label>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText className='sos-text-small'>$</InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder=""
                      type="text" 
                      name='Avg_Gross_Sales_Used__c'
                      onChange={handleChange('Avg_Gross_Sales_Used__c')}
                      onBlur={handleBlur('Avg_Gross_Sales_Used__c')}
                      value={values.Avg_Gross_Sales_Used__c}
                    />
                  </InputGroup>
                </FormGroup>
                <small className='text-danger'>{errors.Avg_Gross_Sales_Used__c && touched.Avg_Gross_Sales_Used__c ? errors.Avg_Gross_Sales_Used__c : null}</small>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default forwardRef(Step1);
