import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';

export const getBrokerMetricsStart = () => ({
  type: types.GET_BROKER_METRICS_START
});

export const getBrokerMetricsSuccess = payload => ({
  type: types.GET_BROKER_METRICS_SUCCESS,
  payload
});

export const getBrokerMetricsFail = payload => ({
  type: types.GET_BROKER_METRICS_FAIL,
  payload
});

export const getBrokerMetricsCleanup = () => ({
  type: types.GET_BROKER_METRICS_CLEANUP
});

export const getBrokerMetrics = portal => async dispatch => {
  try {
    dispatch(getBrokerMetricsStart());
    const requestObj = {
      path: `broker/${portal}/metrics`,
      method: "GET"
    }
    const  data  = await AxiosCall(requestObj);
    dispatch(getBrokerMetricsSuccess(data[0]));
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(getBrokerMetricsFail(error));
  }
}
