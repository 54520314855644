import "./index.css";
import React, { useState, useEffect } from "react";
import { Button, message, Form, Input, Divider, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import { useParams, useHistory } from "react-router-dom";
import ReactGA from "react-ga";

//actions
import {
  uploadBankStatement,
  uploadBankStatementCleanup,
} from "../../store/actions/upload-bank-statement";
import {
  connectToPlaid,
  connectToPlaidCleanup,
} from "../../store/actions/connect-to-plaid";
import {
  checkEmailExist,
  checkEmailExistCleanup,
} from "../../store/actions/check-email-exist";

//components
import UploadComponent from "../../components/UploadComponent";
import Loader from "../../components/Loader";

//utils
import useQuery from "../../utils/useQuery";

const PlaidPage = () => {
  const dispatch = useDispatch();
  const uploadBankStatementState = useSelector((s) => s.uploadBankStatement);
  const connectToPlaidState = useSelector((s) => s.connectToPlaid);
  const checkEmailExistState = useSelector((s) => s.checkEmailExist);
  const { opportunityId } = useParams();
  const [plaidEmail, setPlaidEmail] = useState(null);
  const [emailExist, setEmailExist] = useState(false);
  const history = useHistory();
  const [modalVisible, setModalVisible] = useState(false);
  const [acceptedFiles, setAcceptedFiles] = useState([]);
  const email = useQuery().get("email");

  const uploadAttachment = () => {
    if (!acceptedFiles.length) return;
    dispatch(uploadBankStatement({ documents: acceptedFiles }, opportunityId));
  };

  const handlePlaidConnection = () => {
    dispatch(connectToPlaid({ email: plaidEmail }, false));
  };

  const handleConfirmEmail = (values) => {
    setPlaidEmail(values.email);
    dispatch(checkEmailExist({ email: values.email }));
  };

  useEffect(() => {
    if (uploadBankStatementState.isSuccessful) {
      if (modalVisible) {
        message.success("credit card statement uploaded successfully", 2);
        setModalVisible(false);
      } else {
        message.success(
          "Bank statement and credit card statement uploaded successfully",
          2
        );
      }
      dispatch(uploadBankStatementCleanup());
      if (opportunityId) {
        history.push("/verified-success");
      }
    } else if (uploadBankStatementState.error) {
      message.error(`Oops! ${uploadBankStatementState.error}`, 2.5);
      dispatch(uploadBankStatementCleanup());
    }
  }, [uploadBankStatementState]);

  useEffect(() => {
    if (connectToPlaidState.isSuccessful) {
      message.success("Bank statement uploaded successfully", 2);
      dispatch(connectToPlaidCleanup());
      if (opportunityId) {
        history.push("/verified-success");
      }
      //setModalVisible(true);
    } else if (connectToPlaidState.error) {
      message.error(`Oops! ${connectToPlaidState.error}`, 2.5);
      dispatch(connectToPlaidCleanup());
    }
  }, [connectToPlaidState]);

  useEffect(() => {
    if (checkEmailExistState.isSuccessful) {
      setEmailExist(true);
      dispatch(checkEmailExistCleanup());
    } else if (checkEmailExistState.error) {
      message.error(`Oops! ${checkEmailExistState.error}`, 2.5);
      dispatch(checkEmailExistCleanup());
    }
  }, [checkEmailExistState]);

  useEffect(() => {
    const locationUrl = window.location.pathname.split("/");
    ReactGA.pageview(locationUrl[1], [], "Public fund application success");
  }, []);

  return (
    <div className="container plaid-page">
      <div className="content">
        <Row>
          <Col className="mr-auto ml-auto order-1 order-md-2" md="8" sm="12">
            <Card>
              <CardTitle className="text-center p-4">
                <h2 className="sos-text-primary mb-0 font-weight-bold">
                  Upload Bank Statement
                </h2>
              </CardTitle>
              <CardBody className="p-5">
                <Loader
                  isLoading={
                    checkEmailExistState.isLoading ||
                    connectToPlaidState.isLoading ||
                    uploadBankStatementState.isLoading
                  }
                >
                  <h4 className="text-center mt-0">
                    Connect to bank with Plaid
                  </h4>
                  {emailExist && plaidEmail ? (
                    <section className="text-center mt-5">
                      <Button
                        onClick={handlePlaidConnection}
                        className="sos-bg-primary text-white"
                        disabled={connectToPlaidState.isLoading}
                      >
                        Connect to bank with Plaid
                      </Button>
                    </section>
                  ) : (
                    <section className="text-center mt-5">
                      <Form
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        name="confirm-email"
                        onFinish={handleConfirmEmail}
                        className="p-4 justify-content-center"
                        layout="inline"
                      >
                        <Form.Item
                          name="email"
                          placeholder="Email"
                          rules={[
                            {
                              required: true,
                              message: "Please input your email!",
                            },
                            {
                              type: "email",
                              message: "Input a valid email!",
                            },
                          ]}
                          initialValue={email}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item>
                          <Button
                            htmlType="submit"
                            className="sos-bg-primary text-white"
                            disabled={checkEmailExistState.isLoading}
                          >
                            Confirm Email
                          </Button>
                        </Form.Item>
                      </Form>
                    </section>
                  )}
                  {opportunityId ? (
                    <div>
                      <Divider>OR</Divider>
                      <h4 className="text-center">
                        Manually Upload Statements
                      </h4>
                      <center>
                        <UploadComponent
                          setAcceptedFiles={setAcceptedFiles}
                          onSubmit={uploadAttachment}
                          disabled={uploadBankStatementState.isLoading}
                          title="Please upload your bank statement and credit card statement"
                        />
                      </center>
                      <Modal
                        title="Upload Credit Card Statement"
                        centered
                        visible={modalVisible}
                        footer={null}
                      >
                        <UploadComponent
                          setAcceptedFiles={setAcceptedFiles}
                          onSubmit={uploadAttachment}
                          disabled={uploadBankStatementState.isLoading}
                          title="Please upload your credit card statement"
                        />
                      </Modal>
                    </div>
                  ) : null}
                </Loader>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default PlaidPage;
