import React from 'react';
import { useSelector } from "react-redux";
import { Card, Divider, Spin } from 'antd';
import { CardTitle } from 'reactstrap';

//utils
import { formatCurrency } from '../../../../utils/helpers';

const SyndicatorDetails = () => {
  const getSyndicatorState = useSelector(s => s.getSyndicator);
  const syndicatorArr = useSelector(s => s.getSyndicator.data);

  return (
    <div className="container syndicator-details-page details-page">
      <div className="table-container">
        <Card>
          <CardTitle className="text-cente p-4">
            <div className="sos-text-primary mb-0 font-weight-bold d-flex justify-content-between">
              <div>Syndicator Details</div>
            </div>
          </CardTitle>
        </Card>
        <hr />
        {syndicatorArr.map((syndicator) => {
          return <Card key={syndicator.Id} >
          {getSyndicatorState.isLoading && !syndicator ? (
            <Spin size='large' className='loader' />
          ) : syndicator ? (
            <div>
              <Divider orientation="left">
                <h4 className='sos-font-weight-600 mt-0 mb-0'>Details</h4>
              </Divider>
              <div className='row'>
                <div className='col-md-6'>
                  <p>
                    <strong>Syndicator Name: </strong>{syndicator.Name ? syndicator.Name : ''}
                  </p>
                </div>
                <div className='col-md-6'>
                  <p>
                    <strong>Account: </strong>{syndicator.Account__r && syndicator.Account__r.Name 
                      ? syndicator.Account__r.Name : ''}
                  </p>
                </div>
                <div className='col-md-6'>
                  <p>
                    <strong>Contact: </strong>{syndicator.Contact__r && syndicator.Contact__r.Name 
                      ? syndicator.Contact__r.Name : ''}
                  </p>
                </div>
                <div className='col-md-6'>
                  <p>
                    <strong>Disbursement Schedule: </strong>{syndicator.Disbursement_Schedule__c ? syndicator.Disbursement_Schedule__c : ''}
                  </p>
                </div>
                <div className='col-md-6'>
                  <p>
                    <strong>% of Funding: </strong>{syndicator.Percentage_of_Funding__c ? syndicator.Percentage_of_Funding__c : 0}
                  </p>
                </div>
                <div className='col-md-6'>
                  <p>
                    <strong>Management Fee %: </strong>{syndicator.Management_Fee__c ? syndicator.Management_Fee__c : 0}
                  </p>
                </div>
                <div className='col-md-6'>
                  <p>
                    <strong>Management Fee % Of: </strong>{syndicator.Management_Fee_Of__c ? syndicator.Management_Fee_Of__c : ''}
                  </p>
                </div>
                <div className='col-md-6'>
                  <p>
                    <strong>Upfront Fee: </strong>{`${syndicator.Upfront_Fee__c ? formatCurrency(syndicator.Upfront_Fee__c) : 0}`}
                  </p>
                </div>
                <div className='col-md-6'>
                  <p>
                    <strong>Upfront Fee %: </strong>{syndicator.Upfront_Fee_percent__c ? syndicator.Upfront_Fee_percent__c : 0}
                  </p>
                </div>
                <div className='col-md-6'>
                  <p>
                    <strong>Upfront Fee % Of: </strong>{syndicator.Upfront_Fee_percent__c ? syndicator.Upfront_Fee_percent__c : 0}
                  </p>
                </div>
                <div className='col-md-6'>
                  <p>
                    <strong>Total Broker Fees: </strong>{`${syndicator.Total_Broker_Fees__c ? formatCurrency(syndicator.Total_Broker_Fees__c) : 0}`}
                  </p>
                </div>
                <div className='col-md-6'>
                  <p>
                    <strong>Syndications: </strong>{syndicator.Syndications__c ? syndicator.Syndications__c : 0}
                  </p>
                </div>
                <div className='col-md-6'>
                  <p>
                    <strong>Payables: </strong>{syndicator.Paybales__c ? syndicator.Paybales__c : 0}
                  </p>
                </div>
                <div className='col-md-6'>
                  <p>
                    <strong>Remittances: </strong>{syndicator.Remittances__c ? syndicator.Remittances__c : 0}
                  </p>
                </div>
                <div className='col-md-6'>
                  <p>
                    <strong>Payables Due: </strong>{syndicator.Payables_Due__c ? formatCurrency(syndicator.Payables_Due__c) : 0}
                  </p>
                </div>
                <div className='col-md-6'>
                  <p>
                    <strong>Amount Syndicated: </strong>{`${syndicator.Amount_Syndicated__c ? formatCurrency(syndicator.Amount_Syndicated__c) : 0}`}
                  </p>
                </div>
                <div className='col-md-6'>
                  <p>
                    <strong>Payables Paid: </strong>{`${syndicator.Payables_Paid__c ? formatCurrency(syndicator.Payables_Paid__c) : 0}`}
                  </p>
                </div>
                <div className='col-md-6'>
                  <p>
                    <strong>Management Fees: </strong>{`${syndicator.Management_Fees__c ? formatCurrency(syndicator.Management_Fees__c) : 0}`}
                  </p>
                </div>
                <div className='col-md-6'>
                  <p>
                    <strong>Syndication Balance: </strong>{`${syndicator.Syndication_balance__c ? formatCurrency(syndicator.Syndication_balance__c) : 0}`}
                  </p>
                </div>
                <div className='col-md-6'>
                  <p>
                    <strong>Total Invested: </strong>{`${syndicator.Total_Invested__c ? formatCurrency(syndicator.Total_Invested__c) : 0}`}
                  </p>
                </div>
              </div>
            </div>
          ) : null}
        </Card>
        })}
        
      </div>
    </div>
  );
}

export default SyndicatorDetails;
