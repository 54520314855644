// --------------- import external dpendencies --------------
import React from "react";
import { CloseOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

// --------------- import internal dpendencies --------------
// ------ import styles ------
import "./style.css";
// ------- import assets -------
import ThankYouIcon from "../../assets/svg/thank-you.svg";

function ThankYou() {
  return (
    <>
      <section>
        <div className="thankyou-wrapper">
          <div className="auth-box">
            <div className="close-box">
              <CloseOutlined style={{ fontSize: "1.6rem", color: "#375B8E" }} />
            </div>
            <div className="thankyou-content">
              <img src={ThankYouIcon} alt="thank-you" />
              <article>
                <h1>Thank you for Submitting, </h1>
                <span> To track your application process</span>
                <Link to="#">Create an account</Link>
              </article>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ThankYou;
