export const getValueObject = (arr, value) => {
  const valObj = arr ? arr.find(obj => obj.value === value ) : '';
  return valObj;
}

export const formatCurrency = value => {
  if(value){
    const formatter = new Intl.NumberFormat('en-Us', {
      style: 'currency',
      currency: 'USD'
    });
  
    return formatter.format(value);
  }
}

export const formatTaxId = value => {
  let formatted = value;
  const dash = value.slice(2,3);
  if(dash && dash !== '-'){
    formatted = `${value.slice(0,2)}-${value.slice(2)}`
  }
  return formatted;
}

export const formatSSN = value => {
  value = value.replace(/\D/g,'');
  //if(value.length > 9){
    //value = value.substring(0,9)
  //}
  let formatted = value;
  const dash = value.slice(3,4);
  if(dash && dash !== '-'){
    formatted = `${value.slice(0,3)}-${value.slice(3)}`
  }

  const secondDash = value.slice(6,7);
  if(secondDash && secondDash !== '-'){
    formatted = `${formatted.slice(0,6)}-${formatted.slice(6)}`
  }
  return formatted;
}

export const formatPhone = value => {
  value = value.replace(/\D/g,'');
  //if(value.length > 10){
    //value = value.substring(0,10)
  //}
  let formatted = value;
  const dash = value.slice(3,4);
  if(dash && dash !== '-'){
    formatted = `${value.slice(0,3)}-${value.slice(3)}`
  }

  const secondDash = value.slice(7,8);
  if(secondDash && secondDash !== '-'){
    formatted = `${formatted.slice(0,7)}-${formatted.slice(7)}`
  }

  return formatted;
}