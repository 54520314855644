import "./styles/app.css";
import React from "react";
import ReactGA from "react-ga";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import Routes from "./routes";
import { store, persistor } from "./store";

// ---------- test account code -----------
// const trackingId = "UA-226673348-1";

// ---------- production account code marketing ----------
// const trackingId = "UA-227213316-1";

// ---------- production account code moises ----------
const trackingId = "UA-57803709-1";

ReactGA.initialize(trackingId);

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Routes />
      </PersistGate>
    </Provider>
  );
};

export default App;
