import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';

export const getSingleOffersStart = () => ({
  type: types.GET_SINGLE_OFFER_START
});

export const getSingleOffersSuccess = payload => ({
  type: types.GET_SINGLE_OFFER_SUCCESS,
  payload
});

export const getSingleOffersFail = payload => ({
  type: types.GET_SINGLE_OFFER_FAIL,
  payload
});

export const getSingleOffersCleanup = () => ({
  type: types.GET_SINGLE_OFFER_CLEANUP
});

export const getSingleOffers = (portal,id )=> async dispatch => {
  try {
    dispatch(getSingleOffersStart());
    const requestObj = {
      path: `offer/${portal}/${id}`,
      method: "GET"
    }
    const  data  = await AxiosCall(requestObj);
    dispatch(getSingleOffersSuccess(data));
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(getSingleOffersFail(error));
  }
}
