import React from "react";
import { Modal, Button, Spin, Alert  } from "antd";

const AccountOptionModal = ({
  children,
  modalVisible,
  setModalVisible,
  loading,
  accountNum,
}) => {
  return (
    <>
      <Button className={accountNum<1&&"d-none" }type="primary" onClick={() => setModalVisible(true)}>
        Existing Account
      </Button>
      <Modal
        closable={false}
        title={null}
        style={{ top: 20}}
        width={1520}
        visible={modalVisible}
        footer={<Button onClick={() => setModalVisible(false)}>Close</Button>}
      >
        <Spin spinning={loading} tip="Loading...">
          <Alert
            message="Select from an existing account"
            description="To enable you proceed an application with an existing account without getting to restart, kindly select and account or close to continue as a new account."
            type="info"
            closable
          />
          <p className="pt-5" style={{ overflowX:"auto", maxHeight:"90vh" }}>{children}</p>
        </Spin>
      </Modal>
    </>
  );
};

export default AccountOptionModal;
