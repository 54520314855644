import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

class Footer extends React.Component {
  render() {
    return (
      <footer
        className="page-footer font-small indigo"
        style={{ background: "#292F4A" }}
      >
        <div class="container text-center text-md-left pt-5 pb-5">
          <div class="row">
            <div class="col-md-3 mx-auto">
              <h5 class="font-weight-bold text-uppercase mt-3 mb-4 text-white footer-links">
                OUR PRODUCTS
              </h5>

              <ul class="list-unstyled">
                <li>
                  <Link to="/contact" className="footer-links">
                    Brokers/ISO'S
                  </Link>
                </li>
                <li>
                  <a href="https://altbanq.com/free-money-mondays/" className="footer-links">
                    Free money mondays
                  </a>
                </li>
               
                <li>
                  <a href="https://altbanq.com/our-products/" className="footer-links">
                    Term Loan
                  </a>
                </li>
              </ul>
            </div>

            <hr class="clearfix w-100 d-md-none" />

            <div class="col-md-3 mx-auto">
              <h5 class="font-weight-bold text-uppercase mt-3 mb-4 text-white footer-links">
                COMPANY
              </h5>

              <ul class="list-unstyled">
                <li>
                  <a href="https://altbanq.com/investor-relations/" className="footer-links">
                    Our process
                  </a>
                </li>
                <li>
                  <a href="https://altbanq.com/about-us/" className="footer-links">
                    About us
                  </a>
                </li>
                <li>
                  <a href="https://altbanq.com/for-press-2/" className="footer-links">
                    FAQ
                  </a>
                </li>
              </ul>
            </div>

            <hr class="clearfix w-100 d-md-none" />

            <div class="col-md-3 mx-auto">
              <h5 class="font-weight-bold text-uppercase mt-3 mb-4 text-white footer-links">
                RESOURCES
              </h5>

              <ul class="list-unstyled">
                <li>
                  <a href="https://www.trustpilot.com/review/altbanq.com" className="footer-links">
                    Client Reviews
                  </a>
                </li>
                <li>
                  <a href="https://altbanq.com/blog/" className="footer-links">
                    Blog
                  </a>
                </li>
              </ul>
            </div>

            <hr class="clearfix w-100 d-md-none" />

            <div class="col-md-3 mx-auto">
              <h5 class="font-weight-bold text-uppercase mt-3 mb-4 text-white footer-links footer-links">
                CONTACT US
              </h5>

              <ul class="list-unstyled">
                <li>
                  <a href="#!" className="footer-links">
                    (212) 235-5455
                  </a>
                </li>
                <li>
                  <a href="mailto:info@altbanq.com?subject=\info@altbanq.com" className="footer-links">
                    info@altbanq.com
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <hr className="bg-white w-100" />
        <div className=" container d-flex justify-content-between   pt-5 pb-5">
          <div>
            <a href="#!" className="footer-links mr-5">
              Legal{" "}
            </a>

            <a href="#!" className="footer-links">
              {" "}
              Privacy
            </a>
          </div>

          <div className="d-flex">
            <section>
              <a href="https://twitter.com/altbanqinc?lang=es" target="_blank" className="footer-social">
                <i className="fa fa-twitter"></i> Twitter |
              </a>
              <a href="https://web.facebook.com/altbanqinc?_rdc=1&_rdr" target="_blank" className="footer-social">
                <i className="fa fa-facebook"></i> Facebook |
              </a>
              <a href="https://www.linkedin.com/company/altbanq/" target="_blank"  className="footer-social">
                <i className="fa fa-linkedin"></i> LinkedIn |
              </a>
              <a href="https://www.instagram.com/altbanq/" target="_blank"  className="footer-social">
                <i className="fa fa-instagram"></i> instagram
              </a>
            </section>
          </div>
        </div>
      </footer>

      // </div>
    );
  }
}

Footer.propTypes = {
  default: PropTypes.bool,
  fluid: PropTypes.bool,
};

export default Footer;
