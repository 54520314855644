import "./index.css";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Card, Divider } from "antd";
import { CardTitle } from "reactstrap";
import moment from "moment";

//actions
import {
  getSyndicatorDeals,
  getSyndicatorDealsCleanup,
} from "../../../store/actions/get-syndicators-deals";

//components
import Loader from "../../../components/Loader";

//utils
import { formatCurrency } from "../../../utils/helpers";

const SyndicationDealDetailsSection = ({ type, id }) => {
  const dispatch = useDispatch();
  const getSyndicatorDealsState = useSelector((s) => s.getSyndicatorDeals);
  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(getSyndicatorDeals(false, type, id));
  }, []);

  useEffect(() => {
    if (getSyndicatorDealsState.isSuccessful) {
      setData(getSyndicatorDealsState.data[0]);
      dispatch(getSyndicatorDealsCleanup());
    } else if (getSyndicatorDealsState.error) {
      dispatch(getSyndicatorDealsCleanup());
    }
  }, [getSyndicatorDealsState]);

  return (
    <div className=" container broker-deal-details-page">
      <div className="table-container-syn">
        <Card>
          <CardTitle className="text-cente p-4">
            <div className="sos-text-primary mb-0 font-weight-bold d-flex justify-content-between">
              <div>Syndication Details</div>
            </div>
          </CardTitle>
        </Card>
        <hr />
        <Card>
          {getSyndicatorDealsState.isLoading && !data ? (
            <div className="m-5 d-flex justify-content-center">
              <Loader isLoading={getSyndicatorDealsState.isLoading} />
            </div>
          ) : data ? (
            <div>
              <Divider orientation="center">
                <h4 className="sos-font-weight-600 mt-0 mb-0">Details</h4>
              </Divider>
              <div className="row">
                <div className="col-md-6">
                  <p>
                    <strong>Syndicator: </strong>
                    {data.Syndicator__r && data.Syndicator__r.Name}
                  </p>
                  <p>
                    <strong>Syndicator Name: </strong>
                    {data.Syndicator__r &&
                      data.Syndicator__r.Contact__r &&
                      data.Syndicator__r.Contact__r.Name}
                  </p>
                  <p>
                    <strong>Repayment: </strong>
                    {data.RepaymentId__r && data.RepaymentId__r.Name}
                  </p>
                  <p>
                    <strong>Disbursement Schedule: </strong>
                    {data.Disbursement_Schedule__c}
                  </p>
                  <p>
                    <strong>Investment Amount: </strong>
                    {formatCurrency(
                      data.Investment_Amount__c === null
                        ? 0
                        : data.Investment_Amount__c
                    )}
                  </p>
                  <p>
                    <strong>Repayment (Gross) Amount: </strong>
                    {formatCurrency(
                      data.Repayment_Amt_Gross__c === null
                        ? 0
                        : data.Repayment_Amt_Gross__c
                    )}
                  </p>
                  <p>
                    <strong> Gross % Participation in Deal: </strong>
                    {data.Gross_Participation_in_Deal__c}%
                  </p>
                  <p>
                    <strong>Syndication Age: </strong>
                    {data.Syndication_Age__c} days old
                  </p>
                  <p>
                    <strong>Net Payback Amount: </strong>
                    {formatCurrency(
                      data.Net_Payback_Amt__c === null
                        ? 0
                        : data.Net_Payback_Amt__c
                    )}
                  </p>
                  <p>
                    <strong>% of Payback: </strong>
                    {data.of_Payback__c}%
                  </p>
                  <p>
                    <strong> Total Paid: </strong>
                    {formatCurrency(
                      data.Total_Paid_Formula__c === null
                        ? 0
                        : data.Total_Paid_Formula__c
                    )}
                  </p>
                  <p>
                    <strong> Total Payables: </strong>
                    {formatCurrency(
                      data.Total_Payables_Formula__c === null
                        ? 0
                        : data.Total_Payables_Formula__c
                    )}
                  </p>
                  <p>
                    <strong>Syndicator Payback Amount: </strong>
                    {formatCurrency(
                      data.Syndicator_Payback_Amt__c === null
                        ? 0
                        : data.Syndicator_Payback_Amt__c
                    )}
                  </p>
                  <p>
                    <strong>Upfront Fee: </strong>
                    {formatCurrency(
                      data.Upfront_Fee__c === null ? 0 : data.Upfront_Fee__c
                    )}
                  </p>
                  <p>
                    <strong>Upfront Fee percent(%): </strong>
                    {data.Upfront_Fee_percent__c}%
                  </p>
                  <p>
                    <strong>Upfront Fee percent % of: </strong>
                    {data.Upfront_Fee_Of__c}
                  </p>
                  <p>
                    <strong>Total Outstanding (Less Paid and Due): </strong>
                    {formatCurrency(
                      data.Total_Outstanding_Less_Paid_and_Due__c === null
                        ? 0
                        : data.Total_Outstanding_Less_Paid_and_Due__c
                    )}
                  </p>
                </div>

                <div className="col-md-6">
                  <p>
                    <strong>Syndication Date: </strong>
                    {data.Syndication_Date__c
                      ? moment(data.Syndication_Date__c).format("MMMM Do, YYYY")
                      : "N/A"}
                  </p>
                  <p>
                    <strong>Percentage Funding: </strong>
                    {data.Percent_Of_Funding__c}%
                  </p>
                  <p>
                    <strong>Gross Profit: </strong>
                    {formatCurrency(
                      data.Gross_Profit__c === null ? 0 : data.Gross_Profit__c
                    )}
                  </p>
                  <p>
                    <strong>Gross % Return: </strong>
                    {data.Gross_P_Return__c}%
                  </p>
                  <p>
                    <strong>Net Profit: </strong>
                    {formatCurrency(
                      data.Net_D_Profit__c === null ? 0 : data.Net_D_Profit__c
                    )}
                  </p>
                  <p>
                    <strong>Net % Return: </strong>
                    {data.Net_P_Return__c}%
                  </p>
                  <p>
                    <strong>Deal Funding Amount: </strong>
                    {formatCurrency(
                      data.Deal_Funding_Amount__c === null
                        ? 0
                        : data.Deal_Funding_Amount__c
                    )}
                  </p>
                  <p>
                    <strong>Broker Commission: </strong>
                    {formatCurrency(
                      data.Broker_Commission__c === null
                        ? 0
                        : data.Broker_Commission__c
                    )}
                  </p>
                  <p>
                    <strong>Total Cash Out: </strong>
                    {formatCurrency(
                      data.Total_Cash_Out__c === null
                        ? 0
                        : data.Total_Cash_Out__c
                    )}
                  </p>
                  <p>
                    <strong>Deal Payback Amount: </strong>
                    {formatCurrency(
                      data.Deal_Payback_Amount__c === null
                        ? 0
                        : data.Deal_Payback_Amount__c
                    )}
                  </p>
                  <p>
                    <strong>Projected Monthly Payment Amount: </strong>
                    {formatCurrency(
                      data.Projected_Monthly_Pmt_Amt__c === null
                        ? 0
                        : data.Projected_Monthly_Pmt_Amt__c
                    )}
                  </p>
                  <p>
                    <strong>Total Due: </strong>
                    {formatCurrency(
                      data.Total_Due__c === null ? 0 : data.Total_Due__c
                    )}
                  </p>
                </div>
              </div>
              <Divider orientation="center">
                <h4 className="sos-font-weight-600 mt-0 mb-0">
                  Balances and Fees
                </h4>
              </Divider>
              <div className="row">
                <div className="col-md-6">
                  <p>
                    <strong>Syndication Amount: </strong>
                    {formatCurrency(
                      data.Syndication_Amount__c === null
                        ? 0
                        : data.Syndication_Amount__c
                    )}
                  </p>
                  <p>
                    <strong>Syndication Balance: </strong>
                    {formatCurrency(
                      data.Syndication_Balance_Formula__c === null
                        ? 0
                        : data.Syndication_Balance_Formula__c
                    )}
                  </p>
                </div>

                <div className="col-md-6">
                  <p>
                    <strong>Management Fee: </strong>
                    {formatCurrency(
                      data.Management_Fee__c === null
                        ? 0
                        : data.Management_Fee__c
                    )}
                  </p>
                  <p>
                    <strong>Management Fee Percent: </strong>
                    {data.Management_Fee_Percent__c}%
                  </p>
                  <p>
                    <strong>Management Fee Percent Of: </strong>
                    {data.Management_Fee_Of__c}
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <h4>Details not found</h4>
          )}
        </Card>
      </div>
    </div>
  );
};

export default SyndicationDealDetailsSection;
