import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Card, Divider, Spin, Tag } from 'antd';
import { CardTitle } from 'reactstrap';
import { useParams } from 'react-router-dom';
import moment from 'moment';

//actions
import { getSinglePayment, getSinglePaymentCleanup } from '../../../store/actions/get-single-payment';

//utils
import { formatCurrency } from '../../../utils/helpers';

const PaymentDetails = () => {
  const dispatch = useDispatch();
  const getSinglePaymentState = useSelector(s => s.getSinglePayment);
  const [paymentDetails, setPaymentDetails] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    dispatch(getSinglePayment(id));
  },[])

  useEffect(() => {
    if(getSinglePaymentState.isSuccessful){
      setPaymentDetails(getSinglePaymentState.data);
      dispatch(getSinglePaymentCleanup());
    }else if(getSinglePaymentState.error){
      dispatch(getSinglePaymentCleanup());
    }
  },[getSinglePaymentState])

  return (
    <div className="pt-5  container payment-details-page details-page">
      <div className="pt-5 table-container">
        <Card>
          <CardTitle className="text-cente p-4">
            <div className="sos-text-primary mb-0 font-weight-bold d-flex justify-content-between">
              <div>Payment Details</div>
            </div>
          </CardTitle>
        </Card>
        <hr />
        <Card>
          {getSinglePaymentState.isLoading && !paymentDetails ? (
            <Spin size='large' className='loader' />
          ) : paymentDetails ? (
            <div>
              <Divider orientation="left">
                <h4 className='sos-font-weight-600 mt-0 mb-0'>Payment Details</h4>
              </Divider>
              <div className='row'>
                <div className='col-md-6'>
                  <p>
                    <strong>Transaction Type: </strong>{paymentDetails.Transaction_Type__c ? paymentDetails.Transaction_Type__c : ''}
                  </p>
                </div>
                <div className='col-md-6'>
                  <p>
                    <strong>Settlement Date: </strong>{paymentDetails.Settlement_Date__c ? moment(paymentDetails.Settlement_Date__c).format('MMMM Do, YYYY') : 'N/A'}
                  </p>
                </div>
                <div className='col-md-6'>
                  <p>
                    <strong>Status: </strong><Tag color={paymentDetails.Status__c === 'Settled' ? 'green' : ''}>{paymentDetails.Status__c ? paymentDetails.Status__c : ''}</Tag>
                  </p>
                </div>
                <div className='col-md-6'>
                  <p>
                    <strong>Balance: </strong>{`${paymentDetails.Running_Balance__c ? formatCurrency(paymentDetails.Running_Balance__c) : ''}`}
                  </p>
                </div>
                <div className='col-md-6'>
                  <p>
                    <strong>Payment Method: </strong>{paymentDetails.Payment_Method__c ? paymentDetails.Payment_Method__c : ''}
                  </p>
                </div>
                <div className='col-md-6'>
                  <p>
                    <strong>Check: </strong>{paymentDetails.Check__c ? paymentDetails.Check__c : ''}
                  </p>
                </div>
                <div className='col-md-6'>
                  <p>
                    <strong>Sequence: </strong>{paymentDetails.Sequence__c ? paymentDetails.Sequence__c : ''}
                  </p>
                </div>
                <div className='col-md-6'>
                  <p>
                    <strong>Payment Frequency: </strong>{paymentDetails.Payment_Frequency__c ? paymentDetails.Payment_Frequency__c : ''}
                  </p>
                </div>
                <div className='col-md-6'>
                  <p>
                    <strong>Effective Date: </strong>{paymentDetails.Effective_Date__c ? moment(paymentDetails.Effective_Date__c).format('MMMM Do, YYYY') : 'N/A'}
                  </p>
                </div>
              </div>
            </div>
          ) : null}
        </Card>
      </div>
    </div>
  );
}

export default PaymentDetails;
