import './index.css';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Card, Progress } from 'antd';
import { CardTitle, Card as RCard, CardBody, Row, Col } from 'reactstrap';

//utils
import { formatCurrency } from '../../../utils/helpers';

//components
import Loader from '../../../components/Loader';

//actions
import { getProfile } from '../../../store/actions/get-profile';

const ReferrerMetrics = () => {
  const dispatch = useDispatch();
  const profile = useSelector(s => s.getProfile.data);
  const profileLoading = useSelector(s => s.getProfile.isLoading);

  useEffect(() => {
    if(!profile){
      dispatch(getProfile());
    }
  },[]);

  return (
    <div className="pt-5  container referrer-metrics-page">
      <div className="pt-5 table-container">
        <Card>
          <CardTitle className="text-cente p-4">
            <div className="sos-text-primary mb-0 font-weight-bold d-flex justify-content-between">
              <div>Referrer Metrics</div>
            </div>
          </CardTitle>
        </Card>
        <hr />
        <Card>
            {profileLoading&& !profile ? (
              <div className='m-5 d-flex justify-content-center'>
                <Loader isLoading={profileLoading && !profile} />
              </div>
            ) : (
            <div>
              <Row>
                <Col lg="6" md="6" sm="12">
                  <RCard className="card-stats">
                    <CardBody>
                      <Row>
                        <Col md="4" xs="5">
                          <div className="icon-big text-center icon-warning">
                            <i className="fa fa-share-square-o text-primary" />
                          </div>
                        </Col>
                        <Col md="8" xs="7">
                          <div className="numbers">
                            <p className="card-category">Total Number of Referrals</p>
                            <CardTitle tag="p">{profile?.Total_Number_of_Referrals__c || 0}</CardTitle>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </RCard>
                </Col>
                <Col lg="6" md="6" sm="12">
                  <RCard className="card-stats">
                    <CardBody>
                      <Row>
                        <Col md="4" xs="5">
                          <div className="icon-big text-center icon-warning">
                            <i className="fa fa-money text-warning" />
                          </div>
                        </Col>
                        <Col md="8" xs="7">
                          <div className="numbers">
                            <p className="card-category">Total Amount Referred</p>
                            <CardTitle tag="p">{formatCurrency(profile?.Total_Amount_Referred__c || 0)}</CardTitle>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </RCard>
                </Col>
                <Col lg="6" md="6" sm="12">
                  <RCard className="card-stats">
                    <CardBody>
                      <Row>
                        <Col md="4" xs="5">
                          <div className="icon-big text-center icon-warning">
                            <i className="fa fa-line-chart text-success" />
                          </div>
                        </Col>
                        <Col md="8" xs="7">
                          <div className="numbers">
                            <p className="card-category">Number of Funded Referrals</p>
                            <CardTitle tag="p">{profile?.Number_of_Funded_Referrals__c || 0}</CardTitle>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </RCard>
                </Col>
                <Col lg="6" md="6" sm="12">
                  <RCard className="card-stats">
                    <CardBody>
                      <Row>
                        <Col md="4" xs="5">
                          <div className="icon-big text-center icon-warning">
                            <i className="fa fa-cubes text-primary" />
                          </div>
                        </Col>
                        <Col md="8" xs="7">
                          <div className="numbers">
                            <p className="card-category">Total Funded Amount from Referrals</p>
                            <CardTitle tag="p">{formatCurrency(profile?.Total_Funded_Amount_from_Referrals__c || 0)}</CardTitle>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </RCard>
                </Col>
                <Col lg="6" md="6" sm="12">
                  <RCard className="card-stats">
                    <CardBody>
                      <Row>
                        <Col md="4" xs="5">
                          <div className="icon-big text-center icon-warning">
                            <i className="nc-icon nc-money-coins text-warning" />
                          </div>
                        </Col>
                        <Col md="8" xs="7">
                          <div className="numbers">
                            <p className="card-category">Total Commissions</p>
                            <CardTitle tag="p">{formatCurrency(profile?.Total_Commissions__c || 0)}</CardTitle>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </RCard>
                </Col>
                <Col lg="6" md="6" sm="12">
                  <RCard className="card-stats">
                    <CardBody>
                      <Row>
                        <Col md="4" xs="5">
                          <div className="icon-big text-center icon-warning">
                            <i className="fa fa-check-circle-o text-success" />
                          </div>
                        </Col>
                        <Col md="8" xs="7">
                          <div className="numbers">
                            <p className="card-category">Total Commissions Paid</p>
                            <CardTitle tag="p">{formatCurrency(profile?.Total_Commissions_Paid__c || 0)}</CardTitle>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </RCard>
                </Col>
                <Col lg="6" md="6" sm="12">
                  <RCard className="card-stats">
                    <CardBody>
                      <Row>
                        <Col md="4" xs="5">
                          <div className="icon-big text-center icon-warning">
                            <i className="fa fa-exclamation-circle text-danger" />
                          </div>
                        </Col>
                        <Col md="8" xs="7">
                          <div className="numbers">
                            <p className="card-category">Total Commissions Outstanding</p>
                            <CardTitle tag="p">{formatCurrency(profile?.Total_Commissions_Outstanding__c || 0)}</CardTitle>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </RCard>
                </Col>
              </Row>
            </div>
          )}
        </Card>
      </div>
    </div>
  )
}

export default ReferrerMetrics;
