import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';

export const uploadBankStatementStart = () => ({
  type: types.UPLOAD_BANK_STATEMENT_START
});

export const uploadBankStatementSuccess = payload => ({
  type: types.UPLOAD_BANK_STATEMENT_SUCCESS,
  payload
});

export const uploadBankStatementFail = payload => ({
  type: types.UPLOAD_BANK_STATEMENT_FAIL,
  payload
});

export const uploadBankStatementCleanup = () => ({
  type: types.UPLOAD_BANK_STATEMENT_CLEANUP
});

export const uploadBankStatement = (payload, opportunityId) => async dispatch => {
  try {
    dispatch(uploadBankStatementStart());
    const form = new FormData();
    payload.documents.forEach((document, index) => form.append(`bank_statement-${index}`, document));
    const requestObj = {
      path: `attachment/upload/bankstatement/${opportunityId}`,
      method: "POST",
      data: form,
      contentType: 'multipart/form-data'
    }
    const data = await AxiosCall(requestObj);
    dispatch(uploadBankStatementSuccess(data));
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(uploadBankStatementFail(error));
  }
}
