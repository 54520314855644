import React from 'react';

const Dashboard = () => {
  return (
    <div>
      <h4>Hello Dashboard</h4>
    </div>
  )
};

export default Dashboard;
