export const login = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null
};

export const applyForFunding = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null
};

export const myDeals = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null
};

export const myAdvances= {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null
};

export const uploadBankStatement = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null
};

export const connectToPlaid = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null
};

export const checkEmailExist = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null
};

export const getAdvanceDetails = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null
}

export const dealsDetails = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null
};

export const getDealsOffer = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null
};

export const getSingleOffer= {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null
};

export const getRepayments = {
  isLoading: false,
  isSuccessful: false,
  data: [],
  error: null
};

export const getSingleRepayment = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null
};

export const getPayments = {
  isLoading: false,
  isSuccessful: false,
  data: [],
  error: null
};

export const getSinglePayment = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null
};

export const getAllOffers= {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null
};

export const getApplicantMatric = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null
};

export const getBrokerMetricsByIso = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null
};

export const getMyISODeals= {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null
};

export const getBrokerMetrics = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null
};

export const getMyISOOppurtunity = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null
};

export const getMyISOStipulation = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null
};

export const getMyISOOffers = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null
};
 
export const getMyISORepayments = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null
};

export const getMyISOStageHistory = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null
};

export const getSingleOfferInfo = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null
};

export const getSingleOfferSummary = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null
};

export const getSingleStipulationAttachment = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null
};

export const addStipulationAttachment = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null
};

export const getProfile = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null
};

export const getISOSingleRepayment = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null
};

export const getLookupList = {
  isLoading: false,
  isSuccessful: false,
  data: {},
  error: null
};

export const createBrokerFunding ={
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null
};

export const getSyndicator = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null
};

export const getSyndicatorDeals = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null
};

export const getFlexOffer = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null
};

export const updateOffer = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null
};

export const getAccount = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null
};

export const getSingleAccount = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null
};

export const createWebToLead = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null
};

export const searchInstitution = {
  isLoading: false,
  isSuccessful: false,
  data: [],
  error: null
};

export const savePSAInformation = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null
};

export const updateContact = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null
};

export const generatePaymentHistory = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null
};

export const generatePaymentHistorySyndicator = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null
};

export const generateReferralCode = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null
}

export const updateBankDetails = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null
};
