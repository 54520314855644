// ------------- import external dependncies -------------
import React from "react";
import { Switch, Route } from "react-router-dom";

// ------------ import internal dependncies ------------
import { newPublicRoutes } from "../routes/routes";
// ------ import assets ------
import BrandLogo from "../assets/img/SOS.logo.png";

function NewPublicLayout() {
  return (
    <>
      <main className="public-auth">
        <div className="brand_logo">
          <img src={BrandLogo} alt="logo" style={{ height: "56px" }} />
        </div>
        {newPublicRoutes.map((prop, key) => (
          <Route
            exact={prop.layout === "/new-public"}
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        ))}
      </main>
    </>
  );
}

export default NewPublicLayout;
