import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';

export const getApplicantMatricStart = () => ({
  type: types.GET_APPLICANT_MATRIC_START
});

export const getApplicantMatricSuccess = payload => ({
  type: types.GET_APPLICANT_MATRIC_SUCCESS,
  payload
});

export const getApplicantMatricFail = payload => ({
  type: types.GET_APPLICANT_MATRIC_FAIL,
  payload
});

export const getApplicantMatricCleanup = () => ({
  type: types.GET_APPLICANT_MATRIC_CLEANUP
});

export const getApplicantMatric = portal => async dispatch => {
  try {
    dispatch(getApplicantMatricStart());
    const path =  `applicant/${portal}/metrics`;
    const requestObj = {
      path,
      method: 'GET'
    }
    const  data  = await AxiosCall(requestObj);
    dispatch(getApplicantMatricSuccess(data[0]));
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(getApplicantMatricFail(error));
  }
}

