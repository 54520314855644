// ------------ import external dependencies ------------
import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { useHistory } from "react-router-dom";
import { Card, CardBody } from "reactstrap";

// ------------ import internal dependencies -------------
import "./index.css";

const ApplicationSuccess = () => {
  // ------ intialize custom hooks -------
  const history = useHistory();

  const userLocation = history.location;

  useEffect(() => {
    const userPath = window.location.pathname + window.location.search;
    const splitPath = userPath.split("/");
    let title = "";

    console.log(userLocation);

    if (splitPath === "user") {
      title = "User fund application Success";
    } else if (splitPath === "broker") {
      title = "Broker fund application Success";
    } else {
      // ------ check if route is from contact to specify path to track ------
      title =
        userLocation?.state?.from === "contact-us"
          ? "New-broker-success"
          : "Public fund verified Success";
    }

    ReactGA.pageview(
      window.location.pathname + window.location.search,
      [],
      title
    );
  }, []);

  return (
    <div className="container application-success">
      <div className="content">
        <Card className="success-con">
          {userLocation?.state?.from === "contact-us" ? (
            <CardBody>
              <h4 className="m-5 text-center sos-text-primary sos-font-weight-700">
                Thank you for contacting us. We will be in touch with you
                shortly.
              </h4>
            </CardBody>
          ) : (
            <CardBody>
              <h4 className="m-5 text-center sos-text-primary sos-font-weight-700">
                Thank you for submitting your application. One of our
                representatives will be reaching out to you shortly.
              </h4>
            </CardBody>
          )}
        </Card>
      </div>
    </div>
  );
};

export default ApplicationSuccess;
