let PLAID_CLIENT_ID = "";
let PLAID_PUBLIC_KEY = "";
let PLAID_PRODUCTS = "";
let PLAID_COUNTRY_CODES = "";
let PLAID_ENV = "";
let PLAID_WEBHOOKURL = "";
let serverUrl = "";
let plaidcallback = "";
let clientUrl = "";

if (process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === "production") {
  serverUrl = "https://portal.altbanq.com";
  clientUrl = "https://portal.altbanq.com";
  plaidcallback = serverUrl;
  PLAID_ENV = "production";
  PLAID_CLIENT_ID = "5cebe2b93462c600135e38ef";
  PLAID_PUBLIC_KEY = "c674a873eae8d60805edd080ec30b8";
  PLAID_PRODUCTS = "transactions,assets";
  PLAID_COUNTRY_CODES = "US";
  PLAID_WEBHOOKURL = 'https://soscapital.secure.force.com/plaid/services/apexrest/plaidprocesscsv';
} else if (
  process.env.REACT_APP_ENV &&
  process.env.REACT_APP_ENV === "staging"
) {
  serverUrl = "https://portal.altbanq.com";
  clientUrl = "https://sos-capital.netlify.app";
  plaidcallback = serverUrl;
  PLAID_ENV = "sandbox";
  PLAID_CLIENT_ID = "5da609ee3753c20015b7a9a5";
  PLAID_PUBLIC_KEY = "9aa87e1e0255a69a0bf13240e546c5";
  PLAID_PRODUCTS = "transactions,assets";
  PLAID_COUNTRY_CODES = "US,CA,GB,FR,ES";
  PLAID_WEBHOOKURL = 'https://uatclone-soscapital.cs21.force.com/plaid/services/apexrest/plaidprocesscsv';
} else {
  serverUrl = "http://localhost:3001";
  clientUrl = "http://localhost:3000";
  plaidcallback = serverUrl;
  PLAID_ENV = "sandbox";
  PLAID_CLIENT_ID = "5da609ee3753c20015b7a9a5";
  PLAID_PUBLIC_KEY = "9aa87e1e0255a69a0bf13240e546c5";
  PLAID_PRODUCTS = "transactions,assets";
  PLAID_COUNTRY_CODES = "US,CA,GB,FR,ES";
  PLAID_WEBHOOKURL = 'https://uatclone-soscapital.cs21.force.com/plaid/services/apexrest/plaidprocesscsv';
}

const Config = {
  serverUrl,
  clientUrl,
  plaidcallback,
  rootApiPath: `${serverUrl}/api`,
  PLAID_CLIENT_ID,
  PLAID_PUBLIC_KEY,
  PLAID_PRODUCTS,
  PLAID_COUNTRY_CODES,
  PLAID_ENV,
  PLAID_WEBHOOKURL,
  homePath: "/",
  loginPath: "/login",
  noAccessPath: "/noaccess",
  emailVerify: "/emailverify",
  successLoginRedirectURL: "/portalselect",
  failedLoginRedirectURL: "/loginfailed",
  LogoutRedirectURL: "/login",
  PortalAccess: {
    ApplicantPortal: [
      { title: "Home", path: "/homeborrower", showInHeader: true },
      { title: "Applicant", path: "/applicant", showInHeader: true },
      { title: "Apply For Funding", path: "/submission", showInHeader: true },
      //{title:'My Advances',       path:'/submissionlist',  showInHeader:true},
      { title: "My Deals", path: "/opportunitylist", showInHeader: true },
      { title: "Offers", path: "/offerlist", showInHeader: true },
      { title: "Repayments", path: "/repaymentlist", showInHeader: true },
      //{title:'Payments',          path:'/paymentlist',     showInHeader:true},
    ],
    ISOPortal: [
      { title: "Home", path: "/homeiso", showInHeader: true },
      { title: "ISO Metrics", path: "/isometrics", showInHeader: true },
      { title: "My Deals", path: "/opportunitylist", showInHeader: true },
    ],
    BrokerPortal: [
      { title: "Home", path: "/homebroker", showInHeader: true },
      { title: "Broker", path: "/broker", showInHeader: true },
      { title: "Add Deals", path: "/brokerfunding", showInHeader: true },
      { title: "My Deals", path: "/opportunitylist", showInHeader: true },
      { title: "Repayments", path: "/repaymentlist", showInHeader: true },
      /*{title:'Payments',        path:'/paymentlist',     showInHeader:true},*/
    ],
    SyndicationPortal: [
      { title: "Home", path: "/homesyndication", showInHeader: true },
      { title: "Syndicator", path: "/syndicator", showInHeader: true },
      { title: "Syndications", path: "/syndicationlist", showInHeader: true },
      /*{title:'Syndication Playables', path:'/syndicationplist', showInHeader:true},*/
      {
        title: "Portal Access List",
        path: "/portalaccesslist",
        showInHeader: true,
      },
    ],
  },
};

export default Config;
