import './index.css';
import React, { useEffect } from 'react';
import { Button } from 'antd';
import { useDropzone } from 'react-dropzone';

const UploadComponent = ({ setAcceptedFiles, disabled, onSubmit, title }) => {
  const { getInputProps, getRootProps, acceptedFiles } = useDropzone();

  const loadFiles = acceptedFiles.length ? acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  )) : null;

  useEffect(() => {
    if(acceptedFiles.length){
      setAcceptedFiles(acceptedFiles);
    }
  },[acceptedFiles])

  return (
    <section className='upload-component upload-container mb-5'>
      <p>{title}</p>
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop some files here, or click to select files</p>
      </div>
      <aside>
        <h6 className='text-capitalize mt-4'>Files</h6>
        <ul>{loadFiles}</ul>
      </aside>
      <div className='mt-5 text-center '>
        <Button onClick={onSubmit} disabled={disabled} >Upload</Button> 
      </div>
    </section>
  );
}

export default UploadComponent;
