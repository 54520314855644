// ------------- google autocomplet location helpers -----------
/**
 * Handle load google maps api script and works for other third party script loading
 * @param {string} url
 * @param {function} callback
 */
export const loadScript = (url, callback) => {
  let script = document.createElement("script");
  script.type = "text/javascript";

  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};

/**
 * Intialize google place Autocomplete feature
 * @param {globalVar} object
 * @param {function} updateQuery
 * @param {HTMLInputElement} autoCompleteRef
 */
export const handleScriptLoad = (globalVar, updateQuery, autoCompleteRef) => {
  globalVar = new window.google.maps.places.Autocomplete(autoCompleteRef, {
    // types: ["(cities)", "(regions)"],
    componentRestrictions: { country: "us" },
  });

  globalVar.setFields(["address_components", "formatted_address"]);
  globalVar.addListener("place_changed", () =>
    handlePlaceSelect(globalVar, updateQuery)
  );
};

/**
 * Get selected place information
 * @param {string} globalVar
 * @param {function} updateQuery
 */
export const handlePlaceSelect = async (globalVar, updateQuery) => {
  const addressObject = globalVar.getPlace();
  const query = addressObject.formatted_address;
  updateQuery(query);
  return addressObject;
  // console.log(addressObject);
};

export const loadGMapsScript = (
  autoComplete,
  updateAddressField,
  autoCompleteRef
) => {
  const gmapsScriptURL = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAxecPR8P_uXL16m127Sp7W9kHfPaILuZ8&libraries=places`;
  // if (document.querySelectorAll(`[src="${gmapsScriptURL}"]`).lengh < 1) {
  
  if(window.google && window.google.maps){
    handleScriptLoad(
      autoComplete,
      updateAddressField,
      autoCompleteRef.current.input
    )
  }else{
    loadScript(gmapsScriptURL, () =>
      handleScriptLoad(
        autoComplete,
        updateAddressField,
        autoCompleteRef.current.input
      )
    );
  }
  
  // }
};
