// --------------- import external dpendencies --------------
import React from "react";
import { CloseOutlined } from "@ant-design/icons";
import { Form, Input } from "antd";
import { Link } from "react-router-dom";

// --------------- import internal dpendencies --------------
// ------ import styles ------
import "./style.css";
// ------- import assets -------
import PlaidIcon from "../../assets/svg/plaid.svg";

function NewSignUp() {
  return (
    <>
      <section>
        <div className="authbox-wrapper">
          <div className="auth-box">
            <div className="close-box">
              <CloseOutlined style={{ fontSize: "1.6rem", color: "#375B8E" }} />
            </div>
            <div className="new-account-wrapper">
              <h1>Create Account </h1>
              <Form layout="vertical">
                <Form.Item label="Email" style={{ marginBottom: "1rem" }}>
                  <Input placeholder="Prefilled@gmail.com" />
                </Form.Item>
                <Form.Item label="Password" style={{ marginBottom: "1rem" }}>
                  <Input placeholder="Password" />
                </Form.Item>
              </Form>
              <div className="account-actions">
                <button className="btn-account btn-blue">Create Account</button>
                <button className="btn-account btn-tran">
                  <span>Continue with Google</span>
                </button>
                <span className="login-info">
                  Already have an account? <Link to="/login">Log in</Link>
                </span>
              </div>

              {/* <button className="btn-plaid">
                <img src={PlaidIcon} alt="plaid" />
                <span>
                  AUTHENTICATE WITH PLAID <RightOutlined />
                </span>
              </button> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default NewSignUp;
