import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';
export const myDealsStart = () => ({
  type: types.MY_DEALS_START
});

export const myDealsSuccess = payload => ({
  type: types.MY_DEALS_SUCCESS,
  payload
});

export const myDealsFail = payload => ({
  type: types.MY_DEALS_FAIL,
  payload
});

export const myDealsCleanup = () => ({
  type: types.MY_DEALS_CLEANUP
});

export const myDeals = (portal, isoType=null) => async dispatch => {
  try {
    dispatch(myDealsStart());
    const requestObj = {
      path: isoType ? `opportunity/${portal}/iso/${isoType}` : `opportunity/${portal}`,
      method: "GET",   
    }
    const  data  = await AxiosCall(requestObj);
    dispatch(myDealsSuccess(data));
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(myDealsFail(error));
  }
}
