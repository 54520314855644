import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';

export const generatePaymentHistoryStart = () => ({
  type: types.GENERATE_PAYMENT_HISTORY_START
});

export const generatePaymentHistorySuccess = payload => ({
  type: types.GENERATE_PAYMENT_HISTORY_SUCCESS,
  payload
});

export const generatePaymentHistoryFail = payload => ({
  type: types.GENERATE_PAYMENT_HISTORY_FAIL,
  payload
});

export const generatePaymentHistoryCleanup = () => ({
  type: types.GENERATE_PAYMENT_HISTORY_CLEANUP
});

export const generatePaymentHistory = (id) => async dispatch => {
  try {
    dispatch(generatePaymentHistoryStart());
    const path = `pdfgenerator/paymenthistory/${id}`;
    const requestObj = {
      path,
      method: 'GET'
    }
    const  data  = await AxiosCall(requestObj);
    dispatch(generatePaymentHistorySuccess(data));
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(generatePaymentHistoryFail(error));
  }
}
