import * as types from "../../action-types";
import AxiosCall from "../../../utils/axios";
import ErrorHandler from "../../../utils/error-handler";

export const getSyndicatorDealsStart = () => ({
  type: types.GET_SYNDICATOR_DEALS_START,
});

export const getSyndicatorDealsSuccess = (payload) => ({
  type: types.GET_SYNDICATOR_DEALS_SUCCESS,
  payload,
});

export const getSyndicatorDealsFail = (payload) => ({
  type: types.GET_SYNDICATOR_DEALS_FAIL,
  payload,
});

export const getSyndicatorDealsCleanup = () => ({
  type: types.GET_SYNDICATOR_DEALS_CLEANUP,
});

export const getSyndicatorDeals =
  (payable, type, Id = "", s = false) =>
  async (dispatch) => {
    try {
      dispatch(getSyndicatorDealsStart());
      const path = `syndication${payable ? "p" : ""}/type/${type}${
        s ? "/s" : ""
      }/${Id}`;
      const requestObj = {
        path,
        method: "GET",
      };
      const data = await AxiosCall(requestObj);
      dispatch(getSyndicatorDealsSuccess(data));
    } catch (err) {
      const error = ErrorHandler(err);
      dispatch(getSyndicatorDealsFail(error));
    }
  };
