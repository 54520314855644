// --------------- import external dpendencies --------------
import React from "react";
import { CloseOutlined } from "@ant-design/icons";
import { RightOutlined } from "@ant-design/icons";

// --------------- import internal dpendencies --------------
// ------ import styles ------
import "./style.css";
// ------- import assets -------
import PlaidIcon from "../../assets/svg/plaid.svg";

function AuthenticatePlaid() {
  return (
    <>
      <section>
        <div className="authbox-wrapper">
          <div className="auth-box">
            <div className="close-box">
              <CloseOutlined style={{ fontSize: "1.6rem", color: "#375B8E" }} />
            </div>
            <div className="plaid-content">
              <span>
                {" "}
                To facilitate our underwriting process, <br /> click below to
                authenticate with Plaid{" "}
              </span>
              <button className="btn-plaid">
                <img src={PlaidIcon} alt="plaid" />
                <span>
                  AUTHENTICATE WITH PLAID <RightOutlined />
                </span>
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AuthenticatePlaid;
