import React, { useRef, forwardRef, useImperativeHandle, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Row,
  Col,
  Label,
  FormGroup,
  Input,
  Form
} from 'reactstrap';
import Select from "react-select";
import { Modal } from 'antd';
import { Link } from 'react-router-dom';

//utils
import { LegalEntityType, PurposeOfFunding, LeadSource, yesNoOptions } from '../../../../utils/dropdown-values'
import { getValueObject, formatTaxId } from '../../../../utils/helpers';
import industries from '../../../../utils/industries';
import { customStyles } from "../../../../utils/react-select-settings/index";

const Step3 = ({ values }, ref) => {
  const formikRef = useRef();
  const [modalVisible, setModalVisible] = useState(false);
  const taxIdRegex = /^\d{2}\-?\d{7}$/;
  const phoneRegex = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;
  const urlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;

  const validationSchema = Yup.object().shape({
    DBAName: Yup.string()
      .trim()
      .required('Business Name is required')
      .min(2, 'Company Name should have minimum of two characters'),
    LegalName: Yup.string()
      .trim()
      .required('Business Legal Name is required')
      .min(2, 'Business Legal Name should have minimum of two characters'),
    Industry: Yup.string()
      .required('Industry is required'),
    PurposeOfFunding: Yup.string()
      .required('Funding Purpose is required'),
    LegalEntityType: Yup.string()
      .required('Legal Entity Type is required'),
    TaxID: Yup.string()
      .trim()
      .required('Tax ID is required')
      .matches(taxIdRegex,'Tax ID is not valid'),
    AverageDailyBalance: Yup.number()
      .required('Average Bank Balance is required')
      .min(0, 'Average Bank Balance should not be less than 0'),
    LeadSource: Yup.string()
      .required('Lead Source is required'),
    LeadSourceDetail: Yup.string()
      .when('LeadSource', {
        is: (LeadSource) => LeadSource === 'Partner',
        then: Yup.string()
          .trim()
          .required('Partner that referred you is required')
      }),
    BusinessPhone: Yup.string()
      .trim()
      .matches(phoneRegex,'Business Phone number is not a valid phone number'),
    Website: Yup.string()
      .trim()
      .matches(urlRegex, 'Website should be a valid url'),
    OutstandingDebtExist: Yup.boolean()
      .required('Select an option'),
    OutstandingDebtBalance: Yup.number()
      .when('OutstandingDebtExist', {
        is: (OutstandingDebtExist) => OutstandingDebtExist,
        then: Yup.number()
          .required('Outstanding debt balance is required')
          .min(1, 'Outstanding debt balance should be greater than 0'),
      }),
    OutstandingDebtCreditor: Yup.string()
      .when('OutstandingDebtExist', {
        is: (OutstandingDebtExist) => OutstandingDebtExist,
        then: Yup.string()
          .trim()
          .required('Who the outstanding debt is with is required')
          .min(2, 'Who the outstanding debt is with should have minimum of two characters'),
      }),
    agreement: Yup.bool()
      .required("Check the credit report diclaimer")
      .oneOf([true], "Check the credit report diclaimer"),
  });

  useImperativeHandle(ref, () => ({
    isValid: async () => {
      const errors = await formikRef.current.validateForm();
      if(errors.constructor === Object && Object.keys(errors).length === 0){
        return true;
      }else{
        return false;
      }
    },
    getValues: () => {
      return formikRef.current.values
    },
    handleSubmit: () => {
      formikRef.current.handleSubmit();
    }
  }));

  const showDisclaimer = (e) => {
    e.preventDefault();
    setModalVisible(true);
  }

  return (
    <div className='mt-5'>
      <Formik
        initialValues={{
          DBAName:'',
          Industry:'',
          LegalEntityType:'',
          AverageDailyBalance:'',
          LegalName:'',
          PurposeOfFunding:'',
          TaxID:'',
          LeadSource:'',
          agreement: false,
          ...values
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
        }}
        validationSchema={validationSchema}
        innerRef={formikRef}
      >
        {({ handleChange, values, errors, touched, handleBlur, setFieldValue, setFieldTouched }) => (
          <Form>
             <h2 className="sos-text-primary mb-0 font-weight-bold q-text">
             Tell us about your business
            </h2>
            <Row>
            <Col md="6" sm="12" className='mb-4'>
                <FormGroup className={`${errors.DBAName && touched.DBAName ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>Business Name / DBA <span className='text-danger'>*</span></Label>
                  <Input
                    className={"no-margin"}
                    placeholder=""
                    type="text" 
                    name='DBAName'
                    onChange={handleChange('DBAName')}
                    onBlur={handleBlur('DBAName')}
                    value={values.DBAName}
                  />
                </FormGroup>
                <small className='text-danger'>{errors.DBAName && touched.DBAName ? errors.DBAName : null}</small>
              </Col>
              <Col md="6" sm="12" className='mb-4'>
                <FormGroup className={`${errors.LegalName && touched.LegalName ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>LegalName <span className='text-danger'>*</span></Label>
                  <Input
                    className={"no-margin"}
                    placeholder=""
                    type="text"
                    name='LegalName'
                    onChange={handleChange('LegalName')}
                    onBlur={handleBlur('LegalName')}
                    value={values.LegalName}
                  />
                </FormGroup>
                <small className='text-danger'>{errors.LegalName && touched.LegalName ? errors.LegalName : null}</small>
              </Col>
              <Col md="6" sm="12" className='mb-4'>
                <Label className='text-medium'>Industry <span className='text-danger'>*</span></Label>
                <Select
                  components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                  styles={customStyles}
                  placeholder="--None--"
                  name="Industry"
                  value={getValueObject(industries, values.Sic)}
                  onChange={value => {
                    setFieldValue('Industry', value.label);
                    setFieldValue('Sic', value.value);
                  }}
                  options={industries}
                  onBlur={() => setFieldTouched('Industry')}
                />
                <small className='text-danger'>{errors.Industry && touched.Industry ? errors.Industry : null}</small>
              </Col>
              <Col md="6" sm="12" className='mb-4'>
                <Label className='text-medium'>Funding Purpose <span className='text-danger'>*</span></Label>
                <Select
                  components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                  styles={customStyles}
                  placeholder="--None--"
                  name="PurposeOfFunding"
                  value={getValueObject(PurposeOfFunding, values.PurposeOfFunding)}
                  onChange={value => setFieldValue('PurposeOfFunding', value.value)}
                  options={PurposeOfFunding}
                  onBlur={() => setFieldTouched('PurposeOfFunding')}
                />
                <small className='text-danger'>{errors.PurposeOfFunding && touched.PurposeOfFunding ? errors.PurposeOfFunding : null}</small>
              </Col>
              <Col md="6" sm="12" className='mb-4'>
                <Label className='text-medium'>Legal Entity Type <span className='text-danger'>*</span></Label>
                <Select
                  components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                  styles={customStyles}
                  placeholder="--None--"
                  name="LegalEntityType"
                  value={getValueObject(LegalEntityType, values.LegalEntityType)}
                  onChange={value => setFieldValue('LegalEntityType', value.value)}
                  options={LegalEntityType}
                  onBlur={() => setFieldTouched('LegalEntityType')}
                />
                <small className='text-danger'>{errors.LegalEntityType && touched.LegalEntityType ? errors.LegalEntityType : null}</small>
              </Col>
              <Col md="6" sm="12" className='mb-4'>
                <FormGroup className={`${errors.TaxID && touched.TaxID ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>TaxID <span className='text-danger'>*</span></Label>
                  <Input
                    className={"no-margin"}
                    placeholder=""
                    type="text" 
                    name='TaxID'
                    onChange={e => setFieldValue('TaxID', formatTaxId(e.target.value))}
                    onBlur={handleBlur('TaxID')}
                    value={values.TaxID}
                  />
                </FormGroup>
                <small className='text-danger'>{errors.TaxID && touched.TaxID ? errors.TaxID : null}</small>
              </Col>
              <Col md="6" sm="12" className='mb-4'>
                <FormGroup className={`${errors.AverageDailyBalance && touched.AverageDailyBalance ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>Average Bank Balance <span className='text-danger'>*</span></Label>
                  <Input
                    className={"no-margin"}
                    placeholder=""
                    type="text" 
                    name='AverageDailyBalance'
                    onChange={handleChange('AverageDailyBalance')}
                    onBlur={handleBlur('AverageDailyBalance')}
                    value={values.AverageDailyBalance}
                  />
                </FormGroup>
                <small className='text-danger'>{errors.AverageDailyBalance && touched.AverageDailyBalance ? errors.AverageDailyBalance : null}</small>
              </Col>
              <Col md="6" sm="12" className='mb-4'>
                <Label className='text-medium'>Any outstanding debt on the business <span className='text-danger'>*</span></Label>
                <Select
                  components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                  styles={customStyles}
                  placeholder="--None--"
                  name="OutstandingDebtExist"
                  value={getValueObject(yesNoOptions, values.OutstandingDebtExist === undefined ? '' : values.OutstandingDebtExist ? 'Yes' : 'No')}
                  onChange={value => setFieldValue('OutstandingDebtExist', value.value  === 'Yes' ? true : false)}
                  options={yesNoOptions}
                  onBlur={() => setFieldTouched('OutstandingDebtExist')}
                />
                <small className='text-danger'>{errors.OutstandingDebtExist && touched.OutstandingDebtExist ? errors.OutstandingDebtExist : null}</small>
              </Col>
              {values.OutstandingDebtExist ? (
                <Col md="6" sm="12" className='mb-4'>
                  <FormGroup className={`${errors.OutstandingDebtCreditor && touched.OutstandingDebtCreditor ? 'mb-0' : ''}`}>
                    <Label className='text-medium'>Who the outstanding debt is with? <span className='text-danger'>*</span></Label>
                    <Input
                      className={"no-margin"}
                      placeholder=""
                      type="text" 
                      name='OutstandingDebtCreditor'
                      onChange={handleChange('OutstandingDebtCreditor')}
                      onBlur={handleBlur('OutstandingDebtCreditor')}
                      value={values.OutstandingDebtCreditor}
                    />
                  </FormGroup>
                  <small className='text-danger'>{errors.OutstandingDebtCreditor && touched.OutstandingDebtCreditor ? errors.OutstandingDebtCreditor : null}</small>
                </Col>
              ) : null}
              {values.OutstandingDebtExist ? (
                <Col md="6" sm="12" className='mb-4'>
                  <FormGroup className={`${errors.OutstandingDebtBalance && touched.OutstandingDebtBalance ? 'mb-0' : ''}`}>
                    <Label className='text-medium'>Outstanding Balance <span className='text-danger'>*</span></Label>
                    <Input
                      className={"no-margin"}
                      placeholder=""
                      type="text" 
                      name='OutstandingDebtBalance'
                      onChange={handleChange('OutstandingDebtBalance')}
                      onBlur={handleBlur('OutstandingDebtBalance')}
                      value={values.OutstandingDebtBalance}
                    />
                  </FormGroup>
                  <small className='text-danger'>{errors.OutstandingDebtBalance && touched.OutstandingDebtBalance ? errors.OutstandingDebtBalance : null}</small>
                </Col>
              ) : null}
              <Col md="6" sm="12" className='mb-4'>
                <FormGroup className={`${errors.BusinessPhone && touched.BusinessPhone ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>Business Phone Number</Label>
                  <Input
                    className={"no-margin"}
                    placeholder=""
                    type="text" 
                    name='BusinessPhone'
                    onChange={handleChange('BusinessPhone')}
                    onBlur={handleBlur('BusinessPhone')}
                    value={values.BusinessPhone}
                  />
                </FormGroup>
                <small className='text-danger'>{errors.BusinessPhone && touched.BusinessPhone ? errors.BusinessPhone : null}</small>
              </Col>
              <Col md="6" sm="12" className='mb-4'>
                <FormGroup className={`${errors.Website && touched.Website ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>Website</Label>
                  <Input
                    className={"no-margin"}
                    placeholder=""
                    type="text" 
                    name='Website'
                    onChange={handleChange('Website')}
                    onBlur={handleBlur('Website')}
                    value={values.Website}
                  />
                </FormGroup>
                <small className='text-danger'>{errors.Website && touched.Website ? errors.Website : null}</small>
              </Col>
              <Col md="6" sm="12" className='mb-4'>
                <Label className='text-medium'>How Did You Hear About Us? <span className='text-danger'>*</span></Label>
                <Select
                  components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                  styles={customStyles}
                  placeholder="--None--"
                  name="LeadSource"
                  value={getValueObject(LeadSource, values.LeadSource)}
                  onChange={value => {
                    if(value.LeadSourceDetail && value.value !== 'Partner'){
                      setFieldValue('LeadSourceDetail', '');
                    }
                    setFieldValue('LeadSource', value.value);
                  }}
                  options={LeadSource}
                  onBlur={() => setFieldTouched('LeadSource')}
                />
                <small className='text-danger'>{errors.LeadSource && touched.LeadSource ? errors.LeadSource : null}</small>
              </Col>
              {values.LeadSource === 'Partner' || values.LeadSource === 'Employee Referral' ||
                values.LeadSource === 'Website' || values.LeadSource === 'Advertisement' ? (
                <Col md="6" sm="12" className='mb-4'>
                  <FormGroup className={`${errors.LeadSourceDetail && touched.LeadSourceDetail ? 'mb-0' : ''}`}>
                    <Label className='text-medium'>Specify the Referral (Partner/Employee/Website/Advertisement) <span className='text-danger'>*</span></Label>
                    <Input
                      className={"no-margin"}
                      placeholder=""
                      type="text"
                      name='LeadSourceDetail'
                      onChange={handleChange('LeadSourceDetail')}
                      onBlur={handleBlur('LeadSourceDetail')}
                      value={values.LeadSourceDetail}
                    />
                  </FormGroup>
                  <small className='text-danger'>{errors.LeadSourceDetail && touched.LeadSourceDetail ? errors.LeadSourceDetail : null}</small>
                </Col>
              ) : null}
            </Row>
            <FormGroup check className="text-left">
              <Label check>
                <Input
                  className={"no-margin"}
                  type="checkbox"
                  onChange={handleChange("agreement")}
                  onBlur={handleBlur("agreement")}
                  checked={values.agreement}
                />
                <span className="form-check-sign" />I authorize altbanq to access my credit report -{" "}
                <a href="#" onClick={showDisclaimer}>
                  Credit Authorization Disclaimer
                </a>
                .
              </Label>
            </FormGroup>
            <small className="text-danger">
              {errors.agreement && touched.agreement
                ? errors.agreement
                : null}
            </small>
          </Form>
        )}
      </Formik>
      <Modal
        title="Credit Disclaimer"
        visible={modalVisible}
        footer={null}
        onCancel={() => setModalVisible(false)}
      >
        <p>
          Your business is applying generally for credit and not for a specific credit product or amount. 
          Any product/amount offered will depend on the credit and underwriting standards of the lender.
         <br />
          By clicking “Continue”, you 
          <br />
          (i) consent to receiving telemarketing calls and messages, including 
          calls using an automatic telephone dialing system, from Altbanq and those acting on its behalf at the 
          telephone number you have provided above (including your cellular phone number); agree that this consent 
          applies even if the number you have provided is currently on any state, federal, or corporate Do-Not-Call 
          registry; and understand that you are not required to provide this consent as a condition of receiving any 
          credit or services from Altbanq and that you may apply for business credit by contacting us directly; and 
          <br />
          (ii) acknowledge that you have read SOS’s <Link to='/terms'>Application Agreement</Link> and <Link to='/privacy-policy'>Privacy Policy</Link> and understand that you 
          may opt out of receiving communications of your choice from Altbanq as provided in the Privacy Policy.
        </p>
        
      </Modal>
    </div>
  );
};

export default forwardRef(Step3);
