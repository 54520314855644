import './index.css';
import React, { useState, useEffect }  from 'react';
import { Button, message, Form, Input, AutoComplete, Alert } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle
} from 'reactstrap';
import { useHistory } from "react-router-dom";

//actions
import { checkEmailExist, checkEmailExistCleanup } from '../../store/actions/check-email-exist';
import { searchInstitution, searchInstitutionCleanup } from '../../store/actions/search-institution';
import { savePSAInformation, savePSAInformationCleanup } from '../../store/actions/save-psa-information';

//components
import Loader from '../../components/Loader';

const PSALogin = () => {
  const checkEmailExistState = useSelector(s => s.checkEmailExist);
  const savePSAInformationState = useSelector(s => s.savePSAInformation);
  const searchInstitutionState = useSelector(s => s.searchInstitution);
  const dispatch = useDispatch();
  const [plaidEmail, setPlaidEmail] = useState(null);
  const [emailExist, setEmailExist] = useState(false);
  const [selectedInstitution, setSelectedInstitution] = useState(null);
  const [institutionOptions, setInstitutionOptions] = useState([]);
  const [selectInstitutionError, setSelectInstitutionError] = useState(false);
  const [form] = Form.useForm();
  const history = useHistory();
  
  const handleConfirmEmail = values => {
    setPlaidEmail(values.email);
    dispatch(checkEmailExist({email: values.email}));
  }

  const handleInstitutionSelection = (value, option) => {
    setSelectedInstitution(option.institution_id)
  }

  const handleInstitutionSearch = value => {
    if(selectedInstitution){
      setSelectedInstitution(null);
    }
    dispatch(searchInstitution({ query: value }));
  }

  const handleSave = values => {
    if(selectInstitutionError){
      setSelectInstitutionError(false);
    }

    if(!selectedInstitution){
      setSelectInstitutionError(true);
      return;
    }
    delete values.confirm;
    const payload = {
      ...values,
      email: plaidEmail,
      InstitutionID: selectedInstitution
    }
    dispatch(savePSAInformation(payload));
  }

  useEffect(() => {
    if(checkEmailExistState.isSuccessful){
      setEmailExist(true);
      dispatch(checkEmailExistCleanup());
    }else if(checkEmailExistState.error){
      message.error(`Oops! ${checkEmailExistState.error}`, 2.5)
      dispatch(checkEmailExistCleanup());
    }
  },[checkEmailExistState]);

  useEffect(() => {
    if(savePSAInformationState.isSuccessful){
      message.success('Information saved successfully', 2);
      form.resetFields();
      dispatch(savePSAInformationCleanup());
      history.push('/');
    }else if(savePSAInformationState.error){
      message.error(`Oops! ${savePSAInformationState.error}`, 2.5)
      dispatch(savePSAInformationCleanup());
    }
  },[savePSAInformationState]);

  useEffect(() => {
    if(searchInstitutionState.isSuccessful){
      const results = searchInstitutionState.data.map(institution => ({
        label: institution.name,
        value: institution.name,
        institution_id: institution.institution_id
      }));
      setInstitutionOptions(results);
      dispatch(searchInstitutionCleanup());
    }else if(searchInstitutionState.error){
      message.error(`Oops! ${searchInstitutionState.error}`, 2.5)
      dispatch(searchInstitutionCleanup());
    }
  },[searchInstitutionState]);

  return (
    <div className='container psa-page'>
      <div className='content'>
      <Row>
          <Col className='mr-auto ml-auto order-1 order-md-2' md='8' sm='12'>
            <Card>
              <CardTitle className='text-center p-4'>
                <h2 className='sos-text-primary mb-0 font-weight-bold'>PSA - Bank Login Information</h2>
              </CardTitle>
              <CardBody className='p-5'>
                <Loader isLoading={checkEmailExistState.isLoading || savePSAInformationState.isLoading}>
                  {emailExist ? (
                    <section className='text-center mt-5'>
                      {selectInstitutionError ? (
                        <Alert
                          message="Search and select valid institution"
                          type="error"
                          showIcon
                          className="mb-5"
                          closable
                        />
                      ) : null}
                      <Form className='text-left' form={form} onFinish={handleSave}>
                        <Form.Item
                          label='Username'
                          name='username'
                          rules={[{ required: true, message: 'Please input your username!' }]}
                          labelCol={{ span: 24, md: { span: 5 } }}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          label='Password'
                          name='password1'
                          rules={[{ required: true, message: 'Please input your password!' }]}
                          labelCol={{ span: 24, md: { span: 5 } }}
                        >
                          <Input.Password />
                        </Form.Item>
                        <Form.Item
                          name='confirm'
                          label='Confirm Password'
                          dependencies={['password1']}
                          rules={[
                            {
                              required: true,
                              message: 'Please confirm your password!',
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (!value || getFieldValue('password1') === value) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(new Error('Passwords does not match!'));
                              },
                            }),
                          ]}
                          labelCol={{ span: 24, md: { span: 5 } }}
                        >
                          <Input.Password />
                        </Form.Item>
                        <Form.Item
                          name='institution'
                          label='Institution'
                          rules={[
                            {
                              required: true,
                              message: 'Please seaarch and select institution!',
                            }
                          ]}
                          className='text-left'
                          labelCol={{ span: 24, md: { span: 5 } }}
                        >
                          <AutoComplete
                            options={institutionOptions}
                            // style={{ width: 200 }}
                            onSelect={handleInstitutionSelection}
                            onSearch={handleInstitutionSearch}
                            placeholder='Search Institution'
                          />
                        </Form.Item>
                        <Form.Item
                          name='SecurityQuestion1'
                          label='Security Question 1'
                          rules={[{ required: true, message: 'Security question 1 is required', whitespace: true }]}
                          labelCol={{ span: 24, md: { span: 5 } }}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          name='SecurityAnswer1'
                          label='Security Answer 1'
                          rules={[{ required: true, message: 'Security answer 1 is required', whitespace: true }]}
                          labelCol={{ span: 24, md: { span: 5 } }}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          name='SecurityQuestion2'
                          label='Security Question 2'
                          rules={[{ required: true, message: 'Security question 2 is required', whitespace: true }]}
                          labelCol={{ span: 24, md: { span: 5 } }}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          name='SecurityAnswer2'
                          label='Security Answer 2'
                          rules={[{ required: true, message: 'Security answer 2 is required', whitespace: true }]}
                          labelCol={{ span: 24, md: { span: 5 } }}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          name='SecurityQuestion3'
                          label='Security Question 3'
                          rules={[{ required: true, message: 'Security question 3 is required', whitespace: true }]}
                          labelCol={{ span: 24, md: { span: 5 } }}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          name='SecurityAnswer3'
                          label='Security Answer 3'
                          rules={[{ required: true, message: 'Security answer 3 is required', whitespace: true }]}
                          labelCol={{ span: 24, md: { span: 5 } }}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item className='text-center'>
                          <Button htmlType='submit' className='sos-bg-primary text-white' >Submit</Button> 
                        </Form.Item>
                      </Form>
                    </section>
                  ) : (
                    <section className='text-center mt-5'>
                      <Form
                        labelCol={{ span: 24 }}
                        wrapperCol={{span: 24}}
                        name='confirm-email'
                        onFinish={handleConfirmEmail}
                        className='p-4 justify-content-center w-100'
                        layout='inline'
                      >
                        <Form.Item
                          name='email'
                          placeholder='Email'
                          rules={[
                            { 
                              required: true, 
                              message: 'Please input your email!'
                            },
                            {
                              type: 'email',
                              message: 'Input a valid email!'
                            }
                          ]}
                          className='w-100 email-con'
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item>
                          <Button htmlType='submit' className='sos-bg-primary text-white' disabled={checkEmailExistState.isLoading} >Confirm Email</Button> 
                        </Form.Item>
                      </Form>
                    </section>
                  )}
                </Loader>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default PSALogin;
