import Dashboard from "../views/Dashboard";
import Login from "../views/Login";
import Logout from "../utils/logout";
import Error404 from "../views/Error404";
import FundingApplication from "../views/FundApplication";
import MyDeals from "../views/ApplicantPortal/MyDeals";
import MyAdvances from "../views/ApplicantPortal/MyAdvances";
import PlaidPage from "../views/PlaidPage";
import DealsDetails from "../views/ApplicantPortal/DealsDetails";
import OfferDetails from "../views/ApplicantPortal/OfferDetails";
import AdvanceDetails from "../views/ApplicantPortal/AdvanceDetails";
import Repayments from "../views/ApplicantPortal/Repayments";
import RepaymentDetails from "../views/ApplicantPortal/RepaymentDetails";
import PaymentDetails from "../views/ApplicantPortal/PaymentDetails";
import AllOffers from "../views/ApplicantPortal/AllOffers";
import ApplicantMetrics from "../views/ApplicantPortal/ApplicantMetrics";
import ApplicationSuccess from "../views/ApplicationSuccess";
import IsoMetrics from "../views/IsoPortal/IsoMetrics";
import MyISODeals from "../views/IsoPortal/MyISODeals";
import MyISODealsDetails from "../views/IsoPortal/ISODealsDetails";
import MyISOSingleOfferInfo from "../views/IsoPortal/SingleOffer";
import MyISOSingleStipulationAttachment from "../views/IsoPortal/SingleStipulationAttachments";
import Terms from "../views/Terms";
import BrokerMetrics from "../views/BrokerPortal/BrokerMetrics";
import BrokerDeals from "../views/BrokerPortal/Deals";
import BrokerDealDetails from "../views/BrokerPortal/DealDetails";
import PrivacyPolicy from "../views/PrivacyPolicy";
import ISONonUserDeals from "../views/IsoPortal/ISONonUserDeals/index";
import ISONonUserDealsDetails from "../views/IsoPortal/ISONonUserDealsDetails";
import DealsFromISOsDetails from "../views/IsoPortal/DealsFromISOsDetails";
import ISOSingleRepayment from "../views/IsoPortal/SingleRepaymet";
import FundingApplicationForm from "../views/ApplicantPortal/FundApplication/index";
import BrokerOfferDetails from "../views/BrokerPortal/SingleOffer";
import AddDeal from "../views/BrokerPortal/AddDeal";
import Syndicator from "../views/SyndicationPortal/Syndicator";
import BrokerRepayment from "../views/BrokerPortal/Repayments";
import BrokerRepaymentDetails from "../views/BrokerPortal/RepaymentDetailsSection";
import BrokerStipulationAttachments from "../views/BrokerPortal/SingleStipulationAttachments";
import PortalAccess from "../views/PortalAccess";
import SyndicationList from "../views/SyndicationPortal/SyndicationsList";
import SyndicationDealDetailsSection from "../views/SyndicationPortal/DealDetailsSection";
import SyndicationPayableDetails from "../views/SyndicationPortal/SyndicationPayablesDetails";
import BrokerOfferCalculator from "../views/BrokerPortal/OfferCalculator";
import ApplicantOfferCalculator from "../views/ApplicantPortal/OfferCalculator";
import ContactPage from "../views/ContactPage";
import PSALogin from "../views/PSALogin";
import ApplicantStipulationAttachments from "../views/ApplicantPortal/SingleStipulationAttachments";
import ReferralPage from "../views/ReferralPage";
import ReferrerDeals from "../views/ReferrerPortal/Deals";
import ReferrerDealDetails from "../views/ReferrerPortal/DealDetails";
import ReferrerFundApplication from "../views/ReferrerPortal/FundApplication";
import ReferrerMetrics from "../views/ReferrerPortal/ReferrerMetrics";
import BankDetails from "../views/ReferrerPortal/BankDetails";
import SignUp from "../views/SignUp/index";
import ForgotPassword from "../views/ForgotPassword";
import ChangePassword from "../views/ApplicantPortal/ChangePassword";
import EmailVerification from "../views/EmailVerification";
import NewLeadForm from "../views/NewLeadForm";
import AuthenticatePlaid from "../views/AuthenticatePlaid";
import ThankYou from "../views/ThankYou";
import NewSignUp from "../views/NewSignUp";
// -------- user routes --------
export const userRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "fa fa-tachometer",
    component: ApplicantMetrics,
    layout: "/user",
  },
  {
    path: "/deals",
    name: "My Opportunities",
    icon: "fa fa-briefcase",
    component: MyDeals,
    layout: "/user",
  },
  {
    path: "/advance-details/:id",
    component: AdvanceDetails,
    layout: "/user",
  },
  {
    path: "/deal-details/:id",
    component: DealsDetails,
    layout: "/user",
  },
  {
    path: "/offer-details/:id",
    component: OfferDetails,
    layout: "/user",
  },
  {
    path: "/repayments",
    name: "Repayments",
    icon: "fa fa-exchange",
    component: Repayments,
    layout: "/user",
  },
  {
    path: "/repayment-details/:id",
    component: RepaymentDetails,
    layout: "/user",
  },
  {
    path: "/payment-details/:id",
    component: PaymentDetails,
    layout: "/user",
  },
  {
    path: "/offers",
    name: "Offers",
    icon: "fa fa-gift",
    component: AllOffers,
    layout: "/user",
  },
  {
    path: "/application-form",
    name: "Apply for Funding",
    icon: "fa fa-paper-plane",
    component: FundingApplicationForm,
    layout: "/user",
  },
  {
    path: "/calculator/:id",
    component: ApplicantOfferCalculator,
    layout: "/user",
  },
  {
    path: "/stipulation-attachments/:id",
    component: ApplicantStipulationAttachments,
    layout: "/user",
  },
  {
    path: "/application-success",
    component: ApplicationSuccess,
    layout: "/user",
  },
  {
    path: "/change-password",
    name: "Change Password",
    icon: "fa fa-unlock-alt",
    component: ChangePassword,
    layout: "/user",
  },
];

// ------- New routes ------
export const newRoutes = [
  {
    path: "/lead-form",
    name: "Lead-form",
    component: NewLeadForm,
    layout: "/new",
  },
];

// ------- new public routes -------
export const newPublicRoutes = [
  {
    path: "/auth-plaid",
    name: "Auth Plaid",
    component: AuthenticatePlaid,
    layout: "/new-public",
  },
  {
    path: "/thank-you",
    name: "Thank You",
    component: ThankYou,
    layout: "/new-public",
  },
  {
    path: "/sign-up",
    name: "Sign Up",
    component: NewSignUp,
    layout: "/new-public",
  },
];

// --------- Public routes -------
export const publicRoutes = [
  {
    path: "/",
    name: "Login",
    component: Login,
    layout: "/public",
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    layout: "/public",
  },
  {
    path: "/signup",
    name: "SignUp",
    component: SignUp,
    layout: "/public",
  },
  {
    path: "/application-success/:opportunityId",
    name: "Applicaton Success",
    component: PlaidPage,
    layout: "/public",
  },
  {
    path: "/plaid",
    name: "Bank Statement",
    component: PlaidPage,
    layout: "/public",
  },
  {
    path: "/verified-success",
    name: "Verified Success",
    component: ApplicationSuccess,
    layout: "/public",
  },
  {
    path: "/terms",
    name: "Terms",
    component: Terms,
    layout: "/public",
  },
  {
    path: "/privacy-policy",
    name: "Privacy Policy",
    component: PrivacyPolicy,
    layout: "/public",
  },
  {
    path: "/psa",
    name: "PSA Login",
    component: PSALogin,
    layout: "/public",
  },
  {
    path: "/portals",
    name: "Portals",
    component: PortalAccess,
    layout: "/public",
  },
  {
    path: "/logout",
    name: "Logout",
    component: Logout,
    layout: "/public",
  },
  {
    path: "/old-lead-form",
    name: "Old Application Form",
    component: FundingApplication,
    layout: "/public",
  },
  {
    path: "/apply",
    name: "Application Form",
    component: NewLeadForm,
    layout: "/public",
  },
  {
    path: "/contact",
    name: "Contact",
    component: ContactPage,
    layout: "/public",
  },
  {
    path: "/refer",
    name: "Refer",
    component: ReferralPage,
    layout: "/public",
  },
  {
    path: "/forgot-password",
    name: "Forgot Password",
    component: ForgotPassword,
    layout: "/public",
  },
  {
    path: "/email-verification",
    name: "Email Verification",
    component: EmailVerification,
    layout: "/public",
  },
  {
    path: "/404",
    name: "Error404",
    component: Error404,
  },
];

export const adminRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-bank",
    component: Dashboard,
    layout: "/admin",
  },
];

// ------ iso routes -------
export const isoRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "fa fa-tachometer",
    component: IsoMetrics,
    layout: "/iso",
  },
  {
    path: "/deal-details/:id",
    component: MyISODealsDetails,
    layout: "/iso",
  },
  {
    path: "/deals",
    name: "My  Deals",
    icon: "fa fa-briefcase",
    component: MyISODeals,
    layout: "/iso",
  },
  {
    path: "/:type/offer-details/:id",
    component: MyISOSingleOfferInfo,
    layout: "/iso",
  },
  {
    path: "/:type/stipulation-attachments/:id",
    component: MyISOSingleStipulationAttachment,
    layout: "/iso",
  },
  {
    path: "/non-user-deals",
    name: "Non User Deals",
    icon: "fa fa-user-plus",
    component: ISONonUserDeals,
    layout: "/iso",
  },
  {
    path: "/non-user-deal-details/:id",
    component: ISONonUserDealsDetails,
    layout: "/iso",
  },
  {
    path: "/deals-from-iso-details/:id",
    component: DealsFromISOsDetails,
    layout: "/iso",
  },
  {
    path: "/:type/repayment/:id",
    component: ISOSingleRepayment,
    layout: "/iso",
  },
  {
    path: "/change-password",
    name: "Change Password",
    icon: "fa fa-unlock-alt",
    component: ChangePassword,
    layout: "/iso",
  },
];

// ----- broker routes -------
export const brokerRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "fa fa-tachometer",
    component: BrokerMetrics,
    layout: "/broker",
  },
  {
    path: "/deals",
    name: "My Deals",
    icon: "fa fa-briefcase",
    component: BrokerDeals,
    layout: "/broker",
  },
  {
    path: "/deal-details/:id",
    component: BrokerDealDetails,
    layout: "/broker",
  },
  {
    path: "/offer-details/:id",
    component: BrokerOfferDetails,
    layout: "/broker",
  },
  {
    path: "/stipulation-attachments/:id",
    component: BrokerStipulationAttachments,
    layout: "/broker",
  },
  {
    path: "/repayments",
    name: "Repayments",
    icon: "fa fa-exchange",
    component: BrokerRepayment,
    layout: "/broker",
  },
  {
    path: "/repayment-details/:id",
    component: BrokerRepaymentDetails,
    layout: "/broker",
  },
  {
    path: "/add-deal",
    name: "Add Deal",
    icon: "fa fa-paper-plane",
    component: AddDeal,
    layout: "/broker",
  },
  {
    path: "/calculator/:id",
    component: BrokerOfferCalculator,
    layout: "/broker",
  },
  {
    path: "/application-success",
    component: ApplicationSuccess,
    layout: "/broker",
  },
  {
    path: "/change-password",
    name: "Change Password",
    icon: "fa fa-unlock-alt",
    component: ChangePassword,
    layout: "/broker",
  },
];

// -------- syndicaation routes ----------
export const syndicationRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "fa fa-tachometer",
    component: Syndicator,
    layout: "/syndication",
  },
  {
    path: "/deals",
    name: "My Deals",
    icon: "fa fa-briefcase",
    component: SyndicationList,
    layout: "/syndication",
  },
  {
    path: "/:type/syndication-details/:id",
    component: SyndicationDealDetailsSection,
    layout: "/syndication",
  },
  {
    path: "/:type/syndication-payable/:id",
    component: SyndicationPayableDetails,
    layout: "/syndication",
  },
  {
    path: "/change-password",
    name: "Change Password",
    icon: "fa fa-unlock-alt",
    component: ChangePassword,
    layout: "/syndication",
  },
];

// ------- referrer routes --------
export const referrerRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "fa fa-tachometer",
    component: ReferrerMetrics,
    layout: "/referrer",
  },
  {
    path: "/deals",
    name: "Opportunities",
    icon: "fa fa-briefcase",
    component: ReferrerDeals,
    layout: "/referrer",
  },
  {
    path: "/deal-details/:id",
    component: ReferrerDealDetails,
    layout: "/referrer",
  },
  {
    path: "/application-form",
    name: "Apply for Funding",
    icon: "fa fa-paper-plane",
    component: ReferrerFundApplication,
    layout: "/referrer",
  },
  {
    path: "/bank-details",
    name: "Bank Details",
    icon: "fa fa-university ",
    component: BankDetails,
    layout: "/referrer",
  },
  {
    path: "/change-password",
    name: "Change Password",
    icon: "fa fa-unlock-alt",
    component: ChangePassword,
    layout: "/referrer",
  },
];
