import Axios from 'axios';
import config from '../../config';

let baseURL;
const host = config.serverUrl;
if(process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === 'production'){
  baseURL = `${host}/api/`;
}else{
  baseURL = `${host}/apitest/`;
}

console.log('process.env:' , process.env);

const AxiosCall = async requestObj => {
  const { path, method, data, contentType, withCredentials= true, Cookie, CookiePath, responseType='json', fileName } = requestObj;
  const token = localStorage.getItem('authToken');
 
  let headers = { 
    Authorization: `Bearer `+ token,
    'Content-Type': contentType || 'application/json'
  };

  const url = `${baseURL}${path}`;
  try {
    if(responseType === 'blob'){
      headers = { 
        Authorization: `Bearer `+ token,
      };
     
      const response = await Axios({ method, url, data, headers,  responseType: 'blob', withCredentials, Cookie:null, CookiePath:null });
      const docUrl = window.URL.createObjectURL(new Blob([response.data], { type:"application/pdf" }));
      const link = document.createElement('a');
      link.href = docUrl;
      if(Cookie&&CookiePath){
        document.cookie = `${Cookie}; path=${baseURL}/${CookiePath}`
      }(document.cookie=null)

      link.setAttribute('download', fileName); //or any other extension
      document.body.appendChild(link);
      link.click();
    }else{
      const response = await Axios({ method, url, data, headers, json: true, withCredentials, Cookie, CookiePath});

      if(Cookie&&CookiePath){
        document.cookie = `${Cookie}; path=${baseURL}/${CookiePath}`
      }else(document.cookie=null)

      const result = response && response.data;
      return result;
    }
  } catch (error) {
    if(error.response.status === 401){
      localStorage.setItem('authToken', '');
      window.location.href = '/login';
    }
    throw error;
  }
};

export default AxiosCall;