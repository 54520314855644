import React, { useEffect } from 'react';
import { Tabs } from 'antd';
import { useDispatch } from 'react-redux';

//components
import SyndicatorMetrics from './SyndicatorMetrics';
import SyndicatorDetails from './SyndicatorDetails';
import SyndicatorPaymentsExport from './SyndicatorPaymentsExport';

//actions
import { getSyndicator } from '../../../store/actions/get-syndicator';

const DealDetails = () => {
  const { TabPane } = Tabs;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSyndicator(4))
  },[]);

  return (
    <div className='pt-5 mt-5  container advance-details-page'>
      <div className='pt-5 table-container'>
        <Tabs defaultActiveKey='1'>
          <TabPane tab='Metrics' key='1'>
            <SyndicatorMetrics />
          </TabPane>
          <TabPane tab='Details' key='2'>
            <SyndicatorDetails />
          </TabPane>
          <TabPane tab='Report' key='3'>
            <SyndicatorPaymentsExport />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default DealDetails;
