import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';

export const applyForFundingStart = () => ({
  type: types.APPLY_FOR_FUNDING_START
});

export const applyForFundingSuccess = payload => ({
  type: types.APPLY_FOR_FUNDING_SUCCESS,
  payload
});

export const applyForFundingFail = payload => ({
  type: types.APPLY_FOR_FUNDING_FAIL,
  payload
});

export const applyForFundingCleanup = () => ({
  type: types.APPLY_FOR_FUNDING_CLEANUP
});

export const applyForFunding = (payload, referrer=false) => async dispatch => {
  try {
    dispatch(applyForFundingStart());
    const requestObj = {
      path: referrer ? "opportunity/referrer" : "opportunity",
      method: "POST",
      data: payload
    }
    const data = await AxiosCall(requestObj);
    dispatch(applyForFundingSuccess(data));
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(applyForFundingFail(error));
  }
}

export const applicantApplyForFunding = payload => async dispatch => {
  try {
    dispatch(applyForFundingStart());
    const requestObj = {
      path: "opportunity/1",
      method: "POST",
      data: payload
    }
    const data = await AxiosCall(requestObj);
    dispatch(applyForFundingSuccess(data));
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(applyForFundingFail(error));
  }
}
