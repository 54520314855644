import React, {
  useRef,
  forwardRef,
  useImperativeHandle,
  useState,
} from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Row, Col, Label, FormGroup, Input, Form } from "reactstrap";
import Select from "react-select";
import { Alert } from "antd";

//utils
import { HowDoYouPreferToBeContacted } from "../../../../utils/dropdown-values";
import { getValueObject, formatSSN } from "../../../../utils/helpers";
import { customStyles } from "../../../../utils/react-select-settings/index";

const Step2 = ({ values }, ref) => {
  const formikRef = useRef();
  const phoneRegex =
    /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;
  const SSNRegex = /^\d{3}\-?\d{2}\-?\d{4}$/;
  const [ownershipMessage, setOwnershipMessage] = useState(null);

  const validationSchema = Yup.object().shape({
    Title: Yup.string()
      .trim()
      .required("Title is required")
      .min(2, "Title should have minimum of two characters"),
    FirstName: Yup.string()
      .trim()
      .required("First Name is required")
      .min(2, "First Name should have minimum of two characters"),
    LastName: Yup.string()
      .trim()
      .required("Last Name is required")
      .min(2, "Last Name should have minimum of two characters"),
    Email: Yup.string()
      .trim()
      .required("Email is required")
      .email("Specify a valid email"),
    ContactPreference: Yup.string().required("Contact Preference is required"),
    Phone: Yup.string()
      .trim()
      .required("Phone number is required")
      .matches(phoneRegex, "Phone number is not valid"),
    Street: Yup.string()
      .trim()
      .required("Street is required")
      .min(2, "Street should have minimum of two characters"),
    City: Yup.string()
      .trim()
      .required("City is required")
      .min(2, "City should have minimum of two characters"),
    State: Yup.string()
      .trim()
      .required("State is required")
      .min(2, "State should have minimum of two characters"),
    Country: Yup.string()
      .trim()
      .min(2, "Country should have minimum of two characters"),
    PostalCode: Yup.string()
      .trim()
      .required("PostalCode is required")
      .min(2, "PostalCode should have minimum of two characters"),
    Owner1Ownership: Yup.number()
      .required("Percentage ownership is required")
      .min(0, "Minimum value is 0")
      .max(100, "Maximum value is 100"),
    Owner1SSN: Yup.string()
      .trim()
      .required("Owner1SSN is required")
      .min(2, "Owner1SSN should have minimum of two characters")
      .max(11, "Owner1SSN should have not be more than 11 characters")
      .matches(
        SSNRegex,
        "SSN is not valid. it should be in the format XXX-XX-XXXX"
      ),
    Owner2Title: Yup.string().when("HasOwner2", {
      is: (HasOwner2) => HasOwner2,
      then: Yup.string()
        .trim()
        .required("Title is required")
        .min(2, "Title should have minimum of two characters"),
    }),
    Owner2FirstName: Yup.string().when("HasOwner2", {
      is: (HasOwner2) => HasOwner2,
      then: Yup.string()
        .trim()
        .required("First Name is required")
        .min(2, "First Name should have minimum of two characters"),
    }),
    Owner2LastName: Yup.string().when("HasOwner2", {
      is: (HasOwner2) => HasOwner2,
      then: Yup.string()
        .trim()
        .required("Last Name is required")
        .min(2, "Last Name should have minimum of two characters"),
    }),
    Owner2Email: Yup.string().when("HasOwner2", {
      is: (HasOwner2) => HasOwner2,
      then: Yup.string()
        .trim()
        .required("Email is required")
        .email("Specify a valid email"),
    }),
    Owner2Phone: Yup.string().when("HasOwner2", {
      is: (HasOwner2) => HasOwner2,
      then: Yup.string()
        .trim()
        .matches(phoneRegex, "Phone number is not a valid number"),
    }),
    Owner2Ownership: Yup.number().when("HasOwner2", {
      is: (HasOwner2) => HasOwner2,
      then: Yup.number()
        .required("Percentage ownership is required")
        .min(0, "Minimum value is 0")
        .max(100, "Maximum value is 100"),
    }),
    Owner3Title: Yup.string().when("HasOwner3", {
      is: (HasOwner3) => HasOwner3,
      then: Yup.string()
        .trim()
        .required("Title is required")
        .min(2, "Title should have minimum of two characters"),
    }),
    Owner3FirstName: Yup.string().when("HasOwner3", {
      is: (HasOwner3) => HasOwner3,
      then: Yup.string()
        .trim()
        .required("First Name is required")
        .min(2, "First Name should have minimum of two characters"),
    }),
    Owner3LastName: Yup.string().when("HasOwner3", {
      is: (HasOwner3) => HasOwner3,
      then: Yup.string()
        .trim()
        .required("Last Name is required")
        .min(2, "Last Name should have minimum of two characters"),
    }),
    Owner3Email: Yup.string().when("HasOwner3", {
      is: (HasOwner3) => HasOwner3,
      then: Yup.string()
        .trim()
        .required("Email is required")
        .email("Specify a valid email"),
    }),
    Owner3Phone: Yup.string().when("HasOwner3", {
      is: (HasOwner3) => HasOwner3,
      then: Yup.string()
        .trim()
        .matches(phoneRegex, "Phone number is not a valid number"),
    }),
    Owner3Ownership: Yup.number().when("HasOwner3", {
      is: (HasOwner3) => HasOwner3,
      then: Yup.number()
        .required("Percentage ownership is required")
        .min(0, "Minimum value is 0")
        .max(100, "Maximum value is 100"),
    }),
  });

  useImperativeHandle(ref, () => ({
    isValid: async () => {
      const errors = await formikRef.current.validateForm();
      if (errors.constructor === Object && Object.keys(errors).length === 0) {
        return true;
      } else {
        return false;
      }
    },
    getValues: () => {
      return formikRef.current.values;
    },
    handleSubmit: () => {
      formikRef.current.handleSubmit();
    },
  }));

  return (
    <div className="mt-5">
      <Formik
        initialValues={{
          FirstName: "",
          LastName: "",
          ContactPreference: "",
          Street: "",
          State: "",
          PostalCode: "",
          City: "",
          Country: "",
          Title: "",
          Email: "",
          Phone: "",
          Owner1Ownership: "",
          Owner1SSN: "",
          ...values,
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
        }}
        validationSchema={validationSchema}
        innerRef={formikRef}
      >
        {({
          handleChange,
          values,
          errors,
          touched,
          handleBlur,
          setFieldValue,
          setFieldTouched,
        }) => (
          <Form>
            <h2 className="sos-text-primary mb-0 font-weight-bold q-text">
              Tell us about yourself
            </h2>

            <Row>
              <Col md="6" sm="12" className="mb-4">
                <FormGroup
                  className={`${
                    errors.FirstName && touched.FirstName ? "mb-0" : ""
                  }`}
                >
                  <Label className="text-medium">
                    First Name <span className="text-danger">*</span>
                  </Label>
                  <Input
                    className={"no-margin"}
                    placeholder=""
                    type="text"
                    name="FirstName"
                    onChange={handleChange("FirstName")}
                    onBlur={handleBlur("FirstName")}
                    value={values.FirstName}
                  />
                </FormGroup>
                <small className="text-danger">
                  {errors.FirstName && touched.FirstName
                    ? errors.FirstName
                    : null}
                </small>
              </Col>
              <Col md="6" sm="12" className="mb-4">
                <FormGroup
                  className={`${
                    errors.LastName && touched.LastName ? "mb-0" : ""
                  }`}
                >
                  <Label className="text-medium">
                    Last Name <span className="text-danger">*</span>
                  </Label>
                  <Input
                    className={"no-margin"}
                    placeholder=""
                    type="text"
                    name="LastName"
                    onChange={handleChange("LastName")}
                    onBlur={handleBlur("LastName")}
                    value={values.LastName}
                  />
                </FormGroup>
                <small className="text-danger">
                  {errors.LastName && touched.LastName ? errors.LastName : null}
                </small>
              </Col>
              <Col md="6" sm="12" className="mb-4">
                <FormGroup
                  className={`${errors.Title && touched.Title ? "mb-0" : ""}`}
                >
                  <Label className="text-medium">
                    Title <span className="text-danger">*</span>
                  </Label>
                  <Input
                    className={"no-margin"}
                    placeholder=""
                    type="text"
                    name="Title"
                    onChange={handleChange("Title")}
                    onBlur={handleBlur("Title")}
                    value={values.Title}
                  />
                </FormGroup>
                <small className="text-danger">
                  {errors.Title && touched.Title ? errors.Title : null}
                </small>
              </Col>
              <Col md="6" sm="12" className="mb-4">
                <FormGroup
                  className={`${errors.Email && touched.Email ? "mb-0" : ""}`}
                >
                  <Label className="text-medium">
                    Email <span className="text-danger">*</span>
                  </Label>
                  <Input
                    className={"no-margin"}
                    placeholder=""
                    type="email"
                    name="Email"
                    onChange={handleChange("Email")}
                    onBlur={handleBlur("Email")}
                    value={values.Email}
                  />
                </FormGroup>
                <small className="text-danger">
                  {errors.Email && touched.Email ? errors.Email : null}
                </small>
              </Col>
              <Col md="6" sm="12" className="mb-4">
                <Label className="text-medium">
                  How do you prefer to be contacted?{" "}
                  <span className="text-danger">*</span>
                </Label>
                <Select
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  styles={customStyles}
                  placeholder="--None--"
                  name="ContactPreference"
                  value={getValueObject(
                    HowDoYouPreferToBeContacted,
                    values.ContactPreference
                  )}
                  onChange={(value) =>
                    setFieldValue("ContactPreference", value.value)
                  }
                  options={HowDoYouPreferToBeContacted}
                  onBlur={() => setFieldTouched("ContactPreference")}
                />
                <small className="text-danger">
                  {errors.ContactPreference && touched.ContactPreference
                    ? errors.ContactPreference
                    : null}
                </small>
              </Col>
              <Col md="6" sm="12" className="mb-4">
                <FormGroup
                  className={`${errors.Phone && touched.Phone ? "mb-0" : ""}`}
                >
                  <Label className="text-medium">
                    Phone <span className="text-danger">*</span>
                  </Label>
                  <Input
                    className={"no-margin"}
                    placeholder=""
                    type="phone"
                    name="Phone"
                    onChange={handleChange("Phone")}
                    onBlur={handleBlur("Phone")}
                    value={values.Phone}
                  />
                </FormGroup>
                <small className="text-danger">
                  {errors.Phone && touched.Phone ? errors.Phone : null}
                </small>
              </Col>
              <Col md="12" sm="12" className="mb-4">
                <FormGroup
                  className={`${errors.Street && touched.Street ? "mb-0" : ""}`}
                >
                  <Label className="text-medium">
                    Street <span className="text-danger">*</span>
                  </Label>
                  <Input
                    className={"no-margin"}
                    placeholder=""
                    type="text"
                    name="Street"
                    onChange={handleChange("Street")}
                    onBlur={handleBlur("Street")}
                    value={values.Street}
                  />
                </FormGroup>
                <small className="text-danger">
                  {errors.Street && touched.Street ? errors.Street : null}
                </small>
              </Col>
              <Col md="6" sm="12" className="mb-4">
                <FormGroup
                  className={`${errors.City && touched.City ? "mb-0" : ""}`}
                >
                  <Label className="text-medium">
                    City <span className="text-danger">*</span>
                  </Label>
                  <Input
                    className={"no-margin"}
                    placeholder=""
                    type="text"
                    name="City"
                    onChange={handleChange("City")}
                    onBlur={handleBlur("City")}
                    value={values.City}
                  />
                </FormGroup>
                <small className="text-danger">
                  {errors.City && touched.City ? errors.City : null}
                </small>
              </Col>
              <Col md="6" sm="12" className="mb-4">
                <FormGroup
                  className={`${errors.State && touched.State ? "mb-0" : ""}`}
                >
                  <Label className="text-medium">
                    State/Province <span className="text-danger">*</span>
                  </Label>
                  <Input
                    className={"no-margin"}
                    placeholder=""
                    type="text"
                    name="State"
                    onChange={handleChange("State")}
                    onBlur={handleBlur("State")}
                    value={values.State}
                  />
                </FormGroup>
                <small className="text-danger">
                  {errors.State && touched.State ? errors.State : null}
                </small>
              </Col>
              <Col md="6" sm="12" className="mb-4">
                <FormGroup
                  className={`${
                    errors.Country && touched.Country ? "mb-0" : ""
                  }`}
                >
                  <Label className="text-medium">Country</Label>
                  <Input
                    className={"no-margin"}
                    placeholder=""
                    type="text"
                    name="Country"
                    onChange={handleChange("Country")}
                    onBlur={handleBlur("Country")}
                    value={values.Country}
                  />
                </FormGroup>
                <small className="text-danger">
                  {errors.Country && touched.Country ? errors.Country : null}
                </small>
              </Col>
              <Col md="6" sm="12" className="mb-4">
                <FormGroup
                  className={`${
                    errors.PostalCode && touched.PostalCode ? "mb-0" : ""
                  }`}
                >
                  <Label className="text-medium">
                    Zip Code <span className="text-danger">*</span>
                  </Label>
                  <Input
                    className={"no-margin"}
                    placeholder=""
                    type="text"
                    name="PostalCode"
                    onChange={handleChange("PostalCode")}
                    onBlur={handleBlur("PostalCode")}
                    value={values.PostalCode}
                  />
                </FormGroup>
                <small className="text-danger">
                  {errors.PostalCode && touched.PostalCode
                    ? errors.PostalCode
                    : null}
                </small>
              </Col>
              <Col md="6" sm="12" className="mb-4">
                <FormGroup
                  className={`${
                    errors.Owner1SSN && touched.Owner1SSN ? "mb-0" : ""
                  }`}
                >
                  <Label className="text-medium">
                    Social Security Number{" "}
                    <span className="text-danger">*</span>
                  </Label>
                  <Input
                    className={"no-margin"}
                    placeholder=""
                    type="text"
                    name="Owner1SSN"
                    onChange={(e) =>
                      setFieldValue("Owner1SSN", formatSSN(e.target.value))
                    }
                    onBlur={handleBlur("Owner1SSN")}
                    value={values.Owner1SSN}
                  />
                </FormGroup>
                <small className="text-danger">
                  {errors.Owner1SSN && touched.Owner1SSN
                    ? errors.Owner1SSN
                    : null}
                </small>
              </Col>
              <Col md="6" sm="12" className="mb-4">
                <FormGroup
                  className={`${
                    errors.Owner1Ownership && touched.Owner1Ownership
                      ? "mb-0"
                      : ""
                  }`}
                >
                  <Label className="text-medium">
                    Percentage Ownership <span className="text-danger">*</span>
                  </Label>
                  <Input
                    className={"no-margin"}
                    placeholder=""
                    type="text"
                    name="Owner1Ownership"
                    onChange={handleChange("Owner1Ownership")}
                    onBlur={(e) => {
                      setFieldTouched("Owner1Ownership");
                      if (e.target.value < 90) {
                        setFieldValue("HasOwner2", true);
                        setOwnershipMessage(
                          "Because the ownership percentage of the primary contact is less than 90%, please enter additional ownership information"
                        );
                      }
                    }}
                    value={values.Owner1Ownership}
                  />
                </FormGroup>
                <small className="text-danger">
                  {errors.Owner1Ownership && touched.Owner1Ownership
                    ? errors.Owner1Ownership
                    : null}
                </small>
              </Col>
            </Row>
            {ownershipMessage ? (
              <Alert
                message="Add Additional Owners"
                description={ownershipMessage}
                type="info"
                showIcon
                className="mb-5"
                closable
              />
            ) : null}
            <Row>
              <Col md="6" sm="12" className="mb-4">
                <FormGroup check inline>
                  <Label check>
                    <Input
                      className={"no-margin"}
                      type="checkbox"
                      name="HasOwner2"
                      onChange={(e) => {
                        if (values.HasOwner3) {
                          setFieldValue("Owner3Title", "");
                          setFieldValue("Owner3FirstName", "");
                          setFieldValue("Owner3LastName", "");
                          setFieldValue("Owner3Email", "");
                          setFieldValue("Owner3Phone", "");
                          setFieldValue("Owner3Ownership", "");
                          setFieldValue("HasOwner3", false);
                        }

                        if (values.HasOwner2) {
                          setFieldValue("Owner2Title", "");
                          setFieldValue("Owner2FirstName", "");
                          setFieldValue("Owner2LastName", "");
                          setFieldValue("Owner2Email", "");
                          setFieldValue("Owner2Phone", "");
                          setFieldValue("Owner2Ownership", "");
                        }
                        setFieldValue("HasOwner2", e.target.checked);
                      }}
                      onBlur={handleBlur("HasOwner2")}
                      checked={values.HasOwner2}
                    />
                    <span className="form-check-sign" />
                    Add Second Owner
                  </Label>
                </FormGroup>
              </Col>
            </Row>
            {values.HasOwner2 ? (
              <Row>
                <Col sm="12">
                  <h5 className="sos-font-weight-600 text-black sos-text-medium">
                    Second Owner Details
                  </h5>
                </Col>
                <Col md="6" sm="12" className="mb-4">
                  <FormGroup
                    className={`${
                      errors.Owner2FirstName && touched.Owner2FirstName
                        ? "mb-0"
                        : ""
                    }`}
                  >
                    <Label className="text-medium">
                      First Name <span className="text-danger">*</span>
                    </Label>
                    <Input
                      className={"no-margin"}
                      placeholder=""
                      type="text"
                      name="Owner2FirstName"
                      onChange={handleChange("Owner2FirstName")}
                      onBlur={handleBlur("Owner2FirstName")}
                      value={values.Owner2FirstName}
                    />
                  </FormGroup>
                  <small className="text-danger">
                    {errors.Owner2FirstName && touched.Owner2FirstName
                      ? errors.Owner2FirstName
                      : null}
                  </small>
                </Col>
                <Col md="6" sm="12" className="mb-4">
                  <FormGroup
                    className={`${
                      errors.Owner2LastName && touched.Owner2LastName
                        ? "mb-0"
                        : ""
                    }`}
                  >
                    <Label className="text-medium">
                      Last Name <span className="text-danger">*</span>
                    </Label>
                    <Input
                      className={"no-margin"}
                      placeholder=""
                      type="text"
                      name="Owner2LastName"
                      onChange={handleChange("Owner2LastName")}
                      onBlur={handleBlur("Owner2LastName")}
                      checked={values.Owner2LastName}
                    />
                  </FormGroup>
                  <small className="text-danger">
                    {errors.Owner2LastName && touched.Owner2LastName
                      ? errors.Owner2LastName
                      : null}
                  </small>
                </Col>
                <Col md="6" sm="12" className="mb-4">
                  <FormGroup
                    className={`${
                      errors.Owner2Title && touched.Owner2Title ? "mb-0" : ""
                    }`}
                  >
                    <Label className="text-medium">
                      Title <span className="text-danger">*</span>
                    </Label>
                    <Input
                      className={"no-margin"}
                      placeholder=""
                      type="text"
                      name="Owner2Title"
                      onChange={handleChange("Owner2Title")}
                      onBlur={handleBlur("Owner2Title")}
                      value={values.Owner2Title}
                    />
                  </FormGroup>
                  <small className="text-danger">
                    {errors.Owner2Title && touched.Owner2Title
                      ? errors.Owner2Title
                      : null}
                  </small>
                </Col>
                <Col md="6" sm="12" className="mb-4">
                  <FormGroup
                    className={`${
                      errors.Owner2Email && touched.Owner2Email ? "mb-0" : ""
                    }`}
                  >
                    <Label className="text-medium">
                      Email <span className="text-danger">*</span>
                    </Label>
                    <Input
                      className={"no-margin"}
                      placeholder=""
                      type="email"
                      name="Owner2Email"
                      onChange={handleChange("Owner2Email")}
                      onBlur={handleBlur("Owner2Email")}
                      value={values.Owner2Email}
                    />
                  </FormGroup>
                  <small className="text-danger">
                    {errors.Owner2Email && touched.Owner2Email
                      ? errors.Owner2Email
                      : null}
                  </small>
                </Col>
                <Col md="6" sm="12" className="mb-4">
                  <FormGroup
                    className={`${
                      errors.Owner2Phone && touched.Owner2Phone ? "mb-0" : ""
                    }`}
                  >
                    <Label className="text-medium">Phone</Label>
                    <Input
                      className={"no-margin"}
                      placeholder=""
                      type="phone"
                      name="Owner2Phone"
                      onChange={handleChange("Owner2Phone")}
                      onBlur={handleBlur("Owner2Phone")}
                      value={values.Owner2Phone}
                    />
                  </FormGroup>
                  <small className="text-danger">
                    {errors.Owner2Phone && touched.Owner2Phone
                      ? errors.Owner2Phone
                      : null}
                  </small>
                </Col>
                <Col md="6" sm="12" className="mb-4">
                  <FormGroup
                    className={`${
                      errors.Owner2Ownership && touched.Owner2Ownership
                        ? "mb-0"
                        : ""
                    }`}
                  >
                    <Label className="text-medium">
                      Percentage Ownership{" "}
                      <span className="text-danger">*</span>
                    </Label>
                    <Input
                      className={"no-margin"}
                      placeholder=""
                      type="text"
                      name="Owner2Ownership"
                      onChange={handleChange("Owner2Ownership")}
                      onBlur={handleBlur("Owner2Ownership")}
                      value={values.Owner2Ownership}
                    />
                  </FormGroup>
                  <small className="text-danger">
                    {errors.Owner2Ownership && touched.Owner2Ownership
                      ? errors.Owner2Ownership
                      : null}
                  </small>
                </Col>
              </Row>
            ) : null}
            {values.HasOwner2 ? (
              <Row>
                <Col md="6" sm="12" className="mb-4">
                  <FormGroup check inline>
                    <Label check>
                      <Input
                        className={"no-margin"}
                        type="checkbox"
                        name="HasOwner3"
                        onChange={(e) => {
                          if (values.HasOwner3) {
                            setFieldValue("Owner3Title", "");
                            setFieldValue("Owner3FirstName", "");
                            setFieldValue("Owner3LastName", "");
                            setFieldValue("Owner3Email", "");
                            setFieldValue("Owner3Phone", "");
                            setFieldValue("Owner3Ownership", "");
                          }
                          setFieldValue("HasOwner3", e.target.checked);
                        }}
                        onBlur={handleBlur("HasOwner3")}
                        value={values.HasOwner3}
                      />
                      <span className="form-check-sign" />
                      Add Third Owner
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
            ) : null}

            {values.HasOwner3 ? (
              <Row>
                <Col sm="12">
                  <h5 className="sos-font-weight-600 text-black sos-text-medium">
                    Third Owner Details
                  </h5>
                </Col>
                <Col md="6" sm="12" className="mb-4">
                  <FormGroup
                    className={`${
                      errors.Owner3FirstName && touched.Owner3FirstName
                        ? "mb-0"
                        : ""
                    }`}
                  >
                    <Label className="text-medium">
                      First Name <span className="text-danger">*</span>
                    </Label>
                    <Input
                      className={"no-margin"}
                      placeholder=""
                      type="text"
                      name="Owner3FirstName"
                      onChange={handleChange("Owner3FirstName")}
                      onBlur={handleBlur("Owner3FirstName")}
                      value={values.Owner3FirstName}
                    />
                  </FormGroup>
                  <small className="text-danger">
                    {errors.Owner3FirstName && touched.Owner3FirstName
                      ? errors.Owner3FirstName
                      : null}
                  </small>
                </Col>
                <Col md="6" sm="12" className="mb-4">
                  <FormGroup
                    className={`${
                      errors.Owner3LastName && touched.Owner3LastName
                        ? "mb-0"
                        : ""
                    }`}
                  >
                    <Label className="text-medium">
                      Last Name <span className="text-danger">*</span>
                    </Label>
                    <Input
                      className={"no-margin"}
                      placeholder=""
                      type="text"
                      name="Owner3LastName"
                      onChange={handleChange("Owner3LastName")}
                      onBlur={handleBlur("Owner3LastName")}
                      value={values.Owner3LastName}
                    />
                  </FormGroup>
                  <small className="text-danger">
                    {errors.Owner3LastName && touched.Owner3LastName
                      ? errors.Owner3LastName
                      : null}
                  </small>
                </Col>
                <Col md="6" sm="12" className="mb-4">
                  <FormGroup
                    className={`${
                      errors.Owner3Title && touched.Owner3Title ? "mb-0" : ""
                    }`}
                  >
                    <Label className="text-medium">
                      Title <span className="text-danger">*</span>
                    </Label>
                    <Input
                      className={"no-margin"}
                      placeholder=""
                      type="text"
                      name="Owner3Title"
                      onChange={handleChange("Owner3Title")}
                      onBlur={handleBlur("Owner3Title")}
                      value={values.Owner3Title}
                    />
                  </FormGroup>
                  <small className="text-danger">
                    {errors.Owner3Title && touched.Owner3Title
                      ? errors.Owner3Title
                      : null}
                  </small>
                </Col>
                <Col md="6" sm="12" className="mb-4">
                  <FormGroup
                    className={`${
                      errors.Owner3Email && touched.Owner3Email ? "mb-0" : ""
                    }`}
                  >
                    <Label className="text-medium">
                      Email <span className="text-danger">*</span>
                    </Label>
                    <Input
                      className={"no-margin"}
                      placeholder=""
                      type="email"
                      name="Owner3Email"
                      onChange={handleChange("Owner3Email")}
                      onBlur={handleBlur("Owner3Email")}
                      value={values.Owner3Email}
                    />
                  </FormGroup>
                  <small className="text-danger">
                    {errors.Owner3Email && touched.Owner3Email
                      ? errors.Owner3Email
                      : null}
                  </small>
                </Col>
                <Col md="6" sm="12" className="mb-4">
                  <FormGroup
                    className={`${
                      errors.Owner3Phone && touched.Owner3Phone ? "mb-0" : ""
                    }`}
                  >
                    <Label className="text-medium">Phone</Label>
                    <Input
                      className={"no-margin"}
                      placeholder=""
                      type="phone"
                      name="Owner3Phone"
                      onChange={handleChange("Owner3Phone")}
                      onBlur={handleBlur("Owner3Phone")}
                      value={values.Owner3Phone}
                    />
                  </FormGroup>
                  <small className="text-danger">
                    {errors.Owner3Phone && touched.Owner3Phone
                      ? errors.Owner3Phone
                      : null}
                  </small>
                </Col>
                <Col md="6" sm="12" className="mb-4">
                  <FormGroup
                    className={`${
                      errors.Owner3Ownership && touched.Owner3Ownership
                        ? "mb-0"
                        : ""
                    }`}
                  >
                    <Label className="text-medium">
                      Percentage Ownership{" "}
                      <span className="text-danger">*</span>
                    </Label>
                    <Input
                      className={"no-margin"}
                      placeholder=""
                      type="text"
                      name="Owner3Ownership"
                      onChange={handleChange("Owner3Ownership")}
                      onBlur={handleBlur("Owner3Ownership")}
                      value={values.Owner3Ownership}
                    />
                  </FormGroup>
                  <small className="text-danger">
                    {errors.Owner3Ownership && touched.Owner3Ownership
                      ? errors.Owner3Ownership
                      : null}
                  </small>
                </Col>
              </Row>
            ) : null}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default forwardRef(Step2);
