// ------------ import external dependencies ----------
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { message } from "antd";
import { useHistory } from "react-router-dom";
import ReactGA from "react-ga";
import moment from "moment";

// ----------- import internal dpendencies ------------
import Step1 from "./components/step1";
import Step2 from "./components/step2";
import {
  applyForFunding,
  applyForFundingCleanup,
} from "../../store/actions/apply-for-funding";
// ------ import styles ------
import "./style.css";
// ------ import utils ------
import useQuery from "../../utils/useQuery";
import { formatSSN } from "../../utils/helpers";

const NewLeadForm = () => {
  // ------ component state managers -------
  const [step, setStep] = useState(1);
  const [reqObject, setReqObject] = useState({});
  const [step2Values, setStep2Values] = useState(null);
  const [prevOwnerCount, setPrevOwnerCount] = useState(0);

  // ----- initialize custom hooks ------
  const dispatch = useDispatch();
  const applyForFundingState = useSelector((s) => s.applyForFunding);
  const history = useHistory();
  const referralCode = useQuery().get("ref");

  const handleCallBack = (values) => {
    console.log("Merged objects", { ...reqObject, ...values });
    setReqObject({ ...reqObject, ...values });

    if (step === 1) {
      setStep(2);
    }

    if (step === 2) {
      const payload = {
        ...reqObject,
        ...values,
        ...values.otherowners,
        ReferralCode: referralCode,
      };
      payload.DateBusinessEstablished = moment(
        payload.DateBusinessEstablished
      ).format();

      //format ssn of user
      if (payload.Owner1SSN) {
        payload.Owner1SSN = formatSSN(payload.Owner1SSN);
      }

      if (payload.Owner2SSN) {
        payload.Owner2SSN = formatSSN(payload.Owner2SSN);
      }

      if (payload.Owner3SSN) {
        payload.Owner3SSN = formatSSN(payload.Owner3SSN);
      }

      delete payload.otherowners;
      delete payload.continue;
      console.log(payload);
      setReqObject({ ...reqObject, ...values });
      dispatch(applyForFunding(payload));
    }
  };

  useEffect(() => {
    if (applyForFundingState.isSuccessful) {
      message.success("Application submitted successfully");
      dispatch(applyForFundingCleanup());
      history.push(
        `/application-success/${applyForFundingState.data.id}?email=${reqObject.Email}`
      );
    } else if (applyForFundingState.error) {
      message.error("Oops! Application submission failed");
      dispatch(applyForFundingCleanup());
    }
  }, [applyForFundingState]);

  useEffect(() => {
    ReactGA.pageview(
      `portal/${window.location.pathname + window.location.search}`,
      [],
      "Application apply portal - Unregistered user"
    );
  }, []);

  return (
    <>
      <div>
        <div className="multi-stepper">
          <div className="stepper-wrap">
            <div className="stepper">
              <span>1</span>
            </div>
            <span
              className="stepper-desc"
              style={{
                opacity: step === 1 ? "1" : "0.5",
                fontWeight: step === 1 ? "600" : "400",
              }}
            >
              Funding Request
            </span>
          </div>
          <div className="stepper-separator">
            <div
              className="stepper-separator__indicator"
              style={{ width: `${step * 50}%` }}
            ></div>
          </div>
          <div className="stepper-wrap">
            <div className="stepper">
              <span>2</span>
            </div>
            <span
              className="stepper-desc"
              style={{
                opacity: step === 2 ? "1" : "0.5",
                fontWeight: step === 2 ? "600" : "400",
              }}
            >
              Business Information
            </span>
          </div>
        </div>

        {step === 1 && (
          <Step1 defaultValues={{ ...reqObject }} cb={handleCallBack} />
        )}
        {step === 2 && (
          <Step2
            previous={(values, OwnerCount) => {
              setStep2Values(values);
              setPrevOwnerCount(OwnerCount);
              setStep(1);
            }}
            defaultValues={{ ...reqObject }}
            cb={handleCallBack}
            step2Values={step2Values}
            prevOwnerCount={prevOwnerCount}
          />
        )}
      </div>
    </>
  );
};

export default NewLeadForm;
