import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Alert, Button, Form, Input } from "antd";
import {
  Card,
  CardBody,
  Container,
  Col,
  Row,
  CardTitle,
  CardFooter
} from "reactstrap";

//utils
import firebase from "../../utils/firebase";

const ForgotPassword = () => {
  const [message, setMessage] = useState(null);
  const [form] = Form.useForm();

  const handleSubmit = async (values) => {
    try {
      await firebase.doPasswordReset(values.email);
      setMessage(<Alert message={"Reset link has been sent to your mail"} type="success" />);
      form.resetFields();
    } catch (error) {
      setMessage(<Alert message={"Password reset was not successful"} type="error" />);
    }
  };

  return (
    <>
      <div className="forgot-password-page mt-5 pt-5  h-100 pb-5 mb-5">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" lg="4" md="6">
              <Card>
                <CardTitle className="text-center px-4 pt-4 pb-0">
                  <h3 className="sos-text-primary mb-0 font-weight-bold">
                    Forgot Password
                  </h3>
                  <p className='mb-0'>Please enter your email to reset password</p>
                </CardTitle>
               {message ? <div className='mb-4'>{message}</div> : null}
                <CardBody>
                  <Form
                    labelCol={{ span: 24 }}
                    wrapperCol={{span: 24}}
                    name='reset'
                    onFinish={handleSubmit}
                    className='px-4 justify-content-center w-100'
                    layout='vertical'
                    form={form}
                  >
                    <Form.Item
                      name='email'
                      placeholder='Email'
                      label='Email'
                      rules={[
                        { 
                          required: true, 
                          message: 'Please input your email!'
                        },
                        {
                          type: 'email',
                          message: 'Input a valid email!'
                        }
                      ]}
                      className='w-100 mb-4 sos-text-medium sos-font-weight-600'
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item className='text-center'>
                      <Button htmlType='submit' className='sos-bg-primary sos-border-primary text-white' >Reset</Button> 
                    </Form.Item>
                  </Form>
                  <CardFooter>
                    <div className="m-1 text-right">
                      <span>
                        Have an account,{' '} 
                        <Link to="/">Login</Link>
                      </span>
                    </div>
                  </CardFooter>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            background: "black",
          }}
        />
      </div>
    </>
  );
};

export default ForgotPassword;
