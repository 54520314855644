import './index.css';
import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux";
import { Card, Divider, Spin, Progress, Button } from 'antd';
import { CardTitle, Card as RCard, CardBody, Row, Col } from 'reactstrap';
import moment from 'moment';
import { DownloadOutlined } from '@ant-design/icons';

//actions
import { getSingleRepayment, getSingleRepaymentCleanup } from '../../../../store/actions/get-single-repayment';
import { generatePaymentHistory, generatePaymentHistoryCleanup } from '../../../../store/actions/generate-payment-history';

//utils
import { formatCurrency } from '../../../../utils/helpers';

const RepaymentDetailsSection = ({ id }) => {
  const dispatch = useDispatch();
  const getSingleRepaymentState = useSelector(s => s.getSingleRepayment);
  const [repaymentDetails, setRepaymentDetails] = useState(null);
  const [dealId, setDealId] = useState("")
  const generatePaymentHistoryState = useSelector(s => s.generatePaymentHistory);
  const [pdfUrl, setPdfUrl] = useState(null);

  const generate = () => {
    dispatch(generatePaymentHistory(id));
  };

  useEffect(() => {
    dispatch(getSingleRepayment(1, id));
  },[]);

  useEffect(() => {
    if(getSingleRepaymentState.isSuccessful){
      setRepaymentDetails(getSingleRepaymentState.data);
      setDealId(getSingleRepaymentState.data.Opportunity__c)
      dispatch(getSingleRepaymentCleanup());
    }else if(getSingleRepaymentState.error){
      dispatch(getSingleRepaymentCleanup());
    }
  },[getSingleRepaymentState]);

  useEffect(() => {
    if(generatePaymentHistoryState.isSuccessful){
      setPdfUrl(generatePaymentHistoryState.data);
      dispatch(generatePaymentHistoryCleanup());
    }else if(generatePaymentHistoryState.error){
      dispatch(generatePaymentHistoryCleanup());
    }
  },[generatePaymentHistoryState]);

  return (
    <div className='repayment-detail-section'>
      <Card className="d-fleustify-content-between">
        <CardTitle className="text-cente">
          <div className="d-flex justify-content-between align-items-center">
            <div className='sos-text-primary mb-0 font-weight-bold'>Repayment Details</div>
            <div>
              <div className='mb-4 font-weight-bold'>
                <Link className="sos-text-primary" to={`/user/deal-details/${dealId}`}>Link to Opportunity</Link>
              </div>
              {pdfUrl ? (
                <a className="sos-text-primary font-weight-bold" href={pdfUrl} target='_blank'>
                  Download <DownloadOutlined className="sos-text-large" />
                </a> 
              ) : (
                <Button onClick={generate} disabled={generatePaymentHistoryState.isLoading}>
                  Generate Payment Statement
                </Button>
              )}
            </div>
          </div>
        </CardTitle>     
      </Card>
      <hr />
      <Card>
        {getSingleRepaymentState.isLoading && !repaymentDetails ? (
          <Spin size='large' className='loader' />
        ) : repaymentDetails ? (
          <div>
            <Row>
              <Col lg="4" md="6" sm="6">
                <RCard className="card-stats">
                  <CardBody>
                    <Row>
                      <Col md="4" xs="5">
                        <div className="icon-big text-center icon-warning">
                          <i className="nc-icon nc-money-coins text-danger" />
                        </div>
                      </Col>
                      <Col md="8" xs="7">
                        <div className="numbers">
                          <p className="card-category">Balance</p>
                          <CardTitle tag="p">{repaymentDetails.Balance__c ? formatCurrency(repaymentDetails.Balance__c) : 0}</CardTitle>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </RCard>
              </Col>
              <Col lg="4" md="6" sm="6">
                <RCard className="card-stats">
                  <CardBody>
                    <Row>
                      <Col md="4" xs="5">
                        <div className='icon-big text-center'>
                          <Progress type='circle' percent={repaymentDetails.Paid__c ? repaymentDetails.Paid__c : 0} width={50} />
                        </div>
                      </Col>
                      <Col md="8" xs="7">
                        <div className="numbers">
                          <p className="card-category">Paid Percentage</p>
                          <CardTitle tag="p">{`${repaymentDetails.Paid__c ? repaymentDetails.Paid__c : 0}%`}</CardTitle>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </RCard>
              </Col>
              <Col lg="4" md="6" sm="6">
                <RCard className="card-stats">
                  <CardBody>
                    <Row>
                      <Col md="4" xs="5">
                        <div className="icon-big text-center icon-warning">
                          <i className="fa fa-calendar-o text-warning" />
                        </div>
                      </Col>
                      <Col md="8" xs="7">
                        <div className="numbers">
                          <p className="card-category">Funded Date</p>
                          <CardTitle tag="p">{repaymentDetails.Origination_Fee__c ? moment(repaymentDetails.Funded_Date__c).format('MMMM Do, YYYY') : 'N/A'}</CardTitle>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </RCard>
              </Col>
            </Row>
            <Divider orientation="left">
              <h4 className='sos-font-weight-600 mt-0 mb-0'>Details</h4>
            </Divider>
            <div className='row'>
              <div className='col-md-6'>
                <p>
                  <strong>Funding Amount: </strong>{repaymentDetails.Funding_Amount__c ? formatCurrency(repaymentDetails.Funding_Amount__c) : 0}
                </p>
              </div>
              <div className='col-md-6'>
                <p>
                  <strong>Origination Fee: </strong>{repaymentDetails.Origination_Fee__c ? formatCurrency(repaymentDetails.Origination_Fee__c) : 0}
                </p>
              </div>
              <div className='col-md-6'>
                <p>
                  <strong>Payoff %: </strong>{`${repaymentDetails.Payoff_Text_Only__c ? repaymentDetails.Payoff_Text_Only__c : 0}%`}
                </p>
              </div>
              <div className='col-md-6'>
                <p>
                  <strong>Total Pending Amount: </strong>{repaymentDetails.Pending_Amount__c ? formatCurrency(repaymentDetails.Pending_Amount__c) : 0}
                </p>
              </div>
              <div className='col-md-6'>
                <p>
                  <strong>Initial Repayment Amount: </strong>{repaymentDetails.Intial_Repayment_Amount__c ? formatCurrency(repaymentDetails.Intial_Repayment_Amount__c) : 0}
                </p>
              </div>
              <div className='col-md-6'>
                <p>
                  <strong>Total Payments Settled: </strong>{repaymentDetails.Total_Payments_Settled__c ? formatCurrency(repaymentDetails.Total_Payments_Settled__c) : 0}
                </p>
              </div>
              <div className='col-md-6'>
                <p>
                  <strong>Total Outstanding Amount: </strong>{repaymentDetails.Total_Outstanding_Amount__c ? formatCurrency(repaymentDetails.Total_Outstanding_Amount__c) : 0}
                </p>
              </div>
            </div>
            <Divider orientation="left">
              <h4 className='sos-font-weight-600 mt-0 mb-0'>Bank Information</h4>
            </Divider>
            <div className='row'>
              <div className='col-md-6'>
                <p>
                  <strong>Bank Name: </strong>{repaymentDetails.Bank_Name__c ? repaymentDetails.Bank_Name__c : ''}
                </p>
              </div>
              <div className='col-md-6'>
                <p>
                  <strong>Bank Account Number: </strong>{repaymentDetails.Bank_Account_Number__c ? repaymentDetails.Bank_Account_Number__c : ''}
                </p>
              </div>
              <div className='col-md-6'>
                <p>
                  <strong>Bank Routing Number: </strong>{repaymentDetails.Bank_Routing_Number__c ? repaymentDetails.Bank_Routing_Number__c : ''}
                </p>
              </div>
            </div>
          </div>
        ) : null}
      </Card>
    </div>
  );
}

export default RepaymentDetailsSection;
