import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';

export const generatePaymentHistorySyndicatorStart = () => ({
  type: types.GENERATE_PAYMENT_HISTORY_SYNDICATOR_START
});

export const generatePaymentHistorySyndicatorSuccess = payload => ({
  type: types.GENERATE_PAYMENT_HISTORY_SYNDICATOR_SUCCESS,
  payload
});

export const generatePaymentHistorySyndicatorFail = payload => ({
  type: types.GENERATE_PAYMENT_HISTORY_SYNDICATOR_FAIL,
  payload
});

export const generatePaymentHistorySyndicatorCleanup = () => ({
  type: types.GENERATE_PAYMENT_HISTORY_SYNDICATOR_CLEANUP
});

export const generatePaymentHistorySyndicator = (id, cDateStartDateF, cDateEndDateF, bounce) => async dispatch => {
  try {
    dispatch(generatePaymentHistorySyndicatorStart());
    const cBounce = bounce ? '?bounce=true':''
    const path = `pdfgenerator/paymenthistorysyndicator/${id}/${cDateStartDateF}/${cDateEndDateF}${cBounce}`;
    const requestObj = {
      path,
      method: 'GET'
    }
    const  data  = await AxiosCall(requestObj);
    dispatch(generatePaymentHistorySyndicatorSuccess(data));
  } catch (err) {
    const error = ErrorHandler(err);
    console.log('MRA error:', error);
    dispatch(generatePaymentHistorySyndicatorFail(error));
  }
}
