// ------------ import external dependencies ----------
import React, { useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";

// ------------- import internal dpendencies ------------
import AuthSidebar from "../components/AuthSidebar";
import { newRoutes } from "../routes/routes";
// ------ import assets ------
import BrandLogo from "../assets/img/SOS.logo.png";
import MoreIcon from "../assets/svg/more.svg";

const NewAuthLayout = ({ children }) => {
  // ------- component state managers ------
  const [sidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 500) {
      setSidebarOpen(true);
    }
  }, []);

  return (
    <>
      <header className="lead-form-mobile-header">
        <img src={BrandLogo} alt="Mobile brand logo" />
        <img
          src={MoreIcon}
          alt="More icon"
          onClick={() => setSidebarOpen(true)}
        />
      </header>
      <main>
        {sidebarOpen && <AuthSidebar close={() => setSidebarOpen(false)} />}
        <section className="main-section">{children}</section>
        {/* <Switch>
          <section className="main-section">
            {newRoutes.map((prop, key) => (
              <Route
                exact={prop.layout === "/new"}
                path={prop.layout + prop.path}
                component={prop.component}
                key={key}
              />
            ))}
          </section>
        </Switch> */}
      </main>
    </>
  );
};

export default NewAuthLayout;
