import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';

export const getFlexOfferStart = () => ({
  type: types.GET_FLEX_OFFER_START
});

export const getFlexOfferSuccess = payload => ({
  type: types.GET_FLEX_OFFER_SUCCESS,
  payload
});

export const getFlexOfferFail = payload => ({
  type: types.GET_FLEX_OFFER_FAIL,
  payload
});

export const getFlexOfferCleanup = () => ({
  type: types.GET_FLEX_OFFER_CLEANUP
});

export const getFlexOffer = (portal, id) => async dispatch => {
  try {
    dispatch(getFlexOfferStart());
    const path = `offer/${portal}/flexoffer/${id}`;
    const requestObj = {
      path,
      method: 'GET'
    }
    const  data  = await AxiosCall(requestObj);
    dispatch(getFlexOfferSuccess(data[0]));
    
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(getFlexOfferFail(error));
  }
}
