import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';

export const getRepaymentsStart = () => ({
  type: types.GET_REPAYMENTS_START
});

export const getRepaymentsSuccess = payload => ({
  type: types.GET_REPAYMENTS_SUCCESS,
  payload
});

export const getRepaymentsFail = payload => ({
  type: types.GET_REPAYMENTS_FAIL,
  payload
});

export const getRepaymentsCleanup = () => ({
  type: types.GET_REPAYMENTS_CLEANUP
});

export const getRepayments = (currentPortal, opportunityId='', type=null) => async dispatch => {
  try {
    dispatch(getRepaymentsStart());
    const opp = opportunityId ? '/opportunity': '';
    const path = type ? `repayment${opp}/${currentPortal}/iso/${type}/${opportunityId}`
      : `repayment${opp}/${currentPortal}/${opportunityId}`;
    const requestObj = {
      path,
      method: 'GET'
    }
    const  data  = await AxiosCall(requestObj);
    dispatch(getRepaymentsSuccess(data));
    
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(getRepaymentsFail(error));
  }
}
