import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { Alert, message } from "antd";
import GoogleButton from "react-google-button";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row,
  CardTitle,
} from "reactstrap";
import { Button } from "antd";

//utils
import firebase from "../../utils/firebase";

//actions
import {
  checkEmailExist,
  checkEmailExistCleanup,
} from "../../store/actions/check-email-exist";
//actions
import { login, loginCleanup } from "../../store/actions/login";
import { getProfile, getProfileCleanup } from "../../store/actions/get-profile";

const SignUp = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const checkEmailExistState = useSelector((s) => s.checkEmailExist);
  const loginState = useSelector((s) => s.login);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(null);
  const token = localStorage.getItem("authToken");

  const SignInWithGoogle = () => {
    const res = firebase.doSignInWithGoogle();
    res.then((data) =>
      data.user.getIdToken(true).then(function (idToken) {
        dispatch(login({ tokenId: idToken }));
      })
    );
  };

  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let testValid = re.test(String(email).toLowerCase());
    return testValid;
  }

  function validatePassword(password) {
    let testValid = password.trim().length > 0;
    return testValid;
  }

  function validateComfirmPassword(confirmPassword) {
    let testValid = password === confirmPassword;
    return testValid;
  }
  const Validation = () => {
    let emailIsValid = validateEmail(email);
    let passwordValid = validatePassword(password);
    let confirmPasswordValid = validateComfirmPassword(confirmPassword);
    if (emailIsValid && passwordValid && confirmPasswordValid) {
      setError(null);
      dispatch(checkEmailExist({ email }));
    } else if (!emailIsValid) {
      setError(<Alert message={"Incorrect email format"} type="error" />);
    } else if (!passwordValid) {
      setError(<Alert message={"Password must not be empty"} type="error" />);
    } else if (!confirmPasswordValid) {
      setError(
        <Alert
          message={"Confirm password must be equal to password"}
          type="error"
        />
      );
    }
  };

  const SignUpFunc = async () => {
    try {
      const authUser = await firebase.register(email, password);
      authUser.user.sendEmailVerification();
      history.push("/email-verification");
    } catch (err) {
      setError(<Alert message={err.message} type="error" />);
    }
  };

  useEffect(() => {
    document.body.classList.toggle("login-page");
    if (token) {
      dispatch(getProfileCleanup());
      dispatch(getProfile());
      history.push("/portals");
    }
  }, []);

  useEffect(() => {
    if (loginState.isSuccessful && loginState.data) {
      dispatch(getProfileCleanup());
      dispatch(getProfile());
      dispatch(loginCleanup());
      history.push("/portals");
    } else if (loginState.error) {
      setError(<Alert message={loginState.error} type="error" />);
      dispatch(loginCleanup());
    }
  }, [loginState]);

  useEffect(() => {
    if (checkEmailExistState.isSuccessful) {
      if (checkEmailExistState.data === "Email exists") {
        SignUpFunc();
      }

      dispatch(checkEmailExistCleanup());
    } else if (checkEmailExistState.error) {
      setError(<Alert message={checkEmailExistState.error} type="error" />);
      dispatch(checkEmailExistCleanup());
    }
  }, [checkEmailExistState]);
  return (
    <>
      <div className="login-page mt-5 pt-5  h-100 pb-5 mb-5">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" lg="4" md="6">
              <Form action="" className="form">
                <Card className="card-login">
                  <CardTitle className="text-center p-4">
                    <h2 className="sos-text-primary mb-0 mt-4 font-weight-bold">
                      Create Account{" "}
                    </h2>
                  </CardTitle>
                  <CardHeader>{error}</CardHeader>
                  <CardBody>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-single-02" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email..."
                        type="email"
                        autoComplete="on"
                      />
                    </InputGroup>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-key-25" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Password"
                        type="password"
                        autoComplete="off"
                      />
                    </InputGroup>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-key-25" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        placeholder="Confirm Password"
                        type="password"
                        autoComplete="off"
                      />
                    </InputGroup>
                  </CardBody>
                  <br />
                  <CardFooter style={{ marginTop: "-50px" }}>
                    <Button
                      className="btn-rund w-100 font-weight-bold mb-3"
                      type="primary"
                      style={{ height: "45px" }}
                      loading={checkEmailExistState.isLoading}
                      disabled={
                        checkEmailExistState.isLoading || loginState.isLoading
                      }
                      onClick={Validation}
                    >
                      Create Account
                    </Button>
                    <div className="text-center pt-1">
                      <GoogleButton
                        style={{ width: "100%" }}
                        type="dark"
                        onClick={SignInWithGoogle}
                      />
                    </div>
                    <div className="m-1 mt-4 text-right">
                      <FormGroup>
                        <FormGroup check>
                          Have an account?<Link to="/login"> Login</Link>
                        </FormGroup>
                      </FormGroup>
                    </div>
                  </CardFooter>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            background: "black",
          }}
        />
      </div>
    </>
  );
};

export default SignUp;
