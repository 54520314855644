// import './index.css';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Card, Button, message, Form, Input } from 'antd';
import { CardTitle } from 'reactstrap';

//components
import Loader from '../../../components/Loader';

//actions
import { getProfile } from '../../../store/actions/get-profile';
import { updateBankDetails, updateBankDetailsCleanup } from '../../../store/actions/update-bank-details';


const ReferrerMetrics = () => {
  const dispatch = useDispatch();
  const profile = useSelector(s => s.getProfile.data);
  const profileLoading = useSelector(s => s.getProfile.isLoading);
  const updateBankDetailsState = useSelector(s => s.updateBankDetails);
  const [form] = Form.useForm();
  const [enableEdit, setEnableEdit] = useState(false);

  useEffect(() => {
    if(!profile){
      dispatch(getProfile());
    }
  },[]);

  const handleSave = values => {
    dispatch(updateBankDetails(values));
  }

  useEffect(() => {
    if(updateBankDetailsState.isSuccessful){
      message.success('Information saved successfully', 1);
      dispatch(updateBankDetailsCleanup());
      setEnableEdit(false);
      dispatch(getProfile());
    }else if(updateBankDetailsState.error){
      message.error(`Oops! ${updateBankDetailsState.error}`, 2)
      dispatch(updateBankDetailsCleanup());
    }
  },[updateBankDetailsState]);

  return (
    <div className="pt-5  container bank-details-page">
      <div className="pt-5 table-container">
        <Card>
          <CardTitle className="text-cente p-4">
            <div className="sos-text-primary mb-0 font-weight-bold d-flex justify-content-between">
              <div>Bank Details</div>
            </div>
          </CardTitle>
        </Card>
        <hr />
        <Card>
          {profileLoading && !profile ? (
            <div className='m-5 d-flex justify-content-center'>
              <Loader isLoading={profileLoading && !profile} />
            </div>
          ) : (
            <div>
              <Form
                className='text-left'
                form={form}
                onFinish={handleSave}
                wrapperCol={{ span: 24, md: { span: 12 } }}
              >
                <Form.Item
                  label='Bank Name'
                  name='Bank_Name__c'
                  rules={[{ required: true, message: 'Please input your bank!' }]}
                  labelCol={{ span: 24, md: { span: 5 } }}
                  initialValue={profile?.Bank_Name__c || ''}
                  required={enableEdit}
                >
                  <Input readOnly={!enableEdit} />
                </Form.Item>
                <Form.Item
                  name='Bank_Account_Name__c'
                  label='Account Name'
                  rules={[{ required: true, message: 'Account name is required', whitespace: true }]}
                  labelCol={{ span: 24, md: { span: 5 } }}
                  initialValue={profile?.Bank_Account_Name__c || ''}
                  required={enableEdit}
                >
                  <Input readOnly={!enableEdit} />
                </Form.Item>
                <Form.Item
                  name='Bank_Account_Number__c'
                  label='Account Number'
                  rules={[{ required: true, message: 'Account number is required', whitespace: true }]}
                  labelCol={{ span: 24, md: { span: 5 } }}
                  initialValue={profile?.Bank_Account_Number__c || ''}
                  required={enableEdit}
                >
                  <Input readOnly={!enableEdit} />
                </Form.Item>
                <Form.Item
                  className='text-center'
                  wrapperCol={{ span: 24, md: { span: 17 } }}
                >
                  {enableEdit ?  (
                    <div className='inline'>
                      <Button
                        htmlType='submit'
                        className='sos-bg-primary text-white mr-5 sos-border-primary'
                        disabled={updateBankDetailsState.isLoading}
                      >
                        Submit
                      </Button>
                      <Button
                        htmlType='button'
                        className='sos-text-primary sos-border-primary'
                        onClick={() => {
                          form.resetFields();
                          setEnableEdit(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  ) : (
                    <Button
                      htmlType='button'
                      className='sos-bg-primary text-white sos-border-primary'
                      onClick={() => setEnableEdit(true)}
                    >
                      Edit
                    </Button>
                  )}
                </Form.Item>
              </Form>
            </div>
          )}
        </Card>
      </div>
    </div>
  )
}

export default ReferrerMetrics;
