export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGIN_CLEANUP = 'LOGIN_CLEANUP';

export const APPLY_FOR_FUNDING_START = 'APPLY_FOR_FUNDING_START';
export const APPLY_FOR_FUNDING_SUCCESS = 'APPLY_FOR_FUNDING_SUCCESS';
export const APPLY_FOR_FUNDING_FAIL = 'APPLY_FOR_FUNDING_FAIL';
export const APPLY_FOR_FUNDING_CLEANUP = 'APPLY_FOR_FUNDING_CLEANUP';

export const MY_DEALS_START = 'MY_DEALS_START';
export const MY_DEALS_SUCCESS = 'MY_DEALS_SUCCESS';
export const MY_DEALS_FAIL = 'MY_DEALS_FAIL';
export const MY_DEALS_CLEANUP = 'MY_DEALS_CLEANUP';

export const MY_ADVANCES_START = 'MY_ADVANCES_START';
export const MY_ADVANCES_SUCCESS = 'MY_DEALS_SUCCESS';
export const MY_ADVANCES_FAIL = 'MY_ADVANCES_FAIL';
export const MY_ADVANCES_CLEANUP = 'MY_ADVANCES_CLEANUP';

export const UPLOAD_BANK_STATEMENT_START = 'UPLOAD_BANK_STATEMENT_START';
export const UPLOAD_BANK_STATEMENT_SUCCESS = 'UPLOAD_BANK_STATEMENT_SUCCESS';
export const UPLOAD_BANK_STATEMENT_FAIL = 'UPLOAD_BANK_STATEMENT_FAIL';
export const UPLOAD_BANK_STATEMENT_CLEANUP = 'UPLOAD_BANK_STATEMENT_CLEANUP';

export const CONNECT_TO_PLAID_START = 'CONNECT_TO_PLAID_START';
export const CONNECT_TO_PLAID_SUCCESS = 'CONNECT_TO_PLAID_SUCCESS';
export const CONNECT_TO_PLAID_FAIL = 'CONNECT_TO_PLAID_FAIL';
export const CONNECT_TO_PLAID_CLEANUP = 'CONNECT_TO_PLAID_CLEANUP';

export const CHECK_EMAIL_EXIST_START = 'CHECK_EMAIL_EXIST_START';
export const CHECK_EMAIL_EXIST_SUCCESS = 'CHECK_EMAIL_EXIST_SUCCESS';
export const CHECK_EMAIL_EXIST_FAIL = 'CHECK_EMAIL_EXIST_FAIL';
export const CHECK_EMAIL_EXIST_CLEANUP = 'CHECK_EMAIL_EXIST_CLEANUP';

export const GET_ADVANCE_DETAILS_START = 'GET_ADVANCE_DETAILS_START';
export const GET_ADVANCE_DETAILS_SUCCESS = 'GET_ADVANCE_DETAILS_SUCCESS';
export const GET_ADVANCE_DETAILS_FAIL = 'GET_ADVANCE_DETAILS_FAIL';
export const GET_ADVANCE_DETAILS_CLEANUP = 'GET_ADVANCE_DETAILS_CLEANUP';

export const DEALS_DETAILS_START = 'DEALS_DETAILS_START';
export const DEALS_DETAILS_SUCCESS = 'DEALS_DETAILS_SUCCESS';
export const DEALS_DETAILS_FAIL = 'DEALS_DETAILS_FAIL';
export const DEALS_DETAILS_CLEANUP = 'DEALS_DETAILS_CLEANUP';

export const GET_DEALS_OFFERS_START = 'GET_DEALS_OFFERS_START';
export const GET_DEALS_OFFERS_SUCCESS = 'GET_DEALS_OFFERS_SUCCESS';
export const GET_DEALS_OFFERS_FAIL = 'GET_DEALS_OFFERS_FAIL';
export const GET_DEALS_OFFERS_CLEANUP = 'GET_DEALS_OFFERS_CLEANUP';

export const GET_SINGLE_OFFER_START = 'GET_SINGLE_OFFER_START';
export const GET_SINGLE_OFFER_SUCCESS  = 'GET_SINGLE_OFFER_SUCCESS';
export const GET_SINGLE_OFFER_FAIL  = 'GET_SINGLE_OFFER_FAIL';
export const GET_SINGLE_OFFER_CLEANUP  = 'GET_SINGLE_OFFER_CLEANUP';

export const GET_REPAYMENTS_START = 'GET_REPAYMENTS_START';
export const GET_REPAYMENTS_SUCCESS = 'GET_REPAYMENTS_SUCCESS';
export const GET_REPAYMENTS_FAIL = 'GET_REPAYMENTS_FAIL';
export const GET_REPAYMENTS_CLEANUP = 'GET_REPAYMENTS_CLEANUP';

export const GET_SINGLE_REPAYMENT_START = 'GET_SINGLE_REPAYMENT_START';
export const GET_SINGLE_REPAYMENT_SUCCESS = 'GET_SINGLE_REPAYMENT_SUCCESS';
export const GET_SINGLE_REPAYMENT_FAIL = 'GET_SINGLE_REPAYMENT_FAIL';
export const GET_SINGLE_REPAYMENT_CLEANUP = 'GET_SINGLE_REPAYMENT_CLEANUP';

export const GET_PAYMENTS_START = 'GET_PAYMENTS_START';
export const GET_PAYMENTS_SUCCESS = 'GET_PAYMENTS_SUCCESS';
export const GET_PAYMENTS_FAIL = 'GET_PAYMENTS_FAIL';
export const GET_PAYMENTS_CLEANUP = 'GET_PAYMENTS_CLEANUP';

export const GET_SINGLE_PAYMENT_START = 'GET_SINGLE_PAYMENT_START';
export const GET_SINGLE_PAYMENT_SUCCESS = 'GET_SINGLE_PAYMENT_SUCCESS';
export const GET_SINGLE_PAYMENT_FAIL = 'GET_SINGLE_PAYMENT_FAIL';
export const GET_SINGLE_PAYMENT_CLEANUP = 'GET_SINGLE_PAYMENT_CLEANUP';

export const GET_ALL_OFFERS_START = 'GET_ALL_OFFERS_START';
export const GET_ALL_OFFERS_SUCCESS  = 'GET_ALL_OFFERS_SUCCESS';
export const GET_ALL_OFFERS_FAIL  = 'GET_ALL_OFFERS_FAIL';
export const GET_ALL_OFFERS_CLEANUP  = 'GET_ALL_OFFERS_CLEANUP';

export const GET_APPLICANT_MATRIC_START = 'GET_APPLICANT_MATRIC_START';
export const GET_APPLICANT_MATRIC_SUCCESS  = 'GET_APPLICANT_MATRIC_SUCCESS';
export const GET_APPLICANT_MATRIC_FAIL  = 'GET_APPLICANT_MATRIC_FAIL';
export const GET_APPLICANT_MATRIC_CLEANUP  = 'GET_APPLICANT_MATRIC_CLEANUP';

export const GET_BROKER_METRICS_BY_ISO_START = 'GET_BROKER_METRICS_BY_ISO_START';
export const GET_BROKER_METRICS_BY_ISO_SUCCESS  = 'GET_BROKER_METRICS_BY_ISO_SUCCESS';
export const GET_BROKER_METRICS_BY_ISO_FAIL  = 'GET_BROKER_METRICS_BY_ISO_FAIL';
export const GET_BROKER_METRICS_BY_ISO_CLEANUP  = 'GET_BROKER_METRICS_BY_ISO_CLEANUP';

export const GET_MY_ISO_DEALS_START = 'GET_MY_ISO_DEALS_START';
export const GET_MY_ISO_DEALS_SUCCESS  = 'GET_MY_ISO_DEALS_SUCCESS';
export const GET_MY_ISO_DEALS_FAIL  = 'GET_MY_ISO_DEALS_FAIL';
export const GET_MY_ISO_DEALS_CLEANUP  = 'GET_MY_ISO_DEALS_CLEANUP';

export const GET_MY_ISO_OPPORTURNITY_START = 'GET_MY_ISO_OPPORTURNITY_START';
export const GET_MY_ISO_OPPORTURNITY_SUCCESS  = 'GET_MY_ISO_OPPORTURNITY_SUCCESS';
export const GET_MY_ISO_OPPORTURNITY_FAIL  = 'GET_MY_ISO_OPPORTURNITY_FAIL';
export const GET_MY_ISO_OPPORTURNITY_CLEANUP  = 'GET_MY_ISO_OPPORTURNITY_CLEANUP';

export const GET_MY_ISO_STIPULATION_START = 'GET_MY_ISO_STIPULATION_START';
export const GET_MY_ISO_STIPULATION_SUCCESS  = 'GET_MY_ISO_STIPULATION_SUCCESS';
export const GET_MY_ISO_STIPULATION_FAIL  = 'GET_MY_ISO_STIPULATION_FAIL';
export const GET_MY_ISO_STIPULATION_CLEANUP  = 'GET_MY_ISO_STIPULATION_CLEANUP';

export const GET_MY_ISO_OFFERS_START = 'GET_MY_ISO_OFFERS_START';
export const GET_MY_ISO_OFFERS_SUCCESS  = 'GET_MY_ISO_OFFERS_SUCCESS';
export const GET_MY_ISO_OFFERS_FAIL  = 'GET_MY_ISO_OFFERS_FAIL';
export const GET_MY_ISO_OFFERS_CLEANUP  = 'GET_MY_ISO_OFFERS_CLEANUP';

export const GET_MY_ISO_REPAYMENTS_START = 'GET_MY_ISO_REPAYMENTS_START';
export const GET_MY_ISO_REPAYMENTS_SUCCESS  = 'GET_MY_ISO_REPAYMENTS_SUCCESS';
export const GET_MY_ISO_REPAYMENTS_FAIL  = 'GET_MY_ISO_REPAYMENTS_FAIL';
export const GET_MY_ISO_REPAYMENTS_CLEANUP  = 'GET_MY_ISO_REPAYMENTS_CLEANUP';

export const GET_MY_ISO_STAGE_HISTORY_START = 'GET_MY_ISO_STAGE_HISTORY_START';
export const GET_MY_ISO_STAGE_HISTORY_SUCCESS  = 'GET_MY_ISO_STAGE_HISTORY_SUCCESS';
export const GET_MY_ISO_STAGE_HISTORY_FAIL  = 'GET_MY_ISO_STAGE_HISTORY_FAIL';
export const GET_MY_ISO_STAGE_HISTORY_CLEANUP  = 'GET_MY_ISO_STAGE_HISTORY_CLEANUP';

export const GET_SINGLE_OFFER_INFO_START = 'GET_SINGLE_OFFER_INFO_START';
export const GET_SINGLE_OFFER_INFO_SUCCESS  = 'GET_SINGLE_OFFER_INFO_SUCCESS';
export const GET_SINGLE_OFFER_INFO_FAIL  = 'GET_SINGLE_OFFER_INFO_FAIL';
export const GET_SINGLE_OFFER_INFO_CLEANUP  = 'GET_SINGLE_OFFER_INFO_CLEANUP';

export const GET_SINGLE_OFFER_SUMMARY_START = 'GET_SINGLE_OFFER_SUMMARY_START';
export const GET_SINGLE_OFFER_SUMMARY_SUCCESS  = 'GET_SINGLE_OFFER_SUMMARY_SUCCESS';
export const GET_SINGLE_OFFER_SUMMARY_FAIL  = 'GET_SINGLE_OFFER_SUMMARY_FAIL';
export const GET_SINGLE_OFFER_SUMMARY_CLEANUP  = 'GET_SINGLE_OFFER_SUMMARY_CLEANUP';

export const GET_SINGLE_STIPULATION_ATTACHMENT_START = 'GET_SINGLE_STIPULATION_ATTACHMENT_START';
export const GET_SINGLE_STIPULATION_ATTACHMENT_SUCCESS  = 'GET_SINGLE_STIPULATION_ATTACHMENT_SUCCESS';
export const GET_SINGLE_STIPULATION_ATTACHMENT_FAIL  = 'GET_SINGLE_STIPULATION_ATTACHMENT_FAIL';
export const GET_SINGLE_STIPULATION_ATTACHMENT_CLEANUP  = 'GET_SINGLE_STIPULATION_ATTACHMENT_CLEANUP';

export const ADD_STIPULATION_ATTACHMENT_START = 'ADD_STIPULATION_ATTACHMENT_START';
export const ADD_STIPULATION_ATTACHMENT_SUCCESS  = 'ADD_STIPULATION_ATTACHMENT_SUCCESS';
export const ADD_STIPULATION_ATTACHMENT_FAIL  = 'ADD_STIPULATION_ATTACHMENT_FAIL';
export const ADD_STIPULATION_ATTACHMENT_CLEANUP  = 'ADD_STIPULATION_ATTACHMENT_CLEANUP';

export const GET_BROKER_METRICS_START = 'GET_BROKER_METRICS_START';
export const GET_BROKER_METRICS_SUCCESS  = 'GET_BROKER_METRICS_SUCCESS';
export const GET_BROKER_METRICS_FAIL  = 'GET_BROKER_METRICS_FAIL';
export const GET_BROKER_METRICS_CLEANUP  = 'GET_BROKER_METRICS_CLEANUP';

export const GET_ISO_SINGLE_REPAYMENT_START = 'GET_ISO_SINGLE_REPAYMENT_START';
export const GET_ISO_SINGLE_REPAYMENT_SUCCESS  = 'GET_ISO_SINGLE_REPAYMENT_SUCCESS';
export const GET_ISO_SINGLE_REPAYMENT_FAIL  = 'GET_ISO_SINGLE_REPAYMENT_FAIL';
export const GET_ISO_SINGLE_REPAYMENT_CLEANUP  = 'GET_ISO_SINGLE_REPAYMENT_CLEANUP';

export const GET_PROFILE_START = 'GET_PROFILE_START';
export const GET_PROFILE_SUCCESS  = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_FAIL  = 'GET_PROFILE_FAIL';
export const GET_PROFILE_CLEANUP  = 'GET_PROFILE_CLEANUP';

export const GET_LOOKUP_LIST_START = 'GET_LOOKUP_LIST_START';
export const GET_LOOKUP_LIST_SUCCESS  = 'GET_LOOKUP_LIST_SUCCESS';
export const GET_LOOKUP_LIST_FAIL  = 'GET_LOOKUP_LIST_FAIL';
export const GET_LOOKUP_LIST_CLEANUP  = 'GET_LOOKUP_LIST_CLEANUP';

export const CREATE_BROKER_FUNDING_START = 'CREATE_BROKER_FUNDING_START';
export const CREATE_BROKER_FUNDING_SUCCESS  = 'CREATE_BROKER_FUNDING_SUCCESS';
export const CREATE_BROKER_FUNDING_FAIL  = 'CREATE_BROKER_FUNDING_FAIL';
export const CREATE_BROKER_FUNDING_CLEANUP  = 'CREATE_BROKER_FUNDING_CLEANUP';

export const GET_SYNDICATOR_START = 'GET_SYNDICATOR_START';
export const GET_SYNDICATOR_SUCCESS  = 'GET_SYNDICATOR_SUCCESS';
export const GET_SYNDICATOR_FAIL  = 'GET_SYNDICATOR_FAIL';
export const GET_SYNDICATOR_CLEANUP  = 'GET_SYNDICATOR_CLEANUP';

export const GET_SYNDICATOR_DEALS_START = 'GET_SYNDICATOR_DEALS_START';
export const GET_SYNDICATOR_DEALS_SUCCESS  = 'GET_SYNDICATOR_DEALS_SUCCESS';
export const GET_SYNDICATOR_DEALS_FAIL  = 'GET_SYNDICATOR_DEALS_FAIL';
export const GET_SYNDICATOR_DEALS_CLEANUP  = 'GET_SYNDICATOR_DEALS_CLEANUP';

export const GET_FLEX_OFFER_START = 'GET_FLEX_OFFER_START';
export const GET_FLEX_OFFER_SUCCESS  = 'GET_FLEX_OFFER_SUCCESS';
export const GET_FLEX_OFFER_FAIL  = 'GET_FLEX_OFFER_FAIL';
export const GET_FLEX_OFFER_CLEANUP  = 'GET_FLEX_OFFER_CLEANUP';

export const UPDATE_OFFER_START = 'UPDATE_OFFER_START';
export const UPDATE_OFFER_SUCCESS  = 'UPDATE_OFFER_SUCCESS';
export const UPDATE_OFFER_FAIL  = 'UPDATE_OFFER_FAIL';
export const UPDATE_OFFER_CLEANUP  = 'UPDATE_OFFER_CLEANUP';

export const GET_ACCOUNT_START = 'GET_ACCOUNT_START';
export const GET_ACCOUNT_SUCCESS  = 'GET_ACCOUNT_SUCCESS';
export const GET_ACCOUNT_FAIL  = 'GET_ACCOUNT_FAIL';
export const GET_ACCOUNT_CLEANUP  = 'GET_ACCOUNT_CLEANUP';

export const GET_SINGLE_ACCOUNT_START = 'GET_SINGLE_ACCOUNT_START';
export const GET_SINGLE_ACCOUNT_SUCCESS  = 'GET_SINGLE_ACCOUNT_SUCCESS';
export const GET_SINGLE_ACCOUNT_FAIL  = 'GET_SINGLE_ACCOUNT_FAIL';
export const GET_SINGLE_ACCOUNT_CLEANUP  = 'GET_SINGLE_ACCOUNT_CLEANUP';

export const CREATE_WEB_TO_LEAD_START = 'CREATE_WEB_TO_LEAD_START';
export const CREATE_WEB_TO_LEAD_SUCCESS  = 'CREATE_WEB_TO_LEAD_SUCCESS';
export const CREATE_WEB_TO_LEAD_FAIL  = 'CREATE_WEB_TO_LEAD_FAIL';
export const CREATE_WEB_TO_LEAD_CLEANUP  = 'CREATE_WEB_TO_LEAD_CLEANUP';

export const SEARCH_INSTITUTION_START = 'SEARCH_INSTITUTION_START';
export const SEARCH_INSTITUTION_SUCCESS  = 'SEARCH_INSTITUTION_SUCCESS';
export const SEARCH_INSTITUTION_FAIL  = 'SEARCH_INSTITUTION_FAIL';
export const SEARCH_INSTITUTION_CLEANUP  = 'SEARCH_INSTITUTION_CLEANUP';

export const SAVE_PSA_INFORMATION_START = 'SAVE_PSA_INFORMATION_START';
export const SAVE_PSA_INFORMATION_SUCCESS  = 'SAVE_PSA_INFORMATION_SUCCESS';
export const SAVE_PSA_INFORMATION_FAIL  = 'SAVE_PSA_INFORMATION_FAIL';
export const SAVE_PSA_INFORMATION_CLEANUP  = 'SAVE_PSA_INFORMATION_CLEANUP';

export const UPDATE_CONTACT_START = 'UPDATE_CONTACT_START';
export const UPDATE_CONTACT_SUCCESS  = 'UPDATE_CONTACT_SUCCESS';
export const UPDATE_CONTACT_FAIL  = 'UPDATE_CONTACT_FAIL';
export const UPDATE_CONTACT_CLEANUP  = 'UPDATE_CONTACT_CLEANUP';

export const GENERATE_PAYMENT_HISTORY_START = 'GENERATE_PAYMENT_HISTORY_START';
export const GENERATE_PAYMENT_HISTORY_SUCCESS  = 'GENERATE_PAYMENT_HISTORY_SUCCESS';
export const GENERATE_PAYMENT_HISTORY_FAIL  = 'GENERATE_PAYMENT_HISTORY_FAIL';
export const GENERATE_PAYMENT_HISTORY_CLEANUP  = 'GENERATE_PAYMENT_HISTORY_CLEANUP';

export const GENERATE_PAYMENT_HISTORY_SYNDICATOR_START = 'GENERATE_PAYMENT_HISTORY_SYNDICATOR_START';
export const GENERATE_PAYMENT_HISTORY_SYNDICATOR_SUCCESS  = 'GENERATE_PAYMENT_HISTORY_SYNDICATOR_SUCCESS';
export const GENERATE_PAYMENT_HISTORY_SYNDICATOR_FAIL  = 'GENERATE_PAYMENT_HISTORY_SYNDICATOR_FAIL';
export const GENERATE_PAYMENT_HISTORY_SYNDICATOR_CLEANUP  = 'GENERATE_PAYMENT_HISTORY_SYNDICATOR_CLEANUP';

export const GENERATE_REFERRAL_CODE_START = 'GENERATE_REFERRAL_CODE_START';
export const GENERATE_REFERRAL_CODE_SUCCESS  = 'GENERATE_REFERRAL_CODE_SUCCESS';
export const GENERATE_REFERRAL_CODE_FAIL  = 'GENERATE_REFERRAL_CODE_FAIL';
export const GENERATE_REFERRAL_CODE_CLEANUP  = 'GENERATE_REFERRAL_CODE_CLEANUP';

export const UPDATE_BANK_DETAILS_START = 'UPDATE_BANK_DETAILS_START';
export const UPDATE_BANK_DETAILS_SUCCESS  = 'UPDATE_BANK_DETAILS_SUCCESS';
export const UPDATE_BANK_DETAILS_FAIL  = 'UPDATE_BANK_DETAILS_FAIL';
export const UPDATE_BANK_DETAILS_CLEANUP  = 'UPDATE_BANK_DETAILS_CLEANUP';
