import * as types from '../../action-types';
import {myDeals as initialState } from "../../initialState";

const myDeals = (state=initialState, action) => {
  switch (action.type) {
    case types.MY_DEALS_START:
      return {
        ...state,
        isLoading: true
      };
    case types.MY_DEALS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccessful: true,
        data: action.payload
      };
    case types.MY_DEALS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case types.MY_DEALS_CLEANUP:
      return {
        ...state,
        isLoading: false,
        isSuccessful: false,
        error: null
      };
    default:
      return state;
  }
}

export default myDeals;
