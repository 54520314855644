import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';

export const getBrokerMetricsByIsoStart = () => ({
  type: types.GET_BROKER_METRICS_BY_ISO_START
});

export const getBrokerMetricsByIsoSuccess = payload => ({
  type: types.GET_BROKER_METRICS_BY_ISO_SUCCESS,
  payload
});

export const getBrokerMetricsByIsoFail = payload => ({
  type: types.GET_BROKER_METRICS_BY_ISO_FAIL,
  payload
});

export const getBrokerMetricsByIsoCleanup = () => ({
  type: types.GET_BROKER_METRICS_BY_ISO_CLEANUP
});

export const getBrokerMetricsByIso = portal => async dispatch => {
  try {
    dispatch(getBrokerMetricsByIsoStart());
    const requestObj = {
      path: `broker/${portal}/byiso/metrics`,
      method: "GET"
    }
    const  data  = await AxiosCall(requestObj);
    dispatch(getBrokerMetricsByIsoSuccess(data[0]));
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(getBrokerMetricsByIsoFail(error));
  }
}
