import React from "react";
import { Tabs } from "antd";
import DealsFromISOs from "./DealsFromISOs";
import IsoNonUserDealsTable from "./ISONonUserDealsTable";
const { TabPane } = Tabs;

const IsoNonUserDeals = () => {
  return (
    <>
      <div className="pt-5  mt-5  container advance-details-page">
        <div className="pt-5 table-container">
          <Tabs defaultActiveKey="1">
            <TabPane tab="Non User Deal" key="1">
              <IsoNonUserDealsTable />
            </TabPane>
            <TabPane tab="Deals From ISOs" key="2">
              <DealsFromISOs />
            </TabPane>
          </Tabs>
        </div>
      </div>
    </>
  );
};
export default IsoNonUserDeals;
