import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';

export const savePSAInformationStart = () => ({
  type: types.SAVE_PSA_INFORMATION_START
});

export const savePSAInformationSuccess = payload => ({
  type: types.SAVE_PSA_INFORMATION_SUCCESS,
  payload
});

export const savePSAInformationFail = payload => ({
  type: types.SAVE_PSA_INFORMATION_FAIL,
  payload
});

export const savePSAInformationCleanup = () => ({
  type: types.SAVE_PSA_INFORMATION_CLEANUP
});

export const savePSAInformation = payload => async dispatch => {
  try {
    dispatch(savePSAInformationStart());
    const requestObj = {
      path: "psa/add",
      method: "POST",
      data: payload
    }
    const  data  = await AxiosCall(requestObj);
    dispatch(savePSAInformationSuccess(data));
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(savePSAInformationFail(error));
  }
}
