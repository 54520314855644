import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';
export const getSingleOfferSummaryStart = () => ({
  type: types.GET_SINGLE_OFFER_SUMMARY_START
});

export const getSingleOfferSummarySuccess = payload => ({
  type: types.GET_SINGLE_OFFER_SUMMARY_SUCCESS,
  payload
});

export const getSingleOfferSummaryFail = payload => ({
  type: types.GET_SINGLE_OFFER_SUMMARY_FAIL,
  payload
});

export const getSingleOfferSummaryCleanup = () => ({
  type: types.GET_SINGLE_OFFER_SUMMARY_CLEANUP
});

export const getSingleOfferSummary = (portal,type,id)=> async dispatch => {
  try {
    dispatch(getSingleOfferSummaryStart());
    const requestObj = {
      path: `offersummary/${portal}/iso/${type}/${id}`,
      method: "GET",   
    }
    const  data  = await AxiosCall(requestObj);
    dispatch(getSingleOfferSummarySuccess(data));
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(getSingleOfferSummaryFail(error));
  }
}