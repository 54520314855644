import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';

export const generateReferralCodeStart = () => ({
  type: types.GENERATE_REFERRAL_CODE_START
});

export const generateReferralCodeSuccess = payload => ({
  type: types.GENERATE_REFERRAL_CODE_SUCCESS,
  payload
});

export const generateReferralCodeFail = payload => ({
  type: types.GENERATE_REFERRAL_CODE_FAIL,
  payload
});

export const generateReferralCodeCleanup = () => ({
  type: types.GENERATE_REFERRAL_CODE_CLEANUP
});

export const generateReferralCode = (payload) => async dispatch => {
  try {
    dispatch(generateReferralCodeStart());
    const path = 'contact/refer';
    const requestObj = {
      path,
      method: 'POST',
      data: payload
    }
    const  data  = await AxiosCall(requestObj);
    dispatch(generateReferralCodeSuccess(data));
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(generateReferralCodeFail(error));
  }
}
