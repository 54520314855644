import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import firebase from "../firebase";
import { useDispatch } from "react-redux";
import { getProfileSuccess } from '../../store/actions/get-profile';

export default function Logout() {
  const dispatch = useDispatch();

  useEffect(() => {
    localStorage.clear();
    dispatch(getProfileSuccess(null));
    firebase.logout();
  }, []);

  return (
    <div>
      <Redirect to="/login" />
    </div>
  );
}
