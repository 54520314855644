import './index.css';
import React from 'react';
import { Tabs } from 'antd';
import { useParams } from 'react-router-dom';

//components
import RepaymentDetailsSection from './RepaymentDetailsSection';
import PaymentsSection from './PaymentsSection';

const RepaymentDetails = () => {
  const { TabPane } = Tabs;
  const { id } = useParams();

  return (
    <div className="pt-5  container repayment-details-page">
      <div className="pt-5 table-container">
        <Tabs defaultActiveKey="1">
          <TabPane tab="Repayment Details" key="1">
            <RepaymentDetailsSection id={id} />
          </TabPane>
          <TabPane tab="Payments" key="2">
            <PaymentsSection id={id} />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
}

export default RepaymentDetails;
