import './index.css';
import React, { useRef, forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Row,
  Col,
  Label,
  FormGroup,
  Input,
  Form,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from 'reactstrap';
import Select from "react-select";
import moment from 'moment';
import { DatePicker, Divider } from 'antd';
import { useSelector } from 'react-redux';

//utils
import { getValueObject } from '../../../../../utils/helpers';
import { yesNoOptions } from '../../../../../utils/dropdown-values'

const Step3 = ({ values, initialVal }, ref) => {
  const formikRef = useRef();
  const [salesReps, setSalesReps] = useState([]);
  const lookupData = useSelector(s => s.getLookupList.data);
  const phoneRegex = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

  const validationSchema = Yup.object().shape({
    Bank_Name__c: Yup.string()
      .trim()
      .required('Bank Name is required')
      .min(2, 'Bank Name should have minimum of two characters'),
    Bank_Contact__c: Yup.string()
      .trim()
      .required('Bank Contact is required')
      .min(2, 'Bank Contact should have minimum of two characters'),
    Bank_Phone__c: Yup.string()
      .trim()
      .required('Bank Phone number is required')
      .matches(phoneRegex,'Bank Phone number is not valid'),
    Business_Name__c: Yup.string()
      .trim()
      .required('Business Name is required')
      .min(2, 'Business Name should have minimum of two characters'),
    Business_Contact_Account__c: Yup.string()
      .trim()
      .required('Business Contact/Account is required')
      .min(2, 'Business Contact/Account should have minimum of two characters'),
    Business_Phone__c: Yup.string()
      .trim()
      .required('Business Phone number is required')
      .matches(phoneRegex,'Business Phone number is not valid'),
    Landlord_Mortgagee__c: Yup.string()
      .trim()
      .required('Landlord/Mortgagee is required')
      .min(2, 'Landlord/Mortgagee  should have minimum of two characters'),
    Landlord_Contact_Name__c: Yup.string()
      .trim()
      .required('Landlord Contact Name is required')
      .min(2, 'Landlord Contact Name should have minimum of two characters'),
    Landlord_Phone__c: Yup.string()
      .trim()
      .required('Landlord Phone number is required')
      .matches(phoneRegex,'Landlord Phone number is not valid'),
    Lease_start_date__c: Yup.date()
      .required('Lease start date is required'),
    Time_Left_On_Lease__c: Yup.string()
      .trim()
      .required('Time Left On Lease is required')
      .min(2, 'Time Left On Lease should have minimum of two characters'),
    Monthly_Payment__c: Yup.number()
      .required('Monthly Payment is required')
      .min(1, 'Monthly Payment should be greater than 0'),
    Square_Footage__c: Yup.number()
      .required('Square Footage is required')
      .min(2, 'Square Footage should not be less than 0'),
    Ever_used_a_Business_Cash_Advance__c: Yup.boolean()
      .required('Select an option'),
    Cash_Advance_Company__c: Yup.string()
      .when('Ever_used_a_Business_Cash_Advance__c', {
        is: (Ever_used_a_Business_Cash_Advance__c) => Ever_used_a_Business_Cash_Advance__c,
        then: Yup.string()
          .trim()
        .required('Advance company is required')
        .min(2, 'Advance company should have minimum of two characters'),
      }),
    Balance__c: Yup.number()
      .when('Ever_used_a_Business_Cash_Advance__c', {
        is: (Ever_used_a_Business_Cash_Advance__c) => Ever_used_a_Business_Cash_Advance__c,
        then: Yup.number()
          .required('Advance company balance is required')
          .min(1, 'Advance company balance should be greater than 0'),
      }),
    Sales_Rep__c: Yup.string()
      .trim()
      .required('ISO Sales Rep is required')
      .min(2, 'ISO Sales Rep should have minimum of two characters'),
  });

  useImperativeHandle(ref, () => ({
    isValid: async () => {
      const errors = await formikRef.current.validateForm();
      if(errors.constructor === Object && Object.keys(errors).length === 0){
        return true;
      }else{
        return false;
      }
    },
    getValues: () => {
      return formikRef.current.values
    },
    handleSubmit: () => {
      formikRef.current.handleSubmit();
    }
  }));

  useEffect(() => {
    if(lookupData && lookupData.isosalesrep && lookupData.isosalesrep.length){
      const formatedData = lookupData.isosalesrep.map(rep => ({
        label: rep.Name,
        value: rep.Id
      }));
      setSalesReps(formatedData);
    }
  },[])

  return (
    <div className='mt-5 add-deal-step3'>
   <Formik
        initialValues={{
          Bank_Name__c: initialVal.Bank_Name__c ? initialVal.Bank_Name__c : "",
          Bank_Contact__c: initialVal.Bank_Contact__c
            ? initialVal.Bank_Contact__c
            : "",
          Bank_Phone__c: initialVal.Bank_Phone__c
            ? initialVal.Bank_Phone__c
            : "",
          Business_Name__c: initialVal.Business_Name__c
            ? initialVal.Business_Name__c
            : "",
          Business_Contact_Account__c: initialVal.Business_Contact_Account__c
            ? initialVal.Business_Contact_Account__c
            : "",
          Business_Phone__c: initialVal.Business_Phone__c
            ? initialVal.Business_Phone__c
            : "",
          Landlord_Mortgagee__c: initialVal.Landlord_Mortgagee__c
            ? initialVal.Landlord_Mortgagee__c
            : "",
          Landlord_Contact_Name__c: initialVal.Landlord_Contact_Name__c
            ? initialVal.Landlord_Contact_Name__c
            : "",
          Landlord_Phone__c: initialVal.Landlord_Phone__c
            ? initialVal.Landlord_Phone__c
            : "",
          Lease_start_date__c: initialVal.Lease_start_date__c
            ? initialVal.Lease_start_date__c
            : "",
          Time_Left_On_Lease__c: initialVal.Time_Left_On_Lease__c
            ? indexedDB.Time_Left_On_Lease__c
            : "",
          Monthly_Payment__c: "",
          Square_Footage__c: "",
          Ever_used_a_Business_Cash_Advance__c: "",
          Cash_Advance_Company__c: "",
          Balance__c: "",
          Sales_Rep__c: "",
          ...values,
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
        }}
        enableReinitialize={true}
        validationSchema={validationSchema}
        innerRef={formikRef}
      >
        {({ handleChange, values, errors, touched, handleBlur, setFieldValue, setFieldTouched }) => (
          <Form>
            <Divider  orientation='left' className='sos-font-weight-600'>Bank/Trade Information</Divider>
            <Row>
              <Col md="6" sm="12" className='mb-4'>
                <FormGroup className={`${errors.Bank_Name__c && touched.Bank_Name__c ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>Bank Name <span className='text-danger'>*</span></Label>
                  <Input
                    placeholder=""
                    type="text" 
                    name='Bank_Name__c'
                    onChange={handleChange('Bank_Name__c')}
                    onBlur={handleBlur('Bank_Name__c')}
                    value={values.Bank_Name__c}
                  />
                </FormGroup>
                <small className='text-danger'>{errors.Bank_Name__c && touched.Bank_Name__c ? errors.Bank_Name__c : null}</small>
              </Col>
              <Col md="6" sm="12" className='mb-4'>
                <FormGroup className={`${errors.Bank_Contact__c && touched.Bank_Contact__c ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>Bank Contact <span className='text-danger'>*</span></Label>
                  <Input
                    placeholder=""
                    type="text" 
                    name='Bank_Contact__c'
                    onChange={handleChange('Bank_Contact__c')}
                    onBlur={handleBlur('Bank_Contact__c')}
                    value={values.Bank_Contact__c}
                  />
                </FormGroup>
                <small className='text-danger'>{errors.Bank_Contact__c && touched.Bank_Contact__c ? errors.Bank_Contact__c : null}</small>
              </Col>
              <Col md="6" sm="12" className='mb-4'>
                <FormGroup className={`${errors.Bank_Phone__c && touched.Bank_Phone__c ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>Bank Phone <span className='text-danger'>*</span></Label>
                  <Input
                    placeholder=""
                    type="text"
                    name='Bank_Phone__c'
                    onChange={handleChange('Bank_Phone__c')}
                    onBlur={handleBlur('Bank_Phone__c')}
                    value={values.Bank_Phone__c}
                  />
                </FormGroup>
                <small className='text-danger'>{errors.Bank_Phone__c && touched.Bank_Phone__c ? errors.Bank_Phone__c : null}</small>
              </Col>
              <Col md="6" sm="12" className='mb-4'>
                <FormGroup className={`${errors.Business_Name__c && touched.Business_Name__c ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>Business Name <span className='text-danger'>*</span></Label>
                  <Input
                    placeholder=""
                    type="text"
                    name='Business_Name__c'
                    onChange={handleChange('Business_Name__c')}
                    onBlur={handleBlur('Business_Name__c')}
                    value={values.Business_Name__c}
                  />
                </FormGroup>
                <small className='text-danger'>{errors.Business_Name__c && touched.Business_Name__c ? errors.Business_Name__c : null}</small>
              </Col>
              <Col md="6" sm="12" className='mb-4'>
                <FormGroup className={`${errors.Business_Contact_Account__c && touched.Business_Contact_Account__c ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>Business Contact/Account <span className='text-danger'>*</span></Label>
                  <Input
                    placeholder=""
                    type="text"
                    name='Business_Contact_Account__c'
                    onChange={handleChange('Business_Contact_Account__c')}
                    onBlur={handleBlur('Business_Contact_Account__c')}
                    value={values.Business_Contact_Account__c}
                  />
                </FormGroup>
                <small className='text-danger'>{errors.Business_Contact_Account__c && touched.Business_Contact_Account__c ? errors.Business_Contact_Account__c : null}</small>
              </Col>
              <Col md="6" sm="12" className='mb-4'>
                <FormGroup className={`${errors.Business_Phone__c && touched.Business_Phone__c ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>Business Phone <span className='text-danger'>*</span></Label>
                  <Input
                    placeholder=""
                    type="text"
                    name='Business_Phone__c'
                    onChange={handleChange('Business_Phone__c')}
                    onBlur={handleBlur('Business_Phone__c')}
                    value={values.Business_Phone__c}
                  />
                </FormGroup>
                <small className='text-danger'>{errors.Business_Phone__c && touched.Business_Phone__c ? errors.Business_Phone__c : null}</small>
              </Col>
            </Row>
            <Divider  orientation='left' className='sos-font-weight-600'>Property Information</Divider>
            <Row>
              <Col md="6" sm="12" className='mb-4'>
                <FormGroup className={`${errors.Landlord_Mortgagee__c && touched.Landlord_Mortgagee__c ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>Landlord/Mortgagee <span className='text-danger'>*</span></Label>
                  <Input
                    placeholder=""
                    type="text"
                    name='Landlord_Mortgagee__c'
                    onChange={handleChange('Landlord_Mortgagee__c')}
                    onBlur={handleBlur('Landlord_Mortgagee__c')}
                    value={values.Landlord_Mortgagee__c}
                  />
                </FormGroup>
                <small className='text-danger'>{errors.Landlord_Mortgagee__c && touched.Landlord_Mortgagee__c ? errors.Landlord_Mortgagee__c : null}</small>
              </Col>
              <Col md="6" sm="12" className='mb-4'>
                <FormGroup className={`${errors.Landlord_Contact_Name__c && touched.Landlord_Contact_Name__c ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>Landlord Contact Name <span className='text-danger'>*</span></Label>
                  <Input
                    placeholder=""
                    type="text"
                    name='Landlord_Contact_Name__c'
                    onChange={handleChange('Landlord_Contact_Name__c')}
                    onBlur={handleBlur('Landlord_Contact_Name__c')}
                    value={values.Landlord_Contact_Name__c}
                  />
                </FormGroup>
                <small className='text-danger'>{errors.Landlord_Contact_Name__c && touched.Landlord_Contact_Name__c ? errors.Landlord_Contact_Name__c : null}</small>
              </Col>
              <Col md="6" sm="12" className='mb-4'>
                <FormGroup className={`${errors.Landlord_Phone__c && touched.Landlord_Phone__c ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>Landlord Phone Number <span className='text-danger'>*</span></Label>
                  <Input
                    placeholder=""
                    type="text"
                    name='Landlord_Phone__c'
                    onChange={handleChange('Landlord_Phone__c')}
                    onBlur={handleBlur('Landlord_Phone__c')}
                    value={values.Landlord_Phone__c}
                  />
                </FormGroup>
                <small className='text-danger'>{errors.Landlord_Phone__c && touched.Landlord_Phone__c ? errors.Landlord_Phone__c : null}</small>
              </Col>
              <Col sm='12' md='6'>
                <FormGroup className={`${errors.Lease_start_date__c && touched.Lease_start_date__c ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>Lease Start Date <span className='text-danger'>*</span></Label>
                    <DatePicker
                      className='col-12'
                      value={values.Lease_start_date__c && moment(values.Lease_start_date__c)}
                      onChange={date => {
                        setFieldValue('Lease_start_date__c', moment(date).format())
                      }}
                      onBlur={() => setFieldTouched('Lease_start_date__c')}
                      allowClear={false}
                      format='MM/DD/YYYY'
                    />
                </FormGroup>
                <small className='text-danger'>{errors.Lease_start_date__c && touched.Lease_start_date__c ? errors.Lease_start_date__c : null}</small>
              </Col>
              <Col md="6" sm="12" className='mb-4'>
                <FormGroup className={`${errors.Time_Left_On_Lease__c && touched.Time_Left_On_Lease__c ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>Time Left On Lease <span className='text-danger'>*</span></Label>
                  <Input
                    placeholder=""
                    type="text"
                    name='Time_Left_On_Lease__c'
                    onChange={handleChange('Time_Left_On_Lease__c')}
                    onBlur={handleBlur('Time_Left_On_Lease__c')}
                    value={values.Time_Left_On_Lease__c}
                  />
                </FormGroup>
                <small className='text-danger'>{errors.Time_Left_On_Lease__c && touched.Time_Left_On_Lease__c ? errors.Time_Left_On_Lease__c : null}</small>
              </Col>
              <Col md="6" sm="12" className='mb-4'>
                <FormGroup className={`${errors.Monthly_Payment__c && touched.Monthly_Payment__c ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>Monthly Payment <span className='text-danger'>*</span></Label>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText className='sos-text-small'>$</InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder=""
                      type="text"
                      name='Monthly_Payment__c'
                      onChange={handleChange('Monthly_Payment__c')}
                      onBlur={handleBlur('Monthly_Payment__c')}
                      value={values.Monthly_Payment__c}
                    />
                  </InputGroup>
                </FormGroup>
                <small className='text-danger'>{errors.Monthly_Payment__c && touched.Monthly_Payment__c ? errors.Monthly_Payment__c : null}</small>
              </Col>
              <Col md="6" sm="12" className='mb-4'>
                <FormGroup className={`${errors.Square_Footage__c && touched.Square_Footage__c ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>Square Footage <span className='text-danger'>*</span></Label>
                  <Input
                    placeholder=""
                    type="text"
                    name='Square_Footage__c'
                    onChange={handleChange('Square_Footage__c')}
                    onBlur={handleBlur('Square_Footage__c')}
                    value={values.Square_Footage__c}
                  />
                </FormGroup>
                <small className='text-danger'>{errors.Square_Footage__c && touched.Square_Footage__c ? errors.Square_Footage__c : null}</small>
              </Col>
            </Row>
            <Divider  orientation='left' className='sos-font-weight-600'>Other Information</Divider>
            <Row>
              <Col md="6" sm="12" className='mb-4'>
                <Label className='text-medium'>Ever Used Business Cash Advance <span className='text-danger'>*</span></Label>
                <Select
                  className="react-select info"
                  classNamePrefix="react-select"
                  placeholder="--None--"
                  name="Ever_used_a_Business_Cash_Advance__c"
                  value={getValueObject(yesNoOptions, values.Ever_used_a_Business_Cash_Advance__c === undefined ? '' : values.Ever_used_a_Business_Cash_Advance__c ? 'Yes' : 'No')}
                  onChange={value => setFieldValue('Ever_used_a_Business_Cash_Advance__c', value.value  === 'Yes' ? true : false)}
                  options={yesNoOptions}
                  onBlur={() => setFieldTouched('Ever_used_a_Business_Cash_Advance__c')}
                />
                <small className='text-danger'>{errors.Ever_used_a_Business_Cash_Advance__c && touched.Ever_used_a_Business_Cash_Advance__c ? errors.Ever_used_a_Business_Cash_Advance__c : null}</small>
              </Col>
              {values.Ever_used_a_Business_Cash_Advance__c ? (
                <Col md="6" sm="12" className='mb-4'>
                  <FormGroup className={`${errors.Cash_Advance_Company__c && touched.Cash_Advance_Company__c ? 'mb-0' : ''}`}>
                    <Label className='text-medium'>Advance Company <span className='text-danger'>*</span></Label>
                    <Input
                      placeholder=""
                      type="text" 
                      name='Cash_Advance_Company__c'
                      onChange={handleChange('Cash_Advance_Company__c')}
                      onBlur={handleBlur('Cash_Advance_Company__c')}
                      value={values.Cash_Advance_Company__c}
                    />
                  </FormGroup>
                  <small className='text-danger'>{errors.Cash_Advance_Company__c && touched.Cash_Advance_Company__c ? errors.Cash_Advance_Company__c : null}</small>
                </Col>
              ) : null}
              {values.Ever_used_a_Business_Cash_Advance__c ? (
                <Col sm='12' md='6'>
                  <FormGroup className={`${errors.Balance__c && touched.Balance__c ? 'mb-0' : ''}`}>
                    <Label className='text-medium'>Advance Company Balance <span className='text-danger'>*</span></Label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText className='sos-text-small'>$</InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder=""
                        type="text" 
                        name='Balance__c'
                        onChange={handleChange('Balance__c')}
                        onBlur={handleBlur('Balance__c')}
                        value={values.Balance__c}
                      />
                    </InputGroup>
                  </FormGroup>
                  <small className='text-danger'>{errors.Balance__c && touched.Balance__c ? errors.Balance__c : null}</small>
                </Col>
              ) : null}
            </Row>
            <Divider  orientation='left' className='sos-font-weight-600'>ISO Information</Divider>
            <Row>
              <Col md="6" sm="12" className='mb-4'>
                <Label className='text-medium'>ISO Sales Rep <span className='text-danger'>*</span></Label>
                <Select
                  className="react-select info"
                  classNamePrefix="react-select"
                  placeholder="--None--"
                  name="Sales_Rep__c"
                  value={getValueObject(salesReps, values.Sales_Rep__c)}
                  onChange={value => setFieldValue('Sales_Rep__c', value.value)}
                  options={salesReps}
                  onBlur={() => setFieldTouched('Sales_Rep__c')}
                />
                <small className='text-danger'>{errors.Sales_Rep__c && touched.Sales_Rep__c ? errors.Sales_Rep__c : null}</small>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default forwardRef(Step3);
