import * as types from '../../action-types';
import { getLookupList as initialState } from "../../initialState";

const getLookupList = (state=initialState, action) => {
  switch (action.type) {
    case types.GET_LOOKUP_LIST_START:
      return {
        ...state,
        isLoading: true
      };
    case types.GET_LOOKUP_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccessful: true,
        data: {
          ...state.data,
          [action.payload.lookupPath]: action.payload.data
        }
      };
    case types.GET_LOOKUP_LIST_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case types.GET_LOOKUP_LIST_CLEANUP:
      return {
        ...state,
        isLoading: false,
        isSuccessful: false,
        error: null
      };
    default:
      return state;
  }
}

export default getLookupList;
