import * as types from '../../action-types';
import {getSingleAccount as initialState } from "../../initialState";

const getSingleAccount = (state=initialState, action) => {
  switch (action.type) {
    case types.GET_SINGLE_ACCOUNT_START:
      return {
        ...state,
        isLoading: true
      };
    case types.GET_SINGLE_ACCOUNT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccessful: true,
        data: action.payload
      };
    case types.GET_SINGLE_ACCOUNT_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case types.GET_SINGLE_ACCOUNT_CLEANUP:
      return {
        ...state,
        isLoading: false,
        isSuccessful: false,
        error: null
      };
    default:
      return state;
  }
}

export default getSingleAccount;
