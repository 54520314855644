import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';

export const updateOfferStart = () => ({
  type: types.UPDATE_OFFER_START
});

export const updateOfferSuccess = payload => ({
  type: types.UPDATE_OFFER_SUCCESS,
  payload
});

export const updateOfferFail = payload => ({
  type: types.UPDATE_OFFER_FAIL,
  payload
});

export const updateOfferCleanup = () => ({
  type: types.UPDATE_OFFER_CLEANUP
});

export const updateOffer = (id, portal, payload) => async dispatch => {
  try {
    dispatch(updateOfferStart());
    const path = `offer/${portal}/${id}`;
    const requestObj = {
      path,
      method: 'PUT',
      data: payload
    }
    const  data  = await AxiosCall(requestObj);
    dispatch(updateOfferSuccess(data));
    
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(updateOfferFail(error));
  }
}
