import React from 'react';
import { Tabs } from 'antd';
import { useParams } from 'react-router-dom';

//components
import DealDetailsSection from './DealDetailsSection';
import DealStipulations from './DealStipulations';
import DealOffers from './DealOffers';
import DealRepayments from './DealRepayments';

const { TabPane } = Tabs;
const DealDetails = () => {
  const { id } = useParams();

  return (
    <div className='pt-5 mt-5  container advance-details-page'>
      <div className='pt-5 table-container'>
        <Tabs defaultActiveKey='1'>
          <TabPane tab='Deal Details' key='1'>
            <DealDetailsSection id={id} />
          </TabPane>
          <TabPane tab='Stipulation' key='2'>
            <DealStipulations id={id} />
          </TabPane>
          <TabPane tab='Deal Offers' key='3'>
            <DealOffers id={id} />
          </TabPane>
          <TabPane tab='Repayments' key='4'>
            <DealRepayments id={id} />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default DealDetails;
