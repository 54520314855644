import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';
export const getSingleOfferInfoStart = () => ({
  type: types.GET_SINGLE_OFFER_INFO_START
});

export const getSingleOfferInfoSuccess = payload => ({
  type: types.GET_SINGLE_OFFER_INFO_SUCCESS,
  payload
});

export const getSingleOfferInfoFail = payload => ({
  type: types.GET_SINGLE_OFFER_INFO_FAIL,
  payload
});

export const getSingleOfferInfoCleanup = () => ({
  type: types.GET_SINGLE_OFFER_INFO_CLEANUP
});

export const getSingleOfferInfo = (portal,type,id)=> async dispatch => {
  try {
    dispatch(getSingleOfferInfoStart());
    const requestObj = {
      path: `offer/${portal}/iso/${type}/${id}`,
      method: "GET",   
    }
    const  data  = await AxiosCall(requestObj);
    dispatch(getSingleOfferInfoSuccess(data));
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(getSingleOfferInfoFail(error));
  }
} 