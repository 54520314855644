import './index.css'
import React from 'react'
import { Link } from 'react-router-dom'
import Img404 from '../../assets/img/404.png'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Navbars/AuthNavbar'
const Error404 = () => {
  return (
    <div className='rules-page container-fluid pl-0 pr-0 pt-5'>
      <div className='page-wraper'>
        <Header />
        <div className='page-content pt-5'>
          <div className='img-responsive m-auto' style={{ maxWidth: '500px' }}>
            <img src={Img404} alt='404' />
          </div>

          <div className='section-full content-inner-3 bg-white'>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-12 col-md-12 col-sm-12 error-page text-center'>
                  <h3>OOPS! Take a breath</h3>

                  <div>That page does not exist but you can get back home.</div>

                  <Link to={'/'} className='site-button btn btn-success'>
                    Back To Home
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <Footer />
    </div>
  )
}

export default Error404
