import "./index.css";
import React, { useState, useRef, useEffect } from "react";
import { Row, Col, CardBody, CardTitle } from "reactstrap";
import { Steps, Button, message, Card} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
// components
import Step1 from "../../FundApplication/Steps/step1";
import Step2 from "../../FundApplication/Steps/step2";
import Step3 from "../../FundApplication/Steps/step3";
import Loader from "../../../components/Loader";

//actions
import {
  applyForFunding,
  applyForFundingCleanup,
} from "../../../store/actions/apply-for-funding";


const FundApplication = () => {
  const { Step } = Steps;
  const [current, setCurrent] = useState(0);
  const step1Ref = useRef();
  const step2Ref = useRef();
  const step3Ref = useRef();
  const [step1Values, setStep1Values] = useState({});
  const [step2Values, setStep2Values] = useState({});
  const [step3Values, setStep3Values] = useState({});
  const dispatch = useDispatch();
  const applyForFundingState = useSelector((s) => s.applyForFunding);
  const history = useHistory();
  

  const steps = [
    {
      title: "Funding",
      icon: "nc-icon nc-touch-id",
      component: <Step1 ref={step1Ref} values={step1Values} />,
    },
    {
      title: "About",
      icon: "nc-icon nc-single-02",
      component: <Step2 ref={step2Ref} values={step2Values} />,
    },
    {
      title: "Business",
      icon: "nc-icon nc-pin-3",
      component: <Step3 ref={step3Ref} values={step3Values} />,
    },
  ];

  const validateAndNext = async (ref, setState) => {
    ref.current.handleSubmit();
    const isValid = await ref.current.isValid();
    if (isValid) {
      setState(ref.current.getValues());
      setCurrent(current + 1);
    }
  };

  const next = () => {
    switch (current) {
      case 0:
        validateAndNext(step1Ref, setStep1Values);
        break;
      case 1:
        validateAndNext(step2Ref, setStep2Values);
        break;
      default:
        break;
    }
  };

  const prev = () => {
    setCurrent(current - 1);
    switch (current) {
      case 1:
        setStep2Values(step2Ref.current.getValues());
        break;
      case 2:
        setStep3Values(step3Ref.current.getValues());
        break;
      default:
        break;
    }
  };

  const handleSubmit = async () => {
    step3Ref.current.handleSubmit();
    const isValid = await step3Ref.current.isValid();

    if (isValid) {
      const thirdStepValues = step3Ref.current.getValues();
      setStep3Values(thirdStepValues);
      const payload = {
        ...step1Values,
        ...step2Values,
        ...thirdStepValues
      };
      dispatch(applyForFunding(payload, true));
    }
  };

  useEffect(() => {
    if (applyForFundingState.isSuccessful) {
      message.success("Application submitted successfully");
      const email = step2Values.Email;
      setStep1Values({});
      setStep2Values({});
      setStep3Values({});
      setCurrent(0);
      dispatch(applyForFundingCleanup());
      history.push(
        `/bank-statement/${applyForFundingState.data.id}?email=${email}`
      );
    } else if (applyForFundingState.error) {
      message.error("Oops! Application submission failed");
      dispatch(applyForFundingCleanup());
    }
  }, [applyForFundingState]);

  return (
    <div className='pt-5 container referrer-application-form-page'>
      <div className="pt-5 table-container">
        <Card>
          <CardTitle className='text-cente p-4'>
            <div className='sos-text-primary mb-0 font-weight-bold d-flex justify-content-between'>
              <div>Apply for Funding on Behalf of Referrals and Get Commissions</div>
            </div>
          </CardTitle>
        </Card>
        <hr />
        <section className="applicationpage-container">
          <div
            className="container expandable"
            style={{ background: "white" }}
          >
            <div className="content">
              <Row id="form-display">
                <Col className="mr-auto ml-auto order-1 order-md-2" md="12" sm="12">
                  <CardBody>
                    <Loader
                      isLoading={applyForFundingState.isLoading}
                      text="Submitting application..."
                    >
                      <div className="step-small">
                        <Steps
                          progressDot={true}
                          current={current}
                          direction="vertical"
                        >
                          {steps.map((item, index) => (
                            <Step key={item.title} title={item.title} />
                          ))}
                        </Steps>
                      </div>
                      <div className="step-large ">
                        <Steps
                          size="small"
                          current={current}
                        >
                          {steps.map((item, index) => (
                            <Step key={item.title} title={item.title} />
                          ))}
                        </Steps>
                      </div>
                      <div className="steps-content">
                        {steps[current].component}
                      </div>
                      <div className="steps-action mt-2 d-flex justify-content-between">
                        {current > 0 && (
                          <Button
                            className="step-btn"
                            style={{ margin: "0 8px 0 0" }}
                            onClick={() => prev()}
                          >
                            Previous
                          </Button>
                        )}
                        {current < steps.length - 1 && (
                          <Button
                            className="step-btn"
                            type="primary"
                            onClick={() => next()}
                          >
                            Next
                          </Button>
                        )}
                        {current === steps.length - 1 && (
                          <Button
                            className="step-btn"
                            type="primary"
                            onClick={handleSubmit}
                            disabled={applyForFundingState.isLoading}
                          >
                            Done
                          </Button>
                        )}
                      </div>
                    </Loader>
                  </CardBody>
                </Col>
              </Row>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default FundApplication;
