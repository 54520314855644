import * as types from '../../action-types';
import { searchInstitution as initialState } from "../../initialState";

const searchInstitution = (state=initialState, action) => {
  switch (action.type) {
    case types.SEARCH_INSTITUTION_START:
      return {
        ...state,
        isLoading: true
      };
    case types.SEARCH_INSTITUTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccessful: true,
        data: action.payload
      };
    case types.SEARCH_INSTITUTION_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case types.SEARCH_INSTITUTION_CLEANUP:
      return {
        ...state,
        isLoading: false,
        isSuccessful: false,
        error: null
      };
    default:
      return state;
  }
}

export default searchInstitution;
