import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';

export const getISOOffersStart = () => ({
  type: types.GET_MY_ISO_OFFERS_START
});

export const getISOOffersSuccess = payload => ({
  type: types.GET_MY_ISO_OFFERS_SUCCESS,
  payload
});

export const getISOOffersFail = payload => ({
  type: types.GET_MY_ISO_OFFERS_FAIL,
  payload
});

export const getISOOffersCleanup = () => ({
  type: types.GET_MY_ISO_OFFERS_CLEANUP
});

export const getISOOffers = (portal,type,id)=> async dispatch => {
  try {
    dispatch(getISOOffersStart());
    const requestObj = {
      path: `offer/opportunity/${portal}/iso/${type}/${id}`,
      method: "GET" 
    }
    const  data  = await AxiosCall(requestObj);
    dispatch(getISOOffersSuccess(data));
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(getISOOffersFail(error));
  }
}
