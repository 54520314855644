import "./index.css";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card } from "antd";
import { CardTitle, Card as RCard, CardBody, Row, Col } from "reactstrap";

//actions
import { getSyndicatorCleanup } from "../../../../store/actions/get-syndicator";

//utils
import { formatCurrency } from "../../../../utils/helpers";

//components
import Loader from "../../../../components/Loader";

const SyndicatorMetrics = () => {
  const dispatch = useDispatch();
  const getSyndicatorState = useSelector((s) => s.getSyndicator);
  const [syndicator, setSyndicator] = useState(null);

  useEffect(() => {
    if (getSyndicatorState.isSuccessful) {
      
      console.log('MRA getSyndicatorState.data:', getSyndicatorState.data);

      let Amount_Syndicated__c = 0;
      let Payables_Paid__c = 0;
      let Management_Fees__c = 0;
      let Syndication_balance__c = 0;
      let Syndications__c = 0;
      let Paybales__c = 0;
      let Remittances__c = 0;
      let Total_Invested__c = 0;
      let Total_Broker_Fees__c = 0;
      let Syndication_Balance_non_Defaulted__c = 0;


      getSyndicatorState.data.map((syndicator) => {
        Amount_Syndicated__c += parseFloat(syndicator.Amount_Syndicated__c);
        Payables_Paid__c += parseFloat(syndicator.Payables_Paid__c);
        Management_Fees__c += parseFloat(syndicator.Management_Fees__c);
        Syndication_balance__c += parseFloat(syndicator.Syndication_balance__c);
        Syndications__c += parseFloat(syndicator.Syndications__c);
        Paybales__c += parseFloat(syndicator.Paybales__c);
        Remittances__c += parseFloat(syndicator.Remittances__c);
        Total_Invested__c += parseFloat(syndicator.Total_Invested__c);
        Total_Broker_Fees__c += parseFloat(syndicator.Total_Broker_Fees__c);
        Syndication_Balance_non_Defaulted__c += parseFloat(syndicator.Syndication_Balance_non_Defaulted__c);
      })

      const cData = {
        Amount_Syndicated__c,
        Payables_Paid__c,
        Management_Fees__c,
        Syndication_balance__c,
        Syndications__c,
        Paybales__c,
        Remittances__c,
        Total_Invested__c,
        Total_Broker_Fees__c,
        Syndication_Balance_non_Defaulted__c,
      }
      //setSyndicator(getSyndicatorState.data);
      setSyndicator(cData);
      
      dispatch(getSyndicatorCleanup());
    } else if (getSyndicatorState.error) {
      dispatch(getSyndicatorCleanup());
    }
  }, [getSyndicatorState]);


  console.log('MRA syndicator:', syndicator);

  return (
    <div className="container syndicator-metrics-page">
      <div className="table-container">
        <Card>
          <CardTitle className="text-cente p-4">
            <div className="sos-text-primary mb-0 font-weight-bold d-flex justify-content-between">
              <div>Syndicator Metrics</div>
            </div>
          </CardTitle>
        </Card>
        <hr />
        <Card>
          {getSyndicatorState.isLoading && !syndicator ? (
            <div className="m-5 d-flex justify-content-center">
              <Loader isLoading={getSyndicatorState.isLoading} />
            </div>
          ) : syndicator ? (
            <div>
              <Row>
                <Col lg="4" md="6" sm="6">
                  <RCard className="card-stats">
                    <CardBody>
                      <Row>
                        <Col md="4" xs="5">
                          <div className="icon-big text-center icon-warning">
                            <i className="nc-icon nc-money-coins sos-text-primary" />
                          </div>
                        </Col>
                        <Col md="8" xs="7">
                          <div className="numbers">
                            <p className="card-category">Amount Syndicated</p>
                            <CardTitle tag="p">
                              {syndicator.Amount_Syndicated__c
                                ? formatCurrency(
                                    syndicator.Amount_Syndicated__c
                                  )
                                : "$0.00"}
                            </CardTitle>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </RCard>
                </Col>
                <Col lg="4" md="6" sm="6">
                  <RCard className="card-stats">
                    <CardBody>
                      <Row>
                        <Col md="4" xs="5">
                          <div className="icon-big text-center icon-warning">
                            <i className="fa fa-ticket sos-text-primary" />
                          </div>
                        </Col>
                        <Col md="8" xs="7">
                          <div className="numbers">
                            <p className="card-category">Payables Paid</p>
                            <CardTitle tag="p">
                              {syndicator.Payables_Paid__c
                                ? formatCurrency(syndicator.Payables_Paid__c)
                                : "$0.00"}
                            </CardTitle>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </RCard>
                </Col>
                <Col lg="4" md="6" sm="6">
                  <RCard className="card-stats">
                    <CardBody>
                      <Row>
                        <Col md="4" xs="5">
                          <div className="icon-big text-center icon-warning">
                            <i className="fa fa-users sos-text-primary" />
                          </div>
                        </Col>
                        <Col md="8" xs="7">
                          <div className="numbers">
                            <p className="card-category">Management Fees</p>
                            <CardTitle tag="p">
                              {syndicator.Management_Fees__c
                                ? formatCurrency(syndicator.Management_Fees__c)
                                : "$0.00"}
                            </CardTitle>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </RCard>
                </Col>
                <Col lg="4" md="6" sm="6">
                  <RCard className="card-stats">
                    <CardBody>
                      <Row>
                        <Col md="4" xs="5">
                          <div className="icon-big text-center icon-warning">
                            <i className="fa fa-university sos-text-primary" />
                          </div>
                        </Col>
                        <Col md="8" xs="7">
                          <div className="numbers">
                            <p className="card-category">Syndication Balance</p>
                            <CardTitle tag="p">
                              {syndicator.Syndication_balance__c
                                ? formatCurrency(
                                    syndicator.Syndication_balance__c
                                  )
                                : "$0.00"}
                            </CardTitle>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </RCard>
                </Col>
                <Col lg="4" md="6" sm="6">
                  <RCard className="card-stats">
                    <CardBody>
                      <Row>
                        <Col md="4" xs="5">
                          <div className="icon-big text-center icon-warning">
                            <i className="fa fa-exchange sos-text-primary" />
                          </div>
                        </Col>
                        <Col md="8" xs="7">
                          <div className="numbers">
                            <p className="card-category">Syndications</p>
                            <CardTitle tag="p">{`${
                              syndicator.Syndications__c
                                ? syndicator.Syndications__c
                                : 0
                            }`}</CardTitle>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </RCard>
                </Col>
                <Col lg="4" md="6" sm="6">
                  <RCard className="card-stats">
                    <CardBody>
                      <Row>
                        <Col md="4" xs="5">
                          <div className="icon-big text-center icon-warning">
                            <i className="fa fa-plus sos-text-primary" />
                          </div>
                        </Col>
                        <Col md="8" xs="7">
                          <div className="numbers">
                            <p className="card-category">Payables</p>
                            <CardTitle tag="p">
                              {syndicator.Paybales__c
                                ? syndicator.Paybales__c
                                : 0}
                            </CardTitle>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </RCard>
                </Col>
                <Col lg="4" md="6" sm="6">
                  <RCard className="card-stats">
                    <CardBody>
                      <Row>
                        <Col md="4" xs="5">
                          <div className="icon-big text-center icon-warning">
                            <i className="fa fa-minus-circle sos-text-primary" />
                          </div>
                        </Col>
                        <Col md="8" xs="7">
                          <div className="numbers">
                            <p className="card-category">Remittances</p>
                            <CardTitle tag="p">
                              {syndicator.Remittances__c
                                ? syndicator.Remittances__c
                                : 0}
                            </CardTitle>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </RCard>
                </Col>
                <Col lg="4" md="6" sm="6">
                  <RCard className="card-stats">
                    <CardBody>
                      <Row>
                        <Col md="4" xs="5">
                          <div className="icon-big text-center icon-warning">
                            <i className="fa fa-money sos-text-primary" />
                          </div>
                        </Col>
                        <Col md="8" xs="7">
                          <div className="numbers">
                            <p className="card-category">Total Invested</p>
                            <CardTitle tag="p">
                              {syndicator.Total_Invested__c
                                ? formatCurrency(syndicator.Total_Invested__c)
                                : "$0.00"}
                            </CardTitle>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </RCard>
                </Col>
                <Col lg="4" md="6" sm="6">
                  <RCard className="card-stats">
                    <CardBody>
                      <Row>
                        <Col md="4" xs="5">
                          <div className="icon-big text-center icon-warning">
                            <i className="fa fa-check-circle-o sos-text-primary" />
                          </div>
                        </Col>
                        <Col md="8" xs="7">
                          <div className="numbers">
                            <p className="card-category">Total Broker Fees</p>
                            <CardTitle tag="p">
                              {syndicator.Total_Broker_Fees__c
                                ? formatCurrency(
                                    syndicator.Total_Broker_Fees__c
                                  )
                                : "$0.00"}
                            </CardTitle>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </RCard>
                </Col>

                <Col lg="4" md="6" sm="6">
                  <RCard className="card-stats">
                    <CardBody>
                      <Row>
                        <Col md="4" xs="5">
                          <div className="icon-big text-center icon-warning">
                            <i className="fa fa-check-circle-o sos-text-primary" />
                          </div>
                        </Col>
                        <Col md="8" xs="7">
                          <div className="numbers">
                            <p className="card-category">
                              Syndication Balance Non Defaulted
                            </p>
                            <CardTitle tag="p">
                              {syndicator.Syndication_Balance_non_Defaulted__c
                                ? formatCurrency(
                                    syndicator.Syndication_Balance_non_Defaulted__c
                                  )
                                : "$0.00"}
                            </CardTitle>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </RCard>
                </Col>
              </Row>
            </div>
          ) : null}
        </Card>
      </div>
    </div>
  );
};

export default SyndicatorMetrics;
