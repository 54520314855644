import * as types from '../../action-types';
import {getMyISOOffers as initialState } from "../../initialState";

const getMyISOOffers = (state=initialState, action) => {
  switch (action.type) {
    case types.GET_MY_ISO_OFFERS_START:
      return {
        ...state,
        isLoading: true
      };
    case types.GET_MY_ISO_OFFERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccessful: true,
        data: action.payload
      };
    case types.GET_MY_ISO_OFFERS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case types.GET_MY_ISO_OFFERS_CLEANUP:
      return {
        ...state,
        isLoading: false,
        isSuccessful: false,
        error: null
      };
    default:
      return state;
  }
}

export default getMyISOOffers;

