import * as types from '../../action-types';
import { checkEmailExist as initialState } from "../../initialState";

const checkEmailExist = (state=initialState, action) => {
  switch (action.type) {
    case types.CHECK_EMAIL_EXIST_START:
      return {
        ...state,
        isLoading: true
      };
    case types.CHECK_EMAIL_EXIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccessful: true,
        data: action.payload
      };
    case types.CHECK_EMAIL_EXIST_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case types.CHECK_EMAIL_EXIST_CLEANUP:
      return {
        ...state,
        isLoading: false,
        isSuccessful: false,
        error: null
      };
    default:
      return state;
  }
}

export default checkEmailExist;
