import './index.css';
import React, { useState, useEffect }  from 'react';
import { Button, message, Form, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle
} from 'reactstrap';
import { CopyOutlined } from '@ant-design/icons';

//components
import Loader from '../../components/Loader';
import config from '../../config';

//actions
import { generateReferralCode, generateReferralCodeCleanup } from '../../store/actions/generate-referral-code';

const ReferralPage = () => {
  const [form] = Form.useForm();
  const [referralLink, setReferralLink] = useState(null);
  const dispatch = useDispatch();
  const generateReferralCodeState = useSelector(s => s.generateReferralCode);

  const handleSubmit = (values) => {
    setReferralLink(null);
    dispatch(generateReferralCode(values));
  }

  const handleCopy = () => {
    if(referralLink){
      navigator.clipboard.writeText(referralLink);
      message.success('Referral link copied');
    } 
  }

  useEffect(() => {
    if(generateReferralCodeState.isSuccessful){
      message.success('Referral link generated successfully', 1);
      const code = generateReferralCodeState.data.referralCode;
      if(code){
        setReferralLink(`${config.clientUrl}/apply?ref=${code}`);
      }
      dispatch(generateReferralCodeCleanup());
    }else if(generateReferralCodeState.error){
      message.error('Could not generate referral link. Please try again', 1);
      dispatch(generateReferralCodeCleanup());
    }
  }, [generateReferralCodeState]);

  return (
    <div className='container referral-page'>
      <div className='content'> 
        <Row>
          <Col className="mr-auto ml-auto order-1 order-md-2" md="8" sm="12">
            <Card>
              <CardTitle className='text-center p-4'>
                <h2 className='sos-text-primary mb-0 font-weight-bold'>Generate Referral Link</h2>
              </CardTitle>
              <CardBody className='p-5'>
                <Loader isLoading={generateReferralCodeState.isLoading}>
                  {referralLink ? (
                    <Form>
                      <Form.Item
                        label='Referral Link'
                        name='referrallink'
                        labelCol={{ span: 24, md: { span: 5 } }}
                      >
                        <Input
                          readOnly
                          defaultValue={referralLink}
                          addonAfter={(
                            <Button size='middle' className='px-2 border-0 rounded-0' onClick={handleCopy}>
                              <CopyOutlined className='sos-text-primary sos-text-xlarge' />
                            </Button>
                          )}
                        />
                      </Form.Item>
                    </Form>
                  ) : (
                    <Form className='text-left' form={form} onFinish={handleSubmit}>
                      <Form.Item
                        label='First Name'
                        name='FirstName'
                        rules={[{ required: true, message: 'Please input your first name!' }]}
                        labelCol={{ span: 24, md: { span: 5 } }}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label='Last Name'
                        name='LastName'
                        rules={[{ required: true, message: 'Please input your last name!' }]}
                        labelCol={{ span: 24, md: { span: 5 } }}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label='Email'
                        name='Email'
                        rules={[
                          { 
                            required: true, 
                            message: 'Please input your email!'
                          },
                          {
                            type: 'email',
                            message: 'Input a valid email!'
                          }
                        ]}
                        labelCol={{ span: 24, md: { span: 5 } }}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item className='text-center'>
                        <Button htmlType='submit' className='sos-bg-primary text-white' >Submit</Button> 
                      </Form.Item>
                    </Form>
                  )}
                </Loader>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default ReferralPage;
