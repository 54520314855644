import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Spin, Card } from "antd";
import { CardTitle } from "reactstrap";
import { Divider } from "antd";
import Currency from "react-currency-formatter";
import { useParams } from "react-router-dom";
//Actions
import {
  myISOSingleRepayment,
  myISOSingleRepaymentCleanup,
} from "../../../store/actions/get-iso-single-repayment";

const ISORepaymentDetails = () => {
  const dispatch = useDispatch();
  const { type, id } = useParams();
  const myISOSingleRepaymentState = useSelector((s) => s.getISOSingleRepayment);
  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(myISOSingleRepayment(2, type, id));
  }, []);

  useEffect(() => {
    if (myISOSingleRepaymentState.isSuccessful) {
      setData(myISOSingleRepaymentState.data[0]);
      dispatch(myISOSingleRepaymentCleanup());
    } else if (myISOSingleRepaymentState.error) {
      dispatch(myISOSingleRepaymentCleanup());
    }
  }, [myISOSingleRepaymentState]);
  return (
    <div className="container advance-details-page">
      <div className="table-container pt-5 mt-5">
        <Card>
          <CardTitle className="text-cente p-4">
            <div className="sos-text-primary mb-0 font-weight-bold d-flex justify-content-between">
              <div>Repayment Details</div>
            </div>
          </CardTitle>
        </Card>
        <hr />
        <Card>
          {myISOSingleRepaymentState.isLoading && !data ? (
            <Spin size="large" className="loader" />
          ) : data ? (
            <div>
              <Divider orientation="left">
                <h4 className="sos-font-weight-600 mt-0 mb-0">Details</h4>
              </Divider>
              <div className="row">
                <div className="col-md-6">
                  <p>
                    <strong>% Paid: </strong>
                    {parseInt(data.Payoff_Text_Only__c)>100?100:data.Payoff_Text_Only__c}
                  </p>
                  <p>
                    <strong>Balance: </strong>
                    <Currency
                      quantity={
                        data.Balance__c === null ||
                        data.Balance__c === undefined
                          ? 0
                          : data.Balance__c
                      }
                      currency="USD"
                    />
                  </p>
                </div>
                <div className="col-md-6">
                  <p>
                    <strong>Payee Account Name: </strong>
                    {data &&
                      data.Payee_Account__r &&
                      data.Payee_Account__r.Name}
                  </p>
                  <p>
                    <strong>Status: </strong>
                    {data.Status__c}
                  </p>
                  <p>
                    <strong>Opportunity Name: </strong>
                    {data.Name}
                  </p>
                </div>
              </div>
              <div>
                <Divider orientation="left">
                  <h4 className="sos-font-weight-600 mt-0 mb-0">ISO</h4>
                </Divider>
                <p>
                  <strong>ISO Account: </strong>
                  {data.Opportunity__r &&
                    data.Opportunity__r.ISO__r &&
                    data.Opportunity__r.ISO__r.Name}
                </p>
              </div>
            </div>
          ) : (
            <h4>Repayment not found</h4>
          )}
        </Card>
      </div>
    </div>
  );
};

export default ISORepaymentDetails;
