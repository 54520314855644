import * as types from '../../action-types';
import {getSingleOfferInfo as initialState } from "../../initialState";

const getSingleOfferInfo = (state=initialState, action) => {
  switch (action.type) {
    case types.GET_SINGLE_OFFER_INFO_START:
      return {
        ...state,
        isLoading: true
      };
    case types.GET_SINGLE_OFFER_INFO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccessful: true,
        data: action.payload
      };
    case types.GET_SINGLE_OFFER_INFO_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case types.GET_SINGLE_OFFER_INFO_CLEANUP:
      return {
        ...state,
        isLoading: false,
        isSuccessful: false,
        error: null
      };
    default:
      return state;
  }
}

export default getSingleOfferInfo;

