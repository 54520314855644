import React, { useEffect } from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch, useRouteMatch, Redirect } from "react-router-dom";

import AuthNavbar from "../components/Navbars/AuthNavbar.js";
import NewAuthLayout from "./NewAuth.js";
import Footer from "../components/Footer2/index";

import { publicRoutes } from "../routes/routes.js";

var ps;

const Pages = () => {
  const match = useRouteMatch("/login");
  // || useRouteMatch('/signup') || useRouteMatch('/arbitrator/signup') || useRouteMatch('/forgot') || useRouteMatch('/resetpassword');
  useEffect(() => {
    //if (navigator.platform.indexOf("Win") > -1) {
      //ps = new PerfectScrollbar("#fullPages"); //this was crashing the app in windows
    //}

    return () => {
      //if (navigator.platform.indexOf("Win") > -1) {
        //ps.destroy();
      //}
    };
  }, []);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/public") {
        // ------ check if path is /apply -------
        if (prop.path === "/apply") {
          return (
            <Route
              exact={prop.path === "/"}
              path={prop.path}
              render={(props) => (
                <NewAuthLayout>
                  <prop.component />
                </NewAuthLayout>
              )}
              key={key}
            />
          );
        }

        return (
          <Route
            exact={prop.path === "/"}
            path={prop.path}
            render={() => (
              <React.Fragment>
                <AuthNavbar />
                <div id="fullPages" className="wrapper wrapper-full-page">
                  <div className="fullpage sectionimage notauth-full-page">
                    <prop.component />
                    <Footer fluid />
                  </div>
                </div>
              </React.Fragment>
            )}
            // component={
            //   <React.Fragment>
            //     <AuthNavbar />
            //     <div id="fullPages" className="wrapper wrapper-full-page">
            //       <div className="fullpage sectionimage notauth-full-page">
            //         {prop.component}
            //         <Footer fluid />
            //       </div>
            //     </div>
            //   </React.Fragment>
            // }
            key={key}
          />
        );
      } else if (prop.path !== "/public") {
        return <Redirect to="/404" key="redirect" />;
      }
    });
  };

  return (
    <div className="public">
      {/* <AuthNavbar />
      <div id="fullPages" className="wrapper wrapper-full-page">
        <div className="fullpage sectionimage notauth-full-page">
          <Switch>{getRoutes(publicRoutes)}</Switch>
          <Footer fluid />
        </div>
      </div> */}
      <Switch>{getRoutes(publicRoutes)}</Switch>
    </div>
  );
};

export default Pages;
