import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';

export const getDealsOffersStart = () => ({
  type: types.GET_DEALS_OFFERS_START
});

export const getDealsOffersSuccess = payload => ({
  type: types.GET_DEALS_OFFERS_SUCCESS,
  payload
});

export const getDealsOffersFail = payload => ({
  type: types.GET_DEALS_OFFERS_FAIL,
  payload
});

export const getDealsOffersCleanup = () => ({
  type: types.GET_DEALS_OFFERS_CLEANUP
});

export const getDealsOffers = (portal,id )=> async dispatch => {
  try {
    dispatch(getDealsOffersStart());
    const requestObj = {
      path: `offer/opportunity/${portal}/${id}`,
      method: "GET"
    }
    const  data  = await AxiosCall(requestObj);
    dispatch(getDealsOffersSuccess(data));
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(getDealsOffersFail(error));
  }
}
