import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Moment from "react-moment";
import { Spin, Card ,Divider } from "antd";
import { CardTitle } from "reactstrap";
import DealsRepayment from "../DealsRepayments"

//Actions
import {
  dealsDetails,
  dealsDetailsCleanup,
} from "../../../store/actions/deals-details";

//utils
import { formatCurrency } from "utils/helpers";

const DealsDetails = (props) => {
  const dispatch = useDispatch();
  const dealsDetailsState = useSelector((s) => s.dealsDetails);
  const [data, setData] = useState(null);
  const id = props.id;

  useEffect(() => {
    dispatch(dealsDetails(1, id));
  }, []);

  useEffect(() => {
    if (dealsDetailsState.isSuccessful) {
      setData(dealsDetailsState.data[0]);
      dispatch(dealsDetailsCleanup());
    } else if (dealsDetailsState.error) {
      dispatch(dealsDetailsCleanup());
    }
  }, [dealsDetailsState]);

  return (
    <div className=" container advance-details-page">
      <div className="table-container">
        <Card>
          <CardTitle className="text-cente p-4">
            <div className="sos-text-primary mb-0 font-weight-bold d-flex justify-content-between">
              <div>Opportunity Details</div>
            </div>
          </CardTitle>
        </Card>
        <hr />
        <Card>
          {dealsDetailsState.isLoading && !data ? (
            <Spin size="large" className="loader" />
          ) : data ? (
            <div>
              <Divider orientation="center">
                <h4 className="sos-font-weight-600 mt-0 mb-0">Information</h4>
              </Divider>
              <div className="row">
                <div className="col-md-6">
                  <p>
                    <strong>DBA: </strong>
                    {data.DBA__c}
                  </p>
                </div>
                <div className="col-md-6">
                  <p>
                    <strong>Account Legal Name: </strong>
                    {data && data.Account && data.Account.Legal_Name__c}
                  </p>
                </div>
                <div className="col-md-6">
                  <p>
                    <strong>Account Name: </strong>
                    {data && data.Account && data.Account.Name}
                  </p>
                </div>
                <div className="col-md-6">
                  <p>
                    <strong>Account Address: </strong>
                    {data && data.Account && data.Account.BillingAddress ? 
                     `${data.Account.BillingAddress.street || ''}${data.Account.BillingAddress.city ? `, ${data.Account.BillingAddress.city}` : ''}
                     ${data.Account.BillingAddress.state ? `, ${data.Account.BillingAddress.state}` : ''}` : ''}
                  </p>
                </div>
                <div className="col-md-6">
                  <p>
                    <strong>Account Tax ID: </strong>
                    {data && data.Account && data.Account.Federal_Tax_ID_No__c}
                  </p>
                </div>
                <div className="col-md-6">
                  <p>
                    <strong>Funded Amount: </strong>
                    {formatCurrency(data?.Amount ?? 0)}
                  </p>
                </div>
                <div className="col-md-6">
                  <p>
                    <strong>Stage: </strong>
                    {data.StageName}
                  </p>
                </div>
                <div className="col-md-6">
                  <p>
                    <strong>Days in Current Stage: </strong>
                    {data.Days_in_Current_Stage__c}
                  </p>
                </div>
                <div className="col-md-6">
                  <p>
                    <strong>Stage Date: </strong>
                    {data.Last_Stage_Change_Date__ ? (
                      <Moment format="Do MMM., YYYY">
                        {data.Last_Stage_Change_Date__c}{" "}
                      </Moment>
                    ) : 'N/A'}
                  </p>
                </div>
                <div className="col-md-6">
                  <p>
                    <strong>Close Date: </strong>
                    {data.CloseDate ? <Moment format="Do MMM., YYYY">{data.CloseDate}</Moment> : 'N/A'}
                  </p>
                </div>
                <div className="col-md-6">
                  <p>
                    <strong>Decline Reason: </strong>
                    {data.Decline_Reason__c}
                  </p>
                </div>
              </div>

              <Divider orientation="center">
                <h4 className="sos-font-weight-600 mt-0 mb-0">Owner Info</h4>
              </Divider>
              <div className="row">
                {data && data.Owner_1__r && (
                  <div className="col-md-6 mb-4">
                      <Divider  orientation="left">First Owner</Divider>
                    <p>
                      <strong>Name: </strong>
                      {data && data.Owner_1__r && data.Owner_1__r.Name}
                    </p>
                    <p>
                      <strong>Phone: </strong>
                      {data && data.Owner_1__r && data.Owner_1__r.Phone}
                    </p>
                    <p>
                      <strong>Mobile: </strong>
                      {data && data.Owner_1__r && data.Owner_1__r.MobilePhone}
                    </p>
                    <p>
                      <strong>SSN: </strong>
                      {data &&
                        data.Owner_1__r &&
                        data.Owner_1__r.Social_Security_Number1__c}
                    </p>
                    <p>
                      <strong>Ownership %: </strong>
                      {data.Owner_1_Ownership__c}
                    </p>
                  </div>
                )}
                {data && data.Owner_2__r && (
                  <div className="col-md-6 mb-4">
                       <Divider  orientation="left">Second Owner</Divider>
                    <p>
                      <strong>Name: </strong>
                      {data && data.Owner_2__r && data.Owner_2__r.Name}
                    </p>
                    <p>
                      <strong>Phone: </strong>
                      {data && data.Owner_2__r && data.Owner_2__r.Phone}
                    </p>
                    <p>
                      <strong>Mobile: </strong>
                      {data && data.Owner_2__r && data.Owner_2__r.MobilePhone}
                    </p>
                    <p>
                      <strong>SSN: </strong>
                      {data &&
                        data.Owner_2__r &&
                        data.Owner_2__r.Social_Security_Number1__c}
                    </p>
                    <p>
                      <strong>Ownership %: </strong>
                      {data.Owner_2_Ownership__c}
                    </p>
                  </div>
                )}

                {data && data.Owner_3__r && (
                  <div className="col-md-6 mb-4">
                    <Divider  orientation="left">Third Owner</Divider>

                    <p>
                      <strong>Name: </strong>
                      {data && data.Owner_3__r && data.Owner_3__r.Name}
                    </p>
                    <p>
                      <strong>Phone: </strong>
                      {data && data.Owner_3__r && data.Owner_3__r.Phone}
                    </p>
                    <p>
                      <strong>Mobile: </strong>
                      {data && data.Owner_3__r && data.Owner_3__r.MobilePhone}
                    </p>
                    <p>
                      <strong>SSN: </strong>
                      {data &&
                        data.Owner_3__r &&
                        data.Owner_3__r.Social_Security_Number1__c}
                    </p>
                    <p>
                      <strong>Ownership %: </strong>
                      {data.Owner_3_Ownership__c}
                    </p>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <h4>Deal not found</h4>
          )}
        </Card>
      </div>
      <DealsRepayment id={id}/>
    </div>
  );
};

export default DealsDetails;
