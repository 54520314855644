import './index.css';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Card, Divider, Spin } from 'antd';
import { CardTitle } from 'reactstrap';
import { useParams } from 'react-router-dom';
import moment from 'moment';

//actions
import { getAdvanceDetails, getAdvanceDetailsCleanup } from '../../../store/actions/get-advance-details';

const AdvanceDetails = () => {
  const dispatch = useDispatch();
  const getAdvanceDetailsState = useSelector(s => s.getAdvanceDetails);
  const [advanceDetails, setAdvanceDetails] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    dispatch(getAdvanceDetails(id));
  },[])

  useEffect(() => {
    if(getAdvanceDetailsState.isSuccessful){
      setAdvanceDetails(getAdvanceDetailsState.data);
      dispatch(getAdvanceDetailsCleanup());
    }else if(getAdvanceDetailsState.error){
      dispatch(getAdvanceDetailsCleanup());
    }
  },[getAdvanceDetailsState])

  return (
    <div className="pt-5  container advance-details-page">
      <div className="pt-5 table-container">
        <Card>
          <CardTitle className="text-cente p-4">
            <div className="sos-text-primary mb-0 font-weight-bold d-flex justify-content-between">
              <div>Advance Details</div>
            </div>
          </CardTitle>
        </Card>
        <hr />
        <Card>
          {getAdvanceDetailsState.isLoading && !advanceDetails ? (
            <Spin size='large' className='loader' />
          ) : advanceDetails ? (
            <div>
              <Divider orientation="left">
                <h4 className='sos-font-weight-600 mt-0 mb-0'>Funding Details</h4>
              </Divider>
              <div className='row'>
                <div className='col-md-6'>
                  <p>
                    <strong>Amount Requested: </strong>{`$${advanceDetails.Amount_Requested__c ? advanceDetails.Amount_Requested__c : 0}`}
                  </p>
                </div>
                <div className='col-md-6'>
                  <p>
                    <strong>Annual Revenue: </strong>{`$${advanceDetails.AnnualRevenue ? advanceDetails.AnnualRevenue : 0}`}
                  </p>
                </div>
                <div className='col-md-6'>
                  <p>
                    <strong>Business Inception: </strong>{moment(advanceDetails.Date_Business_Established__c).format('Do MMM., YYYY')}
                  </p>
                </div>
                <div className='col-md-6'>
                  <p>
                    <strong>When do you require Funding?: </strong>{advanceDetails.Requested_Funding_Timeline__c}
                  </p>
                </div>
              </div>
              <Divider orientation="left">
                <h4 className='sos-font-weight-600 mt-0 mb-0'>Owner Details</h4>
              </Divider>
              <div className='row'>
                <div className='col-md-6'>
                  <p>
                    <strong>Name: </strong>{`${advanceDetails.Title ? `${advanceDetails.Title} ` : ''}${advanceDetails.FirstName} ${advanceDetails.LastName}`}
                  </p>
                </div>
                <div className='col-md-6'>
                  <p>
                    <strong>Email: </strong>{advanceDetails.Email}
                  </p>
                </div>
                <div className='col-md-6'>
                  <p>
                    <strong>Phone: </strong>{advanceDetails.Phone}
                  </p>
                </div>
                <div className='col-md-6'>
                  <p>
                    <strong>How do you prefer to be contacted?: </strong>{advanceDetails.Contact_Preference__c}
                  </p>
                </div>
                <div className='col-md-12'>
                  <p>
                    <strong>Street: </strong>{advanceDetails.Street}
                  </p>
                </div>
                <div className='col-md-6'>
                  <p>
                    <strong>City: </strong>{advanceDetails.City}
                  </p>
                </div>
                <div className='col-md-6'>
                  <p>
                    <strong>State: </strong>{advanceDetails.State}
                  </p>
                </div>
                <div className='col-md-6'>
                  <p>
                    <strong>Country: </strong>{advanceDetails.Country}
                  </p>
                </div>
                <div className='col-md-6'>
                  <p>
                    <strong>Zip code: </strong>{advanceDetails.PostalCode}
                  </p>
                </div>
                {/* <div className='col-md-6'>
                  <p>
                    <strong>Percentage Ownership: </strong>20%
                  </p>
                </div> */}
                {/* <div className='col-md-12'>
                  <div className='row'>
                    <h6 className='col-md-12 sos-font-weight-600 sos-text-semi-medium text-capitalize mb-3 mt-4'>Second Owner Information</h6>
                    <div className='col-md-6'>
                      <p>
                        <strong>Name: </strong>{`${'Mr'} ${'Bolaji'} ${'Mark'}`}
                      </p>
                    </div>
                    <div className='col-md-6'>
                      <p>
                        <strong>Email: </strong>bolm@test.com
                      </p>
                    </div>
                    <div className='col-md-6'>
                      <p>
                        <strong>Phone: </strong>4568999992
                      </p>
                    </div>
                    <div className='col-md-6'>
                      <p>
                        <strong>Percentage Ownership: </strong>20%
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col-md-12'>
                  <div className='row'>
                    <h6 className='col-md-12 sos-font-weight-600 sos-text-semi-medium text-capitalize mb-3 mt-4'>Third Owner Information</h6>
                    <div className='col-md-6'>
                      <p>
                        <strong>Name: </strong>{`${'Mr'} ${'Bolaji'} ${'Mark'}`}
                      </p>
                    </div>
                    <div className='col-md-6'>
                      <p>
                        <strong>Email: </strong>bolm@test.com
                      </p>
                    </div>
                    <div className='col-md-6'>
                      <p>
                        <strong>Phone: </strong>4568999992
                      </p>
                    </div>
                    <div className='col-md-6'>
                      <p>
                        <strong>Percentage Ownership: </strong>20%
                      </p>
                    </div>
                  </div>
                </div> */}
              </div>
              <Divider orientation="left">
                <h4 className='sos-font-weight-600 mt-0 mb-0'>Business Details</h4>
              </Divider>
              <div className='row'>
                <div className='col-md-6'>
                  <p>
                    <strong>Business Name: </strong>{advanceDetails.DBA_Name__c}
                  </p>
                </div>
                <div className='col-md-6'>
                  <p>
                    <strong>Legal Name: </strong>{advanceDetails.Legal_Name__c}
                  </p>
                </div>
                <div className='col-md-6'>
                  <p>
                    <strong>Industry: </strong>{advanceDetails.Industry}
                  </p>
                </div>
                <div className='col-md-6'>
                  <p>
                    <strong>Funding Purpose: </strong>{advanceDetails.Purpose_of_Funding__c}
                  </p>
                </div>
                <div className='col-md-6'>
                  <p>
                    <strong>Legal Entity Type: </strong>{advanceDetails.Legal_Entity_Type__c}
                  </p>
                </div>
                <div className='col-md-6'>
                  <p>
                    <strong>Tax ID: </strong>{advanceDetails.Tax_ID__c}
                  </p>
                </div>
                <div className='col-md-6'>
                  <p>
                    <strong>Average Bank Balance: </strong>{advanceDetails.Average_Daily_Balance__c}
                  </p>
                </div>
                {/* <div className='col-md-6'>
                  <p>
                    <strong>Outstanding Balance: </strong>{'$40000'}
                  </p>
                </div>
                <div className='col-md-6'>
                  <p>
                    <strong>Phone Number: </strong>{'56899000'}
                  </p>
                </div>
                <div className='col-md-6'>
                  <p>
                    <strong>Cellular Number: </strong>{'456770000'}
                  </p>
                </div>
                <div className='col-md-6'>
                  <p>
                    <strong>Website: </strong>{'test.com'}
                  </p>
                </div> */}
                <div className='col-md-6'>
                  <p>
                    <strong>Lead Source: </strong>{advanceDetails.LeadSource}
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <h4>Advance not found</h4>
          )}
        </Card>
      </div>
    </div>
  )
}

export default AdvanceDetails;
