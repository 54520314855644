import app from "firebase/app";
import firebase from "firebase";
import "firebase/auth";
import "firebase/firebase-firestore";
const config = {
  apiKey: "AIzaSyBS0wQtsTp_UK42qfRBYo-SpP9dFLaAvHo",
  authDomain: "sos-portal-1553606261133.firebaseapp.com",
  databaseURL: "https://sos-portal-1553606261133.firebaseio.com",
  projectId: "sos-portal-1553606261133",
  storageBucket: "sos-portal-1553606261133.appspot.com",
  messagingSenderId: "64757245210",
  appId: "1:64757245210:web:b3bf22c87286598f",
};

class Firebase {
  constructor() {
    app.initializeApp(config);
    this.auth = app.auth();
    this.db = app.firestore();
    this.provider = new firebase.auth.GoogleAuthProvider();
  }
  login(email, password) {
    return this.auth.signInWithEmailAndPassword(email, password);
  }
  doSignInWithGoogle() {
    return this.auth.signInWithPopup(this.provider);
  }
  logout() {
    return this.auth.signOut();
  }
  register(email, password) {
    return this.auth.createUserWithEmailAndPassword(email, password);
  }
  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);
  doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);
  doSignInWithEmailAndPassword (email, password) {
    return this.auth.signInWithEmailAndPassword(email, password);
  }
}

export default new Firebase();
