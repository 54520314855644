import React from "react";
import { Tabs } from "antd";
import { useParams } from "react-router-dom";
import SingleOfferDetails from "./OfferDetails";
import OfferSummary from "./OfferSummary"
const { TabPane } = Tabs;
const OfferDetails = () => {
  const {type, id } = useParams();
  const portal = 2;
  return (
    <div className="pt-5 mt-5  container advance-details-page">
      <div className="pt-5 table-container">
        <Tabs defaultActiveKey="1">
          <TabPane tab="Deal Offer Details" key="1">
            <SingleOfferDetails id={id} portal={portal} type={type}/>
          </TabPane>
          <TabPane tab="Deal Offer Summary" key="2">
            <OfferSummary id={id} portal={portal} type={type}/>
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default OfferDetails;
