import './index.css';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Card, Progress } from 'antd';
import { CardTitle, Card as RCard, CardBody, Row, Col } from 'reactstrap';


//actions
import { getBrokerMetricsByIso, getBrokerMetricsByIsoCleanup } from '../../../store/actions/get-broker-metrics-by-iso';

//utils
import { formatCurrency } from '../../../utils/helpers';

//components
import Loader from '../../../components/Loader';


const IsoMetrics = () => {
  const dispatch = useDispatch();
  const getBrokerMetricsByIsoState = useSelector(s => s.getBrokerMetricsByIso);
  const [isoMetrics, setIsoMetrics] = useState(null);
  useEffect(() => {
    dispatch(getBrokerMetricsByIso(2));
  },[])

  useEffect(() => {
    if(getBrokerMetricsByIsoState.isSuccessful){
      setIsoMetrics(getBrokerMetricsByIsoState.data);
      dispatch(getBrokerMetricsByIsoCleanup());
    }else if(getBrokerMetricsByIsoState.error){
      dispatch(getBrokerMetricsByIsoCleanup());
    }
  },[getBrokerMetricsByIsoState])

  return (
    <div className="pt-5  container iso-metrics-page">
      <div className="pt-5 table-container">
        <Card>
          <CardTitle className="text-cente p-4">
            <div className="sos-text-primary mb-0 font-weight-bold d-flex justify-content-between">
              <div>{`Iso Metrics ${isoMetrics && isoMetrics.Name ? `: ${isoMetrics.Name}` : ''}`}</div>
            </div>
          </CardTitle>
        </Card>
        <hr />
        <Card>
          {getBrokerMetricsByIsoState.isLoading && !isoMetrics ? (
            <div className='m-5 d-flex justify-content-center'>
              <Loader isLoading={getBrokerMetricsByIsoState.isLoading} />
            </div>
          ) : isoMetrics ? (
            <div>
              <Row>
                <Col lg="4" md="6" sm="6">
                  <RCard className="card-stats">
                    <CardBody>
                      <Row>
                        <Col md="4" xs="5">
                          <div className="icon-big text-center icon-warning">
                            <i className="nc-icon nc-money-coins sos-text-primary" />
                          </div>
                        </Col>
                        <Col md="8" xs="7">
                          <div className="numbers">
                            <p className="card-category">Total Commissions</p>
                            <CardTitle tag="p">{isoMetrics.Total_Commissions__c ? formatCurrency(isoMetrics.Total_Commissions__c) : '$0.00'}</CardTitle>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </RCard>
                </Col>
                <Col lg="4" md="6" sm="6">
                  <RCard className="card-stats">
                    <CardBody>
                      <Row>
                        <Col md="4" xs="5">
                          <div className="icon-big text-center icon-warning">
                            <i className="fa fa-usd sos-text-primary" />
                          </div>
                        </Col>
                        <Col md="8" xs="7">
                          <div className="numbers">
                            <p className="card-category">Total Number of Deals</p>
                            <CardTitle tag="p">{`${isoMetrics.Total_Number_of_Deals__c ? isoMetrics.Total_Number_of_Deals__c : 0}`}</CardTitle>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </RCard>
                </Col>
                <Col lg="4" md="6" sm="6">
                  <RCard className="card-stats">
                    <CardBody>
                      <Row>
                        <Col md="4" xs="5">
                          <div className="icon-big text-center icon-warning">
                            <i className="fa fa-ban sos-text-primary" />
                          </div>
                        </Col>
                        <Col md="8" xs="7">
                          <div className="numbers">
                            <p className="card-category">Total Number of Declined Deals</p>
                            <CardTitle tag="p">{isoMetrics.Total_Number_of_Declined_Deals__c ? isoMetrics.Total_Number_of_Declined_Deals__c : 0}</CardTitle>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </RCard>
                </Col>
                <Col lg="4" md="6" sm="6">
                  <RCard className="card-stats">
                    <CardBody>
                      <Row>
                        <Col md="4" xs="5">
                          <div className="icon-big text-center icon-warning">
                            <i className="fa fa-check-circle-o sos-text-primary" />
                          </div>
                        </Col>
                        <Col md="8" xs="7">
                          <div className="numbers">
                            <p className="card-category">Total Number of Approved Deals</p>
                            <CardTitle tag="p">{isoMetrics.Total_Number_of_Approved_Deals__c ? isoMetrics.Total_Number_of_Approved_Deals__c : 0}</CardTitle>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </RCard>
                </Col>
                <Col lg="4" md="6" sm="6">
                  <RCard className="card-stats">
                    <CardBody>
                      <Row>
                        <Col md="4" xs="5">
                          <div className="icon-big text-center icon-warning">
                            <i className="fa fa-area-chart sos-text-primary" />
                          </div>
                        </Col>
                        <Col md="8" xs="7">
                          <div className="numbers">
                            <p className="card-category">Approval Ratio</p>
                            <CardTitle tag="p">{isoMetrics.Approval_Ratio__c ? isoMetrics.Approval_Ratio__c : 0}</CardTitle>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </RCard>
                </Col>
                <Col lg="4" md="6" sm="6">
                  <RCard className="card-stats">
                    <CardBody>
                      <Row>
                        <Col md="4" xs="5">
                          <div className="icon-big text-center icon-warning">
                            <i className="fa fa-usd sos-text-primary" />
                          </div>
                        </Col>
                        <Col md="8" xs="7">
                          <div className="numbers">
                            <p className="card-category">Total Number of Funded Deals</p>
                            <CardTitle tag="p">{isoMetrics.Total_Number_of_Funded_Deals__c ? isoMetrics.Total_Number_of_Funded_Deals__c : 0}</CardTitle>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </RCard>
                </Col>
                <Col lg="4" md="6" sm="6">
                  <RCard className="card-stats">
                    <CardBody>
                      <Row>
                        <Col md="4" xs="5">
                          <div className="icon-big text-center icon-warning">
                            <i className="fa fa-money sos-text-primary" />
                          </div>
                        </Col>
                        <Col md="8" xs="7">
                          <div className="numbers">
                            <p className="card-category">Total Amount Funded</p>
                            <CardTitle tag="p">{isoMetrics.Total_Amount_Funded__c ? formatCurrency(isoMetrics.Total_Amount_Funded__c) : '$0.00'}</CardTitle>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </RCard>
                </Col>
                <Col lg="4" md="6" sm="6">
                  <RCard className="card-stats">
                    <CardBody>
                      <Row>
                        <Col md="4" xs="5">
                          <div className="icon-big text-center icon-warning">
                            <i className="fa fa-line-chart sos-text-primary" />
                          </div>
                        </Col>
                        <Col md="8" xs="7">
                          <div className="numbers">
                            <p className="card-category">Funding Ratio</p>
                            <CardTitle tag="p">{isoMetrics.Funding_Ratio__c ? isoMetrics.Funding_Ratio__c : 0}</CardTitle>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </RCard>
                </Col>
              </Row>
            </div>
          ) : null}
        </Card>
      </div>
    </div>
  )
}

export default IsoMetrics;
