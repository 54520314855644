import React from "react";
import { Tabs } from "antd";
import { useParams } from "react-router-dom";
import SyndicationDetails from "./SyndicationDetails";
import SyndiacationPayables from "./SyndicationPayablesList";

const SyndicationDealDetailsSection = () => {
  const { type, id } = useParams();
  const { TabPane } = Tabs;
  return (
    <div className="pt-5 mt-5  container advance-details-page">
      <div className="pt-5 table-container">
        <Tabs defaultActiveKey="1">
          <TabPane tab="Syndication Details" key="1">
            <SyndicationDetails type={type} id={id} />
          </TabPane>
          <TabPane tab="Syndication Payables" key="2">
            <SyndiacationPayables type={type} id={id} />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default SyndicationDealDetailsSection;
