import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';
export const dealsDetailsStart = () => ({
  type: types.DEALS_DETAILS_START
});

export const dealsDetailsSuccess = payload => ({
  type: types.DEALS_DETAILS_SUCCESS,
  payload
});

export const dealsDetailsFail = payload => ({
  type: types.DEALS_DETAILS_FAIL,
  payload
});

export const dealsDetailsCleanup = () => ({
  type: types.DEALS_DETAILS_CLEANUP
});

export const dealsDetails =(portal,id)=> async dispatch => {
  try {
    dispatch(dealsDetailsStart());
    const requestObj = {
      path: `opportunity/${portal}/${id}`,
      method: "GET",   
    }
    const  data  = await AxiosCall(requestObj);
    dispatch(dealsDetailsSuccess(data));
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(dealsDetailsFail(error));
  }
}
