import "./index.css";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Card, Spin } from "antd";
import { CardTitle } from "reactstrap";
import moment from "moment";

//actions
import {
  getSingleRepayment,
  getSingleRepaymentCleanup,
} from "../../../store/actions/get-single-repayment";

//utils
import { formatCurrency } from "../../../utils/helpers";


const RepaymentDetailsSection = () => {
  const dispatch = useDispatch();
  const getSingleRepaymentState = useSelector((s) => s.getSingleRepayment);
  const [repaymentDetails, setRepaymentDetails] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    dispatch(getSingleRepayment(3, id, 1))
  }, []);

  useEffect(() => {
    if (getSingleRepaymentState.isSuccessful) {
      setRepaymentDetails(getSingleRepaymentState.data);
      dispatch(getSingleRepaymentCleanup());
    } else if (getSingleRepaymentState.error) {
      dispatch(getSingleRepaymentCleanup());
    }
  }, [getSingleRepaymentState]);

  return (
    <div className="repayment-detail-section broker-repayment pt-5 mt-5">
      <div className="container">
        <Card>
          <CardTitle className="text-cente p-4">
            <div className="sos-text-primary mb-0 font-weight-bold d-flex justify-content-between">
              <div>Repayment Details</div>
            </div>
          </CardTitle>
        </Card>
      </div>
      <hr />
      <div className="container">
        <Card>
          {getSingleRepaymentState.isLoading && !repaymentDetails ? (
            <Spin size="large" className="loader" />
          ) : repaymentDetails ? (
            <div>
              <div className="row">
              <div className="col-md-6">
                  <p>
                    <strong>Funded Date: </strong>
                    {`${
                      repaymentDetails.Funded_Date__c
                        ? moment(repaymentDetails.Funded_Date__c).format("MMMM Do, YYYY")
                        : "N/A"
                    }`}
                  </p>
                </div>
                <div className="col-md-6">
                  <p>
                    <strong>Funding Amount: </strong>
                    {repaymentDetails.Funding_Amount__c 
                      ? formatCurrency(repaymentDetails.Funding_Amount__c)
                      : "0"}
                  </p>
                </div>

                <div className="col-md-6">
                  <p>
                    <strong>Paid %: </strong>
                    {`${
                      repaymentDetails.Payoff_Text_Only__c
                        ? repaymentDetails.Payoff_Text_Only__c>100?100
                        : repaymentDetails.Payoff_Text_Only__c
                        : 0
                    }%`}
                  </p>
                </div>
                <div className="col-md-6">
                  <p>
                    <strong>Payee Account Name: </strong>
                    {repaymentDetails.Payee_Account__r
                      ? repaymentDetails.Payee_Account__r.Name
                      : ""}
                  </p>
                </div>
              </div>
            </div>
          ) : null}
        </Card>
      </div>
    </div>
  );
};

export default RepaymentDetailsSection;
