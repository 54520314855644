// ------------ import external dpendencies ------------
import React from "react";
import { Link } from "react-router-dom";

// ------------- import internal dependencies ------------
// ------ import assets -------
import BrandLogo from "../../assets/img/SOS.logo.png";
import TrustPilot from "../../assets/img/trustpilot.png";
import MailIcon from "../../assets/svg/mail.svg";
import CallIcon from "../../assets/svg/call.svg";
import LocationIcon from "../../assets/svg/location.svg";
import PadLockIcon from "../../assets/svg/padlock.svg";
import InstagramIcon from "../../assets/svg/icons8-instagram.svg";
import LinkedinIcon from "../../assets/svg/icons8-linked-in.svg";
import FacebookIcon from "../../assets/svg/icons8-facebook.svg";
import TwitterIcon from "../../assets/svg/icons8-twitter.svg";
import CloseIcon from "../../assets/svg/close.svg";
// ------ import styles ------
import "./style.css";

const AuthSidebar = ({ close }) => {
  return (
    <>
      <aside className="auth-sidebar">
        <img
          src={CloseIcon}
          alt="Close icon"
          onClick={close}
          className="close-auth-sidebar"
        />

        {/* ------- heading section ------ */}
        <div>
          <div className="brand-logo">
            <a href="http://altbanq.com/" target="_blank">
              <img src={BrandLogo} alt="logo" />
            </a>
          </div>
          <a
            href="https://www.trustpilot.com/review/altbanq.com"
            target="_blank"
          >
            <img src={TrustPilot} alt="trustpilot" className="trust-pilot" />
          </a>
        </div>

        {/* -------- contact section -------- */}
        <div>
          <div className="contact-details">
            {/* <img style={{color:'red'}} src={MailIcon} alt="mail" /> */}
            <i style={{color:'#29C273'}} class="fa-solid fa-envelope fa-xl"></i>
            <span>Info@altbanq.com</span>
          </div>
          <div className="contact-details">
            {/* <img src={CallIcon} alt="call" /> */}
            <i style={{color:'#29C273'}} class="fa-solid fa-phone fa-xl"></i>
            <span>212-235-5455</span>
          </div>
          <div className="contact-details">
            {/* <img src={LocationIcon} alt="location" /> */}
            <i style={{color:'#29C273'}} class="fa-solid fa-location-dot fa-xl"></i>
            <span>477 Madison Ave Fl 24 New York, New York 10022</span>
          </div>
        </div>

        {/* -------- privacy and socials section -------- */}
        <div>
          <div className="privacy-info">
            {/* <img src={PadLockIcon} alt="privacy" /> */}
            <div style={{paddingTop:'20px'}}>
              <i style={{color:'#29C273'}} class="fa-solid fa-lock fa-xl"></i>
            </div>
            <span className="privacy-separator"></span>
            <span>
              All information submitted to altbanq Lending is secure and
              confidential
            </span>
          </div>
          <div className="sos-socials">
            <a href="https://www.instagram.com/altbanq/" target="_blank">
              <img width="32" src={InstagramIcon} alt="instagram" />
            </a>
            <a
              href="https://www.linkedin.com/company/altbanq/"
              target="_blank"
            >
              <img width="32" src={LinkedinIcon} alt="linkedin" />
            </a>
            <a href="https://www.facebook.com/altbanqinc" target="_blank">
              <img width="32" src={FacebookIcon} alt="facebook" />
            </a>
            <a href="https://twitter.com/altbanq" target="_blank">
              <img width="32" src={TwitterIcon} alt="twitter" />
            </a>
          </div>
          <div className="footer-links">
            <a href="https://altbanq.com/legal-policy/" target="_blank">
              Legal Terms
            </a>
            <span className="privacy-separator"></span>
            <a
              href="https://altbanq.com/frequently-asked-questions/"
              target="_blank"
            >
              FAQ
            </a>
            <span className="privacy-separator"></span>
            <a
              href="https://altbanq.com/privacy-and-security-policy/"
              target="_blank"
            >
              Privacy Policy
            </a>
          </div>
        </div>
      </aside>
    </>
  );
};

export default AuthSidebar;
