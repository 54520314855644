import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';

export const getSingleAccountStart = () => ({
  type: types.GET_SINGLE_ACCOUNT_START
});

export const getSingleAccountSuccess = payload => ({
  type: types.GET_SINGLE_ACCOUNT_SUCCESS,
  payload
});

export const getSingleAccountFail = payload => ({
  type: types.GET_SINGLE_ACCOUNT_FAIL,
  payload
});

export const getSingleAccountCleanup = () => ({
  type: types.GET_SINGLE_ACCOUNT_CLEANUP
});

export const getSingleAccount = (id) => async dispatch => {
  try {
    dispatch(getSingleAccountStart());
    const requestObj = {
      path: `brokerfunding/account/${id}`,
      method: "GET"
    }
    const  data  = await AxiosCall(requestObj);
    dispatch(getSingleAccountSuccess(data));
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(getSingleAccountFail(error));
  }
}
