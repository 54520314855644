import * as types from "../../action-types";
import AxiosCall from "../../../utils/axios";
import ErrorHandler from "../../../utils/error-handler";

export const addStipulationAttachmentStart = () => ({
  type: types.ADD_STIPULATION_ATTACHMENT_START,
});

export const addStipulationAttachmentSuccess = (payload) => ({
  type: types.ADD_STIPULATION_ATTACHMENT_SUCCESS,
  payload,
});

export const addStipulationAttachmentFail = (payload) => ({
  type: types.ADD_STIPULATION_ATTACHMENT_FAIL,
  payload,
});

export const addStipulationAttachmentCleanup = () => ({
  type: types.ADD_STIPULATION_ATTACHMENT_CLEANUP,
});

export const addStipulationAttachment = (portal, type, id, payload) => async (
  dispatch
) => {
  try {
    dispatch(addStipulationAttachmentStart());
    const form = new FormData();
    payload.documents.forEach((document, index) =>
      form.append(`bank_statement-${index}`, document)
    );
    const requestObj = {
      path: `attachment/upload/${portal}/iso/${type}/${id}/stipulation`,
      method: "POST",
      data: form,
      contentType: "multipart/form-data",
    };
    const data = await AxiosCall(requestObj);
    dispatch(addStipulationAttachmentSuccess(data[0]));
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(addStipulationAttachmentFail(error));
  }
};
