const industries = [
    { value: '0111', label: 'Wheat Farming', key: 1 } ,
{ value: '0112', label: 'Rice Farming', key: 2 } ,
{ value: '0115', label: 'Corn Farming', key: 3 } ,
{ value: '0116', label: 'Soybean Farming', key: 4 } ,
{ value: '0119', label: 'Dry Pea and Bean Farming', key: 5 } ,
{ value: '0119', label: 'All Other Grain Farming', key: 6 } ,
{ value: '0119', label: 'Oilseed (Except Soybean) Farming', key: 7 } ,
{
  value: '0119',
  label: 'Oilseed and Grain Combination Farming',
  key: 8
} ,
{ value: '0131', label: 'Cotton Farming', key: 9 } ,
{ value: '0132', label: 'Tobacco Farming', key: 10 } ,
{ value: '0133', label: 'Sugar Beet Farming', key: 11 } ,
{ value: '0133', label: 'Sugarcane Farming', key: 12 } ,
{ value: '0134', label: 'Potato Farming', key: 13 } ,
{ value: '0139', label: 'All Other Misc. Crop Farming', key: 14 } ,
{ value: '0139', label: 'Hay Farming', key: 15 } ,
{ value: '0139', label: 'Peanut Farming', key: 16 } ,
{
  value: '0161',
  label: 'Other Vegetable (Except Potato) and Melon Farming',
  key: 17
} ,
{ value: '0171', label: 'Berry (Except Strawberry) Farming', key: 18 } ,
{ value: '0171', label: 'Strawberry Farming', key: 19 } ,
{ value: '0172', label: 'Grape Vineyards', key: 20 } ,
{ value: '0173', label: 'Tree Nut Farming', key: 21 } ,
{ value: '0174', label: 'Citrus (Except Orange) Groves', key: 22 } ,
{ value: '0174', label: 'Orange Groves', key: 23 } ,
{ value: '0175', label: 'Apple Orchards', key: 24 } ,
{ value: '0175', label: 'Other Noncitrus Fruit Farming', key: 25 } ,
{
  value: '0179',
  label: 'Fruit and Tree Nut Combination Farming',
  key: 26
} ,
{ value: '0181', label: 'Nursery and Tree Production', key: 27 } ,
{ value: '0181', label: 'Floriculture Production', key: 28 } ,
{ value: '0182', label: 'Mushroom Production', key: 29 } ,
{ value: '0182', label: 'Other Food Crops Grown under Cover', key: 30 } ,
{ value: '0191', label: 'All Other Misc. Crop Farming', key: 31 } ,
{ value: '0211', label: 'Cattle Feedlots', key: 32 } ,
{
  value: '0212',
  label: 'Support Activities for Animal Production',
  key: 33
} ,
{ value: '0212', label: 'Beef Cattle Ranching and Farming', key: 34 } ,
{ value: '0213', label: 'Hog and Pig Farming', key: 35 } ,
{ value: '0214', label: 'Goat Farming', key: 36 } ,
{ value: '0214', label: 'Sheep Farming', key: 37 } ,
{ value: '0219', label: 'Animal Production NOC', key: 38 } ,
{ value: '0241', label: 'Dairy Cattle and Milk Production', key: 39 } ,
{
  value: '0251',
  label: 'Broilers and Other Meat Type Chicken Production',
  key: 40
} ,
{ value: '0252', label: 'Chicken Egg Production', key: 41 } ,
{ value: '0253', label: 'Turkey Production', key: 42 } ,
{ value: '0254', label: 'Poultry Hatcheries', key: 43 } ,
{ value: '0259', label: 'Other Poultry Production', key: 44 } ,
{
  value: '0271',
  label: 'Fur-Bearing Animal and Rabbit Production',
  key: 45
} ,
{ value: '0272', label: 'Horses and Other Equine Production', key: 46 } ,
{
  value: '0272',
  label: 'Support Activities for Animal Production',
  key: 47
} ,
{ value: '0279', label: 'Apiculture', key: 48 } ,
{ value: '0279', label: 'Other Aquaculture', key: 49 } ,
{
  value: '0721',
  label: 'Soil Preparation, Planting, and Cultivating',
  key: 50
} ,
{
  value: '0722',
  label: 'Crop Harvesting, Primarily by Machine',
  key: 51
} ,
{
  value: '0723',
  label: 'Postharvest Crop Activities (Except Cotton Ginning)',
  key: 52
} ,
{ value: '0724', label: 'Cotton Ginning', key: 53 } ,
{ value: '0741', label: 'Veterinary Services', key: 54 } ,
{ value: '0742', label: 'Veterinary Services', key: 55 } ,
{
  value: '0752',
  label: 'Pet Care (Except Veterinary) Services',
  key: 56
} ,
{
  value: '0761',
  label: 'Farm Labor Contractors and Crew Leaders',
  key: 57
} ,
{ value: '0762', label: 'Farm Management Services', key: 58 } ,
{ value: '0781', label: 'Landscape Architectural Services', key: 59 } ,
{ value: '0782', label: 'Landscaping Services', key: 60 } ,
{ value: '0783', label: 'Landscaping Services', key: 61 } ,
{ value: '0811', label: 'Timber Tract Operations', key: 62 } ,
{
  value: '0831',
  label: 'Forest Nurseries and Gathering of Forest Products',
  key: 63
} ,
{ value: '0851', label: 'Support Activities for Forestry', key: 64 } ,
{ value: '0912', label: 'Finfish Fishing', key: 65 } ,
{ value: '0913', label: 'Shellfish Fishing', key: 66 } ,
{ value: '0919', label: 'Other Marine Fishing', key: 67 } ,
{
  value: '0921',
  label: 'Finfish Farming and Fish Hatcheries',
  key: 68
} ,
{ value: '0921', label: 'Shellfish Farming', key: 69 } ,
{ value: '0971', label: 'Hunting and Trapping', key: 70 } ,
{ value: '1011', label: 'Iron Ore Mining', key: 71 } ,
{ value: '1021', label: 'Copper Ore and Nickel Ore Mining', key: 72 } ,
{ value: '1031', label: 'Lead Ore and Zinc Ore Mining', key: 73 } ,
{ value: '1041', label: 'Gold Ore Mining', key: 74 } ,
{ value: '1044', label: 'Silver Ore Mining', key: 75 } ,
{
  value: '1081',
  label: 'Dredging: Gold--Endless Bucket or Ladder Type',
  key: 76
} ,
{ value: '1081', label: 'Site Preparation Contractors', key: 77 } ,
{
  value: '1094',
  label: 'Uranium, Radium, Vanadium Ore Mining',
  key: 78
} ,
{ value: '1099', label: 'All Other Metal Ore Mining', key: 79 } ,
{
  value: '1221',
  label: 'Bituminous Coal and Lignite Surface Mining',
  key: 80
} ,
{ value: '1222', label: 'Bituminous Coal Underground Mining', key: 81 } ,
{ value: '1231', label: 'Anthracite Mining', key: 82 } ,
{ value: '1241', label: 'Support Activities for Coal Mining', key: 83 } ,
{ value: '1311', label: 'Natural Gas Liquid Extraction', key: 84 } ,
{
  value: '1311',
  label: 'Crude Petroleum and Natural Gas Extraction',
  key: 85
} ,
{ value: '1321', label: 'Natural Gas Liquid Extraction', key: 86 } ,
{ value: '1381', label: 'Drilling Oil and Gas Wells', key: 87 } ,
{
  value: '1381',
  label: 'Support Activities for Oil and Gas Operations',
  key: 88
} ,
{
  value: '1382',
  label: 'Geophysical Surveying and Mapping Services',
  key: 89
} ,
{
  value: '1382',
  label: 'Support Activities for Oil and Gas Operations',
  key: 90
} ,
{
  value: '1389',
  label: 'Support Activities for Oil and Gas Operations',
  key: 91
} ,
{ value: '1389', label: 'Natural Gas Liquid Extraction', key: 92 } ,
{
  value: '1411',
  label: 'Dimension Stone Mining and Quarrying',
  key: 93
} ,
{
  value: '1422',
  label: 'Crushed and Broken Limestone Mining and Quarrying',
  key: 94
} ,
{
  value: '1423',
  label: 'Crushed and Broken Granite Mining and Quarrying',
  key: 95
} ,
{
  value: '1429',
  label: 'Other Crushed and Broken Stone Mining and Quarrying',
  key: 96
} ,
{
  value: '1442',
  label: 'Construction Sand and Gravel Mining',
  key: 97
} ,
{ value: '1446', label: 'Industrial Sand Mining', key: 98 } ,
{
  value: '1459',
  label: 'Clay and Ceramic and Refractory Minerals Mining',
  key: 99
} ,
{
  value: '1474',
  label: 'Potash, Soda, and Borate Mineral Mining',
  key: 100
} ,
{ value: '1475', label: 'Phosphate Rock Mining', key: 101 } ,
{
  value: '1481',
  label: 'Support Activities for Nonmetallic Minerals (Except Fuels) Mining',
  key: 102
} ,
{
  value: '1499',
  label: 'All Other Nonmetallic Mineral Mining',
  key: 103
} ,
{ value: '1521', label: 'Residential Remodelers', key: 104 } ,
{
  value: '1521',
  label: 'New Single-Family Housing Construction (Except For-Sale Builders)',
  key: 105
} ,
{
  value: '1522',
  label: 'New Multifamily Housing Construction (Except For-Sale Builders)',
  key: 106
} ,
{ value: '1531', label: 'New Housing For-Sale Builders', key: 107 } ,
{
  value: '1611',
  label: 'Poured Concrete Foundation and Structure Contractors',
  key: 108
} ,
{
  value: '1611',
  label: 'Highway, Street, and Bridge Construction',
  key: 109
} ,
{ value: '1611', label: 'Site Preparation Contractors', key: 110 } ,
{
  value: '1622',
  label: 'Highway, Street, and Bridge Construction',
  key: 111
} ,
{
  value: '1622',
  label: 'Other Heavy and Civil Engineering Construction',
  key: 112
} ,
{
  value: '1623',
  label: 'Power and Communication Line and Related Structures Construction',
  key: 113
} ,
{
  value: '1623',
  label: 'Highway, Street, and Bridge Construction',
  key: 114
} ,
{
  value: '1623',
  label: 'Oil and Gas Pipeline and Related Structures Construction',
  key: 115
} ,
{
  value: '1623',
  label: 'Water and Sewer Line and Related Structures Construction',
  key: 116
} ,
{ value: '1629', label: 'Site Preparation Contractors', key: 117 } ,
{
  value: '1629',
  label: 'Other Heavy and Civil Engineering Construction',
  key: 118
} ,
{
  value: '1629',
  label: 'Poured Concrete Foundation and Structure Contractors',
  key: 119
} ,
{
  value: '1629',
  label: 'Other Building Equipment Contractors',
  key: 120
} ,
{
  value: '1629',
  label: 'Water and Sewer Line and Related Structures Construction',
  key: 121
} ,
{
  value: '1629',
  label: 'Power and Communication Line and Related Structures Construction',
  key: 122
} ,
{
  value: '1711',
  label: 'Plumbing, Heating, and Air Conditioning Contractors',
  key: 123
} ,
{
  value: '1721',
  label: 'Highway, Street, and Bridge Construction',
  key: 124
} ,
{
  value: '1721',
  label: 'Painting and Wall Covering Contractors',
  key: 125
} ,
{
  value: '1731',
  label: 'Electrical Contractors and Other Wiring Installation Contractors',
  key: 126
} ,
{
  value: '1731',
  label: 'Security Systems Services (Except Locksmiths)',
  key: 127
} ,
{ value: '1741', label: 'Masonry Contractors', key: 128 } ,
{
  value: '1742',
  label: 'Drywall and Insulation Contractors',
  key: 129
} ,
{ value: '1743', label: 'Tile and Terrazzo Contractors', key: 130 } ,
{ value: '1751', label: 'Framing Contractors', key: 131 } ,
{ value: '1751', label: 'Finish Carpentry Contractors', key: 132 } ,
{ value: '1752', label: 'Flooring Contractors', key: 133 } ,
{ value: '1761', label: 'Siding Contractors', key: 134 } ,
{
  value: '1761',
  label: 'Other Building Finishing Contractors',
  key: 135
} ,
{
  value: '1761',
  label: 'Other Foundation, Structure, and Building Exterior Contractors',
  key: 136
} ,
{ value: '1761', label: 'Roofing Contractors', key: 137 } ,
{ value: '1771', label: 'Masonry Contractors', key: 138 } ,
{
  value: '1771',
  label: 'Poured Concrete Foundation and Structure Contractors',
  key: 139
} ,
{
  value: '1771',
  label: 'All Other Specialty Trade Contractors',
  key: 140
} ,
{
  value: '1771',
  label: 'Structural Steel and Precast Concrete Contractors',
  key: 141
} ,
{
  value: '1781',
  label: 'Water and Sewer Line and Related Structures Construction',
  key: 142
} ,
{
  value: '1791',
  label: 'Structural Steel and Precast Concrete Contractors',
  key: 143
} ,
{
  value: '1791',
  label: 'Other Foundation, Structure, and Building Exterior Contractors',
  key: 144
} ,
{ value: '1793', label: 'Glass and Glazing Contractors', key: 145 } ,
{ value: '1794', label: 'Site Preparation Contractors', key: 146 } ,
{
  value: '1794',
  label: 'Structural Steel and Precast Concrete Contractors',
  key: 147
} ,
{ value: '1795', label: 'Site Preparation Contractors', key: 148 } ,
{
  value: '1796',
  label: 'Other Building Equipment Contractors',
  key: 149
} ,
{
  value: '1796',
  label: 'Other Building Finishing Contractors',
  key: 150
} ,
{
  value: '1796',
  label: 'All Other Specialty Trade Contractors',
  key: 151
} ,
{ value: '1796', label: 'Building Equipment Contractors', key: 152 } ,
{
  value: '1796',
  label: 'Other Foundation, Structure, and Building Exterior Contractors',
  key: 153
} ,
{
  value: '1799',
  label: 'Other Building Finishing Contractors',
  key: 154
} ,
{
  value: '1799',
  label: 'Other Heavy and Civil Engineering Construction',
  key: 155
} ,
{
  value: '1799',
  label: 'Other Foundation, Structure, and Building Exterior Contractors',
  key: 156
} ,
{
  value: '1799',
  label: 'All Other Specialty Trade Contractors',
  key: 157
} ,
{
  value: '1799',
  label: 'Commercial and Institutional Building Construction',
  key: 158
} ,
{ value: '1799', label: 'Remediation Services', key: 159 } ,
{
  value: '2011',
  label: 'Animal (Except Poultry) Slaughtering',
  key: 160
} ,
{ value: '2013', label: 'Meat Processed from Carcasses', key: 161 } ,
{ value: '2015', label: 'Poultry Processing', key: 162 } ,
{ value: '2021', label: 'Creamery Butter Mfg.', key: 163 } ,
{ value: '2022', label: 'Cheese Mfg.', key: 164 } ,
{
  value: '2023',
  label: 'Dry, Condensed, and Evaporated Dairy Product Mfg.',
  key: 165
} ,
{ value: '2024', label: 'Ice Cream and Frozen Dessert Mfg.', key: 166 } ,
{ value: '2026', label: 'Fluid Milk Mfg.', key: 167 } ,
{ value: '2032', label: 'All Other Misc. Food Mfg.', key: 168 } ,
{ value: '2032', label: 'Specialty Canning', key: 169 } ,
{
  value: '2032',
  label: 'Seafood Product Preparation and Packaging',
  key: 170
} ,
{ value: '2033', label: 'Fruit and Vegetable Canning', key: 171 } ,
{ value: '2034', label: 'Dried and Dehydrated Food Mfg.', key: 172 } ,
{
  value: '2034',
  label: 'Frozen Fruit, Juice, and Vegetable Mfg.',
  key: 173
} ,
{ value: '2035', label: 'Fruit and Vegetable Canning', key: 174 } ,
{
  value: '2037',
  label: 'Frozen Fruit, Juice, and Vegetable Mfg.',
  key: 175
} ,
{ value: '2038', label: 'Frozen Specialty Food Mfg.', key: 176 } ,
{ value: '2041', label: 'Flour Milling', key: 177 } ,
{ value: '2043', label: 'Breakfast Cereal Mfg.', key: 178 } ,
{ value: '2044', label: 'Rice Milling', key: 179 } ,
{
  value: '2045',
  label: 'Dry Pasta, Dough, and Flour Mixes Mfg. from Purchased Flour',
  key: 180
} ,
{ value: '2046', label: 'Wet Corn Milling', key: 181 } ,
{ value: '2047', label: 'Dog and Cat Food Mfg.', key: 182 } ,
{ value: '2048', label: 'Other Animal Food Mfg.', key: 183 } ,
{ value: '2051', label: 'Commercial Bakeries', key: 184 } ,
{
  value: '2051',
  label: 'Frozen Cakes, Pies, and Other Pastries Mfg.',
  key: 185
} ,
{ value: '2052', label: 'Cookie and Cracker Mfg.', key: 186 } ,
{ value: '2061', label: 'Cane Sugar Mfg.', key: 187 } ,
{ value: '2062', label: 'Cane Sugar Mfg.', key: 188 } ,
{ value: '2063', label: 'Beet Sugar Mfg.', key: 189 } ,
{ value: '2064', label: 'Nonchocolate Confectionery Mfg.', key: 190 } ,
{
  value: '2066',
  label: 'Chocolate and Confectionery Mfg. from Cacao Beans',
  key: 191
} ,
{
  value: '2066',
  label: 'Confectionery Mfg. from Purchased Chocolate',
  key: 192
} ,
{ value: '2067', label: 'Nonchocolate Confectionery Mfg.', key: 193 } ,
{
  value: '2068',
  label: 'Roasted Nuts and Peanut Butter Mfg.',
  key: 194
} ,
{
  value: '2074',
  label: 'Soybean and Other Oilseed Processing',
  key: 195
} ,
{
  value: '2075',
  label: 'Soybean and Other Oilseed Processing',
  key: 196
} ,
{
  value: '2076',
  label: 'Soybean and Other Oilseed Processing',
  key: 197
} ,
{
  value: '2077',
  label: 'Rendering and Meat Byproduct Processing',
  key: 198
} ,
{
  value: '2079',
  label: 'Fats and Oils Refining and Blending',
  key: 199
} ,
{ value: '2082', label: 'Breweries', key: 200 } ,
{ value: '2083', label: 'Malt Mfg.', key: 201 } ,
{ value: '2084', label: 'Wineries', key: 202 } ,
{ value: '2085', label: 'Distilleries', key: 203 } ,
{ value: '2086', label: 'Soft Drink Mfg.', key: 204 } ,
{ value: '2086', label: 'Bottled Water Mfg.', key: 205 } ,
{
  value: '2087',
  label: 'Flavoring Syrup and Concentrate Mfg.',
  key: 206
} ,
{ value: '2087', label: 'Spice and Extract Mfg.', key: 207 } ,
{ value: '2087', label: 'All Other Misc. Food Mfg.', key: 208 } ,
{
  value: '2091',
  label: 'Seafood Product Preparation and Packaging',
  key: 209
} ,
{
  value: '2092',
  label: 'Seafood Product Preparation and Packaging',
  key: 210
} ,
{ value: '2095', label: 'Coffee and Tea Mfg.', key: 211 } ,
{ value: '2096', label: 'Other Snack Food Mfg.', key: 212 } ,
{ value: '2097', label: 'Ice Mfg.', key: 213 } ,
{
  value: '2098',
  label: 'Dry Pasta, Dough, and Flour Mixes Mfg. from Purchased Flour',
  key: 214
} ,
{ value: '2099', label: 'All Other Misc. Food Mfg.', key: 215 } ,
{
  value: '2099',
  label: 'Roasted Nuts and Peanut Butter Mfg.',
  key: 216
} ,
{
  value: '2099',
  label: 'Mayonnaise, Dressing, and Other Prepared Sauce Mfg.',
  key: 217
} ,
{ value: '2099', label: 'Perishable Prepared Food Mfg.', key: 218 } ,
{ value: '2099', label: 'Spice and Extract Mfg.', key: 219 } ,
{ value: '2099', label: 'Tortilla Mfg.', key: 220 } ,
{ value: '2111', label: 'Tobacco Mfg.', key: 221 } ,
{ value: '2121', label: 'Tobacco Mfg.', key: 222 } ,
{ value: '2131', label: 'Tobacco Mfg.', key: 223 } ,
{ value: '2141', label: 'Tobacco Mfg.', key: 224 } ,
{ value: '2211', label: 'Broadwoven Fabric Mills', key: 225 } ,
{ value: '2221', label: 'Broadwoven Fabric Mills', key: 226 } ,
{ value: '2231', label: 'Broadwoven Fabric Mills', key: 227 } ,
{
  value: '2241',
  label: 'Narrow Fabric Mills and Schiffli Machine Embroidery',
  key: 228
} ,
{ value: '2251', label: 'Hosiery and Sock Mills', key: 229 } ,
{ value: '2252', label: 'Hosiery and Sock Mills', key: 230 } ,
{ value: '2253', label: 'Other Apparel Knitting Mills', key: 231 } ,
{ value: '2254', label: 'Other Apparel Knitting Mills', key: 232 } ,
{ value: '2258', label: 'Knit Fabric Mills', key: 233 } ,
{ value: '2259', label: 'Other Apparel Knitting Mills', key: 234 } ,
{ value: '2259', label: 'Knit Fabric Mills', key: 235 } ,
{
  value: '2269',
  label: 'Textile and Fabric Finishing Mills',
  key: 236
} ,
{ value: '2273', label: 'Carpet and Rug Mills', key: 237 } ,
{ value: '2281', label: 'Fiber, Yarn, and Thread Mills', key: 238 } ,
{
  value: '2281',
  label: 'Textile and Fabric Finishing Mills',
  key: 239
} ,
{ value: '2282', label: 'Fiber, Yarn, and Thread Mills', key: 240 } ,
{ value: '2284', label: 'Fiber, Yarn, and Thread Mills', key: 241 } ,
{
  value: '2284',
  label: 'Textile and Fabric Finishing Mills',
  key: 242
} ,
{ value: '2295', label: 'Fabric Coating Mills', key: 243 } ,
{
  value: '2296',
  label: 'Rope, Cordage, Twine, Tire Cord, and Tire Fabric Mills',
  key: 244
} ,
{
  value: '2298',
  label: 'Rope, Cordage, Twine, Tire Cord, and Tire Fabric Mills',
  key: 245
} ,
{
  value: '2299',
  label: 'All Other Misc. Textile Product Mills',
  key: 246
} ,
{ value: '2299', label: 'Broadwoven Fabric Mills', key: 247 } ,
{ value: '2299', label: 'Nonwoven Fabric Mills', key: 248 } ,
{
  value: '2321',
  label: "Men's and Boys' Cut and Sew Apparel Mfg.",
  key: 249
} ,
{
  value: '2323',
  label: 'Apparel Accessories and Other Apparel Mfg.',
  key: 250
} ,
{
  value: '2326',
  label: "Men's and Boys' Cut and Sew Apparel Mfg.",
  key: 251
} ,
{
  value: '2329',
  label: "Men's and Boys' Cut and Sew Apparel Mfg.",
  key: 252
} ,
{
  value: '2331',
  label: "Women's, Girls', and Infants' Cut and Sew Apparel Mfg.",
  key: 253
} ,
{
  value: '2353',
  label: 'Apparel Accessories and Other Apparel Mfg.',
  key: 254
} ,
{ value: '2371', label: 'Other Cut and Sew Apparel Mfg.', key: 255 } ,
{
  value: '2381',
  label: 'Apparel Accessories and Other Apparel Mfg.',
  key: 256
} ,
{ value: '2389', label: 'Other Cut and Sew Apparel Mfg.', key: 257 } ,
{
  value: '2389',
  label: 'Apparel Accessories and Other Apparel Mfg.',
  key: 258
} ,
{ value: '2391', label: 'Curtain and Linen Mills', key: 259 } ,
{ value: '2392', label: 'Curtain and Linen Mills', key: 260 } ,
{ value: '2393', label: 'Textile Bag and Canvas Mills', key: 261 } ,
{ value: '2394', label: 'Textile Bag and Canvas Mills', key: 262 } ,
{
  value: '2396',
  label: 'Apparel Accessories and Other Apparel Mfg.',
  key: 263
} ,
{ value: '2396', label: 'Commercial Screen Printing', key: 264 } ,
{
  value: '2397',
  label: 'Narrow Fabric Mills and Schiffli Machine Embroidery',
  key: 265
} ,
{
  value: '2399',
  label: 'All Other Misc. Textile Product Mills',
  key: 266
} ,
{ value: '2411', label: 'Logging', key: 267 } ,
{ value: '2421', label: 'Sawmills', key: 268 } ,
{
  value: '2421',
  label: 'Other Millwork (Including Flooring)',
  key: 269
} ,
{ value: '2426', label: 'All Other Misc. Wood Product Mfg.', key: 270 } ,
{ value: '2429', label: 'All Other Misc. Wood Product Mfg.', key: 271 } ,
{ value: '2431', label: 'Wood Window and Door Mfg.', key: 272 } ,
{
  value: '2434',
  label: 'Wood Kitchen Cabinet and Countertop Mfg.',
  key: 273
} ,
{ value: '2435', label: 'Hardwood Veneer and Plywood Mfg.', key: 274 } ,
{ value: '2436', label: 'Softwood Veneer and Plywood Mfg.', key: 275 } ,
{
  value: '2439',
  label: 'Engineered Wood Member (Except Truss) Mfg.',
  key: 276
} ,
{
  value: '2439',
  label: 'Cut Stock, Resawing Lumber, and Planing',
  key: 277
} ,
{ value: '2439', label: 'Truss Mfg.', key: 278 } ,
{ value: '2441', label: 'Wood Container and Pallet Mfg.', key: 279 } ,
{ value: '2448', label: 'Wood Container and Pallet Mfg.', key: 280 } ,
{ value: '2449', label: 'Wood Container and Pallet Mfg.', key: 281 } ,
{ value: '2449', label: 'All Other Misc. Wood Product Mfg.', key: 282 } ,
{
  value: '2451',
  label: 'Manufactured Home (Mobile Home) Mfg.',
  key: 283
} ,
{ value: '2452', label: 'Prefabricated Wood Building Mfg.', key: 284 } ,
{ value: '2491', label: 'Wood Preservation', key: 285 } ,
{ value: '2493', label: 'Reconstituted Wood Product Mfg.', key: 286 } ,
{ value: '2499', label: 'All Other Misc. Wood Product Mfg.', key: 287 } ,
{ value: '2499', label: 'Wood Container and Pallet Mfg.', key: 288 } ,
{ value: '2499', label: 'All Other Misc. Mfg.', key: 289 } ,
{
  value: '2511',
  label: 'Custom Architectural Woodwork and Millwork Mfg.',
  key: 290
} ,
{
  value: '2511',
  label: 'Nonupholstered Wood Household Furniture Mfg.',
  key: 291
} ,
{ value: '2514', label: 'Metal Household Furniture Mfg.', key: 292 } ,
{ value: '2515', label: 'Mattress Mfg.', key: 293 } ,
{
  value: '2517',
  label: 'Nonupholstered Wood Household Furniture Mfg.',
  key: 294
} ,
{
  value: '2519',
  label: 'Household Furniture (Except Wood and Metal) Mfg.',
  key: 295
} ,
{ value: '2521', label: 'Wood Office Furniture Mfg.', key: 296 } ,
{
  value: '2522',
  label: 'Office Furniture (Except Wood) Mfg.',
  key: 297
} ,
{
  value: '2531',
  label: 'Office Supplies (Except Paper) Mfg.',
  key: 298
} ,
{ value: '2591', label: 'Blind and Shade Mfg.', key: 299 } ,
{ value: '2611', label: 'Pulp Mills', key: 300 } ,
{ value: '2621', label: 'Paper (Except Newsprint) Mills', key: 301 } ,
{ value: '2621', label: 'Newsprint Mills', key: 302 } ,
{ value: '2631', label: 'Paperboard Mills', key: 303 } ,
{ value: '2652', label: 'Other Paperboard Container Mfg.', key: 304 } ,
{
  value: '2653',
  label: 'Corrugated and Solid Fiber Box Mfg.',
  key: 305
} ,
{ value: '2655', label: 'Other Paperboard Container Mfg.', key: 306 } ,
{ value: '2656', label: 'Other Paperboard Container Mfg.', key: 307 } ,
{ value: '2657', label: 'Folding Paperboard Box Mfg.', key: 308 } ,
{
  value: '2671',
  label: 'Paper Bag and Coated and Treated Paper Mfg.',
  key: 309
} ,
{
  value: '2671',
  label: 'Plastics Packaging Film and Sheet (Including Laminated) Mfg.',
  key: 310
} ,
{
  value: '2672',
  label: 'Paper Bag and Coated and Treated Paper Mfg.',
  key: 311
} ,
{ value: '2673', label: 'Plastics Bag and Pouch Mfg.', key: 312 } ,
{
  value: '2673',
  label: 'Paper Bag and Coated and Treated Paper Mfg.',
  key: 313
} ,
{
  value: '2674',
  label: 'Paper Bag and Coated and Treated Paper Mfg.',
  key: 314
} ,
{
  value: '2675',
  label: 'Paper Bag and Coated and Treated Paper Mfg.',
  key: 315
} ,
{ value: '2675', label: 'Stationery Product Mfg.', key: 316 } ,
{ value: '2676', label: 'Sanitary Paper Product Mfg.', key: 317 } ,
{ value: '2677', label: 'Stationery Product Mfg.', key: 318 } ,
{ value: '2678', label: 'Stationery Product Mfg.', key: 319 } ,
{
  value: '2679',
  label: 'All Other Converted Paper Product Mfg.',
  key: 320
} ,
{
  value: '2679',
  label: 'Paper Bag and Coated and Treated Paper Mfg.',
  key: 321
} ,
{ value: '2711', label: 'Newspaper Publishers', key: 322 } ,
{ value: '2721', label: 'Periodical Publishers', key: 323 } ,
{ value: '2731', label: 'Book Publishers', key: 324 } ,
{ value: '2732', label: 'Books Printing', key: 325 } ,
{ value: '2741', label: 'All Other Publishers', key: 326 } ,
{
  value: '2752',
  label: 'Commercial Printing (Except Screen and Books)',
  key: 327
} ,
{
  value: '2754',
  label: 'Commercial Printing (Except Screen and Books)',
  key: 328
} ,
{ value: '2754', label: 'Commercial Gravure Printing', key: 329 } ,
{
  value: '2759',
  label: 'Commercial Printing (Except Screen and Books)',
  key: 330
} ,
{
  value: '2761',
  label: 'Commercial Printing (Except Screen and Books)',
  key: 331
} ,
{ value: '2771', label: 'Greeting Card Publishers', key: 332 } ,
{ value: '2782', label: 'Books Printing', key: 333 } ,
{ value: '2789', label: 'Support Activities for Printing', key: 334 } ,
{ value: '2791', label: 'Support Activities for Printing', key: 335 } ,
{
  value: '2812',
  label: 'Other Basic Inorganic Chemical Mfg.',
  key: 336
} ,
{ value: '2813', label: 'Industrial Gas Mfg.', key: 337 } ,
{ value: '2816', label: 'Synthetic Dye and Pigment Mfg.', key: 338 } ,
{
  value: '2819',
  label: 'Alumina Refining and Primary Aluminum Production',
  key: 339
} ,
{
  value: '2819',
  label: 'All Other Misc. Chemical Product and Preparation Mfg.',
  key: 340
} ,
{
  value: '2819',
  label: 'Other Basic Inorganic Chemical Mfg.',
  key: 341
} ,
{ value: '2821', label: 'Plastics Material and Resin Mfg.', key: 342 } ,
{ value: '2822', label: 'Synthetic Rubber Mfg.', key: 343 } ,
{
  value: '2823',
  label: 'Artificial and Synthetic Fibers and Filaments Mfg.',
  key: 344
} ,
{
  value: '2824',
  label: 'Artificial and Synthetic Fibers and Filaments Mfg.',
  key: 345
} ,
{ value: '2833', label: 'Medicinal and Botanical Mfg.', key: 346 } ,
{ value: '2834', label: 'Pharmaceutical Preparation Mfg.', key: 347 } ,
{
  value: '2835',
  label: 'In-Vitro Diagnostic Substance Mfg.',
  key: 348
} ,
{
  value: '2836',
  label: 'Biological Product (Except Diagnostic) Mfg.',
  key: 349
} ,
{ value: '2841', label: 'Soap and Other Detergent Mfg.', key: 350 } ,
{
  value: '2842',
  label: 'Polish and Other Sanitation Good Mfg.',
  key: 351
} ,
{ value: '2843', label: 'Surface Active Agent Mfg.', key: 352 } ,
{ value: '2843', label: 'Nitrogenous Fertilizer Mfg.', key: 353 } ,
{ value: '2844', label: 'Toilet Preparation Mfg.', key: 354 } ,
{ value: '2851', label: 'Paint and Coating Mfg.', key: 355 } ,
{
  value: '2861',
  label: 'Cyclic Crude, Intermediate, and Gum and Wood Chemical Mfg.',
  key: 356
} ,
{
  value: '2865',
  label: 'Cyclic Crude, Intermediate, and Gum and Wood Chemical Mfg.',
  key: 357
} ,
{ value: '2865', label: 'Synthetic Dye and Pigment Mfg.', key: 358 } ,
{ value: '2869', label: 'Ethyl Alcohol Mfg.', key: 359 } ,
{
  value: '2869',
  label: 'All Other Basic Organic Chemical Mfg.',
  key: 360
} ,
{ value: '2873', label: 'Nitrogenous Fertilizer Mfg.', key: 361 } ,
{ value: '2874', label: 'Phosphatic Fertilizer Mfg.', key: 362 } ,
{ value: '2875', label: 'Fertilizer (Mixing Only) Mfg.', key: 363 } ,
{
  value: '2879',
  label: 'Pesticide and Other Agricultural Chemical Mfg.',
  key: 364
} ,
{ value: '2891', label: 'Adhesive Mfg.', key: 365 } ,
{ value: '2892', label: 'Explosives Mfg.', key: 366 } ,
{ value: '2893', label: 'Printing Ink Mfg.', key: 367 } ,
{
  value: '2899',
  label: 'All Other Misc. Chemical Product and Preparation Mfg.',
  key: 368
} ,
{ value: '2899', label: 'Spice and Extract Mfg.', key: 369 } ,
{ value: '2911', label: 'Petroleum Refineries', key: 370 } ,
{
  value: '2951',
  label: 'Asphalt Paving Mixture and Block Mfg.',
  key: 371
} ,
{
  value: '2952',
  label: 'Asphalt Shingle and Coating Materials Mfg.',
  key: 372
} ,
{
  value: '2992',
  label: 'Petroleum Lubricating Oil and Grease Mfg.',
  key: 373
} ,
{
  value: '2999',
  label: 'All Other Petroleum and Coal Products Mfg.',
  key: 374
} ,
{ value: '3011', label: 'Tire Mfg. (Except Retreading)', key: 375 } ,
{ value: '3021', label: 'Footwear Mfg.', key: 376 } ,
{
  value: '3052',
  label: 'Rubber and Plastics Hoses and Belting Mfg.',
  key: 377
} ,
{
  value: '3053',
  label: 'Gasket, Packing, and Sealing Device Mfg.',
  key: 378
} ,
{
  value: '3061',
  label: 'Rubber Product Mfg. for Mechanical Use',
  key: 379
} ,
{ value: '3069', label: 'All Other Rubber Product Mfg.', key: 380 } ,
{ value: '3069', label: 'All Other Plastics Product Mfg.', key: 381 } ,
{
  value: '3081',
  label: 'Unlaminated Plastics Film and Sheet (Except Packaging) Mfg.',
  key: 382
} ,
{
  value: '3082',
  label: 'Unlaminated Plastics Profile Shape Mfg.',
  key: 383
} ,
{
  value: '3083',
  label: 'Laminated Plastics Plate, Sheet (Except Packaging), and Shape Mfg.',
  key: 384
} ,
{
  value: '3084',
  label: 'Plastics Pipe and Pipe Fitting Mfg.',
  key: 385
} ,
{ value: '3085', label: 'Plastics Bottle Mfg.', key: 386 } ,
{
  value: '3086',
  label: 'Urethane and Other Foam Product (Except Polystyrene) Mfg.',
  key: 387
} ,
{
  value: '3086',
  label: 'Plastics Pipe and Pipe Fitting Mfg.',
  key: 388
} ,
{ value: '3086', label: 'Polystyrene Foam Product Mfg.', key: 389 } ,
{
  value: '3087',
  label: 'Custom Compounding of Purchased Resins',
  key: 390
} ,
{ value: '3088', label: 'Plastics Plumbing Fixture Mfg.', key: 391 } ,
{ value: '3089', label: 'All Other Plastics Product Mfg.', key: 392 } ,
{
  value: '3111',
  label: 'Leather and Hide Tanning and Finishing',
  key: 393
} ,
{
  value: '3131',
  label: 'All Other Leather Good and Allied Product Mfg.',
  key: 394
} ,
{ value: '3142', label: 'Footwear Mfg.', key: 395 } ,
{ value: '3143', label: 'Footwear Mfg.', key: 396 } ,
{ value: '3144', label: 'Footwear Mfg.', key: 397 } ,
{ value: '3149', label: 'Footwear Mfg.', key: 398 } ,
{
  value: '3151',
  label: 'Apparel Accessories and Other Apparel Mfg.',
  key: 399
} ,
{
  value: '3161',
  label: 'All Other Leather Good and Allied Product Mfg.',
  key: 400
} ,
{ value: '3171', label: "Women's Handbag and Purse Mfg.", key: 401 } ,
{
  value: '3172',
  label: 'All Other Leather Good and Allied Product Mfg.',
  key: 402
} ,
{
  value: '3199',
  label: 'All Other Leather Good and Allied Product Mfg.',
  key: 403
} ,
{ value: '3211', label: 'Flat Glass Mfg.', key: 404 } ,
{ value: '3221', label: 'Glass Container Mfg.', key: 405 } ,
{
  value: '3229',
  label: 'Other Pressed and Blown Glass and Glassware Mfg.',
  key: 406
} ,
{
  value: '3231',
  label: 'Glass Product Mfg. Made of Purchased Glass',
  key: 407
} ,
{ value: '3241', label: 'Cement Mfg.', key: 408 } ,
{
  value: '3251',
  label: 'Clay Building Material and Refractories Mfg.',
  key: 409
} ,
{
  value: '3253',
  label: 'Clay Building Material and Refractories Mfg.',
  key: 410
} ,
{
  value: '3255',
  label: 'Clay Building Material and Refractories Mfg.',
  key: 411
} ,
{
  value: '3259',
  label: 'Clay Building Material and Refractories Mfg.',
  key: 412
} ,
{
  value: '3261',
  label: 'Pottery, Ceramics, and Plumbing Fixture Mfg.',
  key: 413
} ,
{
  value: '3263',
  label: 'Pottery, Ceramics, and Plumbing Fixture Mfg.',
  key: 414
} ,
{
  value: '3264',
  label: 'Pottery, Ceramics, and Plumbing Fixture Mfg.',
  key: 415
} ,
{
  value: '3269',
  label: 'Pottery, Ceramics, and Plumbing Fixture Mfg.',
  key: 416
} ,
{ value: '3271', label: 'Concrete Block and Brick Mfg.', key: 417 } ,
{ value: '3272', label: 'Concrete Pipe Mfg.', key: 418 } ,
{ value: '3272', label: 'Other Concrete Product Mfg.', key: 419 } ,
{ value: '3273', label: 'Ready-Mix Concrete Mfg.', key: 420 } ,
{ value: '3274', label: 'Lime Mfg.', key: 421 } ,
{ value: '3281', label: 'Cut Stone and Stone Product Mfg.', key: 422 } ,
{ value: '3291', label: 'Abrasive Product Mfg.', key: 423 } ,
{
  value: '3291',
  label: 'All Other Misc. Fabricated Metal Product Mfg.',
  key: 424
} ,
{
  value: '3292',
  label: 'All Other Misc. Nonmetallic Mineral Product Mfg.',
  key: 425
} ,
{
  value: '3295',
  label: 'Ground or Treated Mineral and Earth Mfg.',
  key: 426
} ,
{ value: '3296', label: 'Mineral Wool Mfg.', key: 427 } ,
{
  value: '3297',
  label: 'Clay Building Material and Refractories Mfg.',
  key: 428
} ,
{
  value: '3299',
  label: 'All Other Misc. Nonmetallic Mineral Product Mfg.',
  key: 429
} ,
{ value: '3299', label: 'Gypsum Product Mfg.', key: 430 } ,
{
  value: '3312',
  label: 'All Other Petroleum and Coal Products Mfg.',
  key: 431
} ,
{
  value: '3312',
  label: 'Iron and Steel Mills and Ferroalloy Mfg.',
  key: 432
} ,
{
  value: '3313',
  label: 'Secondary Smelting, Refining, and Alloying of Nonferrous Metal (Except Copper and Aluminum)',
  key: 433
} ,
{
  value: '3313',
  label: 'Iron and Steel Mills and Ferroalloy Mfg.',
  key: 434
} ,
{
  value: '3315',
  label: 'Other Fabricated Wire Product Mfg.',
  key: 435
} ,
{ value: '3315', label: 'Steel Wire Drawing', key: 436 } ,
{ value: '3316', label: 'Rolled Steel Shape Mfg.', key: 437 } ,
{
  value: '3317',
  label: 'Iron and Steel Pipe and Tube Mfg. from Purchased Steel',
  key: 438
} ,
{ value: '3321', label: 'Iron Foundries', key: 439 } ,
{ value: '3322', label: 'Iron Foundries', key: 440 } ,
{ value: '3324', label: 'Steel Investment Foundries', key: 441 } ,
{
  value: '3325',
  label: 'Steel Foundries (Except Investment)',
  key: 442
} ,
{
  value: '3331',
  label: 'Nonferrous Metal (Except Aluminum) Smelting and Refining',
  key: 443
} ,
{
  value: '3334',
  label: 'Alumina Refining and Primary Aluminum Production',
  key: 444
} ,
{
  value: '3339',
  label: 'Nonferrous Metal (Except Aluminum) Smelting and Refining',
  key: 445
} ,
{
  value: '3341',
  label: 'Secondary Smelting and Alloying of Aluminum',
  key: 446
} ,
{
  value: '3351',
  label: 'Copper Rolling, Drawing, Extruding, and Alloying',
  key: 447
} ,
{
  value: '3353',
  label: 'Aluminum Sheet, Plate, and Foil Mfg.',
  key: 448
} ,
{
  value: '3354',
  label: 'Other Aluminum Rolling, Drawing, and Extruding',
  key: 449
} ,
{
  value: '3356',
  label: 'Nonferrous Metal (Except Copper and Aluminum) Rolling, Drawing, and Extruding',
  key: 450
} ,
{
  value: '3357',
  label: 'Nonferrous Metal (Except Copper and Aluminum) Rolling, Drawing, and Extruding',
  key: 451
} ,
{
  value: '3357',
  label: 'Copper Rolling, Drawing, Extruding, and Alloying',
  key: 452
} ,
{ value: '3357', label: 'Fiber Optic Cable Mfg.', key: 453 } ,
{
  value: '3357',
  label: 'Other Communication and Energy Wire Mfg.',
  key: 454
} ,
{ value: '3363', label: 'Aluminum Die-Casting Foundries', key: 455 } ,
{
  value: '3364',
  label: 'Nonferrous Metal Die-Casting Foundries',
  key: 456
} ,
{
  value: '3365',
  label: 'Aluminum Foundries (Except Die-Casting)',
  key: 457
} ,
{
  value: '3366',
  label: 'Other Nonferrous Metal Foundries (Except Die-Casting)',
  key: 458
} ,
{
  value: '3369',
  label: 'Other Nonferrous Metal Foundries (Except Die-Casting)',
  key: 459
} ,
{ value: '3398', label: 'Metal Heat Treating', key: 460 } ,
{ value: '3411', label: 'Metal Can Mfg.', key: 461 } ,
{ value: '3412', label: 'Other Metal Container Mfg.', key: 462 } ,
{
  value: '3421',
  label: 'Metal Kitchen Cookware, Utensil, Cutlery, and Flatware (Except Precious) Mfg.',
  key: 463
} ,
{ value: '3421', label: 'Saw Blade and Handtool Mfg.', key: 464 } ,
{ value: '3423', label: 'Saw Blade and Handtool Mfg.', key: 465 } ,
{ value: '3425', label: 'Saw Blade and Handtool Mfg.', key: 466 } ,
{ value: '3429', label: 'Hardware Mfg.', key: 467 } ,
{
  value: '3431',
  label: 'All Other Misc. Fabricated Metal Product Mfg.',
  key: 468
} ,
{
  value: '3432',
  label: 'Plumbing Fixture Fitting and Trim Mfg.',
  key: 469
} ,
{
  value: '3433',
  label: 'Heating Equipment (Except Warm Air Furnaces) Mfg.',
  key: 470
} ,
{ value: '3441', label: 'Fabricated Structural Metal Mfg.', key: 471 } ,
{ value: '3442', label: 'Metal Window and Door Mfg.', key: 472 } ,
{ value: '3443', label: 'Plate Work Mfg.', key: 473 } ,
{
  value: '3443',
  label: 'Power Boiler and Heat Exchanger Mfg.',
  key: 474
} ,
{ value: '3443', label: 'Metal Tank (Heavy Gauge) Mfg.', key: 475 } ,
{ value: '3444', label: 'Sheet Metal Work Mfg.', key: 476 } ,
{
  value: '3446',
  label: 'Ornamental and Architectural Metal Work Mfg.',
  key: 477
} ,
{
  value: '3448',
  label: 'Prefabricated Metal Building and Component Mfg.',
  key: 478
} ,
{ value: '3449', label: 'Fabricated Structural Metal Mfg.', key: 479 } ,
{ value: '3449', label: 'Custom Roll Forming', key: 480 } ,
{ value: '3451', label: 'Precision Turned Product Mfg.', key: 481 } ,
{
  value: '3452',
  label: 'Bolt, Nut, Screw, Rivet, and Washer Mfg.',
  key: 482
} ,
{ value: '3462', label: 'Iron and Steel Forging', key: 483 } ,
{ value: '3463', label: 'Nonferrous Forging', key: 484 } ,
{ value: '3465', label: 'Motor Vehicle Metal Stamping', key: 485 } ,
{
  value: '3466',
  label: 'Metal Crown, Closure, and Other Metal Stamping (Except Automotive)',
  key: 486
} ,
{
  value: '3469',
  label: 'Metal Kitchen Cookware, Utensil, Cutlery, and Flatware (Except Precious) Mfg.',
  key: 487
} ,
{
  value: '3469',
  label: 'Metal Crown, Closure, and Other Metal Stamping (Except Automotive)',
  key: 488
} ,
{ value: '3469', label: 'Powder Metallurgy Part Mfg.', key: 489 } ,
{
  value: '3471',
  label: 'Electroplating, Plating, Polishing, Anodizing, and Coloring',
  key: 490
} ,
{
  value: '3479',
  label: 'Metal Coating, Engraving (Except Jewelry and Silverware), and Allied Services to Manufacturers',
  key: 491
} ,
{ value: '3482', label: 'Small Arms Ammunition Mfg.', key: 492 } ,
{
  value: '3483',
  label: 'Ammunition (Except Small Arms) Mfg.',
  key: 493
} ,
{
  value: '3484',
  label: 'Small Arms, Ordnance, and Ordnance Accessories Mfg.',
  key: 494
} ,
{
  value: '3489',
  label: 'Small Arms, Ordnance, and Ordnance Accessories Mfg.',
  key: 495
} ,
{ value: '3491', label: 'Industrial Valve Mfg.', key: 496 } ,
{
  value: '3492',
  label: 'Fluid Power Valve and Hose Fitting Mfg.',
  key: 497
} ,
{ value: '3493', label: 'Spring Mfg.', key: 498 } ,
{ value: '3495', label: 'Spring Mfg.', key: 499 } ,
{
  value: '3495',
  label: 'Other Measuring and Controlling Device Mfg.',
  key: 500
} ,
{
  value: '3497',
  label: 'All Other Misc. Fabricated Metal Product Mfg.',
  key: 501
} ,
{
  value: '3497',
  label: 'Paper Bag and Coated and Treated Paper Mfg.',
  key: 502
} ,
{
  value: '3498',
  label: 'Fabricated Pipe and Pipe Fitting Mfg.',
  key: 503
} ,
{
  value: '3499',
  label: 'All Other Misc. Fabricated Metal Product Mfg.',
  key: 504
} ,
{ value: '3499', label: 'Hardware Mfg.', key: 505 } ,
{
  value: '3511',
  label: 'Turbine and Turbine Generator Set Units Mfg.',
  key: 506
} ,
{ value: '3519', label: 'Other Engine Equipment Mfg.', key: 507 } ,
{ value: '3523', label: 'Farm Machinery and Equipment Mfg.', key: 508 } ,
{
  value: '3524',
  label: 'Lawn and Garden Tractor and Home Lawn and Garden Equipment Mfg.',
  key: 509
} ,
{ value: '3531', label: 'Construction Machinery Mfg.', key: 510 } ,
{
  value: '3532',
  label: 'Mining Machinery and Equipment Mfg.',
  key: 511
} ,
{
  value: '3533',
  label: 'Oil and Gas Field Machinery and Equipment Mfg.',
  key: 512
} ,
{ value: '3534', label: 'Elevator and Moving Stairway Mfg.', key: 513 } ,
{
  value: '3535',
  label: 'Conveyor and Conveying Equipment Mfg.',
  key: 514
} ,
{
  value: '3536',
  label: 'Overhead Traveling Crane, Hoist, and Monorail System Mfg.',
  key: 515
} ,
{
  value: '3537',
  label: 'Industrial Truck, Tractor, Trailer, and Stacker Machinery Mfg.',
  key: 516
} ,
{ value: '3541', label: 'Machine Tool Mfg.', key: 517 } ,
{ value: '3542', label: 'Machine Tool Mfg.', key: 518 } ,
{ value: '3543', label: 'All Other Misc. Wood Product Mfg.', key: 519 } ,
{ value: '3543', label: 'Industrial Pattern Mfg.', key: 520 } ,
{
  value: '3543',
  label: 'All Other Misc. Fabricated Metal Product Mfg.',
  key: 521
} ,
{ value: '3544', label: 'Industrial Mold Mfg.', key: 522 } ,
{ value: '3544', label: 'Saw Blade and Handtool Mfg.', key: 523 } ,
{
  value: '3544',
  label: 'Special Die and Tool, Die Set, Jig, and Fixture Mfg.',
  key: 524
} ,
{
  value: '3545',
  label: 'Cutting Tool and Machine Tool Accessory Mfg.',
  key: 525
} ,
{ value: '3546', label: 'Power-Driven Handtool Mfg.', key: 526 } ,
{
  value: '3547',
  label: 'Rolling Mill and Other Metalworking Machinery Mfg.',
  key: 527
} ,
{
  value: '3549',
  label: 'Rolling Mill and Other Metalworking Machinery Mfg.',
  key: 528
} ,
{ value: '3552', label: 'Other Industrial Machinery Mfg.', key: 529 } ,
{
  value: '3553',
  label: 'Sawmill, Woodworking, and Paper Machinery Mfg.',
  key: 530
} ,
{
  value: '3554',
  label: 'Sawmill, Woodworking, and Paper Machinery Mfg.',
  key: 531
} ,
{
  value: '3555',
  label: 'Printing Machinery and Equipment Mfg.',
  key: 532
} ,
{ value: '3556', label: 'Food Product Machinery Mfg.', key: 533 } ,
{ value: '3559', label: 'Other Industrial Machinery Mfg.', key: 534 } ,
{ value: '3559', label: 'Semiconductor Machinery Mfg.', key: 535 } ,
{ value: '3561', label: 'Pump and Pumping Equipment Mfg.', key: 536 } ,
{ value: '3562', label: 'Ball and Roller Bearing Mfg.', key: 537 } ,
{ value: '3563', label: 'Air and Gas Compressor Mfg.', key: 538 } ,
{
  value: '3564',
  label: 'Industrial and Commercial Fan and Blower and Air Purification Equipment Mfg.',
  key: 539
} ,
{ value: '3565', label: 'Packaging Machinery Mfg.', key: 540 } ,
{
  value: '3566',
  label: 'Speed Changer, Industrial High-Speed Drive, and Gear Mfg.',
  key: 541
} ,
{
  value: '3567',
  label: 'Industrial Process Furnace and Oven Mfg.',
  key: 542
} ,
{
  value: '3568',
  label: 'Mechanical Power Transmission Equipment Mfg.',
  key: 543
} ,
{ value: '3571', label: 'Electronic Computer Mfg.', key: 544 } ,
{ value: '3572', label: 'Computer Storage Device Mfg.', key: 545 } ,
{
  value: '3575',
  label: 'Computer Terminal and Other Computer Peripheral Equipment Mfg.',
  key: 546
} ,
{
  value: '3579',
  label: 'Computer and Peripheral Equipment Mfg.',
  key: 547
} ,
{
  value: '3581',
  label: 'Other Commercial and Service Industry Machinery Mfg.',
  key: 548
} ,
{
  value: '3582',
  label: 'Other Commercial and Service Industry Machinery Mfg.',
  key: 549
} ,
{
  value: '3585',
  label: 'Air Conditioning and Warm Air Heating Equipment and Commercial and Industrial Refrigeration Equipment Mfg.',
  key: 550
} ,
{ value: '3585', label: 'Other Motor Vehicle Parts Mfg.', key: 551 } ,
{
  value: '3586',
  label: 'Measuring and Dispensing Pump Mfg.',
  key: 552
} ,
{
  value: '3589',
  label: 'Other Commercial and Service Industry Machinery Mfg.',
  key: 553
} ,
{
  value: '3592',
  label: 'Motor Vehicle Gasoline Engine and Engine Parts Mfg.',
  key: 554
} ,
{
  value: '3593',
  label: 'Fluid Power Cylinder and Actuator Mfg.',
  key: 555
} ,
{ value: '3594', label: 'Fluid Power Pump and Motor Mfg.', key: 556 } ,
{ value: '3596', label: 'Scale and Balance Mfg.', key: 557 } ,
{ value: '3599', label: 'Machine Shops', key: 558 } ,
{
  value: '3612',
  label: 'Power, Distribution, and Specialty Transformer Mfg.',
  key: 559
} ,
{
  value: '3613',
  label: 'Switchgear and Switchboard Apparatus Mfg.',
  key: 560
} ,
{ value: '3621', label: 'Motor and Generator Mfg.', key: 561 } ,
{ value: '3624', label: 'Carbon and Graphite Product Mfg.', key: 562 } ,
{ value: '3625', label: 'Relay and Industrial Control Mfg.', key: 563 } ,
{
  value: '3629',
  label: 'All Other Misc. Electrical Equipment and Component Mfg.',
  key: 564
} ,
{ value: '3631', label: 'Household Cooking Appliance Mfg.', key: 565 } ,
{
  value: '3632',
  label: 'Household Refrigerator and Home Freezer Mfg.',
  key: 566
} ,
{ value: '3633', label: 'Household Laundry Equipment Mfg.', key: 567 } ,
{ value: '3634', label: 'Small Electrical Appliance Mfg.', key: 568 } ,
{
  value: '3635',
  label: 'Software and Other Prerecorded Compact Disc, Tape, and Record Reproducing',
  key: 569
} ,
{
  value: '3639',
  label: 'Other Major Household Appliance Mfg.',
  key: 570
} ,
{ value: '3639', label: 'Other Industrial Machinery Mfg.', key: 571 } ,
{ value: '3641', label: 'Electric Lamp Bulb and Part Mfg.', key: 572 } ,
{
  value: '3643',
  label: 'Current-Carrying Wiring Device Mfg.',
  key: 573
} ,
{
  value: '3644',
  label: 'Noncurrent-Carrying Wiring Device Mfg.',
  key: 574
} ,
{
  value: '3645',
  label: 'Residential Electric Lighting Fixture Mfg.',
  key: 575
} ,
{
  value: '3646',
  label: 'Commercial, Industrial, and Institutional Electric Lighting Fixture Mfg.',
  key: 576
} ,
{
  value: '3647',
  label: 'Motor Vehicle Electrical and Electronic Equipment Mfg.',
  key: 577
} ,
{ value: '3648', label: 'Other Lighting Equipment Mfg.', key: 578 } ,
{ value: '3651', label: 'Audio and Video Equipment Mfg.', key: 579 } ,
{
  value: '3652',
  label: 'Integrated Record Production/Distribution',
  key: 580
} ,
{
  value: '3652',
  label: 'Software and Other Prerecorded Compact Disc, Tape, and Record Reproducing',
  key: 581
} ,
{ value: '3661', label: 'Telephone Apparatus Mfg.', key: 582 } ,
{
  value: '3663',
  label: 'Radio and Television Broadcasting and Wireless Communications Equipment Mfg.',
  key: 583
} ,
{
  value: '3669',
  label: 'Other Communications Equipment Mfg.',
  key: 584
} ,
{ value: '3671', label: 'Other Electronic Component Mfg.', key: 585 } ,
{ value: '3672', label: 'Bare Printed Circuit Board Mfg.', key: 586 } ,
{
  value: '3674',
  label: 'Semiconductor and Related Device Mfg.',
  key: 587
} ,
{
  value: '3675',
  label: 'Capacitor, Resistor, Coil, Transformer, and Other Inductor Mfg.',
  key: 588
} ,
{
  value: '3676',
  label: 'Capacitor, Resistor, Coil, Transformer, and Other Inductor Mfg.',
  key: 589
} ,
{
  value: '3677',
  label: 'Electronic Coil, Transformer, and Other Inductor Mfg.',
  key: 590
} ,
{ value: '3678', label: 'Electronic Connector Mfg.', key: 591 } ,
{ value: '3679', label: 'Other Electronic Component Mfg.', key: 592 } ,
{
  value: '3679',
  label: 'Printed Circuit Assembly (Electronic Assembly) Mfg.',
  key: 593
} ,
{ value: '3691', label: 'Storage Battery Mfg.', key: 594 } ,
{ value: '3692', label: 'Primary Battery Mfg.', key: 595 } ,
{
  value: '3695',
  label: 'Blank Magnetic and Optical Recording Media Mfg.',
  key: 596
} ,
{
  value: '3699',
  label: 'All Other Misc. Electrical Equipment and Component Mfg.',
  key: 597
} ,
{ value: '3711', label: 'Automobile Mfg.', key: 598 } ,
{ value: '3711', label: 'Heavy Duty Truck Mfg.', key: 599 } ,
{
  value: '3711',
  label: 'Light Truck and Utility Vehicle Mfg.',
  key: 600
} ,
{ value: '3711', label: 'Motor Vehicle Body Mfg.', key: 601 } ,
{ value: '3714', label: 'Other Motor Vehicle Parts Mfg.', key: 602 } ,
{ value: '3714', label: 'Motor Vehicle Brake System Mfg.', key: 603 } ,
{
  value: '3714',
  label: 'Motor Vehicle Transmission and Power Train Parts Mfg.',
  key: 604
} ,
{
  value: '3714',
  label: 'Motor Vehicle Steering and Suspension Components (Except Spring) Mfg.',
  key: 605
} ,
{
  value: '3714',
  label: 'Motor Vehicle Gasoline Engine and Engine Parts Mfg.',
  key: 606
} ,
{ value: '3715', label: 'Truck Trailer Mfg.', key: 607 } ,
{ value: '3716', label: 'Motor Home Mfg.', key: 608 } ,
{ value: '3721', label: 'Airplane Mfg.', key: 609 } ,
{
  value: '3724',
  label: 'Aircraft Engine and Engine Parts Mfg.',
  key: 610
} ,
{
  value: '3728',
  label: 'Other Aircraft Parts and Auxiliary Equipment Mfg.',
  key: 611
} ,
{ value: '3731', label: 'Ship Building and Repairing', key: 612 } ,
{ value: '3732', label: 'Boat Building', key: 613 } ,
{ value: '3743', label: 'Railroad Rolling Stock Mfg.', key: 614 } ,
{
  value: '3751',
  label: 'Motorcycle, Bicycle, and Parts Mfg.',
  key: 615
} ,
{
  value: '3761',
  label: 'Guided Missile and Space Vehicle Mfg.',
  key: 616
} ,
{
  value: '3764',
  label: 'Guided Missile and Space Vehicle Propulsion Unit and Propulsion Unit Parts Mfg.',
  key: 617
} ,
{
  value: '3769',
  label: 'Other Guided Missile and Space Vehicle Parts and Auxiliary Equipment Mfg.',
  key: 618
} ,
{ value: '3792', label: 'Travel Trailer and Camper Mfg.', key: 619 } ,
{
  value: '3795',
  label: 'Military Armored Vehicle, Tank, and Tank Component Mfg.',
  key: 620
} ,
{
  value: '3799',
  label: 'All Other Transportation Equipment Mfg.',
  key: 621
} ,
{
  value: '3812',
  label: 'Search, Detection, Navigation, Guidance, Aeronautical, and Nautical System and Instrument Mfg.',
  key: 622
} ,
{
  value: '3822',
  label: 'Automatic Environment Control Mfg. for Residential, Commercial and Appliance Use',
  key: 623
} ,
{
  value: '3823',
  label: 'Instruments and Related Products Mfg. for Measuring, Displaying, and Controlling Industrial Process Variables',
  key: 624
} ,
{
  value: '3824',
  label: 'Totalizing Fluid Meter and Counting Device Mfg.',
  key: 625
} ,
{
  value: '3825',
  label: 'Instrument Mfg. for Measuring and Testing Electricity and Electrical Signals',
  key: 626
} ,
{
  value: '3826',
  label: 'Analytical Laboratory Instrument Mfg.',
  key: 627
} ,
{ value: '3827', label: 'Optical Instrument and Lens Mfg.', key: 628 } ,
{
  value: '3829',
  label: 'Instruments and Related Products Mfg. for Measuring, Displaying, and Controlling Industrial Process Variables',
  key: 629
} ,
{
  value: '3829',
  label: 'Other Measuring and Controlling Device Mfg.',
  key: 630
} ,
{
  value: '3841',
  label: 'Surgical and Medical Instrument Mfg.',
  key: 631
} ,
{
  value: '3842',
  label: 'Surgical Appliance and Supplies Mfg.',
  key: 632
} ,
{ value: '3844', label: 'Irradiation Apparatus Mfg.', key: 633 } ,
{
  value: '3845',
  label: 'Dental Equipment and Supplies Mfg.',
  key: 634
} ,
{
  value: '3845',
  label: 'Electromedical and Electrotherapeutic Apparatus Mfg.',
  key: 635
} ,
{ value: '3851', label: 'Ophthalmic Goods Mfg.', key: 636 } ,
{
  value: '3861',
  label: 'Photographic and Photocopying Equipment Mfg.',
  key: 637
} ,
{
  value: '3861',
  label: 'Photographic Film, Paper, Plate, and Chemical Mfg.',
  key: 638
} ,
{
  value: '3873',
  label: 'Other Measuring and Controlling Device Mfg.',
  key: 639
} ,
{ value: '3911', label: 'Jewelry and Silverware Mfg.', key: 640 } ,
{ value: '3914', label: 'Jewelry and Silverware Mfg.', key: 641 } ,
{ value: '3915', label: 'Jewelry and Silverware Mfg.', key: 642 } ,
{ value: '3931', label: 'Musical Instrument Mfg.', key: 643 } ,
{ value: '3942', label: 'Doll, Toy, and Game Mfg.', key: 644 } ,
{ value: '3944', label: 'Doll, Toy, and Game Mfg.', key: 645 } ,
{
  value: '3944',
  label: 'Motorcycle, Bicycle, and Parts Mfg.',
  key: 646
} ,
{ value: '3949', label: 'Sporting and Athletic Goods Mfg.', key: 647 } ,
{
  value: '3951',
  label: 'Office Supplies (Except Paper) Mfg.',
  key: 648
} ,
{
  value: '3952',
  label: 'Office Supplies (Except Paper) Mfg.',
  key: 649
} ,
{
  value: '3953',
  label: 'Office Supplies (Except Paper) Mfg.',
  key: 650
} ,
{
  value: '3955',
  label: 'Office Supplies (Except Paper) Mfg.',
  key: 651
} ,
{
  value: '3965',
  label: 'Fastener, Button, Needle, and Pin Mfg.',
  key: 652
} ,
{ value: '3991', label: 'Broom, Brush, and Mop Mfg.', key: 653 } ,
{ value: '3993', label: 'Sign Mfg.', key: 654 } ,
{ value: '3995', label: 'Burial Casket Mfg.', key: 655 } ,
{ value: '3996', label: 'All Other Plastics Product Mfg.', key: 656 } ,
{ value: '3999', label: 'All Other Misc. Mfg.', key: 657 } ,
{
  value: '3999',
  label: 'Leather and Hide Tanning and Finishing',
  key: 658
} ,
{ value: '3999', label: 'All Other Misc. Wood Product Mfg.', key: 659 } ,
{
  value: '3999',
  label: 'All Other Misc. Fabricated Metal Product Mfg.',
  key: 660
} ,
{
  value: '3999',
  label: 'All Other Misc. Chemical Product and Preparation Mfg.',
  key: 661
} ,
{ value: '3999', label: 'All Other Plastics Product Mfg.', key: 662 } ,
{ value: '4011', label: 'Line-Haul Railroads', key: 663 } ,
{ value: '4013', label: 'Short Line Railroads', key: 664 } ,
{
  value: '4013',
  label: 'Support Activities for Rail Transportation',
  key: 665
} ,
{
  value: '4111',
  label: 'All Other Transit and Ground Transportation',
  key: 666
} ,
{
  value: '4111',
  label: 'Bus and Other Motor Vehicle Transit Systems',
  key: 667
} ,
{ value: '4111', label: 'Mixed Mode Transit Systems', key: 668 } ,
{ value: '4111', label: 'Commuter Rail Systems', key: 669 } ,
{ value: '4111', label: 'Other Urban Transit Systems', key: 670 } ,
{ value: '4119', label: 'Limousine Service', key: 671 } ,
{ value: '4119', label: 'Special Needs Transportation', key: 672 } ,
{ value: '4119', label: 'Ambulance Services', key: 673 } ,
{ value: '4121', label: 'Taxi Service', key: 674 } ,
{
  value: '4131',
  label: 'Interurban and Rural Bus Transportation',
  key: 675
} ,
{
  value: '4151',
  label: 'School and Employee Bus Transportation',
  key: 676
} ,
{
  value: '4212',
  label: 'Specialized Freight (Except Used Goods) Trucking, Local',
  key: 677
} ,
{ value: '4212', label: 'Other Waste Collection', key: 678 } ,
{ value: '4212', label: 'General Freight Trucking, Local', key: 679 } ,
{ value: '4212', label: 'Hazardous Waste Collection', key: 680 } ,
{
  value: '4213',
  label: 'Specialized Freight (Except Used Goods) Trucking, Long Distance',
  key: 681
} ,
{
  value: '4213',
  label: 'General Freight Trucking, Long-Distance, Truckload',
  key: 682
} ,
{
  value: '4213',
  label: 'Specialized Freight (Except Used Goods) Trucking, Long-Distance',
  key: 683
} ,
{
  value: '4213',
  label: 'General Freight Trucking, Long Distance, Less Than Truckload',
  key: 684
} ,
{
  value: '4213',
  label: 'Used Household and Office Goods Moving',
  key: 685
} ,
{
  value: '4215',
  label: 'Local Messengers and Local Delivery',
  key: 686
} ,
{
  value: '4215',
  label: 'Courier and Express Delivery Services',
  key: 687
} ,
{
  value: '4221',
  label: 'Farm Product Warehousing and Storage',
  key: 688
} ,
{
  value: '4222',
  label: 'Refrigerated Warehousing and Storage',
  key: 689
} ,
{ value: '4225', label: 'General Warehousing and Storage', key: 690 } ,
{
  value: '4225',
  label: 'Lessors of Miniwarehouses and Self-Storage Units',
  key: 691
} ,
{ value: '4226', label: 'Other Warehousing and Storage', key: 692 } ,
{ value: '4412', label: 'Deep Sea Freight Transportation', key: 693 } ,
{
  value: '4449',
  label: 'Inland Water Freight Transportation',
  key: 694
} ,
{ value: '4481', label: 'Deep Sea Passenger Transportation', key: 695 } ,
{
  value: '4482',
  label: 'Coastal and Great Lakes Passenger Transportation',
  key: 696
} ,
{
  value: '4489',
  label: 'Inland Water Passenger Transportation',
  key: 697
} ,
{
  value: '4489',
  label: 'Scenic and Sightseeing Transportation, Water',
  key: 698
} ,
{ value: '4491', label: 'Marine Cargo Handling', key: 699 } ,
{ value: '4491', label: 'Port and Harbor Operations', key: 700 } ,
{ value: '4493', label: 'Marinas', key: 701 } ,
{
  value: '4499',
  label: 'Other Support Activities for Water Transportation',
  key: 702
} ,
{ value: '4499', label: 'Navigational Services to Shipping', key: 703 } ,
{
  value: '4499',
  label: 'Commercial Air, Rail, and Water Transportation Equipment Rental and Leasing',
  key: 704
} ,
{
  value: '4512',
  label: 'Scheduled Passenger Air Transportation',
  key: 705
} ,
{
  value: '4512',
  label: 'Scheduled Freight Air Transportation',
  key: 706
} ,
{
  value: '4522',
  label: 'Scenic and Sightseeing Transportation, Other',
  key: 707
} ,
{
  value: '4522',
  label: 'Nonscheduled Chartered Freight Air Transportation',
  key: 708
} ,
{
  value: '4522',
  label: 'Nonscheduled Chartered Passenger Air Transportation',
  key: 709
} ,
{
  value: '4581',
  label: 'Other Support Activities for Air Transportation',
  key: 710
} ,
{ value: '4581', label: 'Other Airport Operations', key: 711 } ,
{
  value: '4612',
  label: 'Pipeline Transportation of Crude Oil',
  key: 712
} ,
{
  value: '4613',
  label: 'Pipeline Transportation of Refined Petroleum Products',
  key: 713
} ,
{ value: '4619', label: 'All Other Pipeline Transportation', key: 714 } ,
{ value: '4724', label: 'Travel Agencies', key: 715 } ,
{ value: '4725', label: 'Tour Operators', key: 716 } ,
{
  value: '4729',
  label: 'All Other Travel Arrangement and Reservation Services',
  key: 717
} ,
{
  value: '4731',
  label: 'Freight Transportation Arrangement',
  key: 718
} ,
{ value: '4783', label: 'Packing and Crating', key: 719 } ,
{
  value: '4785',
  label: 'Other Support Activities for Water Transportation',
  key: 720
} ,
{
  value: '4785',
  label: 'Other Support Activities for Road Transportation',
  key: 721
} ,
{
  value: '4789',
  label: 'Support Activities for Rail Transportation',
  key: 722
} ,
{
  value: '4789',
  label: 'All Other Support Activities for Transportation',
  key: 723
} ,
{
  value: '4812',
  label: 'Wireless Telecommunications Carriers (Except Satellite)',
  key: 724
} ,
{ value: '4813', label: 'Telecommunications Resellers', key: 725 } ,
{ value: '4813', label: 'Wired Telecommunications Carriers', key: 726 } ,
{ value: '4822', label: 'Wired Telecommunications Carriers', key: 727 } ,
{ value: '4832', label: 'Radio Stations', key: 728 } ,
{ value: '4833', label: 'Television Broadcasting', key: 729 } ,
{
  value: '4841',
  label: 'Cable and Other Subscription Programming',
  key: 730
} ,
{ value: '4899', label: 'Satellite Telecommunications', key: 731 } ,
{ value: '4899', label: 'Telecommunications NOC', key: 732 } ,
{ value: '4899', label: 'All Other Telecommunications', key: 733 } ,
{ value: '4911', label: 'Electric Power Distribution', key: 734 } ,
{
  value: '4922',
  label: 'Pipeline Transportation of Natural Gas',
  key: 735
} ,
{ value: '4924', label: 'Natural Gas Distribution', key: 736 } ,
{
  value: '4941',
  label: 'Water Supply and Irrigation Systems',
  key: 737
} ,
{ value: '4952', label: 'Sewage Treatment Facilities', key: 738 } ,
{
  value: '4953',
  label: 'Hazardous Waste Treatment and Disposal',
  key: 739
} ,
{
  value: '4953',
  label: 'Other Nonhazardous Waste Treatment and Disposal',
  key: 740
} ,
{
  value: '4953',
  label: 'Solid Waste Combustors and Incinerators',
  key: 741
} ,
{ value: '4953', label: 'Solid Waste Landfill', key: 742 } ,
{ value: '4953', label: 'Materials Recovery Facilities', key: 743 } ,
{ value: '4953', label: 'Solid Waste Collection', key: 744 } ,
{
  value: '4959',
  label: 'Exterminating and Pest Control Services',
  key: 745
} ,
{
  value: '4959',
  label: 'All Other Misc. Waste Management Services',
  key: 746
} ,
{ value: '4961', label: 'Steam and Air Conditioning Supply', key: 747 } ,
{
  value: '4971',
  label: 'Water Supply and Irrigation Systems',
  key: 748
} ,
{
  value: '5012',
  label: 'Automobile and Other Motor Vehicle Merchant Wholesalers',
  key: 749
} ,
{
  value: '5013',
  label: 'Motor Vehicle Supplies and New Parts Merchant Wholesalers',
  key: 750
} ,
{
  value: '5014',
  label: 'Tire and Tube Merchant Wholesalers',
  key: 751
} ,
{
  value: '5015',
  label: 'Motor Vehicle Parts (Used) Merchant Wholesalers',
  key: 752
} ,
{ value: '5021', label: 'Furniture Merchant Wholesalers', key: 753 } ,
{
  value: '5023',
  label: 'Home Furnishing Merchant Wholesalers',
  key: 754
} ,
{
  value: '5031',
  label: 'Lumber, Plywood, Millwork, and Wood Panel Merchant Wholesalers',
  key: 755
} ,
{
  value: '5032',
  label: 'Brick, Stone, and Related Construction Material Merchant Wholesalers',
  key: 756
} ,
{ value: '5032', label: 'Other Building Material Dealers', key: 757 } ,
{
  value: '5033',
  label: 'Roofing, Siding, and Insulation Material Merchant Wholesalers',
  key: 758
} ,
{
  value: '5039',
  label: 'Lumber, Plywood, Millwork, and Wood Panel Merchant Wholesalers',
  key: 759
} ,
{
  value: '5039',
  label: 'Other Construction Material Merchant Wholesalers',
  key: 760
} ,
{
  value: '5043',
  label: 'Photographic Equipment and Supplies Merchant Wholesalers',
  key: 761
} ,
{
  value: '5044',
  label: 'Office Equipment Merchant Wholesalers',
  key: 762
} ,
{ value: '5045', label: 'Electronics Stores', key: 763 } ,
{
  value: '5045',
  label: 'Computer and Computer Peripheral Equipment And Saftware Merchant Wholesalers',
  key: 764
} ,
{
  value: '5046',
  label: 'Other Commercial Equipment Merchant Wholesalers',
  key: 765
} ,
{
  value: '5047',
  label: 'Medical, Dental, and Hospital Equipment and Supplies Merchant Wholesalers',
  key: 766
} ,
{
  value: '5048',
  label: 'Ophthalmic Goods Merchant Wholesalers',
  key: 767
} ,
{
  value: '5049',
  label: 'Other Professional Equipment and Supplies Merchant Wholesalers',
  key: 768
} ,
{
  value: '5051',
  label: 'Metal Service Centers and Other Metal Merchant Wholesalers',
  key: 769
} ,
{
  value: '5052',
  label: 'Coal and Other Mineral and Ore Merchant Wholesalers',
  key: 770
} ,
{
  value: '5063',
  label: 'Electrical Apparatus and Equipment, Wiring Supplies, and Related Equipment Merchant Wholesalers',
  key: 771
} ,
{ value: '5063', label: 'Other Building Material Dealers', key: 772 } ,
{
  value: '5064',
  label: 'Household Appliances, Electric Housewares, and Consumer Electronics Merchant Wholesalers',
  key: 773
} ,
{
  value: '5065',
  label: 'Other Electronic Parts and Equipment Merchant Wholesalers',
  key: 774
} ,
{ value: '5072', label: 'Hardware Merchant Wholesalers', key: 775 } ,
{ value: '5074', label: 'Other Building Material Dealers', key: 776 } ,
{
  value: '5074',
  label: 'Plumbing and Heating Equipment and Supplies (Hydronics) Merchant Wholesalers',
  key: 777
} ,
{
  value: '5075',
  label: 'Warm Air Heating and Air-Conditioning Equipment and Supplies Merchant Wholesalers',
  key: 778
} ,
{
  value: '5078',
  label: 'Refrigeration Equipment and Supplies Merchant Wholesalers',
  key: 779
} ,
{
  value: '5082',
  label: 'Construction and Mining (Except Oil Well) Machinery and Equipment Merchant Wholesalers',
  key: 780
} ,
{
  value: '5083',
  label: 'Farm and Garden Machinery and Equipment Merchant Wholesalers',
  key: 781
} ,
{
  value: '5084',
  label: 'Industrial Machinery and Equipment Merchant Wholesalers',
  key: 782
} ,
{
  value: '5085',
  label: 'Industrial Supplies Merchant Wholesalers',
  key: 783
} ,
{
  value: '5087',
  label: 'Service Establishment Equipment and Supplies Merchant Wholesalers',
  key: 784
} ,
{
  value: '5088',
  label: 'Transportation Equipment and Supplies (Except Motor Vehicles) Merchant Wholesalers',
  key: 785
} ,
{
  value: '5091',
  label: 'Sporting and Recreational Goods and Supplies Merchant Wholesalers',
  key: 786
} ,
{
  value: '5092',
  label: 'Toy and Hobby Goods and Supplies Merchant Wholesalers',
  key: 787
} ,
{
  value: '5093',
  label: 'Recyclable Material Merchant Wholesalers',
  key: 788
} ,
{
  value: '5094',
  label: 'Jewelry, Watch, Precious Stone, and Precious Metal Merchant Wholesalers',
  key: 789
} ,
{
  value: '5099',
  label: 'Other Misc. Durable Goods Merchant Wholesalers',
  key: 790
} ,
{
  value: '5111',
  label: 'Printing and Writing Paper Merchant Wholesalers',
  key: 791
} ,
{
  value: '5112',
  label: 'Office Supplies and Stationery Stores',
  key: 792
} ,
{
  value: '5112',
  label: 'Stationery and Office Supplies Merchant Wholesalers',
  key: 793
} ,
{
  value: '5113',
  label: 'Industrial and Personal Service Paper Merchant Wholesalers',
  key: 794
} ,
{
  value: '5122',
  label: "Drugs and Druggists' Sundries Merchant Wholesalers",
  key: 795
} ,
{
  value: '5122',
  label: 'Drugs and Druggists Sundries Merchant Wholesalers',
  key: 796
} ,
{
  value: '5131',
  label: 'Piece Goods, Notions, and Other Dry Goods Merchant Wholesalers',
  key: 797
} ,
{
  value: '5136',
  label: 'Mens and Boys Clothing and Furnishings Merchant Wholesalers',
  key: 798
} ,
{
  value: '5137',
  label: 'Womens, Childrens, and Infants Clothing and Accessories Merchant Wholesalers',
  key: 799
} ,
{ value: '5139', label: 'Footwear Merchant Wholesalers', key: 800 } ,
{
  value: '5141',
  label: 'General Line Grocery Merchant Wholesalers',
  key: 801
} ,
{
  value: '5142',
  label: 'Packaged Frozen Food Merchant Wholesalers',
  key: 802
} ,
{
  value: '5143',
  label: 'Dairy Product (Except Dried or Canned) Merchant Wholesalers',
  key: 803
} ,
{
  value: '5144',
  label: 'Poultry and Poultry Product Merchant Wholesalers',
  key: 804
} ,
{
  value: '5145',
  label: 'Confectionery Merchant Wholesalers',
  key: 805
} ,
{
  value: '5146',
  label: 'Fish and Seafood Merchant Wholesalers',
  key: 806
} ,
{
  value: '5147',
  label: 'Meat and Meat Product Merchant Wholesalers',
  key: 807
} ,
{
  value: '5148',
  label: 'Fresh Fruit and Vegetable Merchant Wholesalers',
  key: 808
} ,
{
  value: '5149',
  label: 'Other Grocery and Related Products Merchant Wholesalers',
  key: 809
} ,
{
  value: '5153',
  label: 'Grain and Field Bean Merchant Wholesalers',
  key: 810
} ,
{ value: '5154', label: 'Livestock Merchant Wholesalers', key: 811 } ,
{
  value: '5159',
  label: 'Other Farm Product Raw Material Merchant Wholesalers',
  key: 812
} ,
{
  value: '5162',
  label: 'Plastics Materials and Basic Forms and Shapes Merchant Wholesalers',
  key: 813
} ,
{
  value: '5169',
  label: 'Other Chemical and Allied Products Merchant Wholesalers',
  key: 814
} ,
{ value: '5171', label: 'Fuel Dealers', key: 815 } ,
{
  value: '5171',
  label: 'Petroleum Bulk Stations and Terminals',
  key: 816
} ,
{
  value: '5172',
  label: 'Petroleum and Petroleum Products Merchant Wholesalers (Except Bulk Stations and Terminals)',
  key: 817
} ,
{ value: '5181', label: 'Beer and Ale Merchant Wholesalers', key: 818 } ,
{
  value: '5182',
  label: 'Wine and Distilled Alcoholic Beverage Merchant Wholesalers',
  key: 819
} ,
{
  value: '5191',
  label: 'Farm Supplies Merchant Wholesalers',
  key: 820
} ,
{
  value: '5191',
  label: 'Nursery, Garden Center, and Farm Supply Stores',
  key: 821
} ,
{
  value: '5192',
  label: 'Book, Periodical, and Newspaper Merchant Wholesalers',
  key: 822
} ,
{
  value: '5193',
  label: 'Flower, Nursery Stock, and Florists Supplies Merchant Wholesalers',
  key: 823
} ,
{
  value: '5194',
  label: 'Tobacco and Tobacco Product Merchant Wholesalers',
  key: 824
} ,
{
  value: '5198',
  label: 'Paint, Varnish, and Supplies Merchant Wholesalers',
  key: 825
} ,
{
  value: '5199',
  label: 'Other Misc. Nondurable Goods Merchant Wholesalers',
  key: 826
} ,
{ value: '5211', label: 'Other Building Material Dealers', key: 827 } ,
{ value: '5211', label: 'Home Centers', key: 828 } ,
{ value: '5231', label: 'Other Building Material Dealers', key: 829 } ,
{ value: '5231', label: 'Paint and Wallpaper Stores', key: 830 } ,
{ value: '5251', label: 'Hardware Stores', key: 831 } ,
{
  value: '5261',
  label: 'Farm Supplies Merchant Wholesalers',
  key: 832
} ,
{ value: '5261', label: 'Outdoor Power Equipment Stores', key: 833 } ,
{
  value: '5271',
  label: 'Manufactured (Mobile) Home Dealers',
  key: 834
} ,
{
  value: '5311',
  label: 'Department Stores (Except Discount Department Stores)',
  key: 835
} ,
{ value: '5311', label: 'Discount Department Stores', key: 836 } ,
{
  value: '5331',
  label: 'All Other General Merchandise Stores',
  key: 837
} ,
{
  value: '5399',
  label: 'All Other General Merchandise Stores',
  key: 838
} ,
{ value: '5411', label: 'Convenience Stores', key: 839 } ,
{
  value: '5411',
  label: 'Supermarkets and Other Grocery (Except Convenience) Stores',
  key: 840
} ,
{ value: '5421', label: 'Fish and Seafood Markets', key: 841 } ,
{ value: '5421', label: 'Meat Markets', key: 842 } ,
{ value: '5431', label: 'Fruit and Vegetable Markets', key: 843 } ,
{ value: '5441', label: 'Confectionery and Nut Stores', key: 844 } ,
{ value: '5451', label: 'All Other Specialty Food Stores', key: 845 } ,
{ value: '5461', label: 'Retail Bakeries', key: 846 } ,
{ value: '5461', label: 'Baked Goods Stores', key: 847 } ,
{
  value: '5461',
  label: 'Snack and Nonalcoholic Beverage Bars',
  key: 848
} ,
{ value: '5499', label: 'All Other Specialty Food Stores', key: 849 } ,
{ value: '5499', label: 'Food (Health) Supplement Stores', key: 850 } ,
{ value: '5511', label: 'New Car Dealers', key: 851 } ,
{ value: '5521', label: 'Used Car Dealers', key: 852 } ,
{
  value: '5531',
  label: 'Automotive Parts and Accessories Stores',
  key: 853
} ,
{ value: '5531', label: 'Tire Dealers', key: 854 } ,
{
  value: '5541',
  label: 'Gasoline Stations with Convenience Stores',
  key: 855
} ,
{ value: '5541', label: 'Other Gasoline Stations', key: 856 } ,
{ value: '5551', label: 'Boat Dealers', key: 857 } ,
{ value: '5561', label: 'Recreational Vehicle Dealers', key: 858 } ,
{
  value: '5571',
  label: 'Motorcycle, ATV, and All Other Motor Vehicle Dealers',
  key: 859
} ,
{
  value: '5599',
  label: 'Motorcycle, ATV, and All Other Motor Vehicle Dealers',
  key: 860
} ,
{ value: '5611', label: 'Mens Clothing Stores', key: 861 } ,
{ value: '5621', label: 'Womens Clothing Stores', key: 862 } ,
{
  value: '5641',
  label: 'Childrens and Infants Clothing Stores',
  key: 863
} ,
{ value: '5651', label: 'Family Clothing Stores', key: 864 } ,
{ value: '5661', label: 'Shoe Stores', key: 865 } ,
{ value: '5699', label: 'Other Clothing Stores', key: 866 } ,
{ value: '5699', label: 'Clothing Accessories Stores', key: 867 } ,
{ value: '5712', label: 'Furniture Stores', key: 868 } ,
{ value: '5713', label: 'Floor Covering Stores', key: 869 } ,
{ value: '5714', label: 'Window Treatment Stores', key: 870 } ,
{ value: '5722', label: 'Household Appliance Stores', key: 871 } ,
{ value: '5731', label: 'Electronics Stores', key: 872 } ,
{ value: '5734', label: 'Electronics Stores', key: 873 } ,
{
  value: '5735',
  label: 'Musical Instrument and Supplies Stores',
  key: 874
} ,
{
  value: '5736',
  label: 'Musical Instrument and Supplies Stores',
  key: 875
} ,
{
  value: '5812',
  label: 'Snack and Nonalcoholic Beverage Bars',
  key: 876
} ,
{ value: '5812', label: 'Food Service Contractors', key: 877 } ,
{ value: '5812', label: 'Caterers', key: 878 } ,
{ value: '5812', label: 'Full-Service Restaurants', key: 879 } ,
{ value: '5812', label: 'Limited-Service Restaurants', key: 880 } ,
{
  value: '5812',
  label: 'Cafeterias, Grill Buffets, and Buffets',
  key: 881
} ,
{ value: '5812', label: 'Theater Cos. and Dinner Theaters', key: 882 } ,
{
  value: '5813',
  label: 'Drinking Places (Alcoholic Beverages)',
  key: 883
} ,
{ value: '5912', label: 'Pharmacies and Drug Stores', key: 884 } ,
{ value: '5921', label: 'Beer, Wine, and Liquor Stores', key: 885 } ,
{
  value: '5932',
  label: 'All Other Nondepository Credit Intermediation',
  key: 886
} ,
{ value: '5932', label: 'Used Merchandise Stores', key: 887 } ,
{ value: '5941', label: 'Sporting Goods Stores', key: 888 } ,
{ value: '5942', label: 'Book Stores', key: 889 } ,
{ value: '5944', label: 'Jewelry Stores', key: 890 } ,
{ value: '5945', label: 'Hobby, Toy, and Game Stores', key: 891 } ,
{ value: '5946', label: 'Electronics Stores', key: 892 } ,
{
  value: '5947',
  label: 'Gift, Novelty, and Souvenir Stores',
  key: 893
} ,
{ value: '5948', label: 'Luggage and Leather Goods Stores', key: 894 } ,
{
  value: '5949',
  label: 'Sewing, Needlewoork, and Piece Goods Stores',
  key: 895
} ,
{ value: '5961', label: 'Electronic Auctions', key: 896 } ,
{ value: '5961', label: 'Electronic Shopping', key: 897 } ,
{ value: '5961', label: 'Mail Order Houses', key: 898 } ,
{ value: '5962', label: 'Vending Machine Operators', key: 899 } ,
{
  value: '5963',
  label: 'Other Direct Selling Establishments',
  key: 900
} ,
{ value: '5983', label: 'Fuel Dealers', key: 901 } ,
{ value: '5984', label: 'Fuel Dealers', key: 902 } ,
{ value: '5989', label: 'Fuel Dealers', key: 903 } ,
{ value: '5992', label: 'Florists', key: 904 } ,
{ value: '5993', label: 'Tobacco Stores', key: 905 } ,
{ value: '5994', label: 'News Dealers and Newsstands', key: 906 } ,
{ value: '5995', label: 'Optical Goods Stores', key: 907 } ,
{
  value: '5999',
  label: 'All Other Health and Personal Care Stores',
  key: 908
} ,
{ value: '5999', label: 'Art Dealers', key: 909 } ,
{
  value: '5999',
  label: 'All Other Misc. Store Retailers (Except Tobacco Stores)',
  key: 910
} ,
{
  value: '5999',
  label: 'Cosmetics, Beauty Supplies, and Perfume Stores',
  key: 911
} ,
{ value: '5999', label: 'Pet and Pet Supplies Stores', key: 912 } ,
{
  value: '5999',
  label: 'Other Grocery and Related Products Merchant Wholesalers',
  key: 913
} ,
{
  value: '6011',
  label: 'Monetary Authorities--Central Bank',
  key: 914
} ,
{ value: '6022', label: 'Depository Credit Intermediation', key: 915 } ,
{ value: '6029', label: 'Commercial Banking', key: 916 } ,
{ value: '6061', label: 'Credit Unions', key: 917 } ,
{
  value: '6099',
  label: 'Other Activities Related to Credit Intermediation',
  key: 918
} ,
{ value: '6141', label: 'Consumer Lending', key: 919 } ,
{ value: '6141', label: 'Credit Card Issuing', key: 920 } ,
{ value: '6162', label: 'Real Estate Credit', key: 921 } ,
{
  value: '6163',
  label: 'Mortgage and Nonmortgage Loan Brokers',
  key: 922
} ,
{ value: '6211', label: 'Securities Brokerage', key: 923 } ,
{
  value: '6211',
  label: 'Investment Banking and Securities Dealing',
  key: 924
} ,
{ value: '6221', label: 'Commodity Contracts Brokerage', key: 925 } ,
{
  value: '6231',
  label: 'Securities and Commodity Exchanges',
  key: 926
} ,
{ value: '6282', label: 'Investment Advice', key: 927 } ,
{ value: '6311', label: 'Direct Life Insurance Carriers', key: 928 } ,
{
  value: '6331',
  label: 'Direct Property and Casualty Insurance Carriers',
  key: 929
} ,
{ value: '6361', label: 'Direct Title Insurance Carriers', key: 930 } ,
{ value: '6371', label: 'Health and Welfare Funds', key: 931 } ,
{ value: '6371', label: 'Pension Funds', key: 932 } ,
{ value: '6411', label: 'Claims Adjusting', key: 933 } ,
{ value: '6411', label: 'Insurance Agencies and Brokerages', key: 934 } ,
{ value: '6411', label: 'Insurance Agencies & Brokerages', key: 935 } ,
{
  value: '6411',
  label: 'All Other Insurance Related Activities',
  key: 936
} ,
{
  value: '6512',
  label: 'Lessors of Nonresidential Buildings (Except Miniwarehouses)',
  key: 937
} ,
{
  value: '6513',
  label: 'Lessors of Residential Buildings and Dwellings',
  key: 938
} ,
{
  value: '6514',
  label: 'Lessors of Residential Buildings and Dwellings',
  key: 939
} ,
{
  value: '6515',
  label: 'Lessors of Other Real Estate Property',
  key: 940
} ,
{
  value: '6519',
  label: 'Lessors of Other Real Estate Property',
  key: 941
} ,
{
  value: '6531',
  label: 'Other Activities Related to Real Estate',
  key: 942
} ,
{
  value: '6531',
  label: 'Offices of Real Estate Agents and Brokerages',
  key: 943
} ,
{ value: '6531', label: 'Offices of Real Estate Appraisers', key: 944 } ,
{ value: '6531', label: 'Residential Property Managers', key: 945 } ,
{ value: '6531', label: 'Nonresidential Property Managers', key: 946 } ,
{
  value: '6541',
  label: 'Title Abstract and Settlement Offices',
  key: 947
} ,
{ value: '6552', label: 'Land Subdivision', key: 948 } ,
{ value: '6553', label: 'Cemeteries and Crematories', key: 949 } ,
{ value: '6712', label: 'Offices of Bank Holding Cos.', key: 950 } ,
{ value: '6719', label: 'Offices of Other Holding Cos.', key: 951 } ,
{ value: '6722', label: 'Open-End Investment Funds', key: 952 } ,
{ value: '6732', label: 'Grantmaking Foundations', key: 953 } ,
{
  value: '6733',
  label: 'Trusts, Estates, and Agency Accounts',
  key: 954
} ,
{ value: '7011', label: 'Casino Hotels', key: 955 } ,
{ value: '7011', label: 'Bed-and-Breakfast Inns', key: 956 } ,
{
  value: '7011',
  label: 'Hotels (Except Casino Hotels) and Motels',
  key: 957
} ,
{ value: '7011', label: 'All Other Traveler Accommodations', key: 958 } ,
{ value: '7021', label: 'Rooming and Boarding Houses', key: 959 } ,
{
  value: '7032',
  label: 'Recreational and Vacation Camps (Except Campgrounds)',
  key: 960
} ,
{
  value: '7033',
  label: 'RV (Recreational Vehicle) Parks and Campgrounds',
  key: 961
} ,
{ value: '7048', label: 'Other Spectator Sports', key: 962 } ,
{
  value: '7211',
  label: 'Drycleaning and Laundry Services (Except Coin-Operated)',
  key: 963
} ,
{ value: '7213', label: 'Linen Supply', key: 964 } ,
{
  value: '7215',
  label: 'Coin-Operated Laundries and Drycleaners',
  key: 965
} ,
{
  value: '7216',
  label: 'Drycleaning and Laundry Services (Except Coin-Operated)',
  key: 966
} ,
{
  value: '7217',
  label: 'Carpet and Upholstery Cleaning Services',
  key: 967
} ,
{ value: '7218', label: 'Industrial Launderers', key: 968 } ,
{ value: '7219', label: 'Linen Supply', key: 969 } ,
{ value: '7221', label: 'Photography Studios, Portrait', key: 970 } ,
{ value: '7231', label: 'Cosmetology and Barber Schools', key: 971 } ,
{ value: '7231', label: 'Beauty Salons', key: 972 } ,
{ value: '7231', label: 'Nail Salons', key: 973 } ,
{ value: '7241', label: 'Barber Shops', key: 974 } ,
{ value: '7251', label: 'Footwear and Leather Goods Repair', key: 975 } ,
{ value: '7261', label: 'Cemeteries and Crematories', key: 976 } ,
{
  value: '7261',
  label: 'Funeral Homes and Funeral Services',
  key: 977
} ,
{ value: '7291', label: 'Tax Preparation Services', key: 978 } ,
{ value: '7299', label: 'Formal Wear and Costume Rental', key: 979 } ,
{ value: '7299', label: 'All Other Personal Services', key: 980 } ,
{ value: '7299', label: 'Diet and Weight Reducing Centers', key: 981 } ,
{
  value: '7299',
  label: 'Barbershop, Beauty Parlor, or Hair Styling Salon',
  key: 982
} ,
{ value: '7311', label: 'Advertising Agencies', key: 983 } ,
{ value: '7312', label: 'Outdoor Advertising', key: 984 } ,
{ value: '7313', label: 'Media Representatives', key: 985 } ,
{ value: '7319', label: 'Media Buying Agencies', key: 986 } ,
{ value: '7322', label: 'Collection Agencies', key: 987 } ,
{ value: '7323', label: 'Credit Bureaus', key: 988 } ,
{ value: '7331', label: 'Direct Mail Advertising', key: 989 } ,
{
  value: '7334',
  label: 'Other Business Service Centers (Including Copy Shops)',
  key: 990
} ,
{
  value: '7335',
  label: 'Other Nonscheduled Air Transportation',
  key: 991
} ,
{
  value: '7338',
  label: 'Court Reporting and Stenotype Services',
  key: 992
} ,
{
  value: '7338',
  label: 'Telemarketing Bureaus and Other Contact Centers',
  key: 993
} ,
{ value: '7338', label: 'Document Preparation Services', key: 994 } ,
{
  value: '7342',
  label: 'Exterminating and Pest Control Services',
  key: 995
} ,
{ value: '7349', label: 'Janitorial Services', key: 996 } ,
{
  value: '7349',
  label: 'Other Services to Buildings and Dwellings',
  key: 997
} ,
{ value: '7352', label: 'Home Health Equipment Rental', key: 998 } ,
{
  value: '7352',
  label: 'Other Commercial and Industrial Machinery and Equipment Rental and Leasing',
  key: 999
} ,
{
  value: '7353',
  label: 'All Other Specialty Trade Contractors',
  key: 1000
} ,
{
  value: '7353',
  label: 'Construction, Mining, and Forestry Machinery and Equipment Rental and Leasing',
  key: 1001
} ,
{ value: '7359', label: 'General Rental Centers', key: 1002 } ,
{
  value: '7359',
  label: 'Consumer Electronics and Appliances Rental',
  key: 1003
} ,
{ value: '7359', label: 'All Other Consumer Goods Rental', key: 1004 } ,
{ value: '7363', label: 'Temporary Help Services', key: 1005 } ,
{
  value: '7363',
  label: 'Professional Employer Organizations',
  key: 1006
} ,
{
  value: '7371',
  label: 'Custom Computer Programming Services',
  key: 1007
} ,
{
  value: '7372',
  label: 'Software and Other Prerecorded Compact Disc, Tape, and Record Reproducing',
  key: 1008
} ,
{ value: '7372', label: 'Software Publishers', key: 1009 } ,
{ value: '7373', label: 'Computer Systems Design Services', key: 1010 } ,
{
  value: '7374',
  label: 'Data Processing, Hosting, and Related Services',
  key: 1011
} ,
{
  value: '7376',
  label: 'Computer Facilities Management Services',
  key: 1012
} ,
{
  value: '7378',
  label: 'Computer and Office Machine Repair and Maintenance',
  key: 1013
} ,
{ value: '7379', label: 'Other Computer Related Services', key: 1014 } ,
{
  value: '7381',
  label: 'Security Guards and Patrol Services',
  key: 1015
} ,
{ value: '7381', label: 'Armored Car Services', key: 1016 } ,
{ value: '7381', label: 'Investigation Services', key: 1017 } ,
{
  value: '7382',
  label: 'Security Systems Services (Except Locksmiths)',
  key: 1018
} ,
{ value: '7383', label: 'News Syndicates', key: 1019 } ,
{
  value: '7384',
  label: 'Photofinishing Laboratories (Except One-Hour)',
  key: 1020
} ,
{ value: '7384', label: 'One-Hour Photofinishing', key: 1021 } ,
{
  value: '7389',
  label: 'All Other Misc. Chemical Product and Preparation Mfg.',
  key: 1022
} ,
{
  value: '7389',
  label: 'Other Specialized Design Services',
  key: 1023
} ,
{ value: '7389', label: 'Sound Recording Studios', key: 1024 } ,
{ value: '7389', label: 'Other Sound Recording Industries', key: 1025 } ,
{
  value: '7389',
  label: 'Other Services to Buildings and Dwellings',
  key: 1026
} ,
{ value: '7389', label: 'Interior Design Services', key: 1027 } ,
{ value: '7389', label: 'Repossession Services', key: 1028 } ,
{ value: '7389', label: 'All Other Support Services', key: 1029 } ,
{ value: '7389', label: 'Packaging and Labeling Services', key: 1030 } ,
{ value: '7389', label: 'Industrial Design Services', key: 1031 } ,
{ value: '7389', label: 'Drafting Services', key: 1032 } ,
{ value: '7389', label: 'Building Inspection Services', key: 1033 } ,
{ value: '7389', label: 'Convention and Visitors Bureaus', key: 1034 } ,
{
  value: '7389',
  label: 'All Other Business Support Services',
  key: 1035
} ,
{
  value: '7389',
  label: 'Translation and Interpretation Services',
  key: 1036
} ,
{ value: '7389', label: 'Private Mail Centers', key: 1037 } ,
{ value: '7389', label: 'All Other Information Services', key: 1038 } ,
{ value: '7389', label: 'Telephone Answering Services', key: 1039 } ,
{ value: '7389', label: 'All Other Legal Services', key: 1040 } ,
{
  value: '7389',
  label: 'Other Services Related to Advertising',
  key: 1041
} ,
{
  value: '7389',
  label: 'Convention and Trade Show Organizers',
  key: 1042
} ,
{
  value: '7513',
  label: 'Truck, Utility Trailer, and RV (Recreational Vehicle) Rental and Leasing',
  key: 1043
} ,
{ value: '7514', label: 'Passenger Car Rental', key: 1044 } ,
{ value: '7515', label: 'Passenger Car Leasing', key: 1045 } ,
{
  value: '7519',
  label: 'Truck, Utility Trailer, and RV (Recreational Vehicle) Rental and Leasing',
  key: 1046
} ,
{ value: '7521', label: 'Parking Lots and Garages', key: 1047 } ,
{
  value: '7532',
  label: 'Automotive Body, Paint, and Interior Repair and Maintenance',
  key: 1048
} ,
{ value: '7533', label: 'Automotive Exhaust System Repair', key: 1049 } ,
{ value: '7534', label: 'Tire Retreading', key: 1050 } ,
{
  value: '7536',
  label: 'Automotive Glass Replacement Shops',
  key: 1051
} ,
{ value: '7537', label: 'Automotive Transmission Repair', key: 1052 } ,
{ value: '7538', label: 'General Automotive Repair', key: 1053 } ,
{
  value: '7539',
  label: 'Other Automotive Mechanical and Electrical Repair and Maintenance',
  key: 1054
} ,
{
  value: '7539',
  label: 'All Other Automotive Repair and Maintenance',
  key: 1055
} ,
{ value: '7542', label: 'Car Washes', key: 1056 } ,
{ value: '7549', label: 'Motor Vehicle Towing', key: 1057 } ,
{
  value: '7549',
  label: 'Automotive Oil Change and Lubrication Shops',
  key: 1058
} ,
{
  value: '7622',
  label: 'Communication Equipment Repair and Maintenance',
  key: 1059
} ,
{
  value: '7623',
  label: 'Commercial and Industrial Machinery and Equipment (Except Automotive and Electronic) Repair and Maintenance',
  key: 1060
} ,
{ value: '7623', label: 'Household Appliance Stores', key: 1061 } ,
{ value: '7623', label: 'Appliance Repair and Maintenance', key: 1062 } ,
{
  value: '7629',
  label: 'Consumer Electronics Repair and Maintenance',
  key: 1063
} ,
{
  value: '7629',
  label: 'Computer and Office Machine Repair and Maintenance',
  key: 1064
} ,
{ value: '7629', label: 'Household Appliance Stores', key: 1065 } ,
{
  value: '7641',
  label: 'Reupholstery and Furniture Repair',
  key: 1066
} ,
{
  value: '7692',
  label: 'Other Personal and Household Goods Repair and Maintenance',
  key: 1067
} ,
{
  value: '7699',
  label: 'Support Activities for Animal Production',
  key: 1068
} ,
{
  value: '7699',
  label: 'Home and Garden Equipment Repair and Maintenance',
  key: 1069
} ,
{
  value: '7699',
  label: 'Commercial and Industrial Machinery and Equipment (Except Automotive and Electronic) Repair and Maintenance',
  key: 1070
} ,
{ value: '7699', label: 'Septic Tank and Related Services', key: 1071 } ,
{
  value: '7699',
  label: 'Other Electronic and Precision Equipment Repair and Maintenance',
  key: 1072
} ,
{
  value: '7699',
  label: 'Other Personal and Household Goods Repair and Maintenance',
  key: 1073
} ,
{
  value: '7699',
  label: 'Other Support Activities for Water Transportation',
  key: 1074
} ,
{ value: '7699', label: 'Locksmiths', key: 1075 } ,
{
  value: '7699',
  label: 'All Other Home Furnishings Stores',
  key: 1076
} ,
{
  value: '7699',
  label: 'Other Services to Buildings and Dwellings',
  key: 1077
} ,
{
  value: '7812',
  label: 'Motion Picture and Video Production',
  key: 1078
} ,
{
  value: '7819',
  label: 'Teleproduction and Other Postproduction Services',
  key: 1079
} ,
{
  value: '7819',
  label: 'Prerecorded Compact Disc (Except Software), Tape, and Record Producing',
  key: 1080
} ,
{
  value: '7819',
  label: 'Other Motion Picture and Video Industries',
  key: 1081
} ,
{ value: '782', label: 'Landscaping Services', key: 1082 } ,
{
  value: '7822',
  label: 'Motion Picture and Video Distribution',
  key: 1083
} ,
{
  value: '7829',
  label: 'Motion Picture and Video Distribution',
  key: 1084
} ,
{
  value: '7832',
  label: 'Motion Picture Theaters (Except Drive-Ins)',
  key: 1085
} ,
{ value: '7833', label: 'Drive-in Motion Picture Theaters', key: 1086 } ,
{ value: '7841', label: 'Video Tape and Disc Rental', key: 1087 } ,
{
  value: '7911',
  label: 'All Other Amusement and Recreation Industries',
  key: 1088
} ,
{ value: '7922', label: 'Theater Cos. and Dinner Theaters', key: 1089 } ,
{ value: '7922', label: 'Dance Cos.', key: 1090 } ,
{ value: '7922', label: 'Musical Groups and Artists', key: 1091 } ,
{ value: '7933', label: 'Bowling Centers', key: 1092 } ,
{
  value: '7941',
  label: 'Promoters of Performing Arts, Sports, and Similar Events with Facilities',
  key: 1093
} ,
{
  value: '7941',
  label: 'Promoters of Performing Arts, Sports, and Similar Events without Facilities',
  key: 1094
} ,
{ value: '7948', label: 'Racetracks', key: 1095 } ,
{ value: '7948', label: 'Other Spectator Sports', key: 1096 } ,
{
  value: '7991',
  label: 'Fitness and Recreational Sports Centers',
  key: 1097
} ,
{ value: '7992', label: 'Golf Courses and Country Clubs', key: 1098 } ,
{ value: '7993', label: 'Amusement Arcades', key: 1099 } ,
{ value: '7996', label: 'Amusement and Theme Parks', key: 1100 } ,
{
  value: '7997',
  label: 'All Other Amusement and Recreation Industries',
  key: 1101
} ,
{ value: '7997', label: 'Golf Courses and Country Clubs', key: 1102 } ,
{
  value: '7997',
  label: 'Fitness and Recreational Sports Centers',
  key: 1103
} ,
{
  value: '7999',
  label: 'All Other Amusement and Recreation Industries',
  key: 1104
} ,
{ value: '7999', label: 'Recreational Goods Rental', key: 1105 } ,
{
  value: '7999',
  label: 'Fitness and Recreational Sports Centers',
  key: 1106
} ,
{
  value: '7999',
  label: 'Nature Parks and Other Similar Institutions',
  key: 1107
} ,
{
  value: '7999',
  label: 'Promoters of Performing Arts, Sports, and Similar Events with Facilities',
  key: 1108
} ,
{
  value: '7999',
  label: 'Sports and Recreation Instruction',
  key: 1109
} ,
{ value: '7999', label: 'Skiing Facilities', key: 1110 } ,
{ value: '7999', label: 'Other Gambling Industries', key: 1111 } ,
{ value: '7999', label: 'Casinos (Except Casino Hotels)', key: 1112 } ,
{
  value: '7999',
  label: 'Other Similar Organizations (Except Business, Professional, Labor, and Political Organizations)',
  key: 1113
} ,
{ value: '7999', label: 'Other Performing Arts Cos.', key: 1114 } ,
{
  value: '8011',
  label: 'Freestanding Ambulatory Surgical and Emergency Centers',
  key: 1115
} ,
{ value: '8011', label: 'HMO Medical Centers', key: 1116 } ,
{
  value: '8011',
  label: 'Offices of Physicians (Except Mental Health Specialists)',
  key: 1117
} ,
{
  value: '8011',
  label: 'Offices of Physicians, Mental Health Specialists',
  key: 1118
} ,
{ value: '8021', label: 'Offices of Dentists', key: 1119 } ,
{ value: '8041', label: 'Offices of Chiropractors', key: 1120 } ,
{ value: '8042', label: 'Offices of Optometrists', key: 1121 } ,
{ value: '8043', label: 'Offices of Podiatrists', key: 1122 } ,
{
  value: '8049',
  label: 'Offices of Mental Health Practitioners (Except Physicians)',
  key: 1123
} ,
{
  value: '8049',
  label: 'Offices of All Other Misc. Health Practitioners',
  key: 1124
} ,
{
  value: '8049',
  label: 'Offices of Physical, Occupational and Speech Therapists, and Audiologists',
  key: 1125
} ,
{
  value: '8051',
  label: 'Nursing Care Facilities (Skilled Nursing Facilities)',
  key: 1126
} ,
{
  value: '8052',
  label: 'Nursing Care Facilities (Skilled Nursing Facilities)',
  key: 1127
} ,
{
  value: '8052',
  label: 'Residential Intellectual and Developmental Disability Facilities',
  key: 1128
} ,
{
  value: '8059',
  label: 'Continuing Care Retirement Centers',
  key: 1129
} ,
{
  value: '8059',
  label: 'Nursing Care Facilities (Skilled Nursing Facilities)',
  key: 1130
} ,
{
  value: '8062',
  label: 'General Medical and Surgical Hospitals',
  key: 1131
} ,
{
  value: '8063',
  label: 'Psychiatric and Substance Abuse Hospitals',
  key: 1132
} ,
{
  value: '8069',
  label: 'Psychiatric and Substance Abuse Hospitals',
  key: 1133
} ,
{
  value: '8069',
  label: 'Specialty (Except Psychiatric and Substance Abuse) Hospitals',
  key: 1134
} ,
{ value: '8071', label: 'Diagnostic Imaging Centers', key: 1135 } ,
{ value: '8071', label: 'Medical Laboratories', key: 1136 } ,
{ value: '8072', label: 'Dental Laboratories', key: 1137 } ,
{ value: '8082', label: 'Home Health Care Services', key: 1138 } ,
{ value: '8092', label: 'Kidney Dialysis Centers', key: 1139 } ,
{ value: '8093', label: 'Family Planning Centers', key: 1140 } ,
{
  value: '8093',
  label: 'Outpatient Mental Health and Substance Abuse Centers',
  key: 1141
} ,
{
  value: '8093',
  label: 'All Other Outpatient Care Centers',
  key: 1142
} ,
{
  value: '8099',
  label: 'All Other Misc. Ambulatory Health Care Services',
  key: 1143
} ,
{ value: '8099', label: 'Blood and Organ Banks', key: 1144 } ,
{ value: '8111', label: 'Offices of Lawyers', key: 1145 } ,
{ value: '8211', label: 'Elementary and Secondary Schools', key: 1146 } ,
{
  value: '8221',
  label: 'Colleges, Universities, and Professional Schools',
  key: 1147
} ,
{ value: '8221', label: 'Junior Colleges', key: 1148 } ,
{ value: '8231', label: 'Libraries and Archives', key: 1149 } ,
{ value: '8243', label: 'Computer Training', key: 1150 } ,
{ value: '8249', label: 'Flight Training', key: 1151 } ,
{ value: '8249', label: 'Apprenticeship Training', key: 1152 } ,
{
  value: '8299',
  label: 'Professional and Management Development Training',
  key: 1153
} ,
{ value: '8299', label: 'Exam Preparation and Tutoring', key: 1154 } ,
{ value: '8299', label: 'Language Schools', key: 1155 } ,
{ value: '8299', label: 'Automobile Driving Schools', key: 1156 } ,
{ value: '8322', label: 'Temporary Shelters', key: 1157 } ,
{ value: '8322', label: 'Community Food Services', key: 1158 } ,
{ value: '8322', label: 'Other Community Housing Services', key: 1159 } ,
{
  value: '8322',
  label: 'Emergency and Other Relief Services',
  key: 1160
} ,
{
  value: '8322',
  label: 'Other Individual and Family Services',
  key: 1161
} ,
{
  value: '8322',
  label: 'Services for the Elderly and Persons with Disabilities',
  key: 1162
} ,
{
  value: '8331',
  label: 'Vocational Rehabilitation Services',
  key: 1163
} ,
{ value: '8351', label: 'Child Day Care Services', key: 1164 } ,
{
  value: '8361',
  label: 'Other Residential Care Facilities',
  key: 1165
} ,
{
  value: '8361',
  label: 'Assisted Living Facilities for the Elderly',
  key: 1166
} ,
{
  value: '8361',
  label: 'Residential Mental Health and Substance Abuse Facilities',
  key: 1167
} ,
{
  value: '8361',
  label: 'Nursing Care Facilities (Skilled Nursing Facilities)',
  key: 1168
} ,
{
  value: '8399',
  label: 'Environment, Conservation, and Wildlife Organizations',
  key: 1169
} ,
{
  value: '8399',
  label: 'Other Grantmaking and Giving Services',
  key: 1170
} ,
{ value: '8399', label: 'Human Rights Organizations', key: 1171 } ,
{ value: '8412', label: 'Museums', key: 1172 } ,
{ value: '8412', label: 'Historical Sites', key: 1173 } ,
{ value: '8422', label: 'Zoos and Botanical Gardens', key: 1174 } ,
{ value: '8621', label: 'Professional Organizations', key: 1175 } ,
{
  value: '8631',
  label: 'Labor Unions and Similar Labor Organizations',
  key: 1176
} ,
{ value: '8641', label: 'Civic and Social Organizations', key: 1177 } ,
{
  value: '8641',
  label: 'Other Similar Organizations (Except Business, Professional, Labor, and Political Organizations)',
  key: 1178
} ,
{ value: '8641', label: 'Child and Youth Services', key: 1179 } ,
{ value: '8651', label: 'Political Organizations', key: 1180 } ,
{ value: '8661', label: 'Religious Organizations', key: 1181 } ,
{
  value: '8699',
  label: 'Other Similar Organizations (Except Business, Professional, Labor, and Political Organizations)',
  key: 1182
} ,
{ value: '8711', label: 'Engineering Services', key: 1183 } ,
{ value: '8712', label: 'Architectural Services', key: 1184 } ,
{
  value: '8713',
  label: 'Surveying and Mapping (Except Geophysical) Services',
  key: 1185
} ,
{ value: '8721', label: 'Other Accounting Services', key: 1186 } ,
{
  value: '8721',
  label: 'Offices of Certified Public Accountants',
  key: 1187
} ,
{
  value: '8732',
  label: 'Research and Development in Biotechnology',
  key: 1188
} ,
{
  value: '8732',
  label: 'Marketing Research and Public Opinion Polling',
  key: 1189
} ,
{ value: '8734', label: 'Testing Laboratories', key: 1190 } ,
{
  value: '8741',
  label: 'Other Heavy and Civil Engineering Construction',
  key: 1191
} ,
{ value: '8741', label: 'Office Administrative Services', key: 1192 } ,
{
  value: '8742',
  label: 'Administrative Management and General Management Consulting Services',
  key: 1193
} ,
{ value: '8742', label: 'Marketing Consulting Services', key: 1194 } ,
{ value: '8743', label: 'Public Relations Agencies', key: 1195 } ,
{ value: '8744', label: 'Facilities Support Services', key: 1196 } ,
{
  value: '8748',
  label: 'Other Management Consulting Services',
  key: 1197
} ,
{ value: '8811', label: 'Private Households', key: 1198 } ,
{ value: '8999', label: 'Record Production', key: 1199 } ,
{
  value: '8999',
  label: 'Environmental Consulting Services',
  key: 1200
} ,
{ value: '9199', label: 'Other General Government Support', key: 1201 } ,
{ value: '9221', label: 'Police Protection', key: 1202 } ,
{ value: '9223', label: 'Correctional Institutions', key: 1203 } ,
{ value: '9224', label: 'Fire Protection', key: 1204 } ,
{
  value: '9511',
  label: 'Administration of Air and Water Resource and Solid Waste Management Programs',
  key: 1205
} ,
{
  value: '9531',
  label: 'Administration of Housing Programs',
  key: 1206
} ,
{ value: '9661', label: 'Space Research and Technology', key: 1207 } ,
{ value: '9711', label: 'National Security', key: 1208 } 

]

export default industries;
