import "./index.css";
import React, { useEffect } from "react";
import { Row, Col, Button as RButton } from "reactstrap";
import ReactGA from "react-ga";
import Fade from "react-reveal/Fade";
import { Form, Input, Button, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

//assets
import Logo from "../../assets/img/sos_logo_with.png";
import SOSPhone from "../../assets/img/contact-sos.png";

//actions
import {
  createWebToLead,
  createWebToLeadCleanup,
} from "../../store/actions/create-web-to-lead";

//components
import SubFooter from "components/SubFooter";

const ContactPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const createWebToLeadState = useSelector((s) => s.createWebToLead);
  const [form] = Form.useForm();

  const onFinish = (values) => {
    dispatch(
      createWebToLead(
        values.first_name,
        values.last_name,
        values.city,
        values.email,
        values.phone,
        values.company,
        values.title,
        values.contactpreference,
        values.country,
        values.state,
        values.street,
        values.zip,
        values.dba,
        values.legalname,
        values.sponsorshiptype
      )
    );
  };
  const portalAccess = () => {
    if (!localStorage.getItem("authToken")) {
      history.push("/login");
    } else {
      history.push("/portals");
    }
  };

  useEffect(() => {
    if (createWebToLeadState.isSuccessful) {
      form.resetFields();
      message.success("We have received your request");
      dispatch(createWebToLeadCleanup());
      history.push("/verified-success", { from: "contact-us" });
    } else if (createWebToLeadState.error) {
      message.error("Your request was not successful, kindly try again");
      dispatch(createWebToLeadCleanup());
    }
  }, [createWebToLeadState]);

  useEffect(() => {
    ReactGA.pageview(
      `/portal/${window.location.pathname + window.location.search}`,
      [],
      "Contact-us"
    );
  }, []);

  return (
    <section className={"applicationpage-container contact-page"}>
      <div className={"interface-contact"}>
        <section className={"container interface-child"}>
          <Row>
            <Col className="mr-auto ml-auto order-1 order-md-2" md="6" sm="12">
              <Fade left>
                <section className={"pt-5 center-medium"}>
                  <h2 className="dont-miss">Don't miss out!</h2>
                  <h5 className="find-out sostext">
                    Sign up with Altbanq and find out <br />
                    how amazing our deals are!{" "}
                  </h5>
                  <RButton
                    onClick={() => portalAccess()}
                    className={"r-button contact-btn-sty"}
                  >
                    BROKER/ISO'S
                  </RButton>
                </section>
              </Fade>
            </Col>
            <Col className="mr-auto ml-auto order-2 order-md-2" md="6" sm="12">
              <Fade left>
                <img src={SOSPhone} alt="sos-phone" />
              </Fade>
            </Col>
          </Row>
        </section>
      </div>
      <section className="text-center pt-5 mid-contact">
        <h1 className="dont-miss">Don't miss out!</h1>
        <section className="center-contact">
          <div className="deal-statement">
            Sign up with Altbanq and <b>find out how amazing our deals are!</b>
            <br />
            In Altbanq Lending we have some of the{" "}
            <b>bests Broker/ISO programs in the industry.</b> Help your clients
            access the capital they need through our fast and seamless process.
          </div>
        </section>
      </section>
      <div
        className="container expandable center-form"
        style={{ background: "white" }}
      >
        <Row md="12" id="form-display" className="form-cont center-form">
          <Col md="6">
            <Form
              form={form}
              className="col-12"
              name="basic"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
            >
              <h2 className="h1-contact">Tell us about yourself</h2>
              <Form.Item
                className="input-sect"
                name="first_name"
                rules={[
                  {
                    required: true,
                    message: "Your First Name is required",
                  },
                ]}
              >
                <Input className="round-input" placeholder="First Name" />
              </Form.Item>
              <Form.Item
                className="input-sect"
                name="last_name"
                rules={[
                  {
                    required: true,
                    message: "Your Last Name is required",
                  },
                ]}
              >
                <Input className="round-input" placeholder="Last Name" />
              </Form.Item>
              <Form.Item
                className="input-sect"
                name="title"
                rules={[
                  {
                    required: true,
                    message: "Please input a Title",
                  },
                ]}
              >
                <Input className="round-input" placeholder="Title/Position" />
              </Form.Item>
              <Form.Item
                className="input-sect"
                name="email"
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "Invalid email address/format!",
                  },
                ]}
              >
                <Input className="round-input" placeholder="Email" />
              </Form.Item>
              <Form.Item className="input-sect" name="phone">
                <Input className="round-input" placeholder="Phone" />
              </Form.Item>
              <div className="up-margin">
                How do you prefer to be contacted?
              </div>
              <Form.Item className="input-sect" name="contactpreference">
                <select
                  className="round-input"
                  id="00N6100000CJqS7"
                  name="00N6100000CJqS7"
                  title="Contact Preference"
                >
                  <option value="Phone">Phone</option>
                  <option value="Email">Email</option>
                  <option value="">--None--</option>
                </select>
              </Form.Item>
              <Form.Item
                className="input-sect"
                name="company"
                rules={[
                  {
                    required: true,
                    message: "Company Name is required",
                  },
                ]}
              >
                <Input className="round-input" placeholder="Company Name" />
              </Form.Item>
              <Form.Item
                className="input-sect"
                name="country"
                rules={[
                  {
                    required: true,
                    message: "Country is required",
                  },
                ]}
              >
                <Input className="round-input" placeholder="Country" />
              </Form.Item>
              <Form.Item
                className="input-sect"
                name="state"
                rules={[
                  {
                    required: true,
                    message: "State is required",
                  },
                ]}
              >
                <Input className="round-input" placeholder="State/Province" />
              </Form.Item>
              <Form.Item
                className="input-sect"
                name="city"
                rules={[
                  {
                    required: true,
                    message: "City is required",
                  },
                ]}
              >
                <Input className="round-input" placeholder="City" />
              </Form.Item>
              <Form.Item
                className="input-sect"
                name="street"
                rules={[
                  {
                    required: true,
                    message: "Street is required",
                  },
                ]}
              >
                <Input className="round-input" placeholder="Street" />
              </Form.Item>
              <Form.Item
                className="input-sect"
                name="zip"
                rules={[
                  {
                    required: true,
                    message: "Zip code is required",
                  },
                ]}
              >
                <Input className="round-input" placeholder="Zip Code" />
              </Form.Item>
              <h2 className="h1-contact">Tell us about your business</h2>
              <Form.Item
                className="input-sect"
                id="00Nq0000002Ao7B"
                name="dba"
                rules={[
                  {
                    required: true,
                    message: "Business name is required",
                  },
                ]}
              >
                <Input
                  placeholder="Business name/DBA"
                  className="round-input"
                  size="20"
                  type="text"
                />
              </Form.Item>
              <Form.Item
                id="00Nq0000002Ao7O"
                className="input-sect"
                name="legalname"
                rules={[
                  {
                    required: true,
                    message: "Business Legal name is required",
                  },
                ]}
              >
                <Input
                  placeholder="Business legal name"
                  className="round-input"
                  size="20"
                  type="text"
                />
              </Form.Item>
              What type of partnership are you looking for?
              <Form.Item
                className="input-sect text-area-contct"
                name="sponsorshiptype"
              >
                <textarea
                  className="round-input text-area-contact"
                  id="00N6100000I9OEM"
                  name="00N6100000I9OEM"
                  type="text"
                  wrap="soft"
                ></textarea>
              </Form.Item>
              <Button
                style={{ borderRadius: "40px" }}
                className="col-md-4 r-button contact-btn-sty"
                loading={createWebToLeadState.isLoading}
                type="primary"
                htmlType="submit"
              >
                Submit
              </Button>
            </Form>
          </Col>
          <Col md="6">
            <section className="section-half ">
              <div className="y-us ml-3 m-5">Why Altbanq?</div>
              <div className="check-div">
                <ul className="checkmark">
                  <li>Fast processing</li>
                  <li>Dedicated ISO support</li>
                  <li>Syndication Opportunities</li>
                  <li>No funding limit</li>
                  <li>3 hour underwriting</li>
                  <li>24 hour funding</li>
                  <li>Flexible terms</li>
                  <li>Lowest fees in the industry</li>
                  <li>API Integration</li>
                  <li>Multiple ways to submit your deals</li>
                </ul>
              </div>
              <div className="mt-3 pt-5 pb-5 w-100 text-center">
                <img src={Logo} width="180px" alt="logo" />
              </div>
            </section>
          </Col>
        </Row>
      </div>
      <SubFooter />
    </section>
  );
};

export default ContactPage;
