import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';
export const myISORepaymentStart = () => ({
  type: types.GET_MY_ISO_REPAYMENTS_START
});

export const myISORepaymentSuccess = payload => ({
  type: types.GET_MY_ISO_REPAYMENTS_SUCCESS,
  payload
});

export const myISORepaymentFail = payload => ({
  type: types.GET_MY_ISO_REPAYMENTS_FAIL,
  payload
});

export const myISORepaymentCleanup = () => ({
  type: types.GET_MY_ISO_REPAYMENTS_CLEANUP
});

export const myISORepayment = (portal,type,id)=> async dispatch => {
  try {
    dispatch(myISORepaymentStart());
    const requestObj = {
      path: `repayment/opportunity/${portal}/iso/${type}/${id}`,
      method: "GET",   
    }
    const  data  = await AxiosCall(requestObj);
    dispatch(myISORepaymentSuccess(data));
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(myISORepaymentFail(error));
  }
}