import { combineReducers } from 'redux';
import login from './login';
import applyForFunding from './apply-for-funding';
import myDeals from "./my-deals";
import myAdvances from "./my-advances";
import uploadBankStatement from './upload-bank-statement';
import connectToPlaid from './connect-to-plaid';
import checkEmailExist from './check-email-exist';
import getAdvanceDetails from './get-advance-details';
import dealsDetails from "./deals-details";
import getDealsOffers from "./get-deals-offers";
import getSingleOffer from "./get-single-offer";
import getRepayments from './get-repayments';
import getSingleRepayment from './get-single-repayment';
import getPayments from './get-payments';
import getSinglePayment from './get-single-payment';
import getAllOffers from "./get-all-offers";
import getApplicantMatric from "./applicant-matric";
import getBrokerMetricsByIso from './get-broker-metrics-by-iso';
import getMyISODeals from "./my-iso-deals";
import getMyISOOppurtunity from "./my-iso-opportunity";
import getMyISOStipulation from "./my-iso-stipulation";
import getMyISOOffers from "./my-iso-offer";
import getMyISORepayments from "./my-iso-repayments";
import getMyISOStageHistory from "./my-iso-stage-history";
import getSingleOfferInfo from "./get-single-offer-info";
import getSingleOfferSummary from "./get-single-offer-summary";
import getSingleStipulationAttachment from "./get-single-stipulation-attachment";
import addStipulationAttachment from "./add-stipulation-attachment";
import getBrokerMetrics from './get-broker-metrics';
import getISOSingleRepayment from "./get-iso-single-repayment";
import getProfile from './get-profile';
import getLookupList from './get-lookup-list';
import createBrokerFunding from './create-broker-funding';
import getSyndicator from './get-syndicator';
import getSyndicatorDeals from './get-syndicators-deals';
import getFlexOffer from './get-flex-offer';
import updateOffer from './update-offer';
import getAccount from "./get-account";
import getSingleAccount from "./get-single-account";
import createWebToLead  from './create-web-to-lead';
import searchInstitution  from './search-institution';
import savePSAInformation  from './save-psa-information';
import updateContact from './update-contact';
import generatePaymentHistory from './generate-payment-history';
import generatePaymentHistorySyndicator from './generate-payment-history-syndicator';
import generateReferralCode from './generate-referral-code';
import updateBankDetails from './update-bank-details';

const rootReducer = combineReducers({
  login,
  applyForFunding,
  myDeals,
  myAdvances,
  uploadBankStatement,
  connectToPlaid,
  checkEmailExist,
  getAdvanceDetails,
  dealsDetails,
  getDealsOffers,
  getSingleOffer,
  getRepayments,
  getSingleRepayment,
  getPayments,
  getSinglePayment,
  getAllOffers,
  getApplicantMatric,
  getBrokerMetricsByIso,
  getMyISODeals,
  getMyISOOppurtunity,
  getMyISOStipulation,
  getMyISOOffers,
  getMyISORepayments,
  getMyISOStageHistory,
  getSingleOfferInfo,
  getSingleOfferSummary,
  getSingleStipulationAttachment,
  addStipulationAttachment,
  getBrokerMetrics,
  getISOSingleRepayment,
  getProfile,
  getLookupList,
  createBrokerFunding,
  getSyndicator,
  getSyndicatorDeals,
  getFlexOffer,
  updateOffer,
  getAccount,
  getSingleAccount,
  createWebToLead,
  searchInstitution,
  savePSAInformation,
  updateContact,
  generatePaymentHistory,
  generatePaymentHistorySyndicator,
  generateReferralCode,
  updateBankDetails
});

export default rootReducer;
