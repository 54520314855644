import "./index.css";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  Slider,
  InputNumber,
  Row,
  Col,
  Button,
  message,
  Alert
} from "antd";
import { CardTitle } from "reactstrap";
import { useParams, useHistory } from "react-router-dom";
import Select from "react-select";

//components
import Loader from "../../../components/Loader";

//actions
import {
  getFlexOffer,
  getFlexOfferCleanup
} from "../../../store/actions/get-flex-offer";
import {
  updateOffer,
  updateOfferCleanup
} from "../../../store/actions/update-offer";

//utils
import { getValueObject, formatCurrency } from "../../../utils/helpers";

const OfferCalculator = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const getFlexOfferState = useSelector((s) => s.getFlexOffer);
  const updateOfferState = useSelector((s) => s.updateOffer);
  const [flexOffer, setFlexOffer] = useState(null);
  const { id } = useParams();
  const [inputValues, setInputValues] = useState({});
  const [maxFunding, setMaxFunding] = useState(0);
  const [minBuyRate, setMinBuyRate] = useState(1.1);
  const [currentBuyRate, setCurrentBuyRate] = useState(null)
  const [maxPaymentAmount, setMaxPaymentAmount] = useState(0);
  const [triggerCalcFundingAmount, setTriggerCalcFundingAmount] =
    useState(false);
  const [calculatorData, setCalculatorData] = useState({
    fundingAmount: 0,
    paybackAmount: 0,
    buyRate: 0,
    weeklyAmount: 0,
    dailyAmount: 0,
    dealMonths: 0,
    paymentFrequency: ""
  });
  const [sliderValues, setSliderValues] = useState({
    minFundingAmount:'',
    maxFundingAmount:'',
    minPaymentValue:0,
    maxPaymentValue:''
  })
 

  const handleSubmit = () => {
    const payload = {
      Term_Months__c: inputValues.Term_Months__c,
      Payment_Amt__c:inputValues.Max_Payment_Amount?inputValues.Max_Payment_Amount:sliderValues.minPaymentAmount,
      Payment_Frequency_Daily__c: inputValues.Payment_Frequency === "Daily",
      Payment_Frequency_Weekly__c: inputValues.Payment_Frequency === "Weekly",
      Rate__c: calculatorData.factorRate,
      Amount__c: inputValues.Funding_Amount,
      Buy_Rate__c: currentBuyRate?currentBuyRate:calculatorData.buyRate,
      Deal_Length__c: inputValues.Term_Months__c * 21,
      PMT_Schedule__c: inputValues.Payment_Frequency
    };
    // console.log(payload);
    // return
    dispatch(updateOffer(id, 1, payload));
  };

  const getMaximumFunding = () => {
    const {
      Base_Factor_Rate__c,
      Max_Expected_Term__c,
      Max_Upsell__c,
      Max_Advance_Amount__c
    } = getFlexOfferState.data;
    const maxAdvanceAmount = Max_Advance_Amount__c;
    const baseFactorRate = Base_Factor_Rate__c;
    const maxTerm = Max_Expected_Term__c;
    const maxUpsell = Max_Upsell__c;
    const maxBuyRate = baseFactorRate - 0.12;
    const monthlyFactor = ((maxBuyRate - 1) * 100) / maxTerm;
    const calculatedBuyRate = parseFloat(
      ((monthlyFactor / 100) * inputValues.Term_Months__c + 1).toFixed(2)
    );
    const buyRate =
      calculatedBuyRate >= minBuyRate ? calculatedBuyRate : minBuyRate;
    const factorRate = maxUpsell / 100 + buyRate;

    let fundingAmount = 0;
    const termArr = Array.from(
      Array(maxTerm - inputValues.Term_Months__c + 1),
      (x, i) => maxTerm - i
    );
    termArr.forEach((val) => {
      if (val === maxTerm) {
        fundingAmount = maxAdvanceAmount;
      } else {
        fundingAmount -= fundingAmount * 0.2;
      }
    });

    let newMaxFunding = 0;
    if (inputValues.Term_Months__c === maxTerm) {
      newMaxFunding = maxAdvanceAmount;
    } else {
      const mfFactor = (maxAdvanceAmount * baseFactorRate) / maxTerm;
      newMaxFunding =
        ((mfFactor * inputValues.Term_Months__c) / factorRate / 100).toFixed() *
        100;
    }

    let totalMaxPaymentAmount = newMaxFunding * factorRate;
    const dailyMaxPaymentAmount = parseFloat(
      (totalMaxPaymentAmount / (inputValues.Term_Months__c * 21)).toFixed(2)
    );
    const weeklyMaxPaymentAmount = parseFloat(
      (totalMaxPaymentAmount / (inputValues.Term_Months__c * 4)).toFixed(2)
    );
    let newMaxPaymentAmount =
      inputValues.Payment_Frequency === "Weekly"
        ? weeklyMaxPaymentAmount
        : dailyMaxPaymentAmount;

    setMaxFunding(newMaxFunding);
    setInputValues((prev) => ({ ...prev, Funding_Amount: fundingAmount }));
    setMaxPaymentAmount(newMaxPaymentAmount);
  };

  const calculate = () => {
    console.log('from calcl function',inputValues.Payment_Frequency);
    //alert('wow')
    if (inputValues.Term_Months__c|| inputValues.Payment_Frequency) {
      const { Base_Factor_Rate__c, Max_Expected_Term__c, Max_Upsell__c } =
        getFlexOfferState.data;
      const baseFactorRate = Base_Factor_Rate__c;
      const maxTerm = Max_Expected_Term__c;
      const upsell = Max_Upsell__c;
      const maxBuyRate = baseFactorRate - 0.12;
      const monthlyFactor = ((maxBuyRate - 1) * 100) / maxTerm;
      const calculatedBuyRate = parseFloat(
        ((monthlyFactor / 100) * 12 + 1).toFixed(2)
      );
      const buyRate =
        calculatedBuyRate >= minBuyRate ? calculatedBuyRate : minBuyRate;
      const factorRate = parseFloat((upsell / 100 + buyRate).toFixed(2));

      const fundingAmount = inputValues.Funding_Amount;
      const paybackAmount = parseFloat((fundingAmount * factorRate).toFixed(2));
      const dailyAmount = parseFloat(
        (paybackAmount / inputValues.Term_Months__c / 21).toFixed(2)
      );
      const weeklyAmount = parseFloat(
        (paybackAmount / (inputValues.Term_Months__c * 4)).toFixed(2)
      );

      const maxDailyAmount = parseFloat(
        ((sliderValues.maxFundingAmount *factorRate) / inputValues.Term_Months__c / 21).toFixed(2)
      );

      const minDailyAmount = parseFloat(
        ((sliderValues.minFundingAmount *factorRate) / inputValues.Term_Months__c / 21).toFixed(2)
      );

      const maxWeeklyAmount =  parseFloat(
        ((sliderValues.maxFundingAmount *factorRate) / (inputValues.Term_Months__c * 4)).toFixed(2)
      )
      
      const minWeeklyAmount =  parseFloat(
        ((sliderValues.minFundingAmount *factorRate) / (inputValues.Term_Months__c * 4)).toFixed(2)
      )

      setSliderValues((prev)=>({
        ...prev,
        minPaymentAmount:inputValues.Payment_Frequency === "Weekly"?minWeeklyAmount:minDailyAmount,
        maxPaymentAmount:inputValues.Payment_Frequency === "Weekly"?maxWeeklyAmount:maxDailyAmount
      }))

      setInputValues((prev) => ({
        ...prev,
        Max_Payment_Amount:
          inputValues.Payment_Frequency === "Weekly"
            ? weeklyAmount
            : dailyAmount
      }));
      setCalculatorData({
        paybackAmount,
        fundingAmount,
        dailyAmount,
        weeklyAmount,
        buyRate: factorRate >= minBuyRate ? factorRate : minBuyRate,
        factorRate,
        dealMonths: inputValues.Term_Months__c,
        paymentFrequency: inputValues.Payment_Frequency,
        Max_Payment_Amount:
        inputValues.Payment_Frequency === "Weekly"
          ? weeklyAmount
          : dailyAmount
      });
    }
  };

  //calculate funding amount based on selected payment amount
  const calculateFundingAmount = () => {
    if (
      inputValues.Term_Months__c &&
      inputValues.Max_Payment_Amount &&
      inputValues.Payment_Frequency
    ) {
      const { Base_Factor_Rate__c, Max_Expected_Term__c, Max_Upsell__c } =
        getFlexOfferState.data;
      const totalDailyPaymentAmount =
        inputValues.Max_Payment_Amount * inputValues.Term_Months__c * 21;
      const totalWeeklyPaymentAmount =
        inputValues.Max_Payment_Amount * inputValues.Term_Months__c * 4;
      const paymentAmount =
        inputValues.Payment_Frequency === "Weekly"
          ? totalWeeklyPaymentAmount
          : totalDailyPaymentAmount;
      const baseFactorRate = Base_Factor_Rate__c;
      const maxTerm = Max_Expected_Term__c;
      const upsell = Max_Upsell__c;
      const maxBuyRate = baseFactorRate - 0.12;
      const monthlyFactor = ((maxBuyRate - 1) * 100) / maxTerm;
      const calculatedBuyRate = parseFloat(
        ((monthlyFactor / 100) * inputValues.Term_Months__c + 1).toFixed(2)
      );
      const buyRate =
        calculatedBuyRate >= minBuyRate ? calculatedBuyRate : minBuyRate;
      const factorRate = parseFloat((upsell / 100 + buyRate).toFixed(2));
      const fundingAmount = (paymentAmount / factorRate / 10).toFixed() * 10;

      setInputValues((prev) => ({ ...prev, Funding_Amount: fundingAmount }));
    }
  };

  const paymentFrequencyOptions = [
    ...(flexOffer && flexOffer.Payment_Frequency_Daily__c
      ? [{ label: "Daily", value: "Daily" }]
      : []),
    ...(flexOffer && flexOffer.Payment_Frequency_Weekly__c
      ? [{ label: "Weekly", value: "Weekly" }]
      : [])
  ];

  const handleChange = (name, value) => {
    setInputValues((prev) => ({ ...prev, [name]: value }));
    //console.log(`state input values `, inputValues);
    if(name === "Term_Months__c"){
      const maxValue = flexOffer.Max_Expected_Term__c
      
      const decrement = (maxValue-value)/maxValue
      console.log(`decrement is ${decrement}`);
      const newMaxFundingAmount = flexOffer.Max_Purchase_Amount__c - (flexOffer.Max_Purchase_Amount__c * decrement)
      const newMinFundingAmount = flexOffer.Min_Purchase_Amount__c - (flexOffer.Min_Purchase_Amount__c * decrement)
      setSliderValues({
        minFundingAmount:parseInt(newMinFundingAmount),
        maxFundingAmount:parseInt(newMaxFundingAmount)
      })
      const buyRateDecimalPoint = calculatorData.buyRate-1
      let newBuyRate = buyRateDecimalPoint - (buyRateDecimalPoint*decrement)
      newBuyRate +=1
       console.log(`new buy rate is ${newBuyRate} and old buy rate is ${calculatorData.buyRate} and decimal point is ${buyRateDecimalPoint}`);
      setInputValues((prev) => ({ ...prev, Funding_Amount: parseInt(newMaxFundingAmount), currentBuyRate:newBuyRate }));
      setCurrentBuyRate(newBuyRate.toFixed(2))

      
    }
    if (name === "Max_Payment_Amount") {
      // setTriggerCalcFundingAmount(true);
      const  decrement = (sliderValues.maxPaymentAmount-value)/sliderValues.maxPaymentAmount
      //console.log(`decrement in payment amount is ${decrement}`);
      const newFundingAmount = (sliderValues.maxFundingAmount - (sliderValues.maxFundingAmount*decrement))
      setInputValues((prev)=>({...prev, Funding_Amount:parseInt(newFundingAmount)}))
    }
  };

  const roundToNearestTen = (value)=>{
    return Math.ceil(value / 10) * 10;
  }
  useEffect(() => {
    dispatch(getFlexOffer(1, id));
  }, []);

  useEffect(() => {
    if (getFlexOfferState.isSuccessful) {
      setFlexOffer(getFlexOfferState.data);
      setSliderValues({
        minFundingAmount:getFlexOfferState.data.Min_Purchase_Amount__c,
        maxFundingAmount:getFlexOfferState.data.Max_Purchase_Amount__c
      })
      //console.log('this is the offer state data',getFlexOfferState.data);
      setInputValues({
        Term_Months__c: getFlexOfferState.data.Max_Expected_Term__c,
        Funding_Amount: getFlexOfferState.data.Max_Purchase_Amount__c,
        payment_frequency:getFlexOfferState.data.Payment_Frequency_Daily__c?'Daily':'Weekly'
      });
      setMaxFunding(getFlexOfferState.data.Max_Advance_Amount__c);
      if (getFlexOfferState.data.Min_Buy_Rate__c) {
        setMinBuyRate(getFlexOfferState.data.Min_Buy_Rate__c);
      }
      dispatch(getFlexOfferCleanup());
    } else if (getFlexOfferState.error) {
      dispatch(getFlexOfferCleanup());
    }
  }, [getFlexOfferState]);

  useEffect(() => {
    if (updateOfferState.isSuccessful) {
      message.success("You have successfully submitted your offer", 2);
      history.push(`/user/offer-details/${id}`);
      dispatch(updateOfferCleanup());
    } else if (updateOfferState.error) {
      message.error("Oops! Your offer could not be submitted", 2);
      dispatch(updateOfferCleanup());
    }
  }, [updateOfferState]);

  useEffect(() => {
    
      //alert('caluclating...')
      calculate();
    
  }, [
    inputValues.Term_Months__c,
    inputValues.Funding_Amount,
    inputValues.Payment_Frequency
  ]);

  useEffect(() => {
    if (
      inputValues.Term_Months__c &&
      inputValues.Max_Payment_Amount &&
      inputValues.Payment_Frequency &&
      triggerCalcFundingAmount
    ) {
      calculateFundingAmount();
      setTriggerCalcFundingAmount(false);
    }
  }, [triggerCalcFundingAmount]);

  useEffect(() => {
    if (inputValues.Term_Months__c && inputValues.Payment_Frequency) {
      getMaximumFunding();
    }
  }, [inputValues.Term_Months__c, inputValues.Payment_Frequency]);

  return (
    <div className="pt-5 container details-page applicant-calculator">
      <div className="pt-5 table-container">
        <Card>
          <CardTitle className="text-cente p-4">
            <div className="sos-text-primary mb-0 font-weight-bold d-flex justify-content-between">
              <div>Offer Calculator</div>
            </div>
          </CardTitle>
        </Card>
        <hr />
        <Card>
          <Loader isLoading={updateOfferState.isLoading}>
            {getFlexOfferState.isLoading && !flexOffer ? (
              <div className="m-5 d-flex justify-content-center">
                <Loader isLoading={getFlexOfferState.isLoading} />
              </div>
            ) : flexOffer &&
              flexOffer.Status__c === "Presented" &&
              flexOffer.Opportunity__r.StageName === "Approved" ? (
              <div className="pt-5 pb-5">
                <Row>
                  <Col span={24} md={{ span: 16 }}>
                    <Row className="mb-4">
                      <Col span={18} md={{ span: 4, order: 1 }}>
                        <p className="mr-md-4">
                          <strong>Expected Term: </strong>
                        </p>
                      </Col>
                      <Col span={6} md={{ span: 4, offset: 1, order: 3 }}>
                        <InputNumber
                          min={flexOffer.Min_Expected_Term__c}
                          max={flexOffer.Max_Expected_Term__c}
                          value={inputValues.Term_Months__c}
                          onChange={(value) =>
                            handleChange("Term_Months__c", value)
                          }
                        />
                      </Col>
                      <Col span={24} md={{ span: 14, order: 2 }}>
                        <Slider
                          min={flexOffer.Min_Expected_Term__c}
                          max={flexOffer.Max_Expected_Term__c}
                          marks={{
                            [flexOffer.Min_Expected_Term__c]: `${flexOffer.Min_Expected_Term__c}`,
                            [flexOffer.Max_Expected_Term__c]: `${flexOffer.Max_Expected_Term__c}`
                          }}
                          onChange={(value) =>
                            handleChange("Term_Months__c", value)
                          }
                          value={
                            typeof inputValues.Term_Months__c === "number"
                              ? inputValues.Term_Months__c
                              : 0
                          }
                        />
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      <Col span={18} md={{ span: 4, order: 1 }}>
                        <p className="mr-md-4">
                          <strong>Funding Amount: </strong>
                        </p>
                      </Col>
                      <Col span={6} md={{ span: 4, offset: 1, order: 3 }}>
                        <InputNumber
                          min={sliderValues.minFundingAmount}
                          max={sliderValues.maxFundingAmount}
                          step={10}
                          value={inputValues.Funding_Amount?inputValues.Funding_Amount:sliderValues.minFundingAmount}
                          onChange={(value) => {
                            if (value % 10 !== 0) {
                              value = Math.ceil(value / 10) * 10;
                            }
                             handleChange("Funding_Amount", value);
                          }}
                        />

                      
                      </Col>
                      <Col span={24} md={{ span: 14, order: 2 }}>
                        <Slider
                          min={sliderValues.minFundingAmount}
                          max={sliderValues.maxFundingAmount}
                          step={10}
                          marks={{
                            [sliderValues.minFundingAmount]: `${sliderValues.minFundingAmount}`,
                            [sliderValues.maxFundingAmount]: `${sliderValues.maxFundingAmount}`
                          }}
                          onChange={(value) =>
                            handleChange("Funding_Amount", value)
                          }
                          value={
                            typeof inputValues.Funding_Amount === "number"
                              ? inputValues.Funding_Amount
                              : 0
                          }
                        />
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      <Col span={24} md={{ span: 4 }}>
                        <p className="mr-md-4">
                          <strong>Payment Frequency: </strong>
                        </p>
                      </Col>
                      <Col span={24} md={{ span: 14 }}>
                        <Select
                          className="react-select info"
                          classNamePrefix="react-select"
                          placeholder="--None--"
                          name="Payment_Frequency"
                          value={getValueObject(
                            paymentFrequencyOptions,
                            inputValues.Payment_Frequency
                          )}
                          onChange={(value) =>
                            handleChange("Payment_Frequency", value.value)
                          }
                          options={paymentFrequencyOptions}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      <Col span={18} md={{ span: 4, order: 1 }}>
                        <p>
                          <strong className="mr-md-4">
                            Maximum Payment Amount:{" "}
                          </strong>
                        </p>
                      </Col>
                      <Col span={6} md={{ span: 4, offset: 1, order: 3 }}>
                        <InputNumber
                          min={sliderValues.minPaymentAmount}
                          max={sliderValues.maxPaymentAmount}
                          step={10}
                          value={inputValues.Max_Payment_Amount?inputValues.Max_Payment_Amount:sliderValues.minPaymentAmount}
                          onChange={(value) => {
                            if (value % 10 !== 0) {
                              value = Math.ceil(value / 10) * 10;
                            }
                            handleChange("Max_Payment_Amount", value);
                          }}
                        />
                      </Col>
                      <Col span={24} md={{ span: 14, order: 2 }}>
                        <Slider
                          min={sliderValues.minPaymentAmount}
                          max={sliderValues.maxPaymentAmount}
                          step={1}
                          marks={{
                            [sliderValues.minPaymentAmount]: [sliderValues.minPaymentAmount],
                            [sliderValues.maxPaymentAmount]: `${sliderValues.maxPaymentAmount}`
                          }}
                          onChange={(value) =>
                            handleChange("Max_Payment_Amount", value)
                          }
                          value={
                            typeof inputValues.Max_Payment_Amount === "number"
                              ? inputValues.Max_Payment_Amount
                              : 0
                          }
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {getFlexOfferState.data &&
                        getFlexOfferState.data.Notes_from_SOS__c ? (
                          <Alert
                            message="Note"
                            description={
                              getFlexOfferState.data.Notes_from_SOS__c
                            }
                            type="info"
                            showIcon
                            className="mb-5"
                          />
                        ) : null}
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24} md={{ span: 8 }} className="mt-5 mt-md-0">
                    <div className="result-con w-100 p-4 rounded-lg">
                      <h4 className="mt-0 sos-font-weight-600 sos-text-primary text-center">
                        Selected Offer Details
                      </h4>
                      <Row>
                        <Col
                          span={24}
                          className="d-flex justify-content-between align-items-center mb-3"
                        >
                          <p className="mb-0">
                            <strong>Advance Amount:</strong>
                          </p>
                          <InputNumber
                            value={formatCurrency(calculatorData.fundingAmount)}
                            readOnly={true}
                            className="result-input"
                          />
                        </Col>
                        <Col
                          span={24}
                          className="d-flex justify-content-between align-items-center mb-3"
                        >
                          <p className="mb-0">
                            <strong>Term (Months):</strong>
                          </p>
                          <InputNumber
                            value={calculatorData.dealMonths}
                            readOnly={true}
                            className="result-input"
                          />
                        </Col>
                        <Col
                          span={24}
                          className="d-flex justify-content-between align-items-center mb-3"
                        >
                          <p className="mb-0">
                            <strong>Buy Rate:</strong>
                          </p>
                          <InputNumber
                            value={currentBuyRate?currentBuyRate:calculatorData.buyRate}
                            readOnly={true}
                            className="result-input"
                          />
                        </Col>
                        <Col
                          span={24}
                          className="d-flex justify-content-between align-items-center mb-3"
                        >
                          <p className="mb-0">
                            <strong>Total Repayment Amount:</strong>
                          </p>
                          <InputNumber
                            value={formatCurrency(roundToNearestTen(calculatorData.paybackAmount))}
                            readOnly={true}
                            className="result-input"
                          />
                        </Col>
                        <Col
                          span={24}
                          className="d-flex justify-content-between align-items-center mb-3"
                        >
                          <p className="mb-0">
                            <strong>Payment Amount:</strong>
                          </p>
                          <InputNumber
                            value={
                              inputValues.Payment_Frequency === "Weekly"
                                ? formatCurrency(calculatorData.weeklyAmount)
                                : formatCurrency(calculatorData.dailyAmount)
                            }
                            readOnly={true}
                            className="result-input"
                          />
                        </Col>
                        <Col
                          span={24}
                          className="d-flex justify-content-between align-items-center mb-3"
                        >
                          <p className="mb-0">
                            <strong>Payment Frequency:</strong>
                          </p>
                          <InputNumber
                            value={calculatorData.paymentFrequency}
                            readOnly={true}
                            className="result-input"
                          />
                        </Col>
                      </Row>
                      <div className="mt-5">
                        <Button
                          type="primary"
                          onClick={handleSubmit}
                          disabled={
                            updateOfferState.isLoading ||
                            !inputValues.Term_Months__c ||
                            !inputValues.Payment_Frequency
                          }
                          className="w-100 py-3 h-auto"
                        >
                          Submit Selected Offer
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            ) : flexOffer &&
              (flexOffer.Status__c !== "Presented" ||
                (flexOffer.Opportunity__r &&
                  flexOffer.Opportunity__r.StageName !== "Approved")) ? (
              <p className="mt-5 text-center sos-text-large text-danger sos-font-weight-600">
                Offer is no longer available
              </p>
            ) : null}
          </Loader>
        </Card>
      </div>
    </div>
  );
};

export default OfferCalculator;
