import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Card, Divider, Spin, Button, message } from 'antd';
import { CardTitle, Label,  } from 'reactstrap';
import { DownloadOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import { generatePaymentHistorySyndicator, generatePaymentHistorySyndicatorCleanup } from '../../../../store/actions/generate-payment-history-syndicator';
import { DatePicker } from 'antd';
import moment from 'moment';
//utils
import { formatCurrency } from '../../../../utils/helpers';

const SyndicatorDetails = () => {
  const dispatch = useDispatch();
  const getSyndicatorState = useSelector(s => s.getSyndicator);
  const syndicator = useSelector(s => s.getSyndicator.data[0]);
  const [pdfUrl, setPdfUrl] = useState(null);
  const generatePaymentHistoryState = useSelector(s => {
    return s.generatePaymentHistorySyndicator
  });

  const setLastWeekDate = (cDay) => {
    let cDate = new Date();
    cDate.setDate(cDate.getDate() - (cDate.getDay() || 7) + cDay);
    cDate.setDate(cDate.getDate() - 7);
    return moment(cDate.getDate(),'DD/MM/YYYY');
  }

  const [cDateStartDateF, setDateStartDate] = useState(setLastWeekDate(1));
  const [cDateEndDateF, setDateEndDate] = useState(setLastWeekDate(5));
  const generate = () => {
    dispatch(generatePaymentHistorySyndicator(syndicator.Id, cDateStartDateF, cDateEndDateF, false));
  };

  const generateBounce = () => {
    dispatch(generatePaymentHistorySyndicator(syndicator.Id, cDateStartDateF, cDateEndDateF, true));
  };

  useEffect(() => {
    if(generatePaymentHistoryState.isSuccessful){
      setPdfUrl(generatePaymentHistoryState.data);
      dispatch(generatePaymentHistorySyndicatorCleanup());
    }else if(generatePaymentHistoryState.error){
      message.error(`Oops! ${generatePaymentHistoryState.error} `, 4)
      dispatch(generatePaymentHistorySyndicatorCleanup());
    }
  },[generatePaymentHistoryState]);
  
  return (
    <div className="container syndicator-details-page details-page">
      <div className="table-container">
        <Card>
          <CardTitle className="text-cente p-4">
            <div className="sos-text-primary mb-0 font-weight-bold d-flex justify-content-between">
              <div>Syndicator Payable Report</div>
            </div>
          </CardTitle>
        </Card>
        <hr />
        <Card>
          {getSyndicatorState.isLoading && !syndicator ? (
            <Spin size='large' className='loader' />
          ) : syndicator ? (
            <div>
              <Divider orientation="left">
                <h4 className='sos-font-weight-600 mt-0 mb-0'>Details</h4>
              </Divider>
              <div className='row'>
                <div className='col-md-6'>
                <Label className='text-medium'>Start Date<span className='text-danger'>*</span></Label>
                    <DatePicker
                      className='col-12'
                      value={cDateStartDateF}
                      onChange={date => setDateStartDate(date)}
                      onBlur={date => {}}
                      allowClear={false}
                      format='MM/DD/YYYY'
                    />
                </div>
                <div className='col-md-6'>
                <Label className='text-medium'>End Date<span className='text-danger'>*</span></Label>
                    <DatePicker
                      className='col-12'
                      value={cDateEndDateF}
                      onChange={date => setDateEndDate(date)}
                      onBlur={() => {}}
                      allowClear={false}
                      format='MM/DD/YYYY'
                    />
                </div>
              </div>
              <div>
              <div className='mb-4 font-weight-bold'>
                { /* <Link className="sos-text-primary" >Link to Opportunity</Link> */ }
              </div>
              <>
                <Button onClick={generate}>
                  Generate Payable Report
                </Button>
                 
                <Button onClick={generateBounce}>
                Generate Bounce Report
                </Button>
              </>
              <><br /><br /></>
              {pdfUrl && (
                <a className="sos-text-primary font-weight-bold" href={pdfUrl} target='_blank'>
                  Download <DownloadOutlined className="sos-text-large" />
                </a> 
              )}
            </div>
            </div>
          ) : null}
        </Card>
      </div>
    </div>
  );
}

export default SyndicatorDetails;
