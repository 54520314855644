import React, { useRef, forwardRef, useImperativeHandle, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Row,
  Col,
  Label,
  FormGroup,
  Input,
  Form
} from 'reactstrap';
import Select from "react-select";
import { Modal } from 'antd';
import { Link } from 'react-router-dom';

//utils
import { LegalEntityType, PurposeOfFunding, LeadSource, yesNoOptions } from '../../../../../utils/dropdown-values'
import { getValueObject, formatTaxId } from '../../../../../utils/helpers';
import industries from '../../../../../utils/industries';

const Step3 = ({ values , initialVal}, ref) => {
  const formikRef = useRef();
  const [modalVisible, setModalVisible] = useState(false);
  const taxIdRegex = /^\d{2}\-?\d{7}$/;
  const phoneRegex = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;
  const urlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;

  const validationSchema = Yup.object().shape({
    DBA_Name__c: Yup.string()
      .trim()
      .required('Business Name is required')
      .min(2, 'Company Name should have minimum of two characters'),
    Legal_Name__c: Yup.string()
      .trim()
      .required('Business Legal Name is required')
      .min(2, 'Business Legal Name should have minimum of two characters'),
    Industry: Yup.string()
      .required('Industry is required'),
    Purpose_of_Funding__c: Yup.string()
      .required('Funding Purpose is required'),
    Legal_Entity_Type__c: Yup.string()
      .required('Legal Entity Type is required'),
    Tax_ID__c: Yup.string()
      .trim()
      .required('Tax ID is required')
      .matches(taxIdRegex,'Tax ID is not valid'),
    Average_Daily_Balance__c: Yup.number()
      .required('Average Bank Balance is required')
      .min(0, 'Average Bank Balance should not be less than 0'),
    LeadSource: Yup.string()
      .required('Lead Source is required'),
    LeadSourceDetail: Yup.string()
      .when('LeadSource', {
        is: (LeadSource) => LeadSource === 'Partner',
        then: Yup.string()
          .trim()
          .required('Partner that referred you is required')
      }),
    BusinessPhone: Yup.string()
      .trim()
      .required('Business Phone number is mandatory.')
      .matches(phoneRegex,'Business Phone number is not a valid phone number'),
    BillingStreet: Yup.string()
      .trim()
      .required('Billing Street is required')
      .min(2, 'Billing Street should have minimum of two characters'),
    BillingCity: Yup.string()
      .trim()
      .required('Billing City is required')
      .min(2, 'Billing City should have minimum of two characters'),
    BillingState: Yup.string()
      .trim()
      .required('Billing State is required')
      .min(2, 'Billing State should have minimum of two characters'),
    BillingCountry: Yup.string()
      .trim()
      .min(2, 'Billing Country should have minimum of two characters'),
      BillingPostalCode: Yup.string()
      .trim()
      .required('Postal Code is required')
      .min(2, 'Billing Postal Code should have minimum of two characters'),
    Website: Yup.string()
      .trim()
      .matches(urlRegex, 'Website should be a valid url'),
    OutstandingDebtExist: Yup.boolean()
      .required('Select an option'),
    OutstandingDebtBalance: Yup.number()
      .when('OutstandingDebtExist', {
        is: (OutstandingDebtExist) => OutstandingDebtExist,
        then: Yup.number()
          .required('Outstanding debt balance is required')
          .min(1, 'Outstanding debt balance should be greater than 0'),
      }),
    OutstandingDebtCreditor: Yup.string()
      .when('OutstandingDebtExist', {
        is: (OutstandingDebtExist) => OutstandingDebtExist,
        then: Yup.string()
          .trim()
          .required('Who the outstanding debt is with is required')
          .min(2, 'Who the outstanding debt is with should have minimum of two characters'),
      }),
      agreement: Yup.bool()
      .required("Check the credit report diclaimer")
      .oneOf([true], "Check the credit report diclaimer"),
  });

  useImperativeHandle(ref, () => ({
    isValid: async () => {
      const errors = await formikRef.current.validateForm();
      if(errors.constructor === Object && Object.keys(errors).length === 0){
        return true;
      }else{
        return false;
      }
    },
    getValues: () => {
      return formikRef.current.values
    },
    handleSubmit: () => {
      formikRef.current.handleSubmit();
    }
  }));

  const showDisclaimer = (e) => {
    e.preventDefault();
    setModalVisible(true);
  }

  return (
    <div className='mt-5'>
      <Formik
      initialValues={{
        DBA_Name__c: initialVal.Name ? initialVal.Name : "",
        Industry: initialVal.Industry ? initialVal.Industry : "",
        Legal_Entity_Type__c: initialVal.Type_of_Entity__c
          ? initialVal.Type_of_Entity__c
          : "",
        Average_Daily_Balance__c: "",
        Legal_Name__c: initialVal.Legal_Name__c
          ? initialVal.Legal_Name__c
          : "",
        Purpose_of_Funding__c: "",
        Tax_ID__c: initialVal.Federal_Tax_ID_No__c
          ? initialVal.Federal_Tax_ID_No__c
          : "",
        LeadSource: initialVal.LeadSource ? initialVal.LeadSource : "",
        Website: initialVal.Website ? initialVal.Website : "",
        BusinessPhone: initialVal.Phone ? initialVal.Phone : "",
        BillingStreet: initialVal.BillingStreet
            ? initialVal.BillingStreet
            : "",
        BillingCity: initialVal.BillingCity ? initialVal.BillingCity : "",
        BillingState: initialVal.BillingState ? initialVal.BillingState : "",
        BillingPostalCode: initialVal.BillingPostalCode
            ? initialVal.BillingPostalCode
            : "",
         BillingCountry: initialVal.BillingCountry
            ? initialVal.BillingCountry
            : "",
        agreement: false,
        Sic: initialVal.Sic ? initialVal.Sic : "",
        ...values,
      }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
        }}
        enableReinitialize={true}
        validationSchema={validationSchema}
        innerRef={formikRef}
      >
        {({ handleChange, values, errors, touched, handleBlur, setFieldValue, setFieldTouched }) => (
          <Form>
            <Row>
            <Col md="6" sm="12" className='mb-4'>
                <FormGroup className={`${errors.DBA_Name__c && touched.DBA_Name__c ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>Business Name / DBA <span className='text-danger'>*</span></Label>
                  <Input
                    placeholder=""
                    type="text" 
                    name='DBA_Name__c'
                    onChange={handleChange('DBA_Name__c')}
                    onBlur={handleBlur('DBA_Name__c')}
                    value={values.DBA_Name__c}
                  />
                </FormGroup>
                <small className='text-danger'>{errors.DBA_Name__c && touched.DBA_Name__c ? errors.DBA_Name__c : null}</small>
              </Col>
              <Col md="6" sm="12" className='mb-4'>
                <FormGroup className={`${errors.Legal_Name__c && touched.Legal_Name__c ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>Business Legal Name<span className='text-danger'>*</span></Label>
                  <Input
                    placeholder=""
                    type="text"
                    name='Legal_Name__c'
                    onChange={handleChange('Legal_Name__c')}
                    onBlur={handleBlur('Legal_Name__c')}
                    value={values.Legal_Name__c}
                  />
                </FormGroup>
                <small className='text-danger'>{errors.Legal_Name__c && touched.Legal_Name__c ? errors.Legal_Name__c : null}</small>
              </Col>
              <Col md="6" sm="12" className='mb-4'>
                <Label className='text-medium'>Industry <span className='text-danger'>*</span></Label>
                <Select
                  className="react-select info"
                  classNamePrefix="react-select"
                  placeholder="--None--"
                  name="Industry"
                  value={getValueObject(industries, values.Sic)}
                  onChange={value => {
                    setFieldValue('Industry', value.label);
                    setFieldValue('Sic', value.value);
                  }}
                  options={industries}
                  onBlur={() => setFieldTouched('Industry')}
                />
                <small className='text-danger'>{errors.Industry && touched.Industry ? errors.Industry : null}</small>
              </Col>
              <Col md="6" sm="12" className='mb-4'>
                <Label className='text-medium'>Funding Purpose <span className='text-danger'>*</span></Label>
                <Select
                  className="react-select info"
                  classNamePrefix="react-select"
                  placeholder="--None--"
                  name="Purpose_of_Funding__c"
                  value={getValueObject(PurposeOfFunding, values.Purpose_of_Funding__c)}
                  onChange={value => setFieldValue('Purpose_of_Funding__c', value.value)}
                  options={PurposeOfFunding}
                  onBlur={() => setFieldTouched('Purpose_of_Funding__c')}
                />
                <small className='text-danger'>{errors.Purpose_of_Funding__c && touched.Purpose_of_Funding__c ? errors.Purpose_of_Funding__c : null}</small>
              </Col>
              <Col md="6" sm="12" className='mb-4'>
                <Label className='text-medium'>Legal Entity Type <span className='text-danger'>*</span></Label>
                <Select
                  className="react-select info"
                  classNamePrefix="react-select"
                  placeholder="--None--"
                  name="Legal_Entity_Type__c"
                  value={getValueObject(LegalEntityType, values.Legal_Entity_Type__c)}
                  onChange={value => setFieldValue('Legal_Entity_Type__c', value.value)}
                  options={LegalEntityType}
                  onBlur={() => setFieldTouched('Legal_Entity_Type__c')}
                />
                <small className='text-danger'>{errors.Legal_Entity_Type__c && touched.Legal_Entity_Type__c ? errors.Legal_Entity_Type__c : null}</small>
              </Col>
              <Col md="6" sm="12" className='mb-4'>
                <FormGroup className={`${errors.Tax_ID__c && touched.Tax_ID__c ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>TaxID <span className='text-danger'>*</span></Label>
                  <Input
                    placeholder=""
                    type="text" 
                    name='Tax_ID__c'
                    onChange={e => setFieldValue('Tax_ID__c', formatTaxId(e.target.value))}
                    onBlur={handleBlur('Tax_ID__c')}
                    value={values.Tax_ID__c}
                  />
                </FormGroup>
                <small className='text-danger'>{errors.Tax_ID__c && touched.Tax_ID__c ? errors.Tax_ID__c : null}</small>
              </Col>
              <Col md="6" sm="12" className='mb-4'>
                <FormGroup className={`${errors.Average_Daily_Balance__c && touched.Average_Daily_Balance__c ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>Average Bank Balance <span className='text-danger'>*</span></Label>
                  <Input
                    placeholder=""
                    type="text" 
                    name='Average_Daily_Balance__c'
                    onChange={handleChange('Average_Daily_Balance__c')}
                    onBlur={handleBlur('Average_Daily_Balance__c')}
                    value={values.Average_Daily_Balance__c}
                  />
                </FormGroup>
                <small className='text-danger'>{errors.Average_Daily_Balance__c && touched.Average_Daily_Balance__c ? errors.Average_Daily_Balance__c : null}</small>
              </Col>
              <Col md="6" sm="12" className='mb-4'>
                <Label className='text-medium'>Any outstanding debt on the business <span className='text-danger'>*</span></Label>
                <Select
                  className="react-select info"
                  classNamePrefix="react-select"
                  placeholder="--None--"
                  name="OutstandingDebtExist"
                  value={getValueObject(yesNoOptions, values.OutstandingDebtExist === undefined ? '' : values.OutstandingDebtExist ? 'Yes' : 'No')}
                  onChange={value => setFieldValue('OutstandingDebtExist', value.value  === 'Yes' ? true : false)}
                  options={yesNoOptions}
                  onBlur={() => setFieldTouched('OutstandingDebtExist')}
                />
                <small className='text-danger'>{errors.OutstandingDebtExist && touched.OutstandingDebtExist ? errors.OutstandingDebtExist : null}</small>
              </Col>
              <Col md="12" sm="12" className='mb-4'>
                <FormGroup className={`${errors.BillingStreet && touched.BillingStreet ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>Billing Street <span className='text-danger'>*</span></Label>
                  <Input
                    placeholder=""
                    type="text" 
                    name='BillingStreet'
                    onChange={handleChange('BillingStreet')}
                    onBlur={handleBlur('BillingStreet')}
                    value={values.BillingStreet}
                  />
                </FormGroup>
                <small className='text-danger'>{errors.BillingStreet && touched.BillingStreet ? errors.BillingStreet : null}</small>
              </Col>
              <Col md="6" sm="12" className='mb-4'>
                <FormGroup className={`${errors.BillingCity && touched.BillingCity ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>Billing City <span className='text-danger'>*</span></Label>
                  <Input
                    placeholder=""
                    type="text" 
                    name='BillingCity'
                    onChange={handleChange('BillingCity')}
                    onBlur={handleBlur('BillingCity')}
                    value={values.BillingCity}
                  />
                </FormGroup>
                <small className='text-danger'>{errors.BillingCity && touched.BillingCity ? errors.BillingCity : null}</small>
              </Col>
              <Col md="6" sm="12" className='mb-4'>
                <FormGroup className={`${errors.BillingState && touched.BillingState ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>Billing State/Province <span className='text-danger'>*</span></Label>
                  <Input
                    placeholder=""
                    type="text" 
                    name='BillingState'
                    onChange={handleChange('BillingState')}
                    onBlur={handleBlur('BillingState')}
                    value={values.BillingState}
                  />
                </FormGroup>
                <small className='text-danger'>{errors.BillingState && touched.BillingState ? errors.BillingState : null}</small>
              </Col>
              <Col md="6" sm="12" className='mb-4'>
                <FormGroup className={`${errors.BillingPostalCode && touched.BillingPostalCode ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>Billing Zip Code <span className='text-danger'>*</span></Label>
                  <Input
                    placeholder=""
                    type="text" 
                    name='BillingPostalCode'
                    onChange={handleChange('BillingPostalCode')}
                    onBlur={handleBlur('BillingPostalCode')}
                    value={values.BillingPostalCode}
                  />
                </FormGroup>
                <small className='text-danger'>{errors.BillingPostalCode && touched.PostalCode ? errors.PostalCode : null}</small>
              </Col>
              <Col md="6" sm="12" className='mb-4'>
                <FormGroup className={`${errors.BillingCountry && touched.BillingCountry ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>Billing Country</Label>
                  <Input
                    placeholder=""
                    type="text" 
                    name='BillingCountry'
                    onChange={handleChange('BillingCountry')}
                    onBlur={handleBlur('BillingCountry')}
                    value={values.BillingCountry}
                  />
                </FormGroup>
                <small className='text-danger'>{errors.BillingCountry && touched.BillingCountry ? errors.BillingCountry : null}</small>
              </Col>
              {values.OutstandingDebtExist ? (
                <Col md="6" sm="12" className='mb-4'>
                  <FormGroup className={`${errors.OutstandingDebtCreditor && touched.OutstandingDebtCreditor ? 'mb-0' : ''}`}>
                    <Label className='text-medium'>Who the outstanding debt is with? <span className='text-danger'>*</span></Label>
                    <Input
                      placeholder=""
                      type="text" 
                      name='OutstandingDebtCreditor'
                      onChange={handleChange('OutstandingDebtCreditor')}
                      onBlur={handleBlur('OutstandingDebtCreditor')}
                      value={values.OutstandingDebtCreditor}
                    />
                  </FormGroup>
                  <small className='text-danger'>{errors.OutstandingDebtCreditor && touched.OutstandingDebtCreditor ? errors.OutstandingDebtCreditor : null}</small>
                </Col>
              ) : null}
              {values.OutstandingDebtExist ? (
                <Col md="6" sm="12" className='mb-4'>
                  <FormGroup className={`${errors.OutstandingDebtBalance && touched.OutstandingDebtBalance ? 'mb-0' : ''}`}>
                    <Label className='text-medium'>Outstanding Balance <span className='text-danger'>*</span></Label>
                    <Input
                      placeholder=""
                      type="text" 
                      name='OutstandingDebtBalance'
                      onChange={handleChange('OutstandingDebtBalance')}
                      onBlur={handleBlur('OutstandingDebtBalance')}
                      value={values.OutstandingDebtBalance}
                    />
                  </FormGroup>
                  <small className='text-danger'>{errors.OutstandingDebtBalance && touched.OutstandingDebtBalance ? errors.OutstandingDebtBalance : null}</small>
                </Col>
              ) : null}
              <Col md="6" sm="12" className='mb-4'>
                <FormGroup className={`${errors.BusinessPhone && touched.BusinessPhone ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>Business Phone Number  <span className='text-danger'>*</span></Label>
                  <Input
                    placeholder=""
                    type="text" 
                    name='BusinessPhone'
                    onChange={handleChange('BusinessPhone')}
                    onBlur={handleBlur('BusinessPhone')}
                    value={values.BusinessPhone}
                  />
                </FormGroup>
                <small className='text-danger'>{errors.BusinessPhone && touched.BusinessPhone ? errors.BusinessPhone : null}</small>
              </Col>
              <Col md="6" sm="12" className='mb-4'>
                <FormGroup className={`${errors.Website && touched.Website ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>Website</Label>
                  <Input
                    placeholder=""
                    type="text" 
                    name='Website'
                    onChange={handleChange('Website')}
                    onBlur={handleBlur('Website')}
                    value={values.Website}
                  />
                </FormGroup>
                <small className='text-danger'>{errors.Website && touched.Website ? errors.Website : null}</small>
              </Col>
              <Col md="6" sm="12" className='mb-4'>
                <Label className='text-medium'>How Did You Hear About Us? <span className='text-danger'>*</span></Label>
                <Select
                  className="react-select info"
                  classNamePrefix="react-select"
                  placeholder="--None--"
                  name="LeadSource"
                  value={getValueObject(LeadSource, values.LeadSource)}
                  onChange={value => {
                    if(value.LeadSourceDetail && value.value !== 'Partner'){
                      setFieldValue('LeadSourceDetail', '');
                    }
                    setFieldValue('LeadSource', value.value);
                  }}
                  options={LeadSource}
                  onBlur={() => setFieldTouched('LeadSource')}
                />
                <small className='text-danger'>{errors.LeadSource && touched.LeadSource ? errors.LeadSource : null}</small>
              </Col>
              {values.LeadSource === 'Partner' || values.LeadSource === 'Employee Referral' ||
                values.LeadSource === 'Website' || values.LeadSource === 'Advertisement' ? (
                <Col md="6" sm="12" className='mb-4'>
                  <FormGroup className={`${errors.LeadSourceDetail && touched.LeadSourceDetail ? 'mb-0' : ''}`}>
                    <Label className='text-medium'>Specify the Referral (Partner/Employee/Website/Advertisement) <span className='text-danger'>*</span></Label>
                    <Input
                      placeholder=""
                      type="text"
                      name='LeadSourceDetail'
                      onChange={handleChange('LeadSourceDetail')}
                      onBlur={handleBlur('LeadSourceDetail')}
                      value={values.LeadSourceDetail}
                    />
                  </FormGroup>
                  <small className='text-danger'>{errors.LeadSourceDetail && touched.LeadSourceDetail ? errors.LeadSourceDetail : null}</small>
                </Col>
              ) : null}
            </Row>
            <FormGroup check className="text-left">
              <Label check>
                <Input
                  type="checkbox"
                  onChange={handleChange("agreement")}
                  onBlur={handleBlur("agreement")}
                  checked={values.agreement}
                />
                <span className="form-check-sign" />I authorize altbanq to access my credit report -{" "}
                <a href="#" onClick={showDisclaimer}>
                  Credit Authorization Disclaimer
                </a>
                .
              </Label>
            </FormGroup>
            <small className="text-danger">
              {errors.agreement && touched.agreement
                ? errors.agreement
                : null}
            </small>
          </Form>
        )}
      </Formik>
      <Modal
        title="Credit Disclaimer"
        visible={modalVisible}
        footer={null}
        onCancel={() => setModalVisible(false)}
      >
        <p>
          Your business is applying generally for credit and not for a specific credit product or amount. 
          Any product/amount offered will depend on the credit and underwriting standards of the lender.
         <br />
          By clicking “Continue”, you 
          <br />
          (i) consent to receiving telemarketing calls and messages, including 
          calls using an automatic telephone dialing system, from Altbanq and those acting on its behalf at the 
          telephone number you have provided above (including your cellular phone number); agree that this consent 
          applies even if the number you have provided is currently on any state, federal, or corporate Do-Not-Call 
          registry; and understand that you are not required to provide this consent as a condition of receiving any 
          credit or services from Altbanq and that you may apply for business credit by contacting us directly; and 
          <br />
          (ii) acknowledge that you have read SOS’s <Link to='/terms'>Application Agreement</Link> and <Link to='/privacy-policy'>Privacy Policy</Link> and understand that you 
          may opt out of receiving communications of your choice from Altbanq as provided in the Privacy Policy.
        </p>
        
      </Modal>
    </div>
  );
};

export default forwardRef(Step3);