import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';

export const getSinglePaymentStart = () => ({
  type: types.GET_SINGLE_PAYMENT_START
});

export const getSinglePaymentSuccess = payload => ({
  type: types.GET_SINGLE_PAYMENT_SUCCESS,
  payload
});

export const getSinglePaymentFail = payload => ({
  type: types.GET_SINGLE_PAYMENT_FAIL,
  payload
});

export const getSinglePaymentCleanup = () => ({
  type: types.GET_SINGLE_PAYMENT_CLEANUP
});

export const getSinglePayment = id => async dispatch => {
  try {
    dispatch(getSinglePaymentStart());
    const path =  `payment/${id}`;
    const requestObj = {
      path,
      method: 'GET'
    }
    const  data  = await AxiosCall(requestObj);
    dispatch(getSinglePaymentSuccess(data[0]));
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(getSinglePaymentFail(error));
  }
}
