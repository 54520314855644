import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';

export const getPaymentsStart = () => ({
  type: types.GET_PAYMENTS_START
});

export const getPaymentsSuccess = payload => ({
  type: types.GET_PAYMENTS_SUCCESS,
  payload
});

export const getPaymentsFail = payload => ({
  type: types.GET_PAYMENTS_FAIL,
  payload
});

export const getPaymentsCleanup = () => ({
  type: types.GET_PAYMENTS_CLEANUP
});

export const getPayments = repaymentId => async dispatch => {
  try {
    dispatch(getPaymentsStart());
    const path = `payment/repayment/${repaymentId}`;
    const requestObj = {
      path,
      method: 'GET'
    }
    const  data  = await AxiosCall(requestObj);
    dispatch(getPaymentsSuccess(data));
    
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(getPaymentsFail(error));
  }
}
