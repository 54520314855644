import "./index.css";
import React, { useState, useRef, useEffect } from "react";
import { Row, Col, CardBody, CardTitle } from "reactstrap";
import { Steps, Button, message, Card } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactGA from "react-ga";

// components
import Step1 from "./Steps/step1";
import Step2 from "./Steps/step2";
import Step3 from "./Steps/step3";
import Loader from "../../../components/Loader";
import AccountOptionModal from "./AccountOptionModal";

//actions
import {
  getLookupList,
  getLookupListCleanup,
} from "../../../store/actions/get-lookup-list";
import {
  createBrokerFunding,
  createBrokerFundingCleanup,
} from "../../../store/actions/create-broker-funding";
import {
  getAccount,
  getAccountCleanup,
} from "../../../store/actions/get-account";
import {
  getSingleAccount,
  getSingleAccountCleanup,
} from "../../../store/actions/get-single-account";

const AddDeal = () => {
  const { Step } = Steps;
  const [current, setCurrent] = useState(0);
  const step1Ref = useRef();
  const step2Ref = useRef();
  const step3Ref = useRef();
  const [step1Values, setStep1Values] = useState({});
  const [step2Values, setStep2Values] = useState({});
  const [step3Values, setStep3Values] = useState({});
  const [allAccounts, setAllAccount] = useState([]);
  const [singleAccounData, setSingleAccountData] = useState({});
  const [modalVisible, setModalVisible] = useState(false);

  const dispatch = useDispatch();
  const createBrokerFundingState = useSelector((s) => s.createBrokerFunding);
  const getAccountState = useSelector((s) => s.getAccount);
  const getSingleAccountState = useSelector((s) => s.getSingleAccount);

  const history = useHistory();
  const steps = [
    {
      title: "Business",
      icon: "nc-icon nc-touch-id",
      component: (
        <Step1
          ref={step1Ref}
          values={step1Values}
          initialVal={singleAccounData}
        />
      ),
    },
    {
      title: "Owner",
      icon: "nc-icon nc-single-02",
      component: <Step2 ref={step2Ref} values={step2Values} />,
    },
    {
      title: "Others",
      icon: "nc-icon nc-pin-3",
      component: (
        <Step3
          ref={step3Ref}
          values={step3Values}
          initialVal={singleAccounData}
        />
      ),
    },
  ];

  const validateAndNext = async (ref, setState) => {
    ref.current.handleSubmit();
    const isValid = await ref.current.isValid();
    if (isValid) {
      setState(ref.current.getValues());
      setCurrent(current + 1);
    }
  };

  const next = () => {
    switch (current) {
      case 0:
        validateAndNext(step1Ref, setStep1Values);
        break;
      case 1:
        validateAndNext(step2Ref, setStep2Values);
        break;
      default:
        break;
    }
  };

  const prev = () => {
    setCurrent(current - 1);
    switch (current) {
      case 1:
        setStep2Values(step2Ref.current.getValues());
        break;
      case 2:
        setStep3Values(step3Ref.current.getValues());
        break;
      default:
        break;
    }
  };

  const addSingleAccounData = (id) => {
    dispatch(getSingleAccount(id));
  };

  const handleSubmit = async () => {
    step3Ref.current.handleSubmit();
    const isValid = await step3Ref.current.isValid();

    if (isValid) {
      const thirdStepValues = step3Ref.current.getValues();
      setStep3Values(thirdStepValues);

      const {
        Name,
        Legal_Name__c,
        DBA__c,
        Type_of_Entity__c,
        Industry,
        Date_Business_Established__c,
        Federal_Tax_ID_No__c,
        StageName,
        Closedate,
        Years_in_Business__c,
        BillingStreet,
        BillingCity,
        BillingState,
        BillingPostalCode,
        Phone,
        Fax,
        Website,
        Number_Bank_Statements_Available__c,
        NDBs_avg__c,
        Use_of_Proceeds__c,
        Requested_Amount_of_Funds__c,
        Credit_Card_Sales__c,
        Avg_Gross_Sales_Used__c,
        Id,
      } = step1Values;

      const {
        Bank_Name__c,
        Bank_Contact__c,
        Bank_Phone__c,
        Business_Name__c,
        Business_Contact_Account__c,
        Business_Phone__c,
        Landlord_Mortgagee__c,
        Landlord_Contact_Name__c,
        Landlord_Phone__c,
        Lease_start_date__c,
        Time_Left_On_Lease__c,
        Monthly_Payment__c,
        Square_Footage__c,
        Ever_used_a_Business_Cash_Advance__c,
        Cash_Advance_Company__c,
        Balance__c,
        Sales_Rep__c,
      } = thirdStepValues;

      const Account = {
        Id,
        Name,
        Legal_Name__c,
        DBA__c,
        Type_of_Entity__c,
        Industry,
        Date_Business_Established__c,
        Federal_Tax_ID_No__c,
        Years_in_Business__c,
        BillingStreet,
        BillingCity,
        BillingState,
        BillingPostalCode,
        Phone,
        Fax,
        Website,
        Number_Bank_Statements_Available__c,
        Use_of_Proceeds__c,
        Requested_Amount_of_Funds__c,
        Credit_Card_Sales__c,
        Avg_Gross_Sales_Used__c,
        Bank_Name__c,
        Bank_Contact__c,
        Bank_Phone__c,
        Business_Name__c,
        Business_Contact_Account__c,
        Business_Phone__c,
        Landlord_Mortgagee__c,
        Landlord_Contact_Name__c,
        Landlord_Phone__c,
        Lease_start_date__c,
        Time_Left_On_Lease__c,
        Monthly_Payment__c,
        Square_Footage__c,
      };

      const Opportunity = {
        StageName,
        Closedate,
        NDBs_avg__c,
        Ever_used_a_Business_Cash_Advance__c,
        Cash_Advance_Company__c,
        Balance__c,
        Sales_Rep__c,
      };

      const {
        FirstName,
        LastName,
        Street,
        State,
        PostalCode,
        City,
        Email,
        MobilePhone,
        Phone: Owner1Phone,
        HomePhone,
        DateOfBirth,
        Owner1Ownership,
        Owner1SSN,
        Owner2FirstName,
        Owner2LastName,
        Owner2Street,
        Owner2State,
        Owner2PostalCode,
        Owner2City,
        Owner2Email,
        Owner2MobilePhone,
        Owner2Phone,
        Owner2HomePhone,
        Owner2DateOfBirth,
        Owner2Ownership,
        Owner2SSN,
        Owner3FirstName,
        Owner3LastName,
        Owner3Street,
        Owner3State,
        Owner3PostalCode,
        Owner3City,
        Owner3Email,
        Owner3MobilePhone,
        Owner3Phone,
        Owner3HomePhone,
        Owner3DateOfBirth,
        Owner3Ownership,
        Owner3SSN,
      } = step2Values;

      const Contact1 = {
        FirstName,
        LastName,
        MailingStreet: Street,
        MailingCity: City,
        MailingState: State,
        MailingPostalCode: PostalCode,
        MobilePhone,
        Phone: Owner1Phone,
        HomePhone,
        Email,
        Of_Ownership__c: Owner1Ownership,
        Birthdate: DateOfBirth,
        Social_Security_Number1__c: Owner1SSN,
      };

      const Contact2 = {
        FirstName: Owner2FirstName,
        LastName: Owner2LastName,
        MailingStreet: Owner2Street,
        MailingCity: Owner2City,
        MailingState: Owner2State,
        MailingPostalCode: Owner2PostalCode,
        MobilePhone: Owner2MobilePhone,
        Phone: Owner2Phone,
        HomePhone: Owner2HomePhone,
        Email: Owner2Email,
        Of_Ownership__c: Owner2Ownership,
        Birthdate: Owner2DateOfBirth,
        Social_Security_Number1__c: Owner2SSN,
      };

      const Contact3 = {
        FirstName: Owner3FirstName,
        LastName: Owner3LastName,
        MailingStreet: Owner3Street,
        MailingCity: Owner3City,
        MailingState: Owner3State,
        MailingPostalCode: Owner3PostalCode,
        MobilePhone: Owner3MobilePhone,
        Phone: Owner3Phone,
        HomePhone: Owner3HomePhone,
        Email: Owner3Email,
        Of_Ownership__c: Owner3Ownership,
        Birthdate: Owner3DateOfBirth,
        Social_Security_Number1__c: Owner3SSN,
      };

      const payload = {
        Account,
        Opportunity,
        Contact1,
        Contact2,
        Contact3,
      };

      dispatch(createBrokerFunding(payload));
    }
  };

  useEffect(() => {
    dispatch(getLookupListCleanup());
    dispatch(getLookupList("isosalesrep"));
    dispatch(getAccount());
  }, []);

  useEffect(() => {
    if (createBrokerFundingState.isSuccessful) {
      message.success("Application submitted successfully");
      setStep1Values({});
      setStep2Values({});
      setStep3Values({});
      setCurrent(0);
      dispatch(createBrokerFundingCleanup());
      history.push(`/broker/application-success`);
    } else if (createBrokerFundingState.error) {
      message.error("Oops! Application submission failed");
      dispatch(createBrokerFundingCleanup());
    }
  }, [createBrokerFundingState]);

  useEffect(() => {
    if (getAccountState.isSuccessful) {
      setAllAccount(getAccountState.data);
      dispatch(getAccountCleanup());
    } else if (getAccountState.error) {
      dispatch(getAccountCleanup());
    }
  }, [getAccountState]);

  useEffect(() => {
    if (getSingleAccountState.isSuccessful) {
      setSingleAccountData(getSingleAccountState.data[0]);
      setModalVisible(false);
      dispatch(getSingleAccountCleanup());
    } else if (getSingleAccountState.error) {
      dispatch(getSingleAccountCleanup());
    }
  }, [getSingleAccountState]);

  useEffect(() => {
    ReactGA.pageview(
      window.location.pathname + window.location.search,
      [],
      "Broker Application"
    );
  }, []);

  return (
    <div className="pt-5 container add-deal-page">
      <div className="pt-5 table-container">
        <Card>
          <CardTitle className="text-cente p-4">
            <div className="sos-text-primary mb-0 font-weight-bold d-flex flex-wrap-wrap flex-wrap justify-content-between">
              <div>Add Deal</div>
            </div>
          </CardTitle>
        </Card>
        <hr />
        <Row>
          <Col className="mr-auto ml-auto" sm="12">
            <Card>
              <CardTitle className="text-center p-4">
                <h2 className="sos-text-primary mb-0 font-weight-bold">
                  Add Deal
                </h2>
              </CardTitle>
              <CardBody className="p-5">
                <Loader
                  isLoading={createBrokerFundingState.isLoading}
                  text="Submitting application..."
                >
                  <Steps current={current}>
                    {steps.map((item) => (
                      <Step key={item.title} title={item.title} />
                    ))}
                  </Steps>
                  { current == 0 &&
                  <div className="pt-3">
                    <AccountOptionModal
                      modalVisible={modalVisible}
                      setModalVisible={setModalVisible}
                      loading={getSingleAccountState.isLoading}
                      accountNum={allAccounts.length}
                    >
                      <div className="container">
                        <div className="row">
                          {allAccounts.map((account) => (
                            <div
                              onClick={() => addSingleAccounData(account.Id)}
                              key={account.Id}
                              className="col-md-4"
                            >
                              <Card
                                className="card"
                                style={{ width: "100%", cursor: "pointer" }}
                              >
                                <div className="card-body">
                                  <h5 className="card-title">{account.Name}</h5>
                                  <p className="card-text">
                                    <b>Business Name :</b>{" "}
                                    {account.Business_Name__c}
                                  </p>
                                </div>
                              </Card>
                            </div>
                          ))}
                        </div>
                      </div>
                    </AccountOptionModal>
                  </div>
                  }
                  <div className="steps-content">
                    {steps[current].component}
                  </div>
                  <div className="steps-action mt-3">
                    {current > 0 && (
                      <Button
                        style={{ margin: "0 8px 0 0" }}
                        onClick={() => prev()}
                      >
                        Previous
                      </Button>
                    )}
                    {current < steps.length - 1 && (
                      <Button type="primary" onClick={() => next()}>
                        Next
                      </Button>
                    )}
                    {current === steps.length - 1 && (
                      <Button
                        type="primary"
                        onClick={handleSubmit}
                        disabled={createBrokerFundingState.isLoading}
                      >
                        Done
                      </Button>
                    )}
                  </div>
                </Loader>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AddDeal;
