import './index.css';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Card, Progress } from 'antd';
import { CardTitle, Card as RCard, CardBody, Row, Col } from 'reactstrap';


//actions
import { getBrokerMetrics, getBrokerMetricsCleanup } from '../../../store/actions/get-broker-metrics';

//utils
import { formatCurrency } from '../../../utils/helpers';

//components
import Loader from '../../../components/Loader';


const BrokerMetrics = () => {
  const dispatch = useDispatch();
  const getBrokerMetricsState = useSelector(s => s.getBrokerMetrics);
  const [brokerMetrics, setBrokerMetrics] = useState(null);
  useEffect(() => {
    dispatch(getBrokerMetrics(3));
  },[])

  useEffect(() => {
    if(getBrokerMetricsState.isSuccessful){
      setBrokerMetrics(getBrokerMetricsState.data);
      dispatch(getBrokerMetricsCleanup());
    }else if(getBrokerMetricsState.error){
      dispatch(getBrokerMetricsCleanup());
    }
  },[getBrokerMetricsState])

  return (
    <div className="pt-5  container broker-metrics-page">
      <div className="pt-5 table-container">
        <Card>
          <CardTitle className="text-cente p-4">
            <div className="sos-text-primary mb-0 font-weight-bold d-flex justify-content-between">
              <div>Broker Metrics</div>
            </div>
          </CardTitle>
        </Card>
        <hr />
        <Card>
          {getBrokerMetricsState.isLoading && !brokerMetrics ? (
            <div className='m-5 d-flex justify-content-center'>
              <Loader isLoading={getBrokerMetricsState.isLoading} />
            </div>
          ) : brokerMetrics ? (
            <div>
              <Row>
                <Col lg="4" md="6" sm="6">
                  <RCard className="card-stats">
                    <CardBody>
                      <Row>
                        <Col md="4" xs="5">
                          <div className="icon-big text-center icon-warning">
                            <i className="nc-icon nc-money-coins sos-text-primary" />
                          </div>
                        </Col>
                        <Col md="8" xs="7">
                          <div className="numbers">
                            <p className="card-category">Total Commissions</p>
                            <CardTitle tag="p">{brokerMetrics.Total_Commissions__c ? formatCurrency(brokerMetrics.Total_Commissions__c) : '$0.00'}</CardTitle>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </RCard>
                </Col>
                <Col lg="4" md="6" sm="6">
                  <RCard className="card-stats">
                    <CardBody>
                      <Row>
                        <Col md="4" xs="5">
                          <div className="icon-big text-center icon-warning">
                            <i className="fa fa-shopping-cart sos-text-primary" />
                          </div>
                        </Col>
                        <Col md="8" xs="7">
                          <div className="numbers">
                            <p className="card-category">Total Number of Deals</p>
                            <CardTitle tag="p">{`${brokerMetrics.Total_Number_of_Deals__c ? brokerMetrics.Total_Number_of_Deals__c : 0}`}</CardTitle>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </RCard>
                </Col>
                <Col lg="4" md="6" sm="6">
                  <RCard className="card-stats">
                    <CardBody>
                      <Row>
                        <Col md="4" xs="5">
                          <div className="icon-big text-center icon-warning">
                            <i className="fa fa-ban sos-text-primary" />
                          </div>
                        </Col>
                        <Col md="8" xs="7">
                          <div className="numbers">
                            <p className="card-category">Total Number of Declined Deals</p>
                            <CardTitle tag="p">{brokerMetrics.Total_Number_of_Declined_Deals__c ? brokerMetrics.Total_Number_of_Declined_Deals__c : 0}</CardTitle>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </RCard>
                </Col>
                <Col lg="4" md="6" sm="6">
                  <RCard className="card-stats">
                    <CardBody>
                      <Row>
                        <Col md="4" xs="5">
                          <div className="icon-big text-center icon-warning">
                            <i className="fa fa-check-circle-o sos-text-primary" />
                          </div>
                        </Col>
                        <Col md="8" xs="7">
                          <div className="numbers">
                            <p className="card-category">Total Number of Approved Deals</p>
                            <CardTitle tag="p">{brokerMetrics.Total_Number_of_Approved_Deals__c ? brokerMetrics.Total_Number_of_Approved_Deals__c : 0}</CardTitle>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </RCard>
                </Col>
                <Col lg="4" md="6" sm="6">
                  <RCard className="card-stats">
                    <CardBody>
                      <Row>
                        <Col md="4" xs="5">
                          <div className="icon-big text-center icon-warning">
                            <i className="fa fa-area-chart sos-text-primary" />
                          </div>
                        </Col>
                        <Col md="8" xs="7">
                          <div className="numbers">
                            <p className="card-category">Approval Ratio</p>
                            <CardTitle tag="p">{brokerMetrics.Approval_Ratio__c ? brokerMetrics.Approval_Ratio__c : 0}</CardTitle>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </RCard>
                </Col>
                <Col lg="4" md="6" sm="6">
                  <RCard className="card-stats">
                    <CardBody>
                      <Row>
                        <Col md="4" xs="5">
                          <div className="icon-big text-center icon-warning">
                            <i className="fa fa-cart-plus sos-text-primary" />
                          </div>
                        </Col>
                        <Col md="8" xs="7">
                          <div className="numbers">
                            <p className="card-category">Total Number of Funded Deals</p>
                            <CardTitle tag="p">{brokerMetrics.Total_Number_of_Funded_Deals__c ? brokerMetrics.Total_Number_of_Funded_Deals__c : 0}</CardTitle>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </RCard>
                </Col>
                <Col lg="4" md="6" sm="6">
                  <RCard className="card-stats">
                    <CardBody>
                      <Row>
                        <Col md="4" xs="5">
                          <div className="icon-big text-center icon-warning">
                            <i className="fa fa-money sos-text-primary" />
                          </div>
                        </Col>
                        <Col md="8" xs="7">
                          <div className="numbers">
                            <p className="card-category">Total Amount Funded</p>
                            <CardTitle tag="p">{brokerMetrics.Total_Amount_Funded__c ? formatCurrency(brokerMetrics.Total_Amount_Funded__c) : '$0.00'}</CardTitle>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </RCard>
                </Col>
                <Col lg="4" md="6" sm="6">
                  <RCard className="card-stats">
                    <CardBody>
                      <Row>
                        <Col md="4" xs="5">
                          <div className="icon-big text-center icon-warning">
                            <i className="fa fa-line-chart sos-text-primary" />
                          </div>
                        </Col>
                        <Col md="8" xs="7">
                          <div className="numbers">
                            <p className="card-category">Funding Ratio</p>
                            <CardTitle tag="p">{brokerMetrics.Funding_Ratio__c ? brokerMetrics.Funding_Ratio__c : 0}</CardTitle>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </RCard>
                </Col>
              </Row>
            </div>
          ) : null}
        </Card>
      </div>
    </div>
  )
}

export default BrokerMetrics;
