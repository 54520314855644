import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';
export const myISOStageHistoryStart = () => ({
  type: types.GET_MY_ISO_STAGE_HISTORY_START
});

export const myISOStageHistorySuccess = payload => ({
  type: types.GET_MY_ISO_STAGE_HISTORY_SUCCESS,
  payload
});

export const myISOStageHistoryFail = payload => ({
  type: types.GET_MY_ISO_STAGE_HISTORY_FAIL,
  payload
});

export const myISOStageHistoryCleanup = () => ({
  type: types.GET_MY_ISO_STAGE_HISTORY_CLEANUP
});

export const myISOStageHistory = (portal,type,id)=> async dispatch => {
  try {
    dispatch(myISOStageHistoryStart());
    const requestObj = {
      path: `opportunity/${portal}/history/iso/${type}/${id}`,
      method: "GET",   
    }
    const  data  = await AxiosCall(requestObj);
    dispatch(myISOStageHistorySuccess(data));
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(myISOStageHistoryFail(error));
  }
}
