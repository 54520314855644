import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Moment from "react-moment";
import { Spin, Card } from "antd";
import { CardTitle } from "reactstrap";
import { Divider } from "antd";
import Currency from "react-currency-formatter";
//Actions
import {
  getSingleOfferInfo,
  getSingleOfferInfoCleanup,
} from "../../../store/actions/get-single-offer-info";

const OffersDetails = ({portal, type, id }) => {
  const dispatch = useDispatch();
  const getSingleOfferInfoState = useSelector((s) => s.getSingleOfferInfo);
  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(getSingleOfferInfo(portal, type, id ));
  }, []);

  useEffect(() => {
    if (getSingleOfferInfoState.isSuccessful) {
      setData(getSingleOfferInfoState.data[0]);
      dispatch(getSingleOfferInfoCleanup());
    } else if (getSingleOfferInfoState.error) {
      dispatch(getSingleOfferInfoCleanup());
    }
  }, [getSingleOfferInfoState]);
  return (
    <div className="container advance-details-page">
      <div className="table-container">
        <Card>
          <CardTitle className="text-cente p-4">
            <div className="sos-text-primary mb-0 font-weight-bold d-flex justify-content-between">
              <div>Deal Offer Details</div>
            </div>
          </CardTitle>
        </Card>
        <hr />
        <Card>
          {getSingleOfferInfoState.isLoading && !data ? (
            <Spin size="large" className="loader" />
          ) : data ? (
            <div>
              <Divider orientation="left">
                <h4 className="sos-font-weight-600 mt-0 mb-0">Information</h4>
              </Divider>
              <div className="row">
                <div className="col-md-6">
                  <p>
                    <strong>Deal Type: </strong>
                    {data.Deal_Type__c}
                  </p>
                  <p>
                    <strong>Funding Amount: </strong>
                    <Currency
                      quantity={data.Amount__c === null ? 0 : data.Amount__c}
                      currency="USD"
                    />
                  </p>

                  <p>
                    <strong>Purchase Amount: </strong>
                    <Currency
                      quantity={
                        data.Purchase_Amount__c === null
                          ? 0
                          : data.Purchase_Amount__c
                      }
                      currency="USD"
                    />
                  </p>
                  <p>
                    <strong>Term (Months): </strong>
                    {data.Term_Months__c}
                  </p>

                  <p>
                    <strong>Holdback %: </strong>
                    {data.Holdback__c}
                  </p>
                </div>
                <div className="col-md-6">
                  <p>
                    <strong>Daily Debit Amount: </strong>
                    <Currency
                      quantity={
                        data.Daily_PB_Amount__c === null
                          ? 0
                          : data.Daily_PB_Amount__c
                      }
                      currency="USD"
                    />
                  </p>
                  <p>
                    <strong>PMT Schedule: </strong>
                    {data.PMT_Schedule__c}
                  </p>
                  <p>
                    <strong>Approval Date: </strong>
                    {data.Approval_Date__c ? (
                      <Moment>{data.Approval_Date__c} </Moment>
                    ) : (
                      "N/A"
                    )}
                  </p>

                  <p>
                    <strong>Payback Amount: </strong>
                    <Currency
                      quantity={
                        data.Payback_Amt__c === null ? 0 : data.Payback_Amt__c
                      }
                      currency="USD"
                    />
                  </p>
                  <p>
                    <strong>Origination Fee: </strong>
                    <Currency
                      quantity={
                        data.Origination_fee__c === null
                          ? 0
                          : data.Origination_fee__c
                      }
                      currency="USD"
                    />
                  </p>
                  <p>
                    <strong>ISO Account: </strong>
                    {data && data.Opportunity__r && data.Opportunity__r.ISO__r}
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <h4>Offer not found</h4>
          )}
        </Card>
      </div>
    </div>
  );
};

export default OffersDetails;
