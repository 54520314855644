import React from "react";
import { Tabs } from "antd";
import { useParams } from "react-router-dom";
import DealsDetailsSection from "./DealsDetailsSection";
import DealsOffersTable from "./DealsOfferTable";
import DealStipulations from "./DealStipulations"

const { TabPane } = Tabs;
const DealsDetails = () => {
  const { id } = useParams();
  return (
    <div className="pt-5 mt-5  container advance-details-page">
      <div className="pt-5 table-container">
        <Tabs defaultActiveKey="1">
          <TabPane tab="Opportunity Details" key="1">
            <DealsDetailsSection id={id} />
          </TabPane>
          <TabPane tab="Opportunity Offers" key="2">
            <DealsOffersTable Id={id} />
          </TabPane>
          <TabPane tab="Opportunity Stipulations" key="4">
            <DealStipulations id={id} />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default DealsDetails;
