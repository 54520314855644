import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';

export const updateBankDetailsStart = () => ({
  type: types.UPDATE_BANK_DETAILS_START
});

export const updateBankDetailsSuccess = payload => ({
  type: types.UPDATE_BANK_DETAILS_SUCCESS,
  payload
});

export const updateBankDetailsFail = payload => ({
  type: types.UPDATE_BANK_DETAILS_FAIL,
  payload
});

export const updateBankDetailsCleanup = () => ({
  type: types.UPDATE_BANK_DETAILS_CLEANUP
});

export const updateBankDetails = (payload) => async dispatch => {
  try {
    dispatch(updateBankDetailsStart());
    const path = 'contact/bankinfo';
    const requestObj = {
      path,
      method: 'PATCH',
      data: payload

    }
    const  data  = await AxiosCall(requestObj);
    dispatch(updateBankDetailsSuccess(data));
    
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(updateBankDetailsFail(error));
  }
}
