import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';
export const myISOOpportunityStart = () => ({
  type: types.GET_MY_ISO_OPPORTURNITY_START
});

export const myISOOpportunitySuccess = payload => ({
  type: types.GET_MY_ISO_OPPORTURNITY_SUCCESS,
  payload
});

export const myISOOpportunityFail = payload => ({
  type: types.GET_MY_ISO_OPPORTURNITY_FAIL,
  payload
});

export const myISOOpportunityCleanup = () => ({
  type: types.GET_MY_ISO_OPPORTURNITY_CLEANUP
});

export const myISOOpportunity = (portal, type, id)=> async dispatch => {
  try {
    dispatch(myISOOpportunityStart());
    const requestObj = {
      path: `opportunity/${portal}/iso/${type}/${id}`,
      method: "GET",   
    }
    const  data  = await AxiosCall(requestObj);
    dispatch(myISOOpportunitySuccess(data));
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(myISOOpportunityFail(error));
  }
}
