import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';

export const getAccountStart = () => ({
  type: types.GET_ACCOUNT_START
});

export const getAccountSuccess = payload => ({
  type: types.GET_ACCOUNT_SUCCESS,
  payload
});

export const getAccountFail = payload => ({
  type: types.GET_ACCOUNT_FAIL,
  payload
});

export const getAccountCleanup = () => ({
  type: types.GET_ACCOUNT_CLEANUP
});

export const getAccount = () => async dispatch => {
  try {
    dispatch(getAccountStart());
    const requestObj = {
      path: `brokerfunding/account`,
      method: "GET"
    }
    const  data  = await AxiosCall(requestObj);
    dispatch(getAccountSuccess(data));
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(getAccountFail(error));
  }
}
