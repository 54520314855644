import React from 'react';
import { Tabs } from 'antd';

//components
import Syndications from './MySyndications';

const DealDetails = () => {
  const { TabPane } = Tabs;

  return (
    <div className='pt-5 mt-5  container advance-details-page'>
      <div className='pt-5 table-container'>
        <Tabs defaultActiveKey='1'>
        <TabPane tab='My Syndications'  key='1'>
            <Syndications type={1} title={"My Syndications"}/>
          </TabPane>
          <TabPane tab='Deals From My Syndications' type={2} key='2'>
            <Syndications type={2} title={'Deals From My Syndications'}/>
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default DealDetails;
