import * as types from '../../action-types';
import { createWebToLead as initialState } from "../../initialState";

const createWebToLead = (state=initialState, action) => {
  switch (action.type) {
    case types.CREATE_WEB_TO_LEAD_START:
      return {
        ...state,
        isLoading: true
      };
    case types.CREATE_WEB_TO_LEAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccessful: true,
        data: action.payload
      };
    case types.CREATE_WEB_TO_LEAD_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case types.CREATE_WEB_TO_LEAD_CLEANUP:
      return {
        ...state,
        isLoading: false,
        isSuccessful: false,
        error: null
      };
    default:
      return state;
  }
}

export default createWebToLead;
