/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import classnames from "classnames";
import { NavLink } from "react-router-dom";

// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
} from "reactstrap";

//svg
import LOGO from '../../assets/img/SOS.logo.png';

class AuthNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
    };
  }
  componentDidMount() {
    window.addEventListener("resize", this.updateColor);
  }
  // this function opens and closes the collapse on small devices
  // it also adds navbar-transparent class to the navbar when closed
  // ad bg-white when opened
  toggleCollapse = () => {
    let newState = {
      collapseOpen: !this.state.collapseOpen,
    };
    this.setState(newState);
  };
  render() {
    const token = localStorage.getItem('authToken');
    const matchPortal = window.location.pathname === '/portals';
    const showLogin = window.location.pathname !== '/login' && window.location.pathname !== '/email-verification';
    const showSignup = window.location.pathname !== '/signup' && window.location.pathname !== '/email-verification';
    return (
      <Navbar
        className={classnames("navbar-absolute mt-0 fixed-top")}
        expand="lg"
        id="nav-bar"
      >
        <Container>
          <div className="navbar-wrapper">
            <NavbarBrand href="https://www.altbanq.com">
              <img src={LOGO} width={110} />
            </NavbarBrand>
          </div>
          <button
            aria-controls="navigation-index"
            aria-expanded={false}
            aria-label="Toggle navigation"
            className="navbar-toggler"
            data-toggle="collapse"
            type="button"
            onClick={this.toggleCollapse}
          >
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
          </button>
          <Collapse
            isOpen={this.state.collapseOpen}
            className="justify-content-end"
            navbar
          >
            {!token ? (
              <Nav navbar>
                {showSignup ? (
                  <NavItem>
                    <NavLink to="/signup" className=" sign-btn-sty rounded-pill ">
                      Create Account 
                    </NavLink>
                  </NavItem>
                ) : null}
                {showLogin ? (
                  <NavItem>
                    <NavLink to="/login" className="text-white log-btn-sty rounded-pill ">
                      Login <i className={"fa fa-angle-right"}></i>
                    </NavLink>
                  </NavItem>
                ) : null}
              </Nav>
            ) : !matchPortal ? (
              <Nav navbar>
                <NavItem>
                  <NavLink to="/portals" className=" text-white log-btn-sty rounded-pill ">
                    My Account <i className={"fa fa-angle-right"}></i>
                  </NavLink>
                </NavItem>
              </Nav>
            ) : matchPortal ? (
              <Nav navbar>
                <NavItem>
                  <NavLink to="/logout" className=" text-white log-btn-sty rounded-pill ">
                    Logout <i className={"fa fa-sign-out"}></i>
                  </NavLink>
                </NavItem>
              </Nav> 
            ) : null}
          </Collapse>
        </Container>
      </Navbar>
    );
  }
}

export default AuthNavbar;
