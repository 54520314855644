import './index.css';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Slider, InputNumber, Row, Col, Button, message, Alert } from 'antd';
import { CardTitle } from 'reactstrap';
import { useParams, useHistory } from 'react-router-dom';
import Select from 'react-select';

//components
import Loader from '../../../components/Loader';

//actions
import { getFlexOffer, getFlexOfferCleanup } from '../../../store/actions/get-flex-offer';
import { updateOffer, updateOfferCleanup } from '../../../store/actions/update-offer';

//utils
import { getValueObject, formatCurrency } from '../../../utils/helpers';


const OfferCalculator = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const getFlexOfferState = useSelector(s => s.getFlexOffer);
  const updateOfferState = useSelector(s => s.updateOffer);
  const [flexOffer, setFlexOffer] = useState(null);
  const { id } = useParams();
  const [inputValues, setInputValues] = useState({});
  const [maxFunding, setMaxFunding] = useState(0);
  const [minBuyRate, setMinBuyRate] = useState(1.10);
  const [maxPaymentAmount, setMaxPaymentAmount] = useState(0);
  const [triggerCalcFundingAmount, setTriggerCalcFundingAmount] = useState(false);
  const [calculatorData, setCalculatorData] = useState({
    advanceAmount: 0,
    buyRate: 0,
    sellRate: 0,
    purchaseRate: 0,
    purchasedPrice: 0,
    dealMonths: 0,
    dailyPurchase: 0,
    dealWeeks: 0,
    weeklyPurchase: 0,
    commission: 0,
    paymentFrequency: ''
  });

  const handleSubmit = () => {
    const payload = {
      Term_Months__c: inputValues.Term_Months__c,
      Upsell__c: calculatorData.sellRate,
      Purchase_Fee__c: calculatorData.purchaseRate,
      Payment_Amt__c: inputValues.Payment_Frequency === 'Weekly' ? calculatorData.weeklyPurchase : calculatorData.dailyPurchase,
      Payment_Frequency_Daily__c: inputValues.Payment_Frequency === 'Daily',
      Payment_Frequency_Weekly__c: inputValues.Payment_Frequency === 'Weekly',
      Rate__c: calculatorData.purchaseRate,
      Amount__c: calculatorData.advanceAmount,
      Buy_Rate__c: calculatorData.buyRate,
      Deal_Length__c: inputValues.Term_Months__c * 21,
      PMT_Schedule__c: inputValues.Payment_Frequency
    }
    dispatch(updateOffer(id, 3, payload));
  }

  const getMaximumFunding = () => {
    const { Base_Factor_Rate__c, Max_Expected_Term__c, Max_Upsell__c, Max_Advance_Amount__c} = getFlexOfferState.data;
    const maxAdvanceAmount = Max_Advance_Amount__c;
    const baseFactorRate = Base_Factor_Rate__c;
    const maxTerm = Max_Expected_Term__c;
    const maxUpsell = Max_Upsell__c;
    const maxBuyRate = baseFactorRate - 0.12;
    const monthlyFactor = (maxBuyRate - 1) * 100/maxTerm;
    const calculatedBuyRate = parseFloat(((monthlyFactor/100) * inputValues.Term_Months__c + 1).toFixed(2));
    const buyRate = calculatedBuyRate >= minBuyRate ? calculatedBuyRate : minBuyRate;
    const factorRate = (maxUpsell/100) + buyRate;

    let fundingAmount = 0;
    const termArr = Array.from(Array((maxTerm - inputValues.Term_Months__c) + 1), (x, i) => maxTerm - i);
    termArr.forEach(val => {
      if(val === maxTerm){
        fundingAmount = maxAdvanceAmount;
      }else{
        fundingAmount -= fundingAmount * 0.2;
      }
    });

    let newMaxFunding = 0;
    if(inputValues.Term_Months__c === maxTerm){
      newMaxFunding = maxAdvanceAmount;
    }else{
      const mfFactor = maxAdvanceAmount * baseFactorRate / maxTerm;
      newMaxFunding = ((mfFactor * inputValues.Term_Months__c / factorRate) / 100).toFixed() * 100;
    }

    const purchaseRate = parseFloat((buyRate + (inputValues.sellRate/100)).toFixed(2));
    const totalMaxPaymentAmount = purchaseRate * newMaxFunding;
    const dailyMaxPaymentAmount = parseFloat((totalMaxPaymentAmount/(inputValues.Term_Months__c * 21)).toFixed(2));
    const weeklyMaxPaymentAmount = parseFloat((totalMaxPaymentAmount/(inputValues.Term_Months__c * 4)).toFixed(2));
    let newMaxPaymentAmount = inputValues.Payment_Frequency === 'Weekly' ? weeklyMaxPaymentAmount : dailyMaxPaymentAmount;

    setMaxFunding(newMaxFunding);
    setInputValues(prev => ({
      ...prev,
      Funding_Amount: fundingAmount
    }));
    setMaxPaymentAmount(newMaxPaymentAmount);
  }

  const calculate = () => {
    if(inputValues.Term_Months__c && inputValues.Funding_Amount && inputValues.Payment_Frequency){
      const { Base_Factor_Rate__c, Max_Expected_Term__c } = getFlexOfferState.data;
      const advanceAmount = inputValues.Funding_Amount;
      const baseFactorRate = Base_Factor_Rate__c;
      const maxTerm = Max_Expected_Term__c;
      const maxBuyRate = baseFactorRate - 0.12;
      const monthlyFactor = (maxBuyRate - 1) * 100/maxTerm;
      const calculatedBuyRate = parseFloat(((monthlyFactor/100) * inputValues.Term_Months__c + 1).toFixed(2));
      const buyRate = calculatedBuyRate >= minBuyRate ? calculatedBuyRate : minBuyRate;

      //generate offer summary data
      const sellRate = inputValues.sellRate;
      const purchaseRate = parseFloat((buyRate + (sellRate/100)).toFixed(2));
      const purchasedPrice = parseFloat((purchaseRate * advanceAmount).toFixed(2));
      const dailyPurchase = parseFloat((purchasedPrice/(inputValues.Term_Months__c * 21)).toFixed(2));
      const weeklyPurchase = parseFloat((purchasedPrice/(inputValues.Term_Months__c * 4)).toFixed(2));
      const commission = (sellRate/100) * advanceAmount;
      setInputValues(prev => ({
        ...prev,
        Max_Payment_Amount: inputValues.Payment_Frequency === 'Weekly' ? weeklyPurchase : dailyPurchase 
      }));
      setCalculatorData({
        advanceAmount,
        buyRate,
        sellRate,
        purchaseRate,
        purchasedPrice,
        dealMonths: inputValues.Term_Months__c,
        dailyPurchase,
        dealWeeks: inputValues.Term_Months__c * 4,
        weeklyPurchase,
        commission,
        paymentFrequency: inputValues.Payment_Frequency,
      });
    }
  }

  //calculate funding amount based on selected payment amount
  const calculateFundingAmount = () => {
    if(inputValues.Term_Months__c && inputValues.Max_Payment_Amount && inputValues.Payment_Frequency){
      const { Base_Factor_Rate__c, Max_Expected_Term__c } = getFlexOfferState.data;
      const totalDailyPaymentAmount = inputValues.Max_Payment_Amount * inputValues.Term_Months__c * 21;
      const totalWeeklyPaymentAmount = inputValues.Max_Payment_Amount * inputValues.Term_Months__c * 4;
      const paymentAmount = inputValues.Payment_Frequency === 'Weekly' ? totalWeeklyPaymentAmount : totalDailyPaymentAmount;
      const baseFactorRate = Base_Factor_Rate__c;
      const maxTerm = Max_Expected_Term__c;
      const maxBuyRate = baseFactorRate - 0.12;
      const monthlyFactor = (maxBuyRate - 1) * 100/maxTerm;
      const calculatedBuyRate = parseFloat(((monthlyFactor/100) * inputValues.Term_Months__c + 1).toFixed(2));
      const buyRate = calculatedBuyRate >= minBuyRate ? calculatedBuyRate : minBuyRate;
      const sellRate = inputValues.sellRate;
      const purchaseRate = parseFloat((buyRate + (sellRate/100)).toFixed(2));
      const fundingAmount = ((paymentAmount / purchaseRate) / 10).toFixed() * 10;

      setInputValues(prev => ({...prev, Funding_Amount: fundingAmount}));
    }
  }

  const paymentFrequencyOptions = [
    ...(flexOffer && flexOffer.Payment_Frequency_Daily__c ? [{ label: 'Daily', value: 'Daily' }] : []),
    ...(flexOffer && flexOffer.Payment_Frequency_Weekly__c ? [{ label: 'Weekly', value: 'Weekly' }] : [])
  ]

  const handleChange = (name, value) => {
    setInputValues(prev => ({...prev, [name]: value}));
    if(name === 'Max_Payment_Amount'){
      setTriggerCalcFundingAmount(true);
    }
  };

  useEffect(() => {
    dispatch(getFlexOffer(3, id));
  },[]);

  useEffect(() => {
    if(getFlexOfferState.isSuccessful){
      setFlexOffer(getFlexOfferState.data);
      setInputValues({
        Term_Months__c: getFlexOfferState.data.Max_Expected_Term__c,
        Funding_Amount: getFlexOfferState.data.Max_Advance_Amount__c,
        sellRate: getFlexOfferState.data.Max_Upsell__c,
      });
      setMaxFunding(getFlexOfferState.data.Max_Advance_Amount__c);
      if(getFlexOfferState.data.Min_Buy_Rate__c){
        setMinBuyRate(getFlexOfferState.data.Min_Buy_Rate__c)
      }
      dispatch(getFlexOfferCleanup());
    }else if(getFlexOfferState.error){
      dispatch(getFlexOfferCleanup());
    }
  },[getFlexOfferState]);

  useEffect(() => {
    if(updateOfferState.isSuccessful){
      message.success('You have successfully submitted your offer', 2);
      history.push(`/broker/offer-details/${id}`);
      dispatch(updateOfferCleanup());
    }else if(updateOfferState.error){
      message.error('Oops! Your offer could not be submitted', 2);
      dispatch(updateOfferCleanup());
    }
  },[updateOfferState]);

  useEffect(() => {
    if(inputValues.Term_Months__c && inputValues.Funding_Amount && inputValues.Payment_Frequency){
      calculate();
    }
  },[inputValues.Term_Months__c, inputValues.Funding_Amount, inputValues.Payment_Frequency, inputValues.sellRate]);

  useEffect(() => {
    if(inputValues.Term_Months__c && inputValues.Max_Payment_Amount && inputValues.Payment_Frequency && triggerCalcFundingAmount){
      calculateFundingAmount();
      setTriggerCalcFundingAmount(false);
    }
  },[triggerCalcFundingAmount]);

  useEffect(() => {
    if(inputValues.Term_Months__c && inputValues.sellRate && inputValues.Payment_Frequency){
      getMaximumFunding();
    }
  },[inputValues.Term_Months__c, inputValues.sellRate, inputValues.Payment_Frequency]);

  return (
    <div className='pt-5 container details-page broker-calculator'>
      <div className='pt-5 table-container'>
        <Card>
          <CardTitle className='text-cente p-4'>
            <div className='sos-text-primary mb-0 font-weight-bold d-flex justify-content-between'>
              <div>Offer Calculator</div>
            </div>
          </CardTitle>
        </Card>
        <hr />
        <Card>
          <Loader isLoading={updateOfferState.isLoading}>
            {getFlexOfferState.isLoading && !flexOffer ? (
              <div className='m-5 d-flex justify-content-center'>
                <Loader isLoading={getFlexOfferState.isLoading} />
              </div>
            ) : flexOffer && flexOffer.Status__c === 'Presented'
              && flexOffer.Opportunity__r.StageName === 'Approved' ? (
              <div className='pt-5 pb-5'>
                <Row>
                  <Col span={24} md={{ span: 16 }}>
                    <Row className='mb-4'>
                      <Col span={18} md={{ span: 4, order: 1 }}>
                        <p className='mr-md-4'>
                          <strong>Expected Term: </strong>
                        </p>
                      </Col>
                      <Col span={6} md={{ span: 4, offset: 1, order: 3 }}>
                        <InputNumber
                          min={6}
                          max={flexOffer.Max_Expected_Term__c}
                          value={inputValues.Term_Months__c}
                          onChange={value => handleChange('Term_Months__c', value)}
                        />
                      </Col>
                      <Col span={24} md={{ span: 14, order: 2 }}>
                        <Slider
                          min={6}
                          max={flexOffer.Max_Expected_Term__c}
                          marks={{
                            6: '6',
                            [flexOffer.Max_Expected_Term__c]: `${flexOffer.Max_Expected_Term__c}`,
                          }}
                          onChange={value => handleChange('Term_Months__c', value)}
                          value={typeof inputValues.Term_Months__c === 'number' ? inputValues.Term_Months__c : 0}
                        />
                      </Col>                      
                    </Row>
                    <Row className='mb-4'>
                      <Col span={18} md={{ span: 4, order: 1 }}>
                        <p>
                          <strong className='mr-md-4'>Funding Amount: </strong>
                        </p>
                      </Col>
                      <Col span={6} md={{ span: 4, offset: 1, order: 3 }}>
                        <InputNumber
                          min={0}
                          max={maxFunding}
                          step={10}
                          value={inputValues.Funding_Amount}
                          onChange={value => {
                            if(value % 10 !== 0){
                              value = Math.ceil(value / 10) * 10;
                            }
                            handleChange('Funding_Amount', value)
                          }}
                        />
                      </Col>
                      <Col span={24} md={{ span: 14, order: 2 }}>
                        <Slider
                          min={0}
                          max={maxFunding}
                          step={10}
                          marks={{
                            0: '0',
                            [maxFunding]: `${maxFunding}`,
                          }}
                          onChange={value => handleChange('Funding_Amount', value)}
                          value={typeof inputValues.Funding_Amount === 'number' ? inputValues.Funding_Amount : 0}
                        />
                      </Col>
                    </Row>
                    <Row className='mb-4'>
                      <Col span={24} md={{ span: 4 }}>
                        <p className='mr-md-4'>
                          <strong>Payment Frequency: </strong>
                        </p>
                      </Col>
                      <Col span={24} md={{ span: 14 }}>
                        <Select
                          className='react-select info'
                          classNamePrefix='react-select'
                          placeholder='--None--'
                          name='Payment_Frequency'
                          value={getValueObject(paymentFrequencyOptions, inputValues.Payment_Frequency)}
                          onChange={value => handleChange('Payment_Frequency', value.value)}
                          options={paymentFrequencyOptions}
                        />
                      </Col>
                    </Row>
                    <Row className='mb-4'>
                      <Col span={18} md={{ span: 4, order: 1 }}>
                        <p className='mr-md-4'>
                          <strong>Commission: </strong>
                        </p>
                      </Col>
                      <Col span={6} md={{ span: 4, offset: 1, order: 3 }}>
                        <InputNumber
                          min={1}
                          max={flexOffer.Max_Upsell__c}
                          value={inputValues.sellRate}
                          onChange={value => handleChange('sellRate', value)}
                        />
                      </Col>
                      <Col span={24} md={{ span: 14, order: 2 }}>
                        <Slider
                          min={1}
                          max={flexOffer.Max_Upsell__c}
                          marks={{
                            1: '1',
                            [flexOffer.Max_Upsell__c]: `${flexOffer.Max_Upsell__c}`,
                          }}
                          onChange={value => handleChange('sellRate', value)}
                          value={typeof inputValues.sellRate === 'number' ? inputValues.sellRate : 0}
                        />
                      </Col>
                    </Row>
                    <Row className='mb-4'>
                      <Col span={18} md={{ span: 4, order: 1 }}>
                        <p>
                          <strong className='mr-md-4'>Maximum Payment Amount: </strong>
                        </p>
                      </Col>
                      <Col span={6} md={{ span: 4, offset: 1, order: 3 }}>
                        <InputNumber
                          min={0}
                          max={maxPaymentAmount}
                          step={1}
                          value={inputValues.Max_Payment_Amount}
                          onChange={value => {
                            if(value % 10 !== 0){
                              value = Math.ceil(value / 10) * 10;
                            }
                            handleChange('Max_Payment_Amount', value);
                          }}
                        />
                      </Col>
                      <Col span={24} md={{ span: 14, order: 2 }}>
                        <Slider
                          min={0}
                          max={maxPaymentAmount}
                          step={1}
                          marks={{
                            0: '0',
                            [maxPaymentAmount]: `${maxPaymentAmount}`,
                          }}
                          onChange={value => handleChange('Max_Payment_Amount', value)}
                          value={typeof inputValues.Max_Payment_Amount === 'number' ? inputValues.Max_Payment_Amount : 0}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {getFlexOfferState.data && getFlexOfferState.data.Notes_from_SOS__c ? (
                          <Alert
                            message="Note"
                            description={getFlexOfferState.data.Notes_from_SOS__c}
                            type="info"
                            showIcon
                            className="mb-5"
                          />
                        ) : null}
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24} md={{ span: 8 }} className='mt-5 mt-md-0'>
                    <div className='result-con w-100 p-4 rounded-lg'>
                      <h4 className='mt-0 sos-font-weight-600 sos-text-primary text-center'>Selected Offer Details</h4>
                      <Row>
                        <Col span={24} className='d-flex justify-content-between align-items-center mb-3'>
                          <p className='mb-0'>
                            <strong>Advance Amount:</strong>
                          </p>
                          <InputNumber
                            value={formatCurrency(calculatorData.advanceAmount)}
                            readOnly={true}
                            className='result-input'
                          />
                        </Col>
                        <Col span={24} className='d-flex justify-content-between align-items-center mb-3'>
                          <p className='mb-0'>
                            <strong>Term (Months):</strong>
                          </p>
                          <InputNumber
                            value={calculatorData.dealMonths}
                            readOnly={true}
                            className='result-input'
                          />
                        </Col>
                        <Col span={24} className='d-flex justify-content-between align-items-center mb-3'>
                          <p className='mb-0'>
                            <strong>Buy Rate:</strong>
                          </p>
                          <InputNumber
                            value={calculatorData.buyRate}
                            readOnly={true}
                            className='result-input'
                          />
                        </Col>
                        <Col span={24} className='d-flex justify-content-between align-items-center mb-3'>
                          <p className='mb-0'>
                            <strong>Purchase Rate:</strong>
                          </p>
                          <InputNumber
                            value={calculatorData.purchaseRate}
                            readOnly={true}
                            className='result-input'
                          />
                        </Col>
                        <Col span={24} className='d-flex justify-content-between align-items-center mb-3'>
                          <p className='mb-0'>
                            <strong>Total Repayments:</strong>
                          </p>
                          <InputNumber
                            value={formatCurrency(calculatorData.purchasedPrice)}
                            readOnly={true}
                            className='result-input'
                          />
                        </Col>
                        <Col span={24} className='d-flex justify-content-between align-items-center mb-3'>
                          <p className='mb-0'>
                            <strong>Payment Amount:</strong>
                          </p>
                          <InputNumber
                            value={inputValues.Payment_Frequency === 'Weekly' ? 
                              formatCurrency(calculatorData.weeklyPurchase) : formatCurrency(calculatorData.dailyPurchase)}
                            readOnly={true}
                            className='result-input'
                          />
                        </Col>
                        <Col span={24} className='d-flex justify-content-between align-items-center mb-3'>
                          <p className='mb-0'>
                            <strong>Payment Frequency:</strong>
                          </p>
                          <InputNumber
                            value={calculatorData.paymentFrequency}
                            readOnly={true}
                            className='result-input'
                          />
                        </Col>
                        <Col span={24} className='d-flex justify-content-between align-items-center mb-3'>
                          <p className='mb-0'>
                            <strong>Commission:</strong>
                          </p>
                          <InputNumber
                            value={formatCurrency(calculatorData.commission)}
                            readOnly={true}
                            className='result-input'
                          />
                        </Col>
                      </Row>
                      <div className='mt-5'>
                        <Button 
                          type='primary' 
                          onClick={handleSubmit} 
                          disabled={updateOfferState.isLoading || !inputValues.Payment_Frequency}
                          className='w-100 py-3 h-auto'
                        >
                          Submit Selected Offer
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            ) : flexOffer && (flexOffer.Status__c !== 'Presented' 
              || (flexOffer.Opportunity__r && flexOffer.Opportunity__r.StageName !== 'Approved')) ? (
              <p className='mt-5 text-center sos-text-large text-danger sos-font-weight-600'>Offer is no longer available</p>
            ) : null}
          </Loader>
        </Card>
      </div>
    </div>
  );
}

export default OfferCalculator;
