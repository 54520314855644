import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Tag, Space, Card, Input, Button } from 'antd';
import moment from 'moment';
import Highlighter from 'react-highlight-words';
import { CardTitle } from 'reactstrap';
import { SearchOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

//actions
import { getPayments, getPaymentsCleanup } from '../../../../store/actions/get-payments';

//utils
import { formatCurrency } from '../../../../utils/helpers';

const Payments = ({ id }) => {
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const dispatch = useDispatch();
  const getPaymentsState = useSelector(s => s.getPayments);

  const getColumnSearchProps = (dataIndex, Title) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${Title}`}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex);
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size='small'
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#29c273' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  useEffect(() => {
    dispatch(getPayments(id));
  }, []);

  useEffect(() => {
    if (getPaymentsState.isSuccessful) {
      setData(getPaymentsState.data);
      dispatch(getPaymentsCleanup());
    } else if (getPaymentsState.error) {
      dispatch(getPaymentsCleanup());
    }
  }, [getPaymentsState]);

  const columns = [
    {
      title: 'Date',
      dataIndex: 'Effective_Date__c',
      key: 'Date',
      render: (text) => <span>{moment(text).format('MMMM Do, YYYY')}</span>,
      sorter: (a, b) => moment(a.Date_App_Received__c).unix() - moment(b.Date_App_Received__c).unix()
    },
    {
      title: 'Name',
      dataIndex: 'Name',
      key: 'Name',
      ...getColumnSearchProps('Name', 'Name'),
      render: (text, data) => <Link to={`/user/payment-details/${data.Id}`}>{text}</Link>,
    },
    {
      title: 'Amount',
      dataIndex: 'Transaction_Amount__c',
      key: 'Amount',
      ...getColumnSearchProps('Transaction_Amount__c', 'Amount'),
      render: text => <span>{formatCurrency(text)}</span>,
    },
    {
      title: 'Status',
      dataIndex: 'Status__c',
      key: 'Status',
      render: (Status) => (
        <Tag color={Status === 'Settled' ? 'green' : ''}>{Status}</Tag>
      ),
      filters: [
        'Settled',
        'Scheduled',
        'Pending',
        'Failed',
        'Cleared'
      ].map((e) => ({ text: e, value: e, key: e })),
      onFilter: (value, record) => record.Status__c.indexOf(value) === 0,
    },
    {
      title: 'Balance',
      dataIndex: 'Running_Balance__c',
      key: 'Balance',
      ...getColumnSearchProps('Running_Balance__c', 'Balance'),
      render: text => <span>{formatCurrency(text)}</span>,
    },
    {
      title: 'Method',
      dataIndex: 'Payment_Method__c',
      key: 'Method',
      ...getColumnSearchProps('Payment_Method__c', 'Method')
    },
    {
      title: 'Transaction Type',
      dataIndex: 'Transaction_Type__c',
      key: 'TransactionType',
      ...getColumnSearchProps('Transaction_Type__c', 'Transaction Type')
    }
  ];
  return (
    <div>
      <Card>
        <CardTitle className='text-cente p-4'>
          <div className='sos-text-primary mb-0 font-weight-bold d-flex justify-content-between'>
            <div>Payments</div>
            <div>
              {data.length} {data.length < 2 ? 'item' : 'items'}
            </div>
          </div>
        </CardTitle>
      </Card>
      <hr />
      <Table
        columns={columns}
        loading={getPaymentsState.isLoading}
        dataSource={data}
        scroll={{ x: 1000 }}
        rowKey={(data) => data.Id}
      />
    </div>
  );
};
export default Payments;
