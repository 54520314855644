import React, { useRef, forwardRef, useImperativeHandle, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Row,
  Col,
  Label,
  FormGroup,
  Input,
  Form
} from 'reactstrap';
import { Alert, DatePicker } from 'antd'
import moment from 'moment';

//utils
import { getValueObject, formatSSN } from '../../../../../utils/helpers';

const Step2 = ({ values }, ref) => {
  const formikRef = useRef();
  const phoneRegex = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;
  const SSNRegex = /^\d{3}\-?\d{2}\-?\d{4}$/;
  const [ownershipMessage, setOwnershipMessage] = useState(null);

  const validationSchema = Yup.object().shape({
    FirstName: Yup.string()
      .trim()
      .required('First Name is required')
      .min(2, 'First Name should have minimum of two characters'),
    LastName: Yup.string()
      .trim()
      .required('Last Name is required')
      .min(2, 'Last Name should have minimum of two characters'),
    Email: Yup.string()
      .trim()
      .required('Email is required')
      .email('Specify a valid email'),
    MobilePhone: Yup.string()
      .trim()
      .required('Mobile Phone number is required')
      .matches(phoneRegex,'Mobile Phone number is not valid'),
    Phone: Yup.string()
      .trim()
      .matches(phoneRegex,'Phone number is not valid'),
    HomePhone: Yup.string()
      .trim()
      .matches(phoneRegex,'Home Phone number is not valid'),
    Street: Yup.string()
      .trim()
      .required('Street is required')
      .min(2, 'Street should have minimum of two characters'),
    City: Yup.string()
      .trim()
      .required('City is required')
      .min(2, 'City should have minimum of two characters'),
    State: Yup.string()
      .trim()
      .required('State is required')
      .min(2, 'State should have minimum of two characters'),
    PostalCode: Yup.string()
      .trim()
      .required('PostalCode is required')
      .min(2, 'PostalCode should have minimum of two characters'),
    Owner1Ownership: Yup.number()
      .required('Percentage ownership is required')
      .min(0, 'Minimum value is 0')
      .max(100, 'Maximum value is 100'),
    Owner1SSN: Yup.string()
      .trim()
      .required('Owner1 SSN is required')
      .min(2, 'Owner1 SSN should have minimum of two characters')
      .max(11, 'Owner1 SSN should have not be more than 11 characters')
      .matches(SSNRegex, 'SSN is not valid. it should be in the format XXX-XX-XXXX'),
    Owner2FirstName: Yup.string()
      .when('HasOwner2', {
        is: (HasOwner2) => HasOwner2,
        then: Yup.string()
          .trim()
          .required('First Name is required')
          .min(2, 'First Name should have minimum of two characters'),
      }),
    Owner2LastName: Yup.string()
      .when('HasOwner2', {
        is: (HasOwner2) => HasOwner2,
        then: Yup.string()
          .trim()
          .required('Last Name is required')
          .min(2, 'Last Name should have minimum of two characters'),
      }),
    Owner2Email: Yup.string()
      .when('HasOwner2', {
        is: (HasOwner2) => HasOwner2,
        then: Yup.string()
          .trim()
          .required('Email is required')
          .email('Specify a valid email'),
      }),
    Owner2MobilePhone: Yup.string()
      .when('HasOwner2', {
        is: (HasOwner2) => HasOwner2,
        then: Yup.string()
          .trim()
          .matches(phoneRegex,'Mobile Phone number is not a valid number'),
      }),
    Owner2Phone: Yup.string()
      .when('HasOwner2', {
        is: (HasOwner2) => HasOwner2,
        then: Yup.string()
          .trim()
          .matches(phoneRegex,'Phone number is not a valid number'),
      }),
    Owner2HomePhone: Yup.string()
      .when('HasOwner2', {
        is: (HasOwner2) => HasOwner2,
        then: Yup.string()
          .trim()
          .matches(phoneRegex,'Home Phone number is not a valid number'),
      }),
    Owner2SSN: Yup.string()
      .when('HasOwner2', {
        is: (HasOwner2) => HasOwner2,
        then: Yup.string()
          .trim()
          .min(2, 'Owner2 SSN should have minimum of two characters')
          .max(11, 'Owner2 SSN should have not be more than 11 characters')
          .matches(SSNRegex, 'SSN is not valid. it should be in the format XXX-XX-XXXX'),
      }),
    Owner2Ownership: Yup.number()
      .when('HasOwner2', {
        is: (HasOwner2) => HasOwner2,
        then: Yup.number()
          .required('Percentage ownership is required')
          .min(0, 'Minimum value is 0')
          .max(100, 'Maximum value is 100'),
      }),
    Owner3FirstName: Yup.string()
      .when('HasOwner3', {
        is: (HasOwner3) => HasOwner3,
        then: Yup.string()
          .trim()
          .required('First Name is required')
          .min(2, 'First Name should have minimum of two characters'),
      }),
    Owner3LastName: Yup.string()
      .when('HasOwner3', {
        is: (HasOwner3) => HasOwner3,
        then: Yup.string()
          .trim()
          .required('Last Name is required')
          .min(2, 'Last Name should have minimum of two characters'),
      }),
    Owner3Email: Yup.string()
      .when('HasOwner3', {
        is: (HasOwner3) => HasOwner3,
        then: Yup.string()
          .trim()
          .required('Email is required')
          .email('Specify a valid email'),
      }),
    Owner3MobilePhone: Yup.string()
      .when('HasOwner3', {
        is: (HasOwner3) => HasOwner3,
        then: Yup.string()
          .trim()
          .matches(phoneRegex,'Mobile Phone number is not a valid number'),
      }),
    Owner3Phone: Yup.string()
      .when('HasOwner3', {
        is: (HasOwner3) => HasOwner3,
        then: Yup.string()
          .trim()
          .matches(phoneRegex,'Phone number is not a valid number'),
      }),
    Owner3HomePhone: Yup.string()
      .when('HasOwner3', {
        is: (HasOwner3) => HasOwner3,
        then: Yup.string()
          .trim()
          .matches(phoneRegex,'Home Phone number is not a valid number'),
      }),
    Owner3SSN: Yup.string()
      .when('HasOwner3', {
        is: (HasOwner3) => HasOwner3,
        then: Yup.string()
          .trim()
          .min(2, 'Owner3 SSN should have minimum of two characters')
          .max(11, 'Owner3 SSN should have not be more than 11 characters')
          .matches(SSNRegex, 'SSN is not valid. it should be in the format XXX-XX-XXXX'),
      }),
    Owner3Ownership: Yup.number()
      .when('HasOwner3', {
        is: (HasOwner3) => HasOwner3,
        then: Yup.number()
          .required('Percentage ownership is required')
          .min(0, 'Minimum value is 0')
          .max(100, 'Maximum value is 100'),
      }),
  });

  useImperativeHandle(ref, () => ({
    isValid: async () => {
      const errors = await formikRef.current.validateForm();
      if(errors.constructor === Object && Object.keys(errors).length === 0){
        return true;
      }else{
        return false;
      }
    },
    getValues: () => {
      return formikRef.current.values
    },
    handleSubmit: () => {
      formikRef.current.handleSubmit();
    }
  }));

  return (
    <div className='mt-5'>
      <Formik
        initialValues={{
          FirstName:'',
          LastName:'',
          Street:'',
          State:'',
          PostalCode:'',
          City:'',
          Email:'',
          MobilePhone: '',
          Phone:'',
          HomePhone: '',
          DateOfBirth: '',
          Owner1Ownership: '',
          Owner1SSN: '',
          ...values
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
        }}
        validationSchema={validationSchema}
        innerRef={formikRef}
      >
        {({ handleChange, values, errors, touched, handleBlur, setFieldValue, setFieldTouched }) => (
          <Form>
            <Row>
              <Col md="6" sm="12" className='mb-4'>
                <FormGroup className={`${errors.FirstName && touched.FirstName ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>First Name <span className='text-danger'>*</span></Label>
                  <Input
                    placeholder=""
                    type="text" 
                    name='FirstName'
                    onChange={handleChange('FirstName')}
                    onBlur={handleBlur('FirstName')}
                    value={values.FirstName}
                  />
                </FormGroup>
                <small className='text-danger'>{errors.FirstName && touched.FirstName ? errors.FirstName : null}</small>
              </Col>
              <Col md="6" sm="12" className='mb-4'>
                <FormGroup className={`${errors.LastName && touched.LastName ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>Last Name <span className='text-danger'>*</span></Label>
                  <Input
                    placeholder=""
                    type="text" 
                    name='LastName'
                    onChange={handleChange('LastName')}
                    onBlur={handleBlur('LastName')}
                    value={values.LastName}
                  />
                </FormGroup>
                <small className='text-danger'>{errors.LastName && touched.LastName ? errors.LastName : null}</small>
              </Col>
              <Col md="6" sm="12" className='mb-4'>
                <FormGroup className={`${errors.Email && touched.Email ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>Email <span className='text-danger'>*</span></Label>
                  <Input
                    placeholder=""
                    type="email" 
                    name='Email'
                    onChange={handleChange('Email')}
                    onBlur={handleBlur('Email')}
                    value={values.Email}
                  />
                </FormGroup>
                <small className='text-danger'>{errors.Email && touched.Email ? errors.Email : null}</small>
              </Col>
              <Col md="6" sm="12" className='mb-4'>
                <FormGroup className={`${errors.MobilePhone && touched.MobilePhone ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>Mobile Phone <span className='text-danger'>*</span></Label>
                  <Input
                    placeholder=""
                    type="phone" 
                    name='MobilePhone'
                    onChange={handleChange('MobilePhone')}
                    onBlur={handleBlur('MobilePhone')}
                    value={values.MobilePhone}
                  />
                </FormGroup>
                <small className='text-danger'>{errors.MobilePhone && touched.MobilePhone ? errors.MobilePhone : null}</small>
              </Col>
              <Col md="6" sm="12" className='mb-4'>
                <FormGroup className={`${errors.Phone && touched.Phone ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>Business Phone </Label>
                  <Input
                    placeholder=""
                    type="phone" 
                    name='Phone'
                    onChange={handleChange('Phone')}
                    onBlur={handleBlur('Phone')}
                    value={values.Phone}
                  />
                </FormGroup>
                <small className='text-danger'>{errors.Phone && touched.Phone ? errors.Phone : null}</small>
              </Col>
              <Col md="6" sm="12" className='mb-4'>
                <FormGroup className={`${errors.HomePhone && touched.HomePhone ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>Home Phone </Label>
                  <Input
                    placeholder=""
                    type="phone" 
                    name='HomePhone'
                    onChange={handleChange('HomePhone')}
                    onBlur={handleBlur('HomePhone')}
                    value={values.HomePhone}
                  />
                </FormGroup>
                <small className='text-danger'>{errors.HomePhone && touched.HomePhone ? errors.HomePhone : null}</small>
              </Col>
              <Col md="12" sm="12" className='mb-4'>
                <FormGroup className={`${errors.Street && touched.Street ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>Street <span className='text-danger'>*</span></Label>
                  <Input
                    placeholder=""
                    type="text" 
                    name='Street'
                    onChange={handleChange('Street')}
                    onBlur={handleBlur('Street')}
                    value={values.Street}
                  />
                </FormGroup>
                <small className='text-danger'>{errors.Street && touched.Street ? errors.Street : null}</small>
              </Col>
              <Col md="6" sm="12" className='mb-4'>
                <FormGroup className={`${errors.City && touched.City ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>City <span className='text-danger'>*</span></Label>
                  <Input
                    placeholder=""
                    type="text" 
                    name='City'
                    onChange={handleChange('City')}
                    onBlur={handleBlur('City')}
                    value={values.City}
                  />
                </FormGroup>
                <small className='text-danger'>{errors.City && touched.City ? errors.City : null}</small>
              </Col>
              <Col md="6" sm="12" className='mb-4'>
                <FormGroup className={`${errors.State && touched.State ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>State/Province <span className='text-danger'>*</span></Label>
                  <Input
                    placeholder=""
                    type="text" 
                    name='State'
                    onChange={handleChange('State')}
                    onBlur={handleBlur('State')}
                    value={values.State}
                  />
                </FormGroup>
                <small className='text-danger'>{errors.State && touched.State ? errors.State : null}</small>
              </Col>
              <Col md="6" sm="12" className='mb-4'>
                <FormGroup className={`${errors.PostalCode && touched.PostalCode ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>Zip/Postal Code <span className='text-danger'>*</span></Label>
                  <Input
                    placeholder=""
                    type="text" 
                    name='PostalCode'
                    onChange={handleChange('PostalCode')}
                    onBlur={handleBlur('PostalCode')}
                    value={values.PostalCode}
                  />
                </FormGroup>
                <small className='text-danger'>{errors.PostalCode && touched.PostalCode ? errors.PostalCode : null}</small>
              </Col>
              <Col sm='12' md='6'>
                <FormGroup className={`${errors.DateOfBirth && touched.DateOfBirth ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>Date of Birth <span className='text-danger'>*</span></Label>
                    <DatePicker
                      className='col-12'
                      value={values.DateOfBirth && moment(values.DateOfBirth)}
                      onChange={date => {
                        setFieldValue('DateOfBirth', moment(date).format())
                      }}
                      onBlur={() => setFieldTouched('DateOfBirth')}
                      allowClear={false}
                      format='MM/DD/YYYY'
                    />
                </FormGroup>
                <small className='text-danger'>{errors.DateOfBirth && touched.DateOfBirth ? errors.DateOfBirth : null}</small>
              </Col>
              <Col md="6" sm="12" className='mb-4'>
                <FormGroup className={`${errors.Owner1SSN && touched.Owner1SSN ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>Social Security Number <span className='text-danger'>*</span></Label>
                  <Input
                    placeholder=""
                    type="text" 
                    name='Owner1SSN'
                    onChange={e => setFieldValue('Owner1SSN', formatSSN(e.target.value))}
                    onBlur={handleBlur('Owner1SSN')}
                    value={values.Owner1SSN}
                  />
                </FormGroup>
                <small className='text-danger'>{errors.Owner1SSN && touched.Owner1SSN ? errors.Owner1SSN : null}</small>
              </Col>
              <Col md="6" sm="12" className='mb-4'>
                <FormGroup className={`${errors.Owner1Ownership && touched.Owner1Ownership ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>Percentage Ownership <span className='text-danger'>*</span></Label>
                  <Input
                    placeholder=""
                    type="text" 
                    name='Owner1Ownership'
                    onChange={handleChange('Owner1Ownership')}
                    onBlur={(e) => {
                      setFieldTouched('Owner1Ownership');
                      if(e.target.value < 90){
                        setFieldValue('HasOwner2', true);
                        setOwnershipMessage('Because the ownership percentage of the primary contact is less than 90%, please enter additional ownership information');
                      }
                    }}
                    value={values.Owner1Ownership}
                  />
                </FormGroup>
                <small className='text-danger'>{errors.Owner1Ownership && touched.Owner1Ownership ? errors.Owner1Ownership : null}</small>
              </Col>
            </Row>
            {ownershipMessage ? (
              <Alert
                message="Add Additional Owners"
                description={ownershipMessage}
                type="info"
                showIcon
                className='mb-5'
                closable
              />
            ) : null}
            <Row>
              <Col md="6" sm="12" className='mb-4'>
                <FormGroup check inline>
                  <Label check>
                    <Input
                      type="checkbox" 
                      name='HasOwner2'
                      onChange={e => {
                        if(values.HasOwner3){
                          setFieldValue('Owner3FirstName', '');
                          setFieldValue('Owner3LastName', '');
                          setFieldValue('Owner3Email', '');
                          setFieldValue('Owner3MobilePhone', '');
                          setFieldValue('Owner3Phone', '');
                          setFieldValue('Owner3HomePhone', '');
                          setFieldValue('Owner3Street', '');
                          setFieldValue('Owner3City', '');
                          setFieldValue('Owner3State', '');
                          setFieldValue('Owner3PostalCode', '');
                          setFieldValue('Owner3DateOfBirth', '');
                          setFieldValue('Owner3SSN', '');
                          setFieldValue('Owner3Ownership', '');
                          setFieldValue('HasOwner3', false);
                        }

                        if(values.HasOwner2){
                          setFieldValue('Owner2FirstName', '');
                          setFieldValue('Owner2LastName', '');
                          setFieldValue('Owner2Email', '');
                          setFieldValue('Owner2MobilePhone', '');
                          setFieldValue('Owner2Phone', '');
                          setFieldValue('Owner2HomePhone', '');
                          setFieldValue('Owner2Street', '');
                          setFieldValue('Owner2City', '');
                          setFieldValue('Owner2State', '');
                          setFieldValue('Owner2PostalCode', '');
                          setFieldValue('Owner2DateOfBirth', '');
                          setFieldValue('Owner2SSN', '');
                          setFieldValue('Owner2Ownership', '');
                        }
                        setFieldValue('HasOwner2', e.target.checked);
                      }}
                      onBlur={handleBlur('HasOwner2')}
                      checked={values.HasOwner2}
                    />
                    <span className="form-check-sign" />Add Second Owner
                  </Label>
                </FormGroup>
              </Col>
            </Row>
            {values.HasOwner2 ? (
              <Row>
                <Col sm="12">
                  <h5 className='sos-font-weight-600 text-black sos-text-medium'>Second Owner Details</h5>
                </Col>
                <Col md="6" sm="12" className='mb-4'>
                  <FormGroup className={`${errors.Owner2FirstName && touched.Owner2FirstName ? 'mb-0' : ''}`}>
                    <Label className='text-medium'>First Name <span className='text-danger'>*</span></Label>
                    <Input
                      placeholder=""
                      type="text" 
                      name='Owner2FirstName'
                      onChange={handleChange('Owner2FirstName')}
                      onBlur={handleBlur('Owner2FirstName')}
                      value={values.Owner2FirstName}
                    />
                  </FormGroup>
                  <small className='text-danger'>{errors.Owner2FirstName && touched.Owner2FirstName ? errors.Owner2FirstName : null}</small>
                </Col>
                <Col md="6" sm="12" className='mb-4'>
                  <FormGroup className={`${errors.Owner2LastName && touched.Owner2LastName ? 'mb-0' : ''}`}>
                    <Label className='text-medium'>Last Name <span className='text-danger'>*</span></Label>
                    <Input
                      placeholder=""
                      type="text" 
                      name='Owner2LastName'
                      onChange={handleChange('Owner2LastName')}
                      onBlur={handleBlur('Owner2LastName')}
                      checked={values.Owner2LastName}
                    />
                  </FormGroup>
                  <small className='text-danger'>{errors.Owner2LastName && touched.Owner2LastName ? errors.Owner2LastName : null}</small>
                </Col>
                <Col md="6" sm="12" className='mb-4'>
                  <FormGroup className={`${errors.Owner2Email && touched.Owner2Email ? 'mb-0' : ''}`}>
                    <Label className='text-medium'>Email <span className='text-danger'>*</span></Label>
                    <Input
                      placeholder=""
                      type="email" 
                      name='Owner2Email'
                      onChange={handleChange('Owner2Email')}
                      onBlur={handleBlur('Owner2Email')}
                      value={values.Owner2Email}
                    />
                  </FormGroup>
                  <small className='text-danger'>{errors.Owner2Email && touched.Owner2Email ? errors.Owner2Email : null}</small>
                </Col>
                <Col md="6" sm="12" className='mb-4'>
                  <FormGroup className={`${errors.Owner2MobilePhone && touched.Owner2MobilePhone ? 'mb-0' : ''}`}>
                    <Label className='text-medium'>Mobile Phone</Label>
                    <Input
                      placeholder=""
                      type="phone" 
                      name='Owner2MobilePhone'
                      onChange={handleChange('Owner2MobilePhone')}
                      onBlur={handleBlur('Owner2MobilePhone')}
                      value={values.Owner2MobilePhone}
                    />
                  </FormGroup>
                  <small className='text-danger'>{errors.Owner2MobilePhone && touched.Owner2MobilePhone ? errors.Owner2MobilePhone : null}</small>
                </Col>
                <Col md="6" sm="12" className='mb-4'>
                  <FormGroup className={`${errors.Owner2Phone && touched.Owner2Phone ? 'mb-0' : ''}`}>
                    <Label className='text-medium'>Business Phone</Label>
                    <Input
                      placeholder=""
                      type="phone" 
                      name='Owner2Phone'
                      onChange={handleChange('Owner2Phone')}
                      onBlur={handleBlur('Owner2Phone')}
                      value={values.Owner2Phone}
                    />
                  </FormGroup>
                  <small className='text-danger'>{errors.Owner2Phone && touched.Owner2Phone ? errors.Owner2Phone : null}</small>
                </Col>
                <Col md="6" sm="12" className='mb-4'>
                  <FormGroup className={`${errors.Owner2HomePhone && touched.Owner2HomePhone ? 'mb-0' : ''}`}>
                    <Label className='text-medium'>Home Phone</Label>
                    <Input
                      placeholder=""
                      type="phone" 
                      name='Owner2HomePhone'
                      onChange={handleChange('Owner2HomePhone')}
                      onBlur={handleBlur('Owner2HomePhone')}
                      value={values.Owner2HomePhone}
                    />
                  </FormGroup>
                  <small className='text-danger'>{errors.Owner2HomePhone && touched.Owner2HomePhone ? errors.Owner2HomePhone : null}</small>
                </Col>
                <Col md="12" sm="12" className='mb-4'>
                  <FormGroup className={`${errors.Owner2Street && touched.Owner2Street ? 'mb-0' : ''}`}>
                    <Label className='text-medium'>Street</Label>
                    <Input
                      placeholder=""
                      type="text" 
                      name='Owner2Street'
                      onChange={handleChange('Owner2Street')}
                      onBlur={handleBlur('Owner2Street')}
                      value={values.Owner2Street}
                    />
                  </FormGroup>
                  <small className='text-danger'>{errors.Owner2Street && touched.Owner2Street ? errors.Owner2Street : null}</small>
                </Col>
                <Col md="6" sm="12" className='mb-4'>
                  <FormGroup className={`${errors.Owner2City && touched.Owner2City ? 'mb-0' : ''}`}>
                    <Label className='text-medium'>City</Label>
                    <Input
                      placeholder=""
                      type="text" 
                      name='Owner2City'
                      onChange={handleChange('Owner2City')}
                      onBlur={handleBlur('Owner2City')}
                      value={values.Owner2City}
                    />
                  </FormGroup>
                  <small className='text-danger'>{errors.Owner2City && touched.Owner2City ? errors.Owner2City : null}</small>
                </Col>
                <Col md="6" sm="12" className='mb-4'>
                  <FormGroup className={`${errors.Owner2State && touched.Owner2State ? 'mb-0' : ''}`}>
                    <Label className='text-medium'>State/Province</Label>
                    <Input
                      placeholder=""
                      type="text" 
                      name='Owner2State'
                      onChange={handleChange('Owner2State')}
                      onBlur={handleBlur('Owner2State')}
                      value={values.Owner2State}
                    />
                  </FormGroup>
                  <small className='text-danger'>{errors.Owner2State && touched.Owner2State ? errors.Owner2State : null}</small>
                </Col>
                <Col md="6" sm="12" className='mb-4'>
                  <FormGroup className={`${errors.Owner2PostalCode && touched.Owner2PostalCode ? 'mb-0' : ''}`}>
                    <Label className='text-medium'>Zip/Postal Code</Label>
                    <Input
                      placeholder=""
                      type="text" 
                      name='Owner2PostalCode'
                      onChange={handleChange('Owner2PostalCode')}
                      onBlur={handleBlur('Owner2PostalCode')}
                      value={values.Owner2PostalCode}
                    />
                  </FormGroup>
                  <small className='text-danger'>{errors.Owner2PostalCode && touched.Owner2PostalCode ? errors.Owner2PostalCode : null}</small>
                </Col>
                <Col sm='12' md='6'>
                  <FormGroup className={`${errors.Owner2DateOfBirth && touched.Owner2DateOfBirth ? 'mb-0' : ''}`}>
                    <Label className='text-medium'>Date of Birth</Label>
                      <DatePicker
                        className='col-12'
                        value={values.Owner2DateOfBirth && moment(values.Owner2DateOfBirth)}
                        onChange={date => {
                          setFieldValue('Owner2DateOfBirth', moment(date).format())
                        }}
                        onBlur={() => setFieldTouched('Owner2DateOfBirth')}
                        allowClear={false}
                        format='MM/DD/YYYY'
                      />
                  </FormGroup>
                  <small className='text-danger'>{errors.Owner2DateOfBirth && touched.Owner2DateOfBirth ? errors.Owner2DateOfBirth : null}</small>
                </Col>
                <Col md="6" sm="12" className='mb-4'>
                  <FormGroup className={`${errors.Owner2SSN && touched.Owner2SSN ? 'mb-0' : ''}`}>
                    <Label className='text-medium'>Social Security Number</Label>
                    <Input
                      placeholder=""
                      type="text" 
                      name='Owner2SSN'
                      onChange={e => setFieldValue('Owner2SSN', formatSSN(e.target.value))}
                      onBlur={handleBlur('Owner2SSN')}
                      value={values.Owner2SSN}
                    />
                  </FormGroup>
                  <small className='text-danger'>{errors.Owner2SSN && touched.Owner2SSN ? errors.Owner2SSN : null}</small>
                </Col>
                <Col md="6" sm="12" className='mb-4'>
                  <FormGroup className={`${errors.Owner2Ownership && touched.Owner2Ownership ? 'mb-0' : ''}`}>
                    <Label className='text-medium'>Percentage Ownership <span className='text-danger'>*</span></Label>
                    <Input
                      placeholder=""
                      type="text" 
                      name='Owner2Ownership'
                      onChange={handleChange('Owner2Ownership')}
                      onBlur={handleBlur('Owner2Ownership')}
                      value={values.Owner2Ownership}
                    />
                  </FormGroup>
                  <small className='text-danger'>{errors.Owner2Ownership && touched.Owner2Ownership ? errors.Owner2Ownership : null}</small>
                </Col>
              </Row>
            ) : null}
            {values.HasOwner2 ? (
              <Row>
                <Col md="6" sm="12" className='mb-4'>
                  <FormGroup check inline>
                    <Label check>
                      <Input
                        type="checkbox" 
                        name='HasOwner3'
                        onChange={e => {
                          if(values.HasOwner3){
                            setFieldValue('Owner3FirstName', '');
                            setFieldValue('Owner3LastName', '');
                            setFieldValue('Owner3Email', '');
                            setFieldValue('Owner3MobilePhone', '');
                            setFieldValue('Owner3Phone', '');
                            setFieldValue('Owner3HomePhone', '');
                            setFieldValue('Owner3Street', '');
                            setFieldValue('Owner3City', '');
                            setFieldValue('Owner3State', '');
                            setFieldValue('Owner3PostalCode', '');
                            setFieldValue('Owner3DateOfBirth', '');
                            setFieldValue('Owner3SSN', '');
                            setFieldValue('Owner3Ownership', '');
                          }
                          setFieldValue('HasOwner3', e.target.checked);
                        }}
                        onBlur={handleBlur('HasOwner3')}
                        value={values.HasOwner3}
                      />
                      <span className="form-check-sign" />Add Third Owner
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
            ) : null}
            {values.HasOwner3 ? (
              <Row>
                <Col sm="12">
                  <h5 className='sos-font-weight-600 text-black sos-text-medium'>Third Owner Details</h5>
                </Col>
                <Col md="6" sm="12" className='mb-4'>
                  <FormGroup className={`${errors.Owner3FirstName && touched.Owner3FirstName ? 'mb-0' : ''}`}>
                    <Label className='text-medium'>First Name <span className='text-danger'>*</span></Label>
                    <Input
                      placeholder=""
                      type="text" 
                      name='Owner3FirstName'
                      onChange={handleChange('Owner3FirstName')}
                      onBlur={handleBlur('Owner3FirstName')}
                      value={values.Owner3FirstName}
                    />
                  </FormGroup>
                  <small className='text-danger'>{errors.Owner3FirstName && touched.Owner3FirstName ? errors.Owner3FirstName : null}</small>
                </Col>
                <Col md="6" sm="12" className='mb-4'>
                  <FormGroup className={`${errors.Owner3LastName && touched.Owner3LastName ? 'mb-0' : ''}`}>
                    <Label className='text-medium'>Last Name <span className='text-danger'>*</span></Label>
                    <Input
                      placeholder=""
                      type="text" 
                      name='Owner3LastName'
                      onChange={handleChange('Owner3LastName')}
                      onBlur={handleBlur('Owner3LastName')}
                      value={values.Owner3LastName}
                    />
                  </FormGroup>
                  <small className='text-danger'>{errors.Owner3LastName && touched.Owner3LastName ? errors.Owner3LastName : null}</small>
                </Col>
                <Col md="6" sm="12" className='mb-4'>
                  <FormGroup className={`${errors.Owner3Email && touched.Owner3Email ? 'mb-0' : ''}`}>
                    <Label className='text-medium'>Email <span className='text-danger'>*</span></Label>
                    <Input
                      placeholder=""
                      type="email" 
                      name='Owner3Email'
                      onChange={handleChange('Owner3Email')}
                      onBlur={handleBlur('Owner3Email')}
                      value={values.Owner3Email}
                    />
                  </FormGroup>
                  <small className='text-danger'>{errors.Owner3Email && touched.Owner3Email ? errors.Owner3Email : null}</small>
                </Col>
                <Col md="6" sm="12" className='mb-4'>
                  <FormGroup className={`${errors.Owner3MobilePhone && touched.Owner3MobilePhone ? 'mb-0' : ''}`}>
                    <Label className='text-medium'>Mobile Phone=</Label>
                    <Input
                      placeholder=""
                      type="phone" 
                      name='Owner3MobilePhone'
                      onChange={handleChange('Owner3MobilePhone')}
                      onBlur={handleBlur('Owner3MobilePhone')}
                      value={values.Owner3MobilePhone}
                    />
                  </FormGroup>
                  <small className='text-danger'>{errors.Owner3MobilePhone && touched.Owner3MobilePhone ? errors.Owner3MobilePhone : null}</small>
                </Col>
                <Col md="6" sm="12" className='mb-4'>
                  <FormGroup className={`${errors.Owner3Phone && touched.Owner3Phone ? 'mb-0' : ''}`}>
                    <Label className='text-medium'>Business Phone</Label>
                    <Input
                      placeholder=""
                      type="phone" 
                      name='Owner3Phone'
                      onChange={handleChange('Owner3Phone')}
                      onBlur={handleBlur('Owner3Phone')}
                      value={values.Owner3Phone}
                    />
                  </FormGroup>
                  <small className='text-danger'>{errors.Owner3Phone && touched.Owner3Phone ? errors.Owner3Phone : null}</small>
                </Col>
                <Col md="6" sm="12" className='mb-4'>
                  <FormGroup className={`${errors.Owner3HomePhone && touched.Owner3HomePhone ? 'mb-0' : ''}`}>
                    <Label className='text-medium'>Home Phone</Label>
                    <Input
                      placeholder=""
                      type="phone"
                      name='Owner3HomePhone'
                      onChange={handleChange('Owner3HomePhone')}
                      onBlur={handleBlur('Owner3HomePhone')}
                      value={values.Owner3HomePhone}
                    />
                  </FormGroup>
                  <small className='text-danger'>{errors.Owner3HomePhone && touched.Owner3HomePhone ? errors.Owner3HomePhone : null}</small>
                </Col>
                <Col md="12" sm="12" className='mb-4'>
                  <FormGroup className={`${errors.Owner3Street && touched.Owner3Street ? 'mb-0' : ''}`}>
                    <Label className='text-medium'>Street</Label>
                    <Input
                      placeholder=""
                      type="text" 
                      name='Owner3Street'
                      onChange={handleChange('Owner3Street')}
                      onBlur={handleBlur('Owner3Street')}
                      value={values.Owner3Street}
                    />
                  </FormGroup>
                  <small className='text-danger'>{errors.Owner3Street && touched.Owner3Street ? errors.Owner3Street : null}</small>
                </Col>
                <Col md="6" sm="12" className='mb-4'>
                  <FormGroup className={`${errors.Owner3City && touched.Owner3City ? 'mb-0' : ''}`}>
                    <Label className='text-medium'>City</Label>
                    <Input
                      placeholder=""
                      type="text" 
                      name='Owner3City'
                      onChange={handleChange('Owner3City')}
                      onBlur={handleBlur('Owner3City')}
                      value={values.Owner3City}
                    />
                  </FormGroup>
                  <small className='text-danger'>{errors.Owner3City && touched.Owner3City ? errors.Owner3City : null}</small>
                </Col>
                <Col md="6" sm="12" className='mb-4'>
                  <FormGroup className={`${errors.Owner3State && touched.Owner3State ? 'mb-0' : ''}`}>
                    <Label className='text-medium'>State/Province</Label>
                    <Input
                      placeholder=""
                      type="text" 
                      name='Owner3State'
                      onChange={handleChange('Owner3State')}
                      onBlur={handleBlur('Owner3State')}
                      value={values.Owner3State}
                    />
                  </FormGroup>
                  <small className='text-danger'>{errors.Owner3State && touched.Owner3State ? errors.Owner3State : null}</small>
                </Col>
                <Col md="6" sm="12" className='mb-4'>
                  <FormGroup className={`${errors.Owner3PostalCode && touched.Owner3PostalCode ? 'mb-0' : ''}`}>
                    <Label className='text-medium'>Zip/Postal Code</Label>
                    <Input
                      placeholder=""
                      type="text" 
                      name='Owner3PostalCode'
                      onChange={handleChange('Owner3PostalCode')}
                      onBlur={handleBlur('Owner3PostalCode')}
                      value={values.Owner3PostalCode}
                    />
                  </FormGroup>
                  <small className='text-danger'>{errors.Owner3PostalCode && touched.Owner3PostalCode ? errors.Owner3PostalCode : null}</small>
                </Col>
                <Col sm='12' md='6'>
                  <FormGroup className={`${errors.Owner3DateOfBirth && touched.Owner3DateOfBirth ? 'mb-0' : ''}`}>
                    <Label className='text-medium'>Date of Birth</Label>
                      <DatePicker
                        className='col-12'
                        value={values.Owner3DateOfBirth && moment(values.Owner3DateOfBirth)}
                        onChange={date => {
                          setFieldValue('Owner3DateOfBirth', moment(date).format())
                        }}
                        onBlur={() => setFieldTouched('Owner3DateOfBirth')}
                        allowClear={false}
                        format='MM/DD/YYYY'
                      />
                  </FormGroup>
                  <small className='text-danger'>{errors.Owner3DateOfBirth && touched.Owner3DateOfBirth ? errors.Owner3DateOfBirth : null}</small>
                </Col>
                <Col md="6" sm="12" className='mb-4'>
                  <FormGroup className={`${errors.Owner3SSN && touched.Owner3SSN ? 'mb-0' : ''}`}>
                    <Label className='text-medium'>Social Security Number</Label>
                    <Input
                      placeholder=""
                      type="text" 
                      name='Owner3SSN'
                      onChange={e => setFieldValue('Owner3SSN', formatSSN(e.target.value))}
                      onBlur={handleBlur('Owner3SSN')}
                      value={values.Owner3SSN}
                    />
                  </FormGroup>
                  <small className='text-danger'>{errors.Owner3SSN && touched.Owner3SSN ? errors.Owner3SSN : null}</small>
                </Col>
                <Col md="6" sm="12" className='mb-4'>
                  <FormGroup className={`${errors.Owner3Ownership && touched.Owner3Ownership ? 'mb-0' : ''}`}>
                    <Label className='text-medium'>Percentage Ownership <span className='text-danger'>*</span></Label>
                    <Input
                      placeholder=""
                      type="text" 
                      name='Owner3Ownership'
                      onChange={handleChange('Owner3Ownership')}
                      onBlur={handleBlur('Owner3Ownership')}
                      value={values.Owner3Ownership}
                    />
                  </FormGroup>
                  <small className='text-danger'>{errors.Owner3Ownership && touched.Owner3Ownership ? errors.Owner3Ownership : null}</small>
                </Col>
              </Row>
            ) : null}
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default forwardRef(Step2);
