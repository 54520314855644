import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';
export const myISOStipulationStart = () => ({
  type: types.GET_MY_ISO_STIPULATION_START
});

export const myISOStipulationSuccess = payload => ({
  type: types.GET_MY_ISO_STIPULATION_SUCCESS,
  payload
});

export const myISOStipulationFail = payload => ({
  type: types.GET_MY_ISO_STIPULATION_FAIL,
  payload
});

export const myISOStipulationCleanup = () => ({
  type: types.GET_MY_ISO_STIPULATION_CLEANUP
});

export const myISOStipulation = (portal,type,id)=> async dispatch => {
  try {
    dispatch(myISOStipulationStart());
    const requestObj = {
      path: `stipulation/${portal}/iso/${type}/${id}`,
      method: "GET",   
    }
    const  data  = await AxiosCall(requestObj);
    dispatch(myISOStipulationSuccess(data));
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(myISOStipulationFail(error));
  }
}
