import React, { useRef, forwardRef, useImperativeHandle } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Row,
  Col,
  Label,
  FormGroup,
  Input,
  Form
} from 'reactstrap';
import Select from "react-select";
import { useSelector } from 'react-redux';

//utils
import { HowDoYouPreferToBeContacted } from '../../../../../utils/dropdown-values'
import { getValueObject, formatSSN } from '../../../../../utils/helpers';

const Step2 = ({ values }, ref) => {
  const formikRef = useRef();
  const phoneRegex = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;
  const SSNRegex = /^\d{3}\-?\d{2}\-?\d{4}$/;
  const profile = useSelector(s => s.getProfile.data);
  const validationSchema = Yup.object().shape({
    Title: Yup.string()
      .trim()
      .required('Title is required')
      .min(2, 'Title should have minimum of two characters'),
    FirstName: Yup.string()
      .trim()
      .required('First Name is required')
      .min(2, 'First Name should have minimum of two characters'),
    LastName: Yup.string()
      .trim()
      .required('Last Name is required')
      .min(2, 'Last Name should have minimum of two characters'),
    Email: Yup.string()
      .trim()
      .required('Email is required')
      .email('Specify a valid email'),
    Contact_Preference__c: Yup.string()
      .required('Contact Preference is required'),
    Phone: Yup.string()
      .trim()
      .required('Phone number is required')
      .matches(phoneRegex,'Phone number is not valid'),
    Street: Yup.string()
      .trim()
      .required('Street is required')
      .min(2, 'Street should have minimum of two characters'),
    City: Yup.string()
      .trim()
      .required('City is required')
      .min(2, 'City should have minimum of two characters'),
    State: Yup.string()
      .trim()
      .required('State is required')
      .min(2, 'State should have minimum of two characters'),
    Country: Yup.string()
      .trim()
      .min(2, 'Country should have minimum of two characters'),
    PostalCode: Yup.string()
      .trim()
      .required('PostalCode is required')
      .min(2, 'PostalCode should have minimum of two characters'),
    Owner1Ownership: Yup.number()
      .required('Percentage ownership is required')
      .min(0, 'Minimum value is 0')
      .max(100, 'Maximum value is 100'),
    Owner1SSN: Yup.string()
      .trim()
      .required('Owner1SSN is required')
      .min(2, 'Owner1SSN should have minimum of two characters')
      .max(11, 'Owner1SSN should have not be more than 11 characters')
      .matches(SSNRegex, 'SSN is not valid. it should be in the format XXX-XX-XXXX'),
  });

  useImperativeHandle(ref, () => ({
    isValid: async () => {
      const errors = await formikRef.current.validateForm();
      if(errors.constructor === Object && Object.keys(errors).length === 0){
        return true;
      }else{
        return false;
      }
    },
    getValues: () => {
      return formikRef.current.values
    },
    handleSubmit: () => {
      formikRef.current.handleSubmit();
    }
  }));

  return (
    <div className='mt-5'>
      <Formik
       initialValues={{
        FirstName: profile.FirstName,
        LastName: profile.LastName,
        Contact_Preference__c: profile.Contact_Preference__c,
        Street: profile.MailingStreet,
        State: profile.MailingState,
        PostalCode: profile.MailingPostalCode,
        City: profile.MailingCity,
        Country: profile.MailingCountry,
        Title: profile.Title,
        Email: profile.Email,
        Phone: profile.MobilePhone,
        Owner1Ownership: "",
        Owner1SSN: "",
        ...values,
      }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
        }}
        enableReinitialize={true}
        validationSchema={validationSchema}
        innerRef={formikRef}
      >
        {({ handleChange, values, errors, touched, handleBlur, setFieldValue, setFieldTouched }) => (
          <Form>
            <Row>
              <Col md="6" sm="12" className='mb-4'>
                <FormGroup className={`${errors.FirstName && touched.FirstName ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>First Name <span className='text-danger'>*</span></Label>
                  <Input
                    placeholder=""
                    type="text" 
                    name='FirstName'
                    onChange={handleChange('FirstName')}
                    onBlur={handleBlur('FirstName')}
                    value={values.FirstName}
                  />
                </FormGroup>
                <small className='text-danger'>{errors.FirstName && touched.FirstName ? errors.FirstName : null}</small>
              </Col>
              <Col md="6" sm="12" className='mb-4'>
                <FormGroup className={`${errors.LastName && touched.LastName ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>Last Name <span className='text-danger'>*</span></Label>
                  <Input
                    placeholder=""
                    type="text" 
                    name='LastName'
                    onChange={handleChange('LastName')}
                    onBlur={handleBlur('LastName')}
                    value={values.LastName}
                  />
                </FormGroup>
                <small className='text-danger'>{errors.LastName && touched.LastName ? errors.LastName : null}</small>
              </Col>
              <Col md="6" sm="12" className='mb-4'>
                <FormGroup className={`${errors.Title && touched.Title ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>Title <span className='text-danger'>*</span></Label>
                  <Input
                    placeholder=""
                    type="text" 
                    name='Title'
                    onChange={handleChange('Title')}
                    onBlur={handleBlur('Title')}
                    value={values.Title}
                  />
                </FormGroup>
                <small className='text-danger'>{errors.Title && touched.Title ? errors.Title : null}</small>
              </Col>
              <Col md="6" sm="12" className='mb-4'>
                <FormGroup className={`${errors.Email && touched.Email ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>Email <span className='text-danger'>*</span></Label>
                  <Input
                    placeholder=""
                    type="email" 
                    name='Email'
                    onChange={handleChange('Email')}
                    onBlur={handleBlur('Email')}
                    value={values.Email}
                    disabled
                  />
                </FormGroup>
                <small className='text-danger'>{errors.Email && touched.Email ? errors.Email : null}</small>
              </Col>
              <Col md="6" sm="12" className='mb-4'>
                <Label className='text-medium'>How do you prefer to be contacted? <span className='text-danger'>*</span></Label>
                <Select
                  className="react-select info"
                  classNamePrefix="react-select"
                  placeholder="--None--"
                  name="Contact_Preference__c"
                  value={getValueObject(HowDoYouPreferToBeContacted, values.Contact_Preference__c)}
                  onChange={value => setFieldValue('Contact_Preference__c', value.value)}
                  options={HowDoYouPreferToBeContacted}
                  onBlur={() => setFieldTouched('Contact_Preference__c')}
                />
                <small className='text-danger'>{errors.Contact_Preference__c && touched.Contact_Preference__c ? errors.Contact_Preference__c : null}</small>
              </Col>
              <Col md="6" sm="12" className='mb-4'>
                <FormGroup className={`${errors.Phone && touched.Phone ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>Phone <span className='text-danger'>*</span></Label>
                  <Input
                    placeholder=""
                    type="phone" 
                    name='Phone'
                    onChange={handleChange('Phone')}
                    onBlur={handleBlur('Phone')}
                    value={values.Phone}
                  />
                </FormGroup>
                <small className='text-danger'>{errors.Phone && touched.Phone ? errors.Phone : null}</small>
              </Col>
              <Col md="12" sm="12" className='mb-4'>
                <FormGroup className={`${errors.Street && touched.Street ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>Street <span className='text-danger'>*</span></Label>
                  <Input
                    placeholder=""
                    type="text" 
                    name='Street'
                    onChange={handleChange('Street')}
                    onBlur={handleBlur('Street')}
                    value={values.Street}
                  />
                </FormGroup>
                <small className='text-danger'>{errors.Street && touched.Street ? errors.Street : null}</small>
              </Col>
              <Col md="6" sm="12" className='mb-4'>
                <FormGroup className={`${errors.City && touched.City ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>City <span className='text-danger'>*</span></Label>
                  <Input
                    placeholder=""
                    type="text" 
                    name='City'
                    onChange={handleChange('City')}
                    onBlur={handleBlur('City')}
                    value={values.City}
                  />
                </FormGroup>
                <small className='text-danger'>{errors.City && touched.City ? errors.City : null}</small>
              </Col>
              <Col md="6" sm="12" className='mb-4'>
                <FormGroup className={`${errors.State && touched.State ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>State/Province <span className='text-danger'>*</span></Label>
                  <Input
                    placeholder=""
                    type="text" 
                    name='State'
                    onChange={handleChange('State')}
                    onBlur={handleBlur('State')}
                    value={values.State}
                  />
                </FormGroup>
                <small className='text-danger'>{errors.State && touched.State ? errors.State : null}</small>
              </Col>
              <Col md="6" sm="12" className='mb-4'>
                <FormGroup className={`${errors.Country && touched.Country ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>Country</Label>
                  <Input
                    placeholder=""
                    type="text" 
                    name='Country'
                    onChange={handleChange('Country')}
                    onBlur={handleBlur('Country')}
                    value={values.Country}
                  />
                </FormGroup>
                <small className='text-danger'>{errors.Country && touched.Country ? errors.Country : null}</small>
              </Col>
              <Col md="6" sm="12" className='mb-4'>
                <FormGroup className={`${errors.PostalCode && touched.PostalCode ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>Zip Code <span className='text-danger'>*</span></Label>
                  <Input
                    placeholder=""
                    type="text" 
                    name='PostalCode'
                    onChange={handleChange('PostalCode')}
                    onBlur={handleBlur('PostalCode')}
                    value={values.PostalCode}
                  />
                </FormGroup>
                <small className='text-danger'>{errors.PostalCode && touched.PostalCode ? errors.PostalCode : null}</small>
              </Col>
              <Col md="6" sm="12" className='mb-4'>
                <FormGroup className={`${errors.Owner1SSN && touched.Owner1SSN ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>Social Security Number <span className='text-danger'>*</span></Label>
                  <Input
                    placeholder=""
                    type="text" 
                    name='Owner1SSN'
                    onChange={e => setFieldValue('Owner1SSN', formatSSN(e.target.value))}
                    onBlur={handleBlur('Owner1SSN')}
                    value={values.Owner1SSN}
                  />
                </FormGroup>
                <small className='text-danger'>{errors.Owner1SSN && touched.Owner1SSN ? errors.Owner1SSN : null}</small>
              </Col>
              <Col md="6" sm="12" className='mb-4'>
                <FormGroup className={`${errors.Owner1Ownership && touched.Owner1Ownership ? 'mb-0' : ''}`}>
                  <Label className='text-medium'>Percentage Ownership <span className='text-danger'>*</span></Label>
                  <Input
                    placeholder=""
                    type="text" 
                    name='Owner1Ownership'
                    onChange={handleChange('Owner1Ownership')}
                    onBlur={handleBlur('Owner1Ownership')}
                    value={values.Owner1Ownership}
                  />
                </FormGroup>
                <small className='text-danger'>{errors.Owner1Ownership && touched.Owner1Ownership ? errors.Owner1Ownership : null}</small>
              </Col>
            </Row>            
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default forwardRef(Step2);
