import * as types from '../../action-types';
import { getRepayments as initialState } from "../../initialState";

const getRepayments = (state=initialState, action) => {
  switch (action.type) {
    case types.GET_REPAYMENTS_START:
      return {
        ...state,
        isLoading: true
      };
    case types.GET_REPAYMENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccessful: true,
        data: action.payload
      };
    case types.GET_REPAYMENTS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case types.GET_REPAYMENTS_CLEANUP:
      return {
        ...state,
        isLoading: false,
        isSuccessful: false,
        error: null
      };
    default:
      return state;
  }
}

export default getRepayments;
