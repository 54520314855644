// ------------ import external dependencies -------------
import React, { useState, useRef, useEffect } from "react";
import ReactGA from "react-ga";
import { Row, Col, CardBody, CardTitle } from "reactstrap";
import { Steps, Button, message, Card } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

// -------------- import internal dependencies --------------
import "./index.css";

// components
import Step1 from "./Steps/step1";
import Step2 from "./Steps/step2";
import Step3 from "./Steps/step3";
import Loader from "../../../components/Loader";

//actions
import {
  applicantApplyForFunding,
  applyForFundingCleanup,
} from "../../../store/actions/apply-for-funding";
import {
  getSingleAccount,
  getSingleAccountCleanup,
} from "../../../store/actions/get-single-account";

const FundApplication = () => {
  const { Step } = Steps;
  const [current, setCurrent] = useState(0);
  const step1Ref = useRef();
  const step2Ref = useRef();
  const step3Ref = useRef();
  const [step1Values, setStep1Values] = useState({});
  const [step2Values, setStep2Values] = useState({});
  const [step3Values, setStep3Values] = useState({});
  const dispatch = useDispatch();
  const applyForFundingState = useSelector((s) => s.applyForFunding);
  const profile = useSelector((s) => s.getProfile.data);
  const getSingleAccountState = useSelector((s) => s.getSingleAccount);
  const [initialValues, setInitialValues] = useState({});
  const history = useHistory();

  const steps = [
    {
      title: "Funding",
      icon: "nc-icon nc-touch-id",
      component: <Step1 ref={step1Ref} values={step1Values} />,
    },
    {
      title: "About",
      icon: "nc-icon nc-single-02",
      component: <Step2 ref={step2Ref} values={step2Values} />,
    },
    {
      title: "Business",
      icon: "nc-icon nc-pin-3",
      component: (
        <Step3 ref={step3Ref} values={step3Values} initialVal={initialValues} />
      ),
    },
  ];

  const validateAndNext = async (ref, setState) => {
    ref.current.handleSubmit();
    const isValid = await ref.current.isValid();
    if (isValid) {
      setState(ref.current.getValues());
      setCurrent(current + 1);
    }
  };

  const next = () => {
    switch (current) {
      case 0:
        validateAndNext(step1Ref, setStep1Values);
        break;
      case 1:
        validateAndNext(step2Ref, setStep2Values);
        break;
      default:
        break;
    }
  };

  const prev = () => {
    setCurrent(current - 1);
    switch (current) {
      case 1:
        setStep2Values(step2Ref.current.getValues());
        break;
      case 2:
        setStep3Values(step3Ref.current.getValues());
        break;
      default:
        break;
    }
  };

  const handleSubmit = async () => {
    step3Ref.current.handleSubmit();
    const isValid = await step3Ref.current.isValid();

    if (isValid) {
      const thirdStepValues = step3Ref.current.getValues();
      setStep3Values(thirdStepValues);
      const payload = {
        ...step1Values,
        ...step2Values,
        ...thirdStepValues,
      };
      dispatch(applicantApplyForFunding(payload));
    }
  };
  useEffect(() => {
    if (applyForFundingState.isSuccessful) {
      message.success("Application submitted successfully");
      setStep1Values({});
      setStep2Values({});
      setStep3Values({});
      setCurrent(0);
      dispatch(applyForFundingCleanup());
      history.push("/user/application-success");
    } else if (applyForFundingState.error) {
      message.error("Oops! Application submission failed");
      dispatch(applyForFundingCleanup());
    }
  }, [applyForFundingState]);

  useEffect(() => {
    dispatch(getSingleAccount(profile.AccountId));
  }, []);

  useEffect(() => {
    if (getSingleAccountState.isSuccessful) {
      setInitialValues(getSingleAccountState.data[0]);
      dispatch(getSingleAccountCleanup());
    } else if (getSingleAccountState.error) {
      dispatch(getSingleAccountCleanup());
    }
  }, [getSingleAccountState]);

  useEffect(() => {
    const userPath = window.location.pathname + window.location.search;
    const splitPath = userPath.split("/");
    let title = "";

    if (splitPath === "user") {
      title = "User fund application";
    } else {
      title = "Public fund application";
    }

    ReactGA.pageview(
      `portal/${window.location.pathname + window.location.search}`,
      [],
      title
    );
  }, []);

  return (
    <div className="pt-5 container applicant-application-form-page">
      <div className="pt-5 table-container">
        <Card>
          <CardTitle className="text-cente p-4">
            <div className="sos-text-primary mb-0 font-weight-bold d-flex justify-content-between">
              <div>Apply for Funding</div>
            </div>
          </CardTitle>
        </Card>
        <hr />
        <Row>
          <Col className="mr-auto ml-auto" sm="12">
            <Card>
              <CardTitle className="text-center p-4">
                <h2 className="sos-text-primary mb-0 font-weight-bold">
                  Apply For Funding
                </h2>
                <h6 className="text-capitalize">Get Funded Fast</h6>
              </CardTitle>
              <CardBody className="p-5">
                <Loader
                  isLoading={applyForFundingState.isLoading}
                  text="Submitting application..."
                >
                  <Steps current={current}>
                    {steps.map((item) => (
                      <Step key={item.title} title={item.title} />
                    ))}
                  </Steps>
                  <div className="steps-content">
                    {steps[current].component}
                  </div>
                  <div className="steps-action mt-5">
                    {current > 0 && (
                      <Button
                        style={{ margin: "0 8px 0 0" }}
                        onClick={() => prev()}
                      >
                        Previous
                      </Button>
                    )}
                    {current < steps.length - 1 && (
                      <Button type="primary" onClick={() => next()}>
                        Next
                      </Button>
                    )}
                    {current === steps.length - 1 && (
                      <Button
                        type="primary"
                        onClick={handleSubmit}
                        disabled={applyForFundingState.isLoading}
                      >
                        Done
                      </Button>
                    )}
                  </div>
                </Loader>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default FundApplication;
