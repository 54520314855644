import * as types from "../../action-types";
import ErrorHandler from "../../../utils/error-handler";

export const createWebToLeadStart = () => ({
  type: types.CREATE_WEB_TO_LEAD_START,
});

export const createWebToLeadSuccess = (payload) => ({
  type: types.CREATE_WEB_TO_LEAD_SUCCESS,
  payload,
});

export const createWebToLeadFail = (payload) => ({
  type: types.CREATE_WEB_TO_LEAD_FAIL,
  payload,
});

export const createWebToLeadCleanup = () => ({
  type: types.CREATE_WEB_TO_LEAD_CLEANUP,
});

export const createWebToLead =
  (
    first_name,
    last_name,
    city,
    email,
    phone,
    company,
    title,
    contactpreference,
    country,
    state,
    street,
    zip,
    dba,
    legalname,
    sponsorshiptype
  ) =>
  async (dispatch) => {
    dispatch(createWebToLeadStart());

    const requestOptions = {
      method: "POST",
      mode: "no-cors",
    };

    fetch(
      `https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8&oid=00D61000000dPny&recordType=01261000000kxIZ&retURL=https://google.com&first_name=${first_name}&last_name=${last_name}&city=${city}&email=${email}&phone=${phone}&company=${company}&title=${title}&00N6100000CJqS7=${contactpreference}&country=${country}&state=${state}&street=${street}&city=${city}&zip=${zip}&00N6100000I9srZ=${dba}&00N6100000I9srm=${legalname}&00N6100000I9OEM=${sponsorshiptype}`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => dispatch(createWebToLeadSuccess(result)))
      .catch((error) => dispatch(createWebToLeadFail(ErrorHandler(error))));
  };
